/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/edit-expert/view-reviews'] || initialState;

export const selectViewReviewsState = createSelector([selectDomain], (ViewReviewsState) => ViewReviewsState);
export const selectReviews = createSelector([selectDomain], (ViewReviewsState) => ViewReviewsState.reviews);
export const selectReviewLoader = createSelector([selectDomain], (ViewReviewsState) => ViewReviewsState.stateLoading);
export const selectLoader = createSelector([selectDomain], (ViewReviewsState) => ViewReviewsState.loading);
export const selectExpertId = createSelector([selectDomain], (ViewReviewsState) => ViewReviewsState.expertId);
export const selectAverageRating = createSelector([selectDomain], (ViewReviewsState) => ViewReviewsState.averageRating);
export const selectTotal = createSelector([selectDomain], (ViewReviewsState) => ViewReviewsState.total);
