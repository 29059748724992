import { AccessTimeFilled } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { durationFormat } from 'utils/formats';

interface ActivityCardProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  className?: string;
  date:string,
}
const ActivityCard = ({
  children, date, sx, className,
}:ActivityCardProps) => (
  <Box
    sx={{
      padding: '1rem',
      ...(Array.isArray(sx) ? sx : [sx]),
    }}
    className={`${className}`}
  >
    {children}
    <div>
      <div className="inline-wrapper">
        <AccessTimeFilled sx={{ color: '#b4b4b4', fontSize: '1rem', mr: '0.5rem' }} />
        {' '}
        <Typography variant="body1">{durationFormat(date)}</Typography>
      </div>
    </div>

  </Box>
);
ActivityCard.defaultProps = {
  sx: [],
  className: '',
};
export default ActivityCard;
