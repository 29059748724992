import { PayloadAction } from '@reduxjs/toolkit';
import { IRestApiResponse } from 'interfaces';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { request } from 'utils/request';
import API from '../../constant';
import { IModifyPluginStatePayload } from './interface';
import { viewPluginActions } from './slice';

export function* viewPluginsGenerator(): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      { path: API.GET_PUGINS_BY_ENT.path, method: API.GET_PUGINS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewPluginActions.viewPluginsSucceeded(response.data));
    } else {
      yield put(viewPluginActions.viewPluginsFailed());
    }
  } catch (error) {
    yield put(viewPluginActions.viewPluginsFailed());
  }
}

export function* modifyPluginStateGenerator({
  payload,
}: PayloadAction<IModifyPluginStatePayload>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.MODIFY_PLUGIN_STATUS.path.replace(':id', payload.id),
        method: API.MODIFY_PLUGIN_STATUS.method,
      },
      {
        isActive: payload.isActive,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewPluginActions.viewPlugins());
    } else {
      yield put(viewPluginActions.viewPluginsFailed());
    }
  } catch (error) {
    yield put(viewPluginActions.viewPluginsFailed());
  }
}

export function* pluginSaga() {
  yield all([takeLatest(viewPluginActions.viewPlugins.type, viewPluginsGenerator)]);
  yield all([takeLatest(viewPluginActions.modifyPluginState.type, modifyPluginStateGenerator)]);
}

export default pluginSaga;
