/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltered } from 'interfaces/api-response';
import {
  ViewContentState, ModalStates,
  ContentDocument, ICreateContentModalForm, IDeleteContent, IOpenModal, IContentFilterPayload,
} from './interface';

export const initialState: ViewContentState = {
  filteredContent: { result: [], total: 0 },
  stateLoading: false,
  expertId: '',
  modal: '',
  loading: false,
};

export const viewContentSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/edit-expert/content',
  initialState,
  reducers: {
    openModal(state: ViewContentState, action:PayloadAction<string>) {
      state.modal = action.payload;
    },
    closeModal(state: ViewContentState) {
      state.modal = ModalStates.close;
    },
    getContent(state:ViewContentState, _action:PayloadAction<IContentFilterPayload>) {
      state.stateLoading = true;
    },
    getContentSucceeded(state:ViewContentState, action:PayloadAction<IFiltered<ContentDocument>>) {
      state.filteredContent.result = action.payload.result.map((content) => ({
        ...content,
        modalState: ModalStates.close,

      }));
      state.filteredContent.total = action.payload.total;
      state.stateLoading = false;
      state.modal = ModalStates.close;
      state.loading = false;
    },
    getContentFailed(state:ViewContentState) {
      state.stateLoading = false;
    },
    openConfirmationModal(state:ViewContentState, action:PayloadAction<IOpenModal>) {
      const temp = state.filteredContent.result;
      temp.forEach((contentItem) => {
        if (contentItem._id === action.payload.id) {
          contentItem.modalState = action.payload.state;
        }
      });
      state.filteredContent.result = [...temp];
    },
    closeConfirmationModal(state:ViewContentState, action:PayloadAction<string>) {
      const temp = state.filteredContent.result;
      temp.forEach((contentItem) => {
        if (contentItem._id === action.payload) {
          contentItem.modalState = ModalStates.close;
        }
      });
      state.filteredContent.result = [...temp];
    },

    createContent(state:ViewContentState, _action:PayloadAction<ICreateContentModalForm>) {
      state.loading = true;
    },
    createContentSucceeded(state:ViewContentState, action:PayloadAction<ContentDocument>) {
      const content = action.payload;
      state.filteredContent.result = [
        { ...content, modalState: ModalStates.close },
        ...state.filteredContent.result,
      ];
      state.loading = false;
      state.modal = ModalStates.close;

      // to trigger reloading:
      state.stateLoading = true;
    },
    createContentFailed(state:ViewContentState) {
      state.loading = false;
    },
    deleteContent(state:ViewContentState, _action:PayloadAction<IDeleteContent>) {
      state.loading = true;
    },
    deleteContentSucceeded(state:ViewContentState, action:PayloadAction<string>) {
      const id = action.payload;
      const content = state.filteredContent
        .result.filter((contentItem) => (contentItem._id !== id));
      state.filteredContent.total -= state.filteredContent.total;
      state.modal = ModalStates.close;
      state.filteredContent.result = [...content];
      state.loading = false;
    },
    deleteContentFailed(state:ViewContentState) {
      state.loading = false;
    },

    setExpertId(state:ViewContentState, action: PayloadAction<string>) {
      state.expertId = action.payload;
    },
  },
});

export const { actions: viewContentActions } = viewContentSlice;
