/* eslint-disable react/require-default-props */
import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridColumns } from '@mui/x-data-grid';
import {
  ClosableModal,
  PrimaryChip,
  PrimaryChipsSlider,
  PrimaryLiteButton, PrimaryLoadingButton, PrimaryOutlinedButton, PrimaryTable, SearchBar,
} from 'components';
import { ClipboardTextIcon } from 'assets/icons';
import { FormSubmissionTypes } from 'config';
import { ISessionFormAvatarWithAction } from '../../../../../interface';
import { IForm } from '../../../../../../forms/interface';

interface SelectFormModalProps {
  selectedForms:ISessionFormAvatarWithAction[],
  filteredForm:IForm[],
  total:number,
  loading:boolean,
  handleUpdate:(formList:ISessionFormAvatarWithAction[])=>void,
  searchFunction:(keyword:string, page:number)=>void,
  selectOne?:boolean,
  maxSelect?:number,
  title?:string,
  subTitle?:string

}

const SelectFormModal = ({
  selectedForms, filteredForm, total, loading, handleUpdate, searchFunction, title = 'Select Form', subTitle = '',
  selectOne = false, maxSelect = 0,
}:SelectFormModalProps) => {
  type Row = typeof forms[number];

  // initiate states
  const [open, setOpen] = React.useState<boolean>(false);
  const [keyword, setKeyword] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [selectedItems, setSelectedItems] = React.useState<ISessionFormAvatarWithAction[]>(
    selectedForms,
  );
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [forms, setForms] = React.useState<ISessionFormAvatarWithAction[]>(
    filteredForm?.map((form) => ({
      ...form,
      id: form._id,
      submission: FormSubmissionTypes.undefined,
      isAdded: (selectedIds.includes(form._id)),
    })),
  );

  // handle modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  // handle search
  const handleSearchChange = (event:any) => {
    setKeyword(event.target.value);
  };

  // handle mutations
  const handleRemoveItem = (id:string) => {
    setSelectedItems((prev) => ([...prev.filter((form) => form.id !== id)]));
  };

  const addForm = (id:string) => {
    const temp = [...forms];
    const index = temp.findIndex(((item:ISessionFormAvatarWithAction) => item.id === id));
    temp[index].isAdded = true;

    // updating the chips
    const tempSelectItems = selectOne ? [temp[index]] : [...selectedItems, temp[index]];
    setSelectedItems(tempSelectItems);
  };

  // update mutations
  const handleUpdateWrapper = () => {
    handleUpdate(selectedItems);
    setOpen(false);
  };

  const createNewForm = () => {
    window.open('../../forms?initiatedBy=session');
  };
  // configure table
  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'formTitle',
        headerName: 'Form Title',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sx={{ mr: 0.7 }}>
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      mr: 1,
                      backgroundColor: 'background.default',
                      color: 'primary.main',
                      borderWidth: 1,
                      borderColor: 'primary.light',
                      borderStyle: 'solid',
                    }}
                  >
                    <ClipboardTextIcon sx={{ fontSize: 16 }} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h4" sx={{ fontWeight: '400' }}>{params.value}</Typography>
                </Grid>

              </Grid>
            </Grid>

          </Grid>

        ),
      },
      {
        field: 'isAdded',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        flex: 0.5,
        renderCell: (params) => (
          <div>
            {params.value ? (
              <PrimaryOutlinedButton>
                Added
              </PrimaryOutlinedButton>
            ) : (
              <>
                {maxSelect > 0 && selectedItems.length < maxSelect && (
                <PrimaryOutlinedButton onClick={() => { addForm(params.row.id); }}>
                  Add
                </PrimaryOutlinedButton>
                )}
                {maxSelect === 0 && (
                <PrimaryOutlinedButton onClick={() => { addForm(params.row.id); }}>
                  Add
                </PrimaryOutlinedButton>
                )}
              </>

            )}

          </div>
        ),
      },

    ],
    [addForm],
  );

  React.useEffect(() => {
    searchFunction(keyword, page);
  }, []);

  React.useEffect(() => {
    searchFunction(keyword, page);
  }, [keyword, page]);

  React.useEffect(() => {
    if (filteredForm) {
      const temp = filteredForm?.map((form) => ({
        ...form,
        id: form._id,
        submission: FormSubmissionTypes.undefined,
        isAdded: (selectedIds.includes(form._id)),
      }));
      setForms(JSON.parse(JSON.stringify(temp)) as ISessionFormAvatarWithAction[]);
    }
  }, [filteredForm, selectedIds]);

  React.useEffect(() => {
    setSelectedIds(selectedItems.map((form:ISessionFormAvatarWithAction) => form.id));
  }, [selectedItems]);

  React.useEffect(() => {
    setSelectedItems(selectedForms);
  }, [selectedForms]);

  return (
    <>
      <PrimaryOutlinedButton
        onClick={handleOpen}
        startIcon={<ClipboardTextIcon />}
      >
        Add form
      </PrimaryOutlinedButton>
      <ClosableModal
        open={open}
        onClose={handleClose}
        title={title}
        subTitle={subTitle}
        modalClass="select-item-modal"
      >

        <div className="form-item">
          <SearchBar
            value={keyword}
            onChange={handleSearchChange}
            placeholder="Search by form name"
          />
        </div>
        <div className="form-item">
          {selectedItems.length > 0 ? (
            <PrimaryChipsSlider>
              {selectedItems.map((item) => (
                <PrimaryChip
                  key={item.id}
                  avatar={(
                    <Avatar
                      sx={{
                        backgroundColor: 'background.default',
                        borderColor: 'primary.light',
                      }}
                      src=""
                    />
                  )}
                  label={item.formTitle}
                  variant="outlined"
                  onDelete={() => { handleRemoveItem(item.id); }}
                />

              ))}
            </PrimaryChipsSlider>

          ) : ''}
        </div>
        <div className="form-item">
          <PrimaryTable
            disableSelectionOnClick
            rows={forms}
            columns={columns}
            loading={loading}
            page={page}
            pageSize={3}
            rowsPerPageOptions={[3]}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            rowCount={total}
            paginationMode="server"
            getRowId={(row) => row._id}
          />
        </div>
        <div style={{ float: 'left' }}>
          <PrimaryOutlinedButton size="large" fullWidth onClick={createNewForm}>
            <AddIcon sx={{ margin: '0 0.5rem' }} />
            Create new form
          </PrimaryOutlinedButton>
        </div>

        <Grid container spacing={3}>
          <Grid item xs>
            <PrimaryLiteButton fullWidth onClick={handleClose}>Cancel</PrimaryLiteButton>
          </Grid>
          <Grid item xs>
            <PrimaryLoadingButton loading={false} fullWidth onClick={handleUpdateWrapper}>
              Add & continue
            </PrimaryLoadingButton>
          </Grid>

        </Grid>
      </ClosableModal>

    </>

  );
};

export default SelectFormModal;
