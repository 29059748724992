import { ClosableModal, PrimaryLoadingButton } from 'components';
import './index.scss';

export interface IResendInvite {
  email:string,
  open:boolean,
  onClose:()=>void,
  resend:()=>void,
  loading:boolean
}
const ResendInvite = ({
  email, open, onClose, resend, loading,
}:IResendInvite) => (
  <ClosableModal
    title={` ${email} 
    has already been invited. Do you want to resend an invite?`}
    subTitle=""
    open={open}
    onClose={onClose}
    modalClass="resend-invite"
  >
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      <PrimaryLoadingButton loading={loading} onClick={resend}> Resend</PrimaryLoadingButton>
    </div>

  </ClosableModal>
);

export default ResendInvite;
