import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

interface PrimaryAccordionProp {
  summary:React.ReactNode;
  details:React.ReactNode;
}
const PrimaryAccordion = ({ summary, details }:PrimaryAccordionProp) => (
  <Accordion sx={{ borderRadius: '6px', border: '1px solid #E5E5E5' }}>
    <AccordionSummary expandIcon={<ArrowDropDownIcon sx={{ color: '#B2B2B2' }} />}>
      {summary}
    </AccordionSummary>
    <AccordionDetails>
      {details}
    </AccordionDetails>
  </Accordion>
);
export default PrimaryAccordion;
