/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltered } from 'interfaces/api-response';
import { LiveClassTypes } from 'config';
import {
  CancelSessionParams,
  IActive,
  IGenerateSession,
  ISession,
  ModalState,
  ViewSessionState,
} from './interface';
import { IDeleteSession, IPaginateFilterPayload } from '../view-sessions/interface';

const frequency = {
  startAt: '',
  endAt: '',
  startDate: '',
  endDate: '',
  dayOfTheMonth: '',
  dayOfTheWeek: '',
  frequency: 'One time',
};

const waitingRoomMedia = {
  fileName: '',
  thumbnailUrl: '',
  contentUrl: '',
  fileType: '',
};

const badge = {
  title: '',
  description: '',
  url: '',
};

export const initialState: ViewSessionState = {
  stateLoading: false,
  loading: false,
  sessionId: '',
  session: {
    id: '',
    isActive: false,
    deleted: false,
    sessionType: '',
    audienceType: '',
    title: '',
    description: '',
    numberOfSessions: 0,
    waitingRoomMedia,
    numberOfParticipants: 0,
    isObserverAllowed: false,
    numberOfObservers: 0,
    sessionMeta: {
      liveClassType: LiveClassTypes.interactive,
      startDate: '',
      endDate: '',
    },
    availableTimes: [],
    frequency,
    entId: {
      entUrl: '',
      entLogoUrl: '',
      webLogoUrl: '',
      _id: '',
      name: '',
    },
    expert: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      profileImgUrl: '',
      attributes: {
        isActive: false,
        bio: '',
        category: '',
        rating: 0,
        noOfRatings: 0,
      },
    },
    fee: '0',
    discount: '0',
    currency: 'USD',
    observerFee: '0',
    observerDiscount: '0',
    observerCurrency: 'USD',
    subscriberFee: '0',
    subscriberCurrency: 'USD',
    subscriberDiscount: '0',
    subscribeObserverFee: '0',
    subscribeObserverCurrency: 'USD',
    subscribeObserverDiscount: '0',
    forms: [],
    media: [],
    language: 'English',
    summary: [],
    updates: [],
    modules: [],
    badge: { badgeTitle: '', badgeDescription: '', badgeImgUrl: '' },
    statistics: {
      allTimeSessionPurchased: 0,
      weeklyTimeSessionPurchased: 0,
      userEnrolled: 0,
      completed: 0,
    },
    purchaseHistory: [],
    bookedSessions: 0,
    bookedObservers: 0,
    availableSessions: 0,
    availableParticipants: 0,
    availableObservers: 0,
    invitations: [],
  },
  sessionUrl: '',
  invitePeople: [],
  subStateLoading: false,
  filteredSessions: { result: [], total: 0 },
  modalState: ModalState.close,
};

export const viewSessionSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-session',
  initialState,
  reducers: {
    setModalState(state: ViewSessionState, action: PayloadAction<string>) {
      state.modalState = action.payload;
    },
    getSession(state: ViewSessionState) {
      state.stateLoading = true;
    },
    getSessionsSucceeded(state: ViewSessionState, action: PayloadAction<ISession>) {
      state.session = action.payload;
      state.stateLoading = false;
    },
    getSessionsFailed(state: ViewSessionState) {
      state.stateLoading = false;
    },
    getFilteredSessions(state: ViewSessionState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredSessionsSucceeded(
      state: ViewSessionState,
      action: PayloadAction<IFiltered<ISession>>,
    ) {
      state.filteredSessions = action.payload;
      state.subStateLoading = false;
    },
    getFilteredSessionsFailed(state: ViewSessionState) {
      state.subStateLoading = false;
    },
    setSessionId(state: ViewSessionState, action: PayloadAction<string>) {
      state.sessionId = action.payload;
    },
    getSessionUrl(state: ViewSessionState, _action: PayloadAction<IGenerateSession>) {
      state.loading = true;
    },
    getSessionUrlSucceeded(state: ViewSessionState, action: PayloadAction<string>) {
      state.loading = false;
      state.sessionUrl = action.payload;
    },
    getSessionUrlFailed(state: ViewSessionState) {
      state.loading = false;
    },
    clearSessionUrl(state: ViewSessionState) {
      state.sessionUrl = '';
    },
    addInvite(state: ViewSessionState, action: PayloadAction<string>) {
      const temp = Array.from(new Set([...state.invitePeople, action.payload]));
      state.invitePeople = temp;
    },
    removeInvite(state: ViewSessionState, action: PayloadAction<string>) {
      const temp = state.invitePeople;
      state.invitePeople = temp.filter((item) => item !== action.payload);
    },
    inviteUser(state: ViewSessionState) {
      state.loading = true;
    },
    inviteUserFailed(state: ViewSessionState) {
      state.loading = false;
    },
    inviteUserSucceeded(state: ViewSessionState) {
      state.loading = false;
      state.invitePeople = [];
    },
    editActiveSession(state: ViewSessionState, _action: PayloadAction<IActive>) {
      state.loading = true;
      state.modalState = ModalState.active;
    },
    editActiveSessionFailed(state: ViewSessionState) {
      state.loading = false;
    },
    editActiveSessionSucceeded(state: ViewSessionState) {
      state.loading = false;
      state.session.isActive = !state.session.isActive;
      state.modalState = ModalState.close;
    },

    deleteSession(state: ViewSessionState, _action: PayloadAction<IDeleteSession>) {
      state.loading = true;
      state.modalState = ModalState.deleteComplete;
    },
    deleteSessionFailed(state: ViewSessionState) {
      state.loading = false;
    },
    deleteSessionSucceeded(state: ViewSessionState) {
      state.loading = false;
    },
    resendInvite(state: ViewSessionState, _action: PayloadAction<string>) {
      state.loading = true;
    },
    resendInviteSucceeded(state: ViewSessionState) {
      state.loading = false;
      state.modalState = ModalState.close;
    },
    resendInviteFailed(state: ViewSessionState) {
      state.loading = false;
    },
    cancelSession(state: ViewSessionState, _action: PayloadAction<CancelSessionParams>) {
      state.loading = true;
    },
    cancelSessionSuccess(state: ViewSessionState) {
      state.loading = false;
      state.modalState = ModalState.cancelComplete;
    },
    cancelSessionFailed(state: ViewSessionState) {
      state.loading = false;
    },
    reset: () => initialState,
  },
});

export const { actions: viewSessionActions } = viewSessionSlice;
