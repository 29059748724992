/* eslint-disable react/require-default-props */
import React from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControlLabel,
  Grid, RadioGroup, Typography,
} from '@mui/material';
import {
  ErrorMessage,
  HintText,
  Label,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimaryRadio,
} from 'components';
import { CourseTypes, SessionType } from 'config';
import { ISessionTypeModalForm } from '../../../../../interface';
import './index.scss';

interface SessionTypeModalFormProps {
  courseOnlySession: boolean;
  onSubmit: SubmitHandler<any>;
  closeModal: () => void;
  courseType?:string

}

const SessionTypeModalForm = ({
  courseOnlySession,
  onSubmit,
  closeModal,
  courseType = '',
}: SessionTypeModalFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useFormContext<ISessionTypeModalForm>();

  const sessionTypeValues = React.useMemo(() => {
    if (courseOnlySession && courseType === CourseTypes.lsm) {
      return [SessionType.liveClass, SessionType.content];
    }
    if (courseOnlySession && courseType === CourseTypes.interactive) {
      return [SessionType.liveClass, SessionType.content, SessionType.liveSession];
    }

    return Object.values(SessionType);
  }, [courseType]);

  const [selectedType, setSelectedType] = React.useState(sessionTypeValues[0]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as SessionType;
    setSelectedType(value);
    setValue('sessionType', value);
  };

  React.useEffect(() => {
    setValue('sessionType', selectedType);
  }, [selectedType, setValue]);

  const sessionTypeDescriptions = [
    {
      type: SessionType.digitalSession,
      description: 'One to one digital analysis session',
    },
    {
      type: SessionType.liveSession,
      description: 'One to one real time analysis session',
    },
    {
      type: SessionType.liveClass,
      description: 'One to many real time session',
    },
    {
      type: SessionType.course,
      description: 'Series of sessions and etc',
    },
    {
      type: SessionType.content,
      description: 'Publish your content',
    },
  ];

  const handleForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={handleForm}>
      <Typography variant="h4" className="form-item">
        Select session type
      </Typography>
      <div className="form-item">
        <Controller
          control={control}
          name="sessionType"
          render={({ field }) => (
            <RadioGroup {...field} value={selectedType}>
              <div className="form-item">
                <Label>{courseOnlySession ? 'Select the type of session you want to add to this course.' : 'Select the type of session you want to create.'}</Label>
              </div>

              <Grid container justifyContent="start-flex" spacing={2}>
                {sessionTypeValues.map((type) => (
                  <Grid item xs={6} key={type}>
                    <Box className="radio-button-box">
                      <FormControlLabel
                        value={type}
                        control={(
                          <PrimaryRadio
                            checked={selectedType === type}
                            onChange={handleRadioChange}
                          />
                        )}
                        label={type}
                      />
                      <div>
                        <HintText>
                          {' '}
                          {sessionTypeDescriptions.find((std) => std.type === type)?.description}
                          {' '}
                        </HintText>
                      </div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          )}
        />

        <ErrorMessage>{errors?.sessionType?.message}</ErrorMessage>
      </div>

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={closeModal}>Cancel</PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton loading={false} fullWidth type="submit">
            Continue
          </PrimaryLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SessionTypeModalForm;
