/* eslint-disable react/require-default-props */
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface TabPanelProps {
  children: React.ReactNode;
  index: number | string;
  value: number | string;
  sx?: SxProps<Theme>
}

function TabPanel(props: TabPanelProps) {
  const defaultStyle:SxProps<Theme> = { p: 3 };
  const {
    children, value, index, sx = defaultStyle, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`create-ent-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box className="tabpanel-inner" sx={sx}>
        <div>{children}</div>
      </Box>
      )}
    </div>
  );
}
export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default TabPanel;
