import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import './styles.scss';

interface Props {
  children: JSX.Element[];
}

const PrimaryChipsSlider = ({ children } : Props) => (
  <Swiper
    slidesPerView="auto"
    spaceBetween={8}
    grabCursor
    loop={false}
    className="primaryChipsSlider"
  >
    { React.Children.map(children, (child) => (
      <SwiperSlide>{child}</SwiperSlide>
    )) }
  </Swiper>
);

export default PrimaryChipsSlider;
