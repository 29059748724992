import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Grid, IconButton, Modal,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrimaryLoadingOutlinedButton } from 'components';
import { ContentImageDataValidation } from 'helpers/validations';
import { ISponsorContent } from 'pages/dashboard/components/ent-account/interface';
import { ModalStates } from '../../../../../interface';
import { selectModal } from '../../../../../selector';
import { onboardEntAccActions } from '../../../../../slice';
import OnboardSponsorLogoModalForm from '../upload-sponsor-logo-modal-form';

interface OnboardSponsorLogoModalProps {
  index: number;
  addSponsorLogo:(url:string, contentUrl:string)=>void
}

const OnboardSponsorLogoModal = ({ index, addSponsorLogo }: OnboardSponsorLogoModalProps) => {
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const methods = useForm<ISponsorContent>({
    defaultValues: {
      contentUrl: '',
      url: '',
    },
    resolver: yupResolver(ContentImageDataValidation),
    shouldFocusError: true,
  });

  const handleOpen = () => {
    dispatch(onboardEntAccActions.openModal(ModalStates.sponsorContent));
  };
  const handleClose = () => {
    dispatch(onboardEntAccActions.closeModal());
    methods.reset();
  };
  const onSubmit: SubmitHandler<ISponsorContent> = React.useCallback(
    (data) => {
      addSponsorLogo(data.url, data.contentUrl);
      handleClose();
    },
    [dispatch],
  );

  return (
    <Grid container justifyContent="flex-start">
      <Grid item>
        <PrimaryLoadingOutlinedButton sx={{ padding: '0.5rem 0' }} onClick={handleOpen}>
          <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
          Upload sponsor logos
        </PrimaryLoadingOutlinedButton>
      </Grid>

      <Modal open={modal === ModalStates.sponsorContent}>
        <Box className="modal-scrollable">
          <IconButton aria-label="close" sx={{ float: 'right' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <FormProvider {...methods}>
            <OnboardSponsorLogoModalForm
              onSubmit={onSubmit}
              closeModal={handleClose}
              index={index}
            />
          </FormProvider>
        </Box>
      </Modal>
    </Grid>
  );
};
export default OnboardSponsorLogoModal;
