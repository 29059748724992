import { IUserDeleteCheckRequest, PollingRequestStatus } from 'interfaces/base-user';
import { AdminDocument } from '../../interface';

export enum ModalStates {
  close = 'CLOSE',
  resetPassword = 'RESET_PASSWORD',
  delete = 'DELETE',
}
export interface IAdmin extends AdminDocument {
  modalState: string;
  isDeleted: boolean;
}

export interface ViewAdminState {
  stateLoading: boolean;
  loading: boolean;
  adminListLoading: boolean;
  deleteCheckStatus: PollingRequestStatus;
  deleteCheckId: string;
  deleteCheck: IUserDeleteCheckRequest;
  adminList: IAdmin[]; // store all the admins of the ent id for selectors
  entId: string;
  admins: { result: IAdmin[]; total: number };
}
export interface IAdminRequest {
  total: number;
  result: AdminDocument[];
}
export interface IOpenModal {
  id: string;
  state: ModalStates;
}

export interface IDeleteAdmin {
  userId: string;
  newResourceOwner: string;
}

export interface IAdminFilterPayload {
  keyword: string;
  authorityLevel: string;
  page: number;
  limit: number;
  withDeleted?: boolean;
  status: string;
}
