import { ArticleDocument } from '../../interface';

export enum ModalStates {
  remove = 'REMOVE',
  publish = 'PUBLISH',
  close = 'CLOSE',
}
export interface IArticle extends ArticleDocument {
  modalState:ModalStates

}

export interface ViewArticleState {
  stateLoading:boolean,
  loading:boolean,
  entId:string,
  articles: { result:IArticle[], total:number }

}
export interface IArticleRequest {
  total:number
  result: ArticleDocument[]
}

export interface IPublishArticle {
  id:string, isActive:boolean
}

export interface IOpenModal {
  id:string,
  state:ModalStates
}

export interface IArticleFilterPayload {
  keyword: string;
  page: number;
  limit: number;
  sortBy: string
}
