import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Box, Typography } from '@mui/material';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './styles.scss';

const CustomRichTextField = React.forwardRef(
  ({
    onChange, value, label, placeholder, defaultValue = '', options, toolbarCustomButtons, editorStyle,
  }: any) => {
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

    React.useEffect(() => {
      const contentBlock = htmlToDraft((value.startsWith('<img>') ? `<p>${value}</p>` : value) || '');
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }, []);

    React.useEffect(() => {
      const contentBlock = htmlToDraft((value.startsWith('<img>') ? `<p>${defaultValue}</p>` : defaultValue) || '');
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }, [defaultValue]);

    React.useEffect(() => {
      if (onChange) {
        let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        content = content.replace(/<div style="text-align:none;"><img/g, '<div style="text-align:center;"><img');
        onChange(content);
      }
    }, [editorState]);

    return (
      <Box>
        <Typography variant="body1">{label}</Typography>
        <Editor
          stripPastedStyles
          placeholder={placeholder}
          editorState={editorState}
          toolbar={options === undefined ? {
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'emoji', 'history', 'remove'],
          } : options}
          toolbarClassName="richTextToolbar"
          wrapperClassName="richTextWrapper"
          editorClassName="richTextEditor"
          onEditorStateChange={setEditorState}
          toolbarCustomButtons={toolbarCustomButtons}
          editorStyle={editorStyle}
        />

      </Box>
    );
  },
);

export default CustomRichTextField;
