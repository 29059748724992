import * as React from 'react';
import Box from '@mui/material/Box';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { PrimaryLoadingOutlinedButton } from 'components';
import { ICreateAdminModalForm } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/components/create-admin/interface';
import { AuthorityLevels } from 'config';
import { selectModal } from '../../../../../selector';
import OnboardCreateAdminModalForm from '../add-new-admin-modal-form';
import { onboardEntAccActions } from '../../../../../slice';
import { ModalStates } from '../../../../../interface';
import createAdminModalFormValidation from '../validation/add-new-admin-modal-form-validation';

interface OnboardCreateAdminModalProps {
  index: number;
}

const OnboardCreateAdminModal = ({ index }: OnboardCreateAdminModalProps) => {
  const modal = useSelector(selectModal);
  const dispatch = useDispatch();
  const methods = useForm<ICreateAdminModalForm>({
    defaultValues: {
      profileImgUrl: '',
      firstName: '',
      lastName: '',
      email: '',
      authorityLevel: AuthorityLevels.CLIENT_ADMIN,
      accessRecordings: false,
      accessClasses: false,
      authenticateComplaints: false,
    },
    shouldFocusError: true,
    resolver: yupResolver(createAdminModalFormValidation),
  });
  const handleOpen = () => {
    dispatch(onboardEntAccActions.openModal(ModalStates.addAdmin));
  };
  const handleClose = () => {
    dispatch(onboardEntAccActions.closeModal());
    methods.reset();
  };

  const onSubmit: SubmitHandler<ICreateAdminModalForm> = React.useCallback(
    (data) => {
      dispatch(onboardEntAccActions.createAdmin(data));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (modal === ModalStates.close) { methods.reset(); }
  }, [modal]);

  return (
    <Grid container justifyContent="flex-start">
      <Grid item>
        <PrimaryLoadingOutlinedButton sx={{ padding: '0.5rem 0' }} onClick={handleOpen}>
          <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
          Add new admin
        </PrimaryLoadingOutlinedButton>
      </Grid>

      <Modal open={modal === ModalStates.addAdmin}>
        <Box className="modal-scrollable">
          <IconButton aria-label="close" sx={{ float: 'right' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <FormProvider {...methods}>
            <OnboardCreateAdminModalForm onSubmit={onSubmit} closeModal={handleClose} />
          </FormProvider>
        </Box>
      </Modal>
    </Grid>
  );
};

export default OnboardCreateAdminModal;
