/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/gift-cards/view-users'] || initialState;

export const selectViewGiftCardUsers = createSelector([selectDomain], (ViewGiftCardUsersState) => ViewGiftCardUsersState);
export const selectStateLoader = createSelector([selectDomain], (ViewGiftCardUsersState) => ViewGiftCardUsersState.stateLoading);
export const selectLoader = createSelector([selectDomain], (ViewGiftCardUsersState) => ViewGiftCardUsersState.loading);
export const selectId = createSelector([selectDomain], (ViewGiftCardUsersState) => ViewGiftCardUsersState.id);
export const selectUserVouchers = createSelector([selectDomain], (ViewGiftCardUsersState) => ViewGiftCardUsersState.userVouchers);
export const selectModalState = createSelector([selectDomain], (ViewGiftCardUsersState) => ViewGiftCardUsersState.modal);
export const selectGiftCard = createSelector([selectDomain], (ViewGiftCardUsersState) => ViewGiftCardUsersState.giftCard);
