const API = {
  POST_UPDATE_USER: {
    path: '/user/update',
    method: 'POST',
  },
  GET_USER: {
    path: '/user/details',
    method: 'GET',
  },
  DELETE_USER: {
    path: '/user-delete',
    method: 'DELETE',
  },
};
export default API;
