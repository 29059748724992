import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { SessionAudienceTypes } from 'config';
import SessionAvailabilityForm from '../availability-form';
import sessionAvailabilityFormValidation from '../../validation/availability-form-vlaidation';
import {
  selectCourseOnlySessionMeta,
  selectLiveAvailability, selectSessionFee, selectSessionInformation,
} from '../../../../selector';
import { CourseOnlySessionStatus, ILiveSessionAvailabilityForm } from '../../../../interface';
import { createSessionActions } from '../../../../slice';

interface SessionDetailsViewProps {
  editMode: boolean
}

export default function SessionAvailabilityView({ editMode }: SessionDetailsViewProps) {
  const dispatch = useDispatch();
  const availableTimes = useSelector(selectLiveAvailability);
  const { audienceType } = useSelector(selectSessionInformation);
  const { status } = useSelector(selectCourseOnlySessionMeta);
  const sessionFee = useSelector(selectSessionFee);
  const methods = useForm<ILiveSessionAvailabilityForm>({
    defaultValues: {
      availableTimes: availableTimes.availableTimes,
      sessionMeta: availableTimes.sessionMeta,
    },
    resolver: yupResolver(sessionAvailabilityFormValidation),
    shouldFocusError: true,
  });

  const onSubmit:
  SubmitHandler<ILiveSessionAvailabilityForm> = React.useCallback((data) => {
    dispatch(createSessionActions.AddLiveSessionAvailability(data));
    if (audienceType === SessionAudienceTypes.courseOnly) {
      if (status === CourseOnlySessionStatus.editing || editMode) {
        dispatch(createSessionActions.editSession(sessionFee));
      } else {
        dispatch(createSessionActions.createSession(sessionFee));
      }
    }
  }, [dispatch]);

  return (
    <div>
      <FormProvider {...methods}>
        <SessionAvailabilityForm
          onSubmit={onSubmit}
        />
      </FormProvider>
    </div>
  );
}
