/* eslint-disable react/jsx-no-useless-fragment */
import { memo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuthentication from 'base/auth/hooks/use-authentication';

interface Props {
  children: any;
}

const AuthWrapper = memo(({ children }: Props) => {
  const { isAuthenticated } = useAuthentication();
  const location = useLocation();

  return isAuthenticated() ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
});

export default AuthWrapper;
