import {
  call, put, select, all, takeLatest,
} from 'redux-saga/effects';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { PayloadAction } from '@reduxjs/toolkit';
import { createEntAccActions } from './slice';
import API from './constants';
import {
  selectActive, selectChargeForm, selectGeneralInformationForm, selectUserAllocationForm,
} from './selector';
import { ICreateTenantPayload } from './interface';

export function* createEntAccGenerator({ payload }:
PayloadAction<ICreateTenantPayload>): any {
  try {
    const generalInformationForm = yield select(selectGeneralInformationForm);
    const chargesForm = yield select(selectChargeForm);
    const userAllocationForm = yield select(selectUserAllocationForm);
    const active = yield select(selectActive);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_ENT_ACC,
      {
        ...generalInformationForm,
        charges: { ...chargesForm, forEntClient: 0, forExpert: 0 },
        ...userAllocationForm,
        active,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(createEntAccActions.createEntAccSucceeded());
      yield payload.resolve();
    } else {
      yield put(createEntAccActions.createEntAccFailed());
      yield payload.reject(response?.data?.message || 'something went wrong');
    }
  } catch (error: any) {
    yield put(createEntAccActions.createEntAccFailed());
    yield payload.reject(error?.message || 'something went wrong');
  }
}

export function* createEntAccSaga() {
  yield all([takeLatest(
    createEntAccActions.createEntAcc.type,
    createEntAccGenerator,
  )]);
}

export default createEntAccSaga;
