import {
  all,
  call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { viewFeedbackActions } from './slice';
import API from './constants';
import { IFeedBack, IFeedbackFilterPayload } from './interface';

export function* viewFeedbackGenerator({ payload }:PayloadAction<IFeedbackFilterPayload>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_FEEDBACK.path}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&status=${payload.status}`, method: API.GET_FEEDBACK.method },
      null,
      true,
      false,
      undefined,
      payload.entId,
    );
    if (response.statusCode === 200) {
      yield put(viewFeedbackActions.viewFeedbackSucceeded(response.data));
    } else {
      yield put(viewFeedbackActions.viewFeedbackFailed());
    }
  } catch (error) {
    yield put(viewFeedbackActions.viewFeedbackFailed());
  }
}

export function* editFeedbackGenerator({ payload }:PayloadAction<IFeedBack>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_FEEDBACK,
      { adminComment: payload.adminComment, status: payload.status, id: payload._id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewFeedbackActions.editFeedbackSucceeded(payload));
    } else {
      yield put(viewFeedbackActions.editFeedbackFailed());
    }
  } catch (error) {
    yield put(viewFeedbackActions.editFeedbackFailed());
  }
}

export function* viewFeedbackSaga() {
  yield all([takeLatest(
    viewFeedbackActions.viewFeedback.type,
    viewFeedbackGenerator,
  ), takeLatest(viewFeedbackActions.editFeedback.type, editFeedbackGenerator)]);
}

export default viewFeedbackSaga;
