/* eslint-disable react/require-default-props */
import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { Close } from '@mui/icons-material';
import { PrimaryOutlinedButton, PrimaryTable } from 'components';
import { MonitorIcon } from 'assets/icons';
import '../index.scss';
import { ISessionAvatarWithAction } from '../../../../../interface';

interface SelectedSessionTableProps {
  items:ISessionAvatarWithAction[],
  handelRemove?:(id:string)=>void
  edit?:boolean

}

const SelectedSessionTable = ({
  items, handelRemove = (id:string) => {},
  edit,
}:SelectedSessionTableProps) => {
  type Row = typeof items[number];

  const editColumn = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'title',
        headerName: 'Session Title',
        headerClassName: 'selected-item-column',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sx={{ mr: 0.7 }}>
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      mr: 1,
                      backgroundColor: 'background.default',
                      color: 'primary.main',
                      borderWidth: 1,
                      borderColor: 'primary.light',
                      borderStyle: 'solid',
                    }}
                  >
                    <MonitorIcon sx={{ fontSize: 16 }} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h4" sx={{ fontWeight: '400' }}>{params.value}</Typography>
                </Grid>

              </Grid>
            </Grid>

          </Grid>

        ),
      },

      {
        field: 'isAdded',
        headerName: '',
        headerClassName: 'selected-item-column',
        headerAlign: 'left',
        align: 'left',
        flex: 0.5,
        renderCell: (params) => (
          <Grid container alignItems="center" sx={{ color: '#E93E3E' }}>
            <Close />
            <PrimaryOutlinedButton onClick={() => { handelRemove(params.row.id); }}>
              Remove
            </PrimaryOutlinedButton>
          </Grid>

        ),
      },

    ],
    [],
  );
  const viewColumn = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'title',
        headerName: 'Session Title',
        headerClassName: 'selected-item-column',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sx={{ mr: 0.7 }}>
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      mr: 1,
                      backgroundColor: 'background.default',
                      color: 'primary.main',
                      borderWidth: 1,
                      borderColor: 'primary.light',
                      borderStyle: 'solid',
                    }}
                  >
                    <MonitorIcon sx={{ fontSize: 16 }} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h4">{params.value}</Typography>
                </Grid>

              </Grid>
            </Grid>

          </Grid>

        ),
      },

    ],
    [],
  );

  return (
    <PrimaryTable
      className="selected-table"
      disableSelectionOnClick
      rows={items}
      columns={edit ? editColumn : viewColumn}
      loading={false}
      hideFooter
      headerHeight={30}
    />

  );
};

SelectedSessionTable.defaultProps = {
  handelRemove: (id:string) => {},
  edit: true,
};

export default SelectedSessionTable;
