/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/view-ent-acc'] || initialState;

export const selectViewEntAccState = createSelector([selectDomain], (ViewEntAccState) => ViewEntAccState);
export const selectEntAccounts = createSelector([selectDomain], (ViewEntAccState) => ViewEntAccState.entAccounts);
export const selectEntAccountsLoader = createSelector([selectDomain], (ViewEntAccState) => ViewEntAccState.entAccountsLoading);
export const selectSCAdminsLoader = createSelector([selectDomain], (ViewEntAccState) => ViewEntAccState.scAdminsLoading);
export const selectLoader = createSelector([selectDomain], (ViewEntAccState) => ViewEntAccState.loading);
export const selectTotalActive = createSelector([selectDomain], (ViewEntAccState) => ViewEntAccState.totalActive);
export const selectTotalInactive = createSelector([selectDomain], (ViewEntAccState) => ViewEntAccState.totalInactive);
export const selectSCAdmins = createSelector([selectDomain], (ViewEntAccState) => ViewEntAccState.scAdmins);
