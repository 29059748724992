import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { SessionAudienceTypes, SessionType } from 'config';
import {
  selectCourseOnlySessionMeta,
  selectLoader, selectSessionDetails, selectSessionFee, selectSessionInformation,
} from '../../../../selector';
import { CourseOnlySessionStatus, ISessionDetailsForm } from '../../../../interface';
import { createSessionActions } from '../../../../slice';
import SessionDetailsModalForm from '../session-details-form';
import sessionDetailsModalFormValidation from '../../validation/session-details-validation';

interface SessionDetailsViewProps {
  editMode:boolean
}

export default function SessionDetailsView({ editMode }:SessionDetailsViewProps) {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);
  const sessionDetails = useSelector(selectSessionDetails);
  const { audienceType, sessionType } = useSelector(selectSessionInformation);
  const { status } = useSelector(selectCourseOnlySessionMeta);
  const sessionFee = useSelector(selectSessionFee);
  const methods = useForm<ISessionDetailsForm>({
    defaultValues: {
      ...sessionDetails,
    },
    resolver: yupResolver(sessionDetailsModalFormValidation),
    shouldFocusError: true,
  });

  const onSubmit:
  SubmitHandler<ISessionDetailsForm> = React.useCallback((data) => {
    dispatch(createSessionActions.AddSessionDetails(data));
    if (audienceType === SessionAudienceTypes.courseOnly && sessionType === SessionType.content) {
      if (status === CourseOnlySessionStatus.editing || editMode) {
        dispatch(createSessionActions.editSession(sessionFee));
      } else {
        dispatch(createSessionActions.createSession(sessionFee));
      }
    }
  }, [dispatch]);

  return (
    <div>
      <FormProvider {...methods}>
        <SessionDetailsModalForm
          onSubmit={onSubmit}
          loading={loading}
          editMode={editMode}
        />
      </FormProvider>
    </div>
  );
}
