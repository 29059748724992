/* eslint-disable react/require-default-props */
import React from 'react';
import { HexColorPicker } from 'react-colorful';
import { Box, InputAdornment } from '@mui/material';
import { CustomOutlineInputField } from '../primary-text-field';
import './index.scss';
import { Label } from '../custom-typography';

interface ColorPickerProps {
  onChange: (newColor: any) => void;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: string;
  name: string;
  label: string;
  testId?:string
}

const ColorPicker = React.forwardRef(
  ({
    onChange, onBlur, value, name, label, testId = '',
  }: ColorPickerProps, ref) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };
    return (
      <Box>
        <Label htmlFor={name}>{label}</Label>
        <div className="color-picker">
          <div className="hex-color-picker">
            <HexColorPicker color={value} onChange={onChange} />

            <div className="text-input-wrapper">
              <CustomOutlineInputField
                onChange={handleChange}
                value={value}
                startAdornment={<InputAdornment position="start">HEX</InputAdornment>}
                type="text"
                onBlur={onBlur ?? undefined}
                name={name ?? undefined}
                ref={ref ?? undefined}
                fullWidth
                id={`${name}`}
                inputProps={{
                  'data-testid': testId ?? '',
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    );
  },
);

export default ColorPicker;
