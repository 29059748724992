import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  Grid, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import React from 'react';
import {
  ContentMediaView,
  ErrorMessage,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimaryTextField,
} from 'components';

import FileDragUploader from 'components/organisms/file-drag-uploader';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { extensionToType } from 'utils/file-uploader';
import { BlockType } from '@surge-global-engineering/form-builder-v2';
import { ICreateContentModalForm } from '../../../interface';

interface ContentModalFormProps {
  onSubmit: SubmitHandler<any>;
  closeModal: () => void;
  loading:boolean;
  blockType?: BlockType;
  scenarios?: UploadScenarios
}

const ContentModalForm = ({
  onSubmit,
  closeModal, loading, blockType, scenarios,
}: ContentModalFormProps) => {
  const {
    control,
    handleSubmit, setValue, setError, clearErrors, watch, reset,
    formState: { errors },
  } = useFormContext<ICreateContentModalForm>();

  const [scenario, setScenario] = React.useState<UploadScenarios>(UploadScenarios.expertContent);

  const watchIsExternalFile = watch('isExternalFile');
  const watchContentUrl = watch('contentUrl');
  const watchFileCategory = watch('fileCategory', '');

  const getScenario = (blockTypeInput?: BlockType): UploadScenarios => (
    blockTypeInput === BlockType.TEXT
      ? UploadScenarios.textBlock
      : UploadScenarios.expertContent
  );

  React.useEffect(() => {
    if (blockType) {
      const updatedScenario = getScenario(blockType);
      setScenario(updatedScenario);
    }
  }, [blockType]);

  React.useEffect(() => {
    if (scenarios) {
      setScenario(scenarios);
    }
  });

  const handleUpload = (res:UploadResponse) => {
    clearErrors('contentUrl');
    setValue('contentUrl', res.url);
    setValue('fileType', res.extension.toUpperCase());
    setValue('fileCategory', res.category ?? '');
    setValue('fileName', res.name);
    if (res.statusCode !== 201) {
      setError('contentUrl', { type: 'custom', message: res.message });
      setValue('contentUrl', '');
    } else {
      setValue('contentUrl', res.url);
    }
  };

  const getMediaDataFromUrl = (url:string) => {
    clearErrors('contentUrl');
    const fileType = `.${url.split('.').pop()}`;
    const fileName = url.split('/').pop();
    if (fileType && fileName && extensionToType.get(fileType.toLowerCase())) {
      const fileCategory = extensionToType.get(fileType.toLowerCase()) as string;
      setValue('fileType', fileType.toUpperCase().replace('.', ''));
      setValue('fileCategory', fileCategory);
      setValue('fileName', fileName);
      return;
    }
    setError('contentUrl', { type: 'custom', message: 'URL is invalid' });
  };
  const handleUrlModeChange = (event: any) => {
    clearErrors();
    reset();
    setValue('isExternalFile', event.target.value === 'url');
  };

  const handleForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)();
  };

  React.useEffect(() => {
    if (watchIsExternalFile && watchContentUrl) getMediaDataFromUrl(watchContentUrl);
  }, [watchContentUrl]);

  return (
    <form onSubmit={handleForm}>

      <Typography variant="h4" className="form-item">
        Upload media
      </Typography>
      <div className="form-item">
        <ToggleButtonGroup
          value={watchIsExternalFile ? 'file' : 'url'}
          fullWidth
          exclusive
          onChange={handleUrlModeChange}
          aria-label="Platform"
        >
          <ToggleButton sx={{ color: '#9D8336' }} value="file">Click to upload a file</ToggleButton>
          <ToggleButton sx={{ color: '#9D8336' }} value="url">Click to enter a url</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {watchIsExternalFile ? (
        <div>
          {watchContentUrl && !errors?.contentUrl && (
            <div className="file-upload-content-media-view d-flex align-items-center justify-content-center mb-2">
              <ContentMediaView url={watchContentUrl} mediaType={watchFileCategory ?? ''} />
            </div>
          )}
          <Controller
            control={control}
            name="contentUrl"
            render={({ field }) => <PrimaryTextField {...field} sx={{ mt: 0 }} label="Media URL" />}
          />
          <div className="mb-3 mt-1">
            {errors && <ErrorMessage>{errors?.contentUrl?.message}</ErrorMessage>}
          </div>
        </div>
      ) : (
        <div className="form-item">
          <Controller
            control={control}
            name="contentUrl"
            render={({ field }) => (
              <FileDragUploader
                textLabel="click or drag a file to this area to upload"
                handleFile={handleUpload}
                scenario={scenario}
                currentContentUrl=""
                uploadAnotherText="Upload another media"
                currentMediaType=""
              />
            )}
          />
          <ErrorMessage>{errors?.contentUrl?.message}</ErrorMessage>
        </div>
      )}

      <div className="form-item">
        <Controller
          control={control}
          name="title"
          render={({ field }) => <PrimaryTextField {...field} label="Title " />}
        />
        <ErrorMessage>{errors?.title?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="description"
          render={({ field }) => <PrimaryTextField multiline {...field} label="Description " />}
        />
        <ErrorMessage>{errors?.description?.message}</ErrorMessage>
      </div>
      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={closeModal}>Cancel</PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} fullWidth type="submit">
            Upload
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

ContentModalForm.defaultProps = {
  blockType: undefined,
  scenarios: undefined,
};

export default ContentModalForm;
