import moment from 'moment';

import { getCurrencySymbol } from './data-utils';

let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d sec',
    m: 'a min',
    mm: '%d mins',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    w: 'a week',
    ww: '%d weeks',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export const dateFormatStandard = (date: string): string => (date && date.length > 0 ? moment(date).format('DD/MM/YYYY') : ''); // 08/08/2022
export const dateTimeFormat = (date: string): string => (date && date.length > 0 ? moment(date).format('MMM DD,YYYY. hh:mm A') : ''); // Aug 22, 2022. 12:00 PM
export const durationFormat = (date: string): string => (date && date.length > 0 ? moment.utc(date).local().fromNow() : ''); // 2 hours ago
export const timeFormat = (date: string): string => (date && date.length > 0 ? moment(date, 'HH:mm:ss').format('h:mm A') : ''); // 9:45 AM
export const monthDayFormat = (date: string): string => (date && date.length > 0 ? moment(date, 'YYYY-MM-DD').format('MMM Do') : '');// Aug 22nd
export const dateFormat = (date: string): string => (date && date.length > 0 ? moment(date).format('MMM DD,YYYY') : '');// Aug 22nd, 2022
export const monthYearFormat = (date: string): string => (date && date.length > 0 ? moment(date).format('MMMM YYYY') : '');// Auguest 2022
export const dayMonthFormat = (date: string): string => (date && date.length > 0 ? moment(date).format('dddd, Do MMM.') : '');// Monday, 3rd Sep
export const dayMonthYearFormat = (date: string): string => (date && date.length > 0 ? moment(date).format('dddd, Do MMM YYYY') : '');// Monday, 3rd Sep 2022
export const isoTimeFormat = (date: string): string => moment(new Date(date)).format('hh:mm A');
export const isoDateFormat = (date: string): string => moment(new Date(date)).format('MMM DD,YYYY');

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export const toHoursAndMinutes = (totalMinutes: number) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};

export const amountFormat = (price: string | number) => Number(price).toFixed(2).toLocaleString();

export const currencyFormat = async (
  amount: string,
  currency: string,
  convertCurrency: string,
  isUseSymbol: boolean = false,
) => {
  let exRates: any = {};
  const coreState = store.getState()['feature/base/core'];
  if (store && coreState) {
    exRates = coreState?.exchangeRates;
    if (exRates?.USD !== undefined) {
      // for USD
      let exRate = exRates.USD / exRates[currency.toUpperCase()];
      // for none USD currencies
      if (convertCurrency !== 'USD') {
        exRate *= exRates[convertCurrency.toUpperCase()];
      }
      return `${isUseSymbol ? `${getCurrencySymbol(currency)} ` : ''}${Number((exRate * +amount).toFixed(2))}`;
    }
    return `${isUseSymbol ? `${getCurrencySymbol(currency)} ` : ''}${Number(amount).toFixed(2)}`;
  }
  return `${isUseSymbol ? `${getCurrencySymbol(currency)} ` : ''}${Number(amount).toFixed(2)}`;
};
