import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import signInFormValidation from 'pages/sign-in/validation/sign-in-form-validation';
import './index.scss';

import {
  Label, Title,
} from 'components';
import useAuthorization from 'base/auth/hooks/use-authorization';
import { BaseLayout } from 'components/templates';
import { authSliceActions } from 'base/auth/slice';
import { selectCallbackPath, selectLoader } from 'base/auth/selectors';
import useAuthentication from 'base/auth/hooks/use-authentication';
import SignInForm from '../sign-in-form';

const SignInView = () => {
  useAuthorization();
  const methods = useForm({
    defaultValues: {
      name: '',
      password: '',
      retainLogin: false,
    },
    resolver: yupResolver(signInFormValidation),
    shouldFocusError: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoader);
  const callbackPath = useSelector(selectCallbackPath);
  const { isAuthenticated } = useAuthentication();
  const handleSubmitFrom: SubmitHandler<any> = useCallback(
    (data) => {
      dispatch(authSliceActions.signIn(data));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (isAuthenticated()) {
      if (callbackPath) {
        navigate(`/${callbackPath}`);
      } else {
        navigate('../');
      }
    } if (!isAuthenticated() && loading) {
      dispatch(authSliceActions.signOut());
    }
  }, []);

  return (
    <BaseLayout>
      <>
        <div style={{ textAlign: 'center' }}>
          <Title>Welcome back!</Title>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Label>Login to your account to continue</Label>
        </div>
        <br />
        <FormProvider {...methods}>
          <SignInForm onSubmit={handleSubmitFrom} />
        </FormProvider>
      </>

    </BaseLayout>

  );
};

export default SignInView;
