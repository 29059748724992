import * as yup from 'yup';

const checkIfAnyItemIsAdded = (value: any, textContext: yup.TestContext) => {
  const { session, form, content } = textContext.parent;
  if (session.length + content.length + form.length === 0) {
    return false;
  }
  return true;
};

const courseModulesFormValidation = yup.object().shape({
  modules: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().trim().required('Module name is a required field. '),
        description: yup.string().trim().test(
          'length-limit',
          'Description must be at most 680 characters',
          (value) => (value?.replace(/(<([^>]+)>)/gi, '')?.replace(/[\r\n]+/gm, '')?.length || 0) <= 680,
        ),
        badge: yup.object().shape({
          badgeTitle: yup.string().trim(),
          badgeDescription: yup.string().trim(),
          badgeImagUrl: yup.string().trim(),
        }),
        session: yup
          .array()
          .label('Module Sessions')
          .test(
            'session',
            'Add at least one session, form or media item to create this module. ',
            checkIfAnyItemIsAdded,
          ),
        forms: yup
          .array()
          .of(
            yup.object().shape({
              id: yup.string().label('Form id'),
              submission: yup.string().label('Form submission'),
            }),
          )
          .nullable()
          .label('Forms'),
        content: yup.array(),
      }),
    )
    .min(1),
});

export default courseModulesFormValidation;
