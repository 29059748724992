export enum ExpertAccountType {
  public = 'public',
  subscription = 'subscription',
}

export enum ExpertSubscriptionFrequency {
  monthly = 'monthly',
  annually = 'annually',
  oneTime = 'onetime',
}
