import { IGiftCardDocument } from '../../interface';

export enum ModalStates {
  close = 'CLOSE',
  delete = 'DELETE',
  create = 'CREATE',
  active = 'ACTIVE',
}
export interface IGiftCard extends IGiftCardDocument {
  modalState:string,
}

export interface ViewGiftCardState {
  stateLoading:boolean,
  loading:boolean,
  entId:string,
  GiftCards: { result :IGiftCard[], total:number }

}
export interface IGiftCardRequest {
  total:number,
  result: IGiftCardDocument[]
}
export interface IOpenModal {
  id:string,
  state:ModalStates
}

export interface IDeleteGiftCard {
  id:string
}

export interface IActivateGiftCard {
  id:string, isActive:boolean
}

export interface IGiftCardFilterPayload {
  keyword: string;
  status:string,
  page: number;
  limit: number;
}
