import * as React from 'react';
import Box from '@mui/material/Box';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { PrimaryLoadingOutlinedButton } from 'components';
import { ICategoryModalForm, ModalStates } from '../../../../../interface';
import { selectModal } from '../../../../../selector';
import onboardCategoryModalFormValidation from '../validation/category-form-validation';
import { onboardEntAccActions } from '../../../../../slice';
import OnboardCategoryModalForm from '../category-modal-form';

interface OnboardCategoryModalProps {
  index: number;
  addCategory:(name:string)=>void
}

const OnboardCategoryModal = ({ index, addCategory }: OnboardCategoryModalProps) => {
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const methods = useForm<ICategoryModalForm>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(onboardCategoryModalFormValidation),
    shouldFocusError: true,
  });

  const handleOpen = () => {
    dispatch(onboardEntAccActions.openModal(ModalStates.category));
  };
  const handleClose = () => {
    dispatch(onboardEntAccActions.closeModal());
    methods.reset();
  };
  const onSubmit: SubmitHandler<ICategoryModalForm> = React.useCallback(
    (data) => {
      addCategory(data.name);
      handleClose();
    },
    [dispatch],
  );

  return (
    <Grid container justifyContent="flex-start">
      <Grid item>
        <PrimaryLoadingOutlinedButton sx={{ padding: '0.5rem 0' }} onClick={handleOpen}>
          <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
          Add new category
        </PrimaryLoadingOutlinedButton>
      </Grid>

      <Modal open={modal === ModalStates.category}>
        <Box className="modal-scrollable">
          <IconButton aria-label="close" sx={{ float: 'right' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <FormProvider {...methods}>
            <OnboardCategoryModalForm onSubmit={onSubmit} closeModal={handleClose} index={index} />
          </FormProvider>
        </Box>
      </Modal>
    </Grid>
  );
};
export default OnboardCategoryModal;
