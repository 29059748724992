const API = {
  CREATE_EXPERT: {
    path: '/expert/create',
    method: 'POST',
  },
  GET_EXPERT: {
    path: '/expert/details',
    method: 'GET',
  },
  GET_ALL_EXPERTS: {
    path: '/expert/all',
    method: 'GET',
  },
  GET_EXPERTS_BY_ENT: {
    path: '/expert/details/ent',
    method: 'GET',
  },
  GET_FILTERED_EXPERTS_BY_ENT: {
    path: '/expert/search',
    method: 'GET',
  },
  RESET_PASSWORD: {
    path: '/user/force-reset',
    method: 'POST',
  },
};
export default API;
