import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSnack } from 'base/core/selector';
import { coreSliceActions } from 'base/core/slice';
import { PAGE_BACKGROUND_COLOR } from 'constants/style';
import { LogoLabel, PrimarySnackbar } from 'components/molecules';

export interface Props {

  children: React.ReactElement;

}
export default function BaseLayout({ children }: Props) {
  const dispatch = useDispatch();
  const { open, message, success } = useSelector(selectSnack);
  const handleClose = () => {
    dispatch(coreSliceActions.closeSnackbar());
  };
  return (
    <div style={{
      backgroundColor: PAGE_BACKGROUND_COLOR,
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
    }}
    >
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      >
        <div
          style={{
            backgroundColor: '#000',
            width: '100%',
            height: '70px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '50px',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LogoLabel>SLOCOACH</LogoLabel>
          </div>

        </div>
        <PrimarySnackbar open={open} message={message} severity={success ? 'success' : 'error'} handleClose={handleClose} />
        <div style={{ width: '400px' }}>
          {children}
        </div>
      </div>

    </div>
  );
}
