import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import CourseModulesForm from '../modules-form';
import sessionAvailabilityFormValidation from '../../validation/modules-form-validation';
import { ICourseModules } from '../../../../interface';
import { selectCourseModules, selectLoader } from '../../../../selector';
import { createSessionActions } from '../../../../slice';

export default function CourseModulesView() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);
  const { modules, isSequentialModuleNavigationEnabled } = useSelector(selectCourseModules);
  const methods = useForm<ICourseModules>({
    defaultValues: {
      modules,
      isSequentialModuleNavigationEnabled,
    },
    resolver: yupResolver(sessionAvailabilityFormValidation),
    shouldFocusError: true,
  });
  const onSubmit:
  SubmitHandler<ICourseModules> = React.useCallback((data) => {
    dispatch(createSessionActions.AddCourseModules(data));
  }, [dispatch]);
  return (
    <div>
      <FormProvider {...methods}>
        <CourseModulesForm
          onSubmit={onSubmit}
          loading={loading}

        />
      </FormProvider>
    </div>
  );
}
