/* eslint-disable no-restricted-syntax */
import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import moment from 'moment';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import {
  getOptionsFromEnum, enumerateDaysBetweenDates, getDateWithTime, getTimeStops,
} from 'utils/data-utils';
import { currencyFormat } from 'utils/formats';
import {
  SessionType,
  SessionFrequencyTypes,
  DaysOfTheWeek,
  CourseTypes,
  LiveClassTypes,
  CategoryResourceType,
} from 'config';
import API from './constants';
import { createSessionActions } from './slice';
import {
  ICourseGoals,
  ICourseModules,
  ICreateSessionForm, ILiveClassFrequencyForm,
  ILiveSessionAvailabilityForm, IPaginateFilterPayload, ISessionDetailsForm, ISessionFeeForm,
} from './interface';
import {
  selectActive, selectCourseGoals, selectCourseModules, selectEntId, selectExpertId,
  selectLiveAvailability, selectLiveClassFrequency,
  selectSessionDetails,
  selectSessionId,
  selectSessionInformation,
} from './selector';
import {
  IAvailableTime, IClass, ISessionMeta,
  SessionDocument,
} from '../../interface';
import { selectActive as selectExpertActive, selectGeneralInformation } from '../../../../selector';

const generateLiveClassSlots = async (
  data: ILiveClassFrequencyForm,
  sessionType:string,
): Promise<any> => {
  const classes: IClass[] = [];

  if (sessionType === SessionType.liveClass) {
    const sessionStartDateTime = moment(`${data.startDate} ${data.startAt}`, 'YYYY-MM-DD HH:mm');
    const sessionEndDateTime = moment(`${data.endDate} ${data.endAt}`, 'YYYY-MM-DD HH:mm');

    if (data.frequency === SessionFrequencyTypes.oneTime) {
      const slotEndDateTime = moment(
        `${data.startDate} ${data.startAt}`,
        'YYYY-MM-DD HH:mm',
      ).add(1, 'hours');
      classes.push({
        from: sessionStartDateTime.toISOString(),
        to: slotEndDateTime.toISOString(),
        date: sessionStartDateTime.toISOString(),
      });
    } else if (data.frequency === SessionFrequencyTypes.everyDay) {
      const now = sessionStartDateTime.clone();
      const endAtEveryDay = now.clone().add(1, 'hours');
      while (now.isSameOrBefore(sessionEndDateTime)) {
        classes.push({
          from: now.toISOString(),
          to: endAtEveryDay.toISOString(),
          date: now.toISOString(),
        });
        now.add(1, 'days');
        endAtEveryDay.add(1, 'days');
      }
    } else if (data.frequency === SessionFrequencyTypes.everyWeek) {
      const now = sessionStartDateTime.clone();
      const endAtEveryWeek = now.clone().add(1, 'hours');
      const daysOfTheWeek = getOptionsFromEnum(DaysOfTheWeek);
      const dayOfWeek = daysOfTheWeek.filter(
        (dayOfTheWeek) => dayOfTheWeek.value === data.dayOfTheWeek,
      )[0];
      const weekdayId = dayOfWeek.id as number;

      if (now.isoWeekday() !== dayOfWeek.id) {
        const diff = (weekdayId + 7 - now.isoWeekday()) % 7;
        now.add(diff, 'days');
        endAtEveryWeek.add(diff, 'days');
      }
      while (now.isSameOrBefore(sessionEndDateTime)) {
        if (now.isoWeekday() !== dayOfWeek.id) {
          now.add(1, 'week').day(dayOfWeek.id);
          endAtEveryWeek.add(1, 'week').day(dayOfWeek.id);
          // eslint-disable-next-line no-continue
          continue;
        }
        classes.push({
          from: now.toISOString(),
          to: endAtEveryWeek.toISOString(),
          date: now.toISOString(),
        });
        now.add(1, 'week').day(dayOfWeek.id);
        endAtEveryWeek.add(1, 'week').day(dayOfWeek.id);
      }
    } else if (data.frequency === SessionFrequencyTypes.everyMonth) {
      const now = sessionStartDateTime.clone();
      const endAtEveryMonth = now.clone().add(1, 'hours');
      const dayOfMonth = Number(data.dayOfTheMonth);
      while (now.isSameOrBefore(sessionEndDateTime)) {
        if (now.date() !== dayOfMonth) {
          if (now.month() === sessionStartDateTime.month()) {
            now.add(0, 'month').date(dayOfMonth);
            endAtEveryMonth.add(0, 'month').date(dayOfMonth);
          } else {
            now.add(1, 'month').date(dayOfMonth);
            endAtEveryMonth.add(1, 'month').date(dayOfMonth);
          }
          // eslint-disable-next-line no-continue
          continue;
        }
        classes.push({
          from: now.toISOString(),
          to: endAtEveryMonth.toISOString(),
          date: now.toISOString(),
        });
        now.add(1, 'month').date(dayOfMonth);
        endAtEveryMonth.add(1, 'month').date(dayOfMonth);
      }
    }
  }

  return classes;
};
const generateLiveSessionSlots = async (
  sessionMeta: ISessionMeta,
  availableTimes: IAvailableTime[],
  sessionDuration: number,
): Promise<IClass[]> => {
  const classes: IClass[] = [];
  const dates = await enumerateDaysBetweenDates(
    `${sessionMeta.startDate} 00:00:00`,
    `${sessionMeta.endDate} 00:00:00`,
  );

  /*
   Create classes array by getting experts avalible dates with his avalible times
   and using the session Duration
  */
  for (const date of dates) {
    for (const avalibleTime of availableTimes) {
      // check if the expert is avalible on the currentDay
      if (avalibleTime.isAvailable && avalibleTime.day === moment(date).format('dddd')) {
        // if the expert is avalible get his avalible times for the day
        for (const time of avalibleTime.times) {
          // calculate the startTime and endTime based on the expert avalible time

          const startTime = getDateWithTime(time.from ?? '08:00:00', date);
          const endTime = avalibleTime?.isAllDay
            ? getDateWithTime(time.to ?? '17:00:00', date)
            : moment(startTime).add(sessionDuration, 'minutes').toISOString();

          // generate slots for the available times  and update those slots to
          // available classes for the session
          const slots = getTimeStops(startTime, endTime, sessionDuration);
          // eslint-disable-next-line array-callback-return
          slots.map((slot: any) => {
            classes.push({
              date,
              from: slot.start,
              to: slot.end,
            });
          });
        }
      }
    }
  }
  return classes;
};

const generateClassSlots = async (
  sessionInformation:ICreateSessionForm,
  liveClassFrequency:ILiveClassFrequencyForm,
  liveSessionAvailability:ILiveSessionAvailabilityForm,
) => {
  let classes = [];
  if (sessionInformation.sessionType === SessionType.liveClass) {
    classes = await generateLiveClassSlots(
      liveClassFrequency,
      sessionInformation.sessionType,
    );
  } else if (sessionInformation.sessionType === SessionType.liveSession) {
    classes = await generateLiveSessionSlots(
      liveSessionAvailability.sessionMeta,
      liveSessionAvailability.availableTimes,
      sessionInformation.sessionDuration,
    );
  }
  return classes;
};

const getCourseModules = async (
  courseModules:ICourseModules,
) => ({
  modules: courseModules.modules.map((module) => {
    const {
      isUpdated, key, version, ...rest
    } = module;

    return {
      ...rest,
      version: isUpdated ? (Number(version) || 0) + 1 : version,
      session: rest.session.map((session) => session.id) ?? [],
      content: rest.content.map((content) => content.id) ?? [],
      form: rest.form.map((form) => ({ id: form.id, submission: form.submission })),
    };
  }),
});

const getUserSettings = async (sessionInformation:ICreateSessionForm) => {
  const userSettings = { ...sessionInformation.userSettings };
  if (sessionInformation.sessionType === SessionType.course) {
    if (sessionInformation.courseType === CourseTypes.lsm) {
      userSettings.allowUpload = false;
      userSettings.allowMessaging = false;
    } else if (sessionInformation.courseType === CourseTypes.interactive) {
      userSettings.allowUpload = true;
      userSettings.allowMessaging = true;
    } else if (sessionInformation.liveClassType === LiveClassTypes.interactive) {
      userSettings.allowUpload = false;
      userSettings.allowMessaging = false;
    }
  }
  return userSettings;
};

const getRequestBody = async (
  sessionInformation:ICreateSessionForm,
  sessionDetails:ISessionDetailsForm,
  payload:ISessionFeeForm,
  liveClassFrequency:ILiveClassFrequencyForm,
  liveSessionAvailability:ILiveSessionAvailabilityForm,
  courseSessionModules:ICourseModules,
  courseGoals:ICourseGoals,
  liveClassType:string,
) => {
  const classes = await generateClassSlots(
    sessionInformation,
    liveClassFrequency,
    liveSessionAvailability,
  );
  const courseModules = await getCourseModules(courseSessionModules);
  const userSettings = await getUserSettings(sessionInformation);

  return ({
    ...sessionInformation,
    ...sessionDetails,
    highlightMedia: (sessionDetails.highlightMedia._id === '' ? null : sessionDetails.highlightMedia._id),
    ...userSettings,
    ...payload,
    ...liveSessionAvailability,
    ...(sessionInformation.sessionType === SessionType.liveClass
      && {
        frequency: {
          startAt: moment(`${liveClassFrequency.startDate} ${liveClassFrequency.startAt}`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          endAt: moment(`${liveClassFrequency.endDate} ${liveClassFrequency.endAt}`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          startDate: moment(`${liveClassFrequency.startDate} ${liveClassFrequency.startAt}`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          endDate: moment(`${liveClassFrequency.endDate} ${liveClassFrequency.endAt}`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          dayOfTheWeek: liveClassFrequency.dayOfTheWeek,
          dayOfTheMonth: liveClassFrequency.dayOfTheMonth,
          frequency: liveClassFrequency.frequency,
          classes,
        },
      }),
    sessionMeta: { ...liveSessionAvailability.sessionMeta, liveClassType },
    ...(sessionInformation.sessionType === SessionType.course && courseModules),
    ...((sessionInformation.sessionType === SessionType.course
      && sessionInformation.courseType === CourseTypes.interactive)
    && courseGoals),
    fee: Number(payload.fee).toFixed(2).toLocaleString(),
    discount: Number(payload.discount).toString(),
    subscriberFee: Number(payload.subscriberFee).toFixed(2).toLocaleString(),
    subscriberDiscount: Number(payload.subscriberDiscount).toString(),
    observerFee: Number(payload.observerFee).toFixed(2).toLocaleString(),
    observerDiscount: Number(payload.observerDiscount).toString(),
    subscribeObserverFee: Number(payload.subscribeObserverFee).toFixed(2).toLocaleString(),
    subscribeObserverDiscount: Number(payload.subscribeObserverDiscount).toString(),
    groups: sessionInformation.groups.map((group) => (group._id)),
    media: sessionInformation.media.map((media) => (media.id)),
    forms: sessionInformation.forms.map((form) => ({ id: form.id, submission: form.submission })),
    classes,
    prerequisites:
    Array.isArray(sessionInformation?.prerequisites)
      ? sessionInformation?.prerequisites.map((i) => i.id)
      : undefined,
    isSequentialModuleNavigationEnabled: courseSessionModules.isSequentialModuleNavigationEnabled,
  });
};

function* assignCategories(resourceId: string, categories: string[]) {
  yield call(
    request,
    API.POST_ASSIGN_CATEGORIES,
    {
      resourceId,
      categoryId: categories,
      resourceType: CategoryResourceType.SESSION,
    },
  );
}

function* getCategories(resourceId: string) {
  const response: IRestApiResponse = yield call(
    request,
    {
      path: `${API.GET_CATEGORIES.path}?resourceId=${resourceId}&resourceType=${CategoryResourceType.SESSION}`,
      method: API.GET_CATEGORIES.method,
    },
    null,
    true,
  );

  return response;
}

export function* createSessionGenerator({ payload }:
PayloadAction<ISessionFeeForm>): any {
  try {
    const expertId = yield select(selectExpertId);
    const entId = yield select(selectEntId);
    const isActive = yield select(selectActive);
    const {
      liveClassType,
      ...sessionInformation
    }:ICreateSessionForm = yield select(selectSessionInformation);
    const sessionDetails:ISessionDetailsForm = yield select(selectSessionDetails);
    const liveSessionAvailability:
    ILiveSessionAvailabilityForm = yield select(selectLiveAvailability);
    const liveClassFrequency:ILiveClassFrequencyForm = yield select(selectLiveClassFrequency);
    const courseSessionModules:ICourseModules = yield select(selectCourseModules);
    const courseGoals:ICourseGoals = yield select(selectCourseGoals);

    const requestBody = yield call(async () => getRequestBody(
      sessionInformation,
      sessionDetails,
      payload,
      liveClassFrequency,
      liveSessionAvailability,
      courseSessionModules,
      courseGoals,
      liveClassType as string,
    ));

    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_SESSION,
      {
        isActive,
        ...requestBody,
        expertId,
        entId,
      },
      true,
    );
    const { categories } = sessionDetails;
    if (response.statusCode === 201) {
      yield assignCategories(response.data?.session?._id, categories);
      yield put(createSessionActions.createSessionSucceeded(response.data));
    } else {
      yield put(createSessionActions.createSessionFailed());
    }
  } catch (error) {
    yield put(createSessionActions.createSessionFailed());
  }
}

export function* editSessionGenerator({ payload }:
PayloadAction<ISessionFeeForm>): any {
  try {
    const id = yield select(selectSessionId);
    const isExpertActive = yield select(selectExpertActive);
    const isActive = yield select(selectActive);

    const {
      liveClassType,
      ...sessionInformation
    }:ICreateSessionForm = yield select(selectSessionInformation);
    const sessionDetails:ISessionDetailsForm = yield select(selectSessionDetails);
    const liveSessionAvailability :ILiveSessionAvailabilityForm = {
      ...yield select(selectLiveAvailability),
      ...{
        sessionMeta: {
          ...(yield select(selectLiveAvailability)).sessionMeta,
        },
      },
    };
    const liveClassFrequency:ILiveClassFrequencyForm = yield select(selectLiveClassFrequency);
    const courseSessionModules:ICourseModules = yield select(selectCourseModules);
    const courseGoals:ICourseGoals = yield select(selectCourseGoals);
    const requestBody = yield call(async () => getRequestBody(
      sessionInformation,
      sessionDetails,
      payload,
      liveClassFrequency,
      liveSessionAvailability,
      courseSessionModules,
      courseGoals,
      liveClassType as string,
    ));
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_SESSION,
      {
        ...(isExpertActive && { isActive }),
        ...requestBody,
        id,
      },
      true,
    );
    const { categories } = sessionDetails;
    if (response.statusCode === 201) {
      yield assignCategories(id, categories);
      yield put(createSessionActions.editSessionSucceeded(response.data));
    } else {
      yield put(createSessionActions.createSessionFailed());
    }
  } catch (error) {
    yield put(createSessionActions.createSessionFailed());
  }
}

export function* getSessionGenerator(): any {
  try {
    const id = yield select(selectSessionId);
    const { currency } = yield select(selectGeneralInformation);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_SESSIONS_BY_ID.path}/${id}`, method: API.GET_SESSIONS_BY_ID.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      const categories: IRestApiResponse = yield getCategories(id);
      const preparedCurrency = currency ?? 'AUD';
      const fee = yield call(currencyFormat, response.data.fee, response.data.currency ?? 'AUD', preparedCurrency);
      const observerFee = yield call(currencyFormat, response.data.observerFee, response.data.observerCurrency ?? 'AUD', preparedCurrency);
      const subscriberFee = yield call(currencyFormat, response.data.subscriberFee, response.data.subscriberCurrency ?? 'AUD', preparedCurrency);
      const subscribeObserverFee = yield call(currencyFormat, response.data.subscribeObserverFee, response.data.subscribeObserverCurrency ?? 'AUD', preparedCurrency);

      const formattedData = yield {
        ...response.data,
        fee,
        subscriberFee,
        observerFee,
        subscribeObserverFee,
        categories: categories.data,
      };
      yield put(createSessionActions.getSessionSucceeded(formattedData as SessionDocument));
    } else {
      yield put(createSessionActions.getSessionFailed());
    }
  } catch (error) {
    yield put(createSessionActions.getSessionFailed());
  }
}

export function* getGroupGenerator({ payload }:PayloadAction<IPaginateFilterPayload>): any {
  try {
    const expertId = yield select(selectExpertId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_GROUPS_BY_EXPERT.path}/?expertId=${expertId}&groupType=${payload.groupType}&keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}`, method: API.GET_GROUPS_BY_EXPERT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(createSessionActions.getFilteredGroupsSucceeded(response.data));
    } else {
      yield put(createSessionActions.getFilteredGroupsFailed());
    }
  } catch (error) {
    yield put(createSessionActions.getFilteredGroupsFailed());
  }
}

export function* resendInviteGenerator({ payload }:PayloadAction<string>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_RESEND_INVITE,
      { id: payload },
      true,
    );
    if (response.statusCode === 201) {
      yield put(createSessionActions.resendInviteSucceeded());
    } else {
      yield put(createSessionActions.resendInviteFailed());
    }
  } catch (error) {
    yield put(createSessionActions.resendInviteFailed());
  }
}

export function* createSessionSaga() {
  yield all([takeLatest(
    createSessionActions.createSession.type,
    createSessionGenerator,
  ),
  takeLatest(
    createSessionActions.editSession.type,
    editSessionGenerator,
  ),
  takeLatest(
    createSessionActions.resendInvite.type,
    resendInviteGenerator,
  ),
  takeLatest(
    createSessionActions.getSession.type,
    getSessionGenerator,
  ),
  takeLatest(
    createSessionActions.getFilteredGroups.type,
    getGroupGenerator,
  ),
  ]);
}

export default createSessionSaga;
