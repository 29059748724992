import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  PrimaryTextField, ErrorMessage, PrimaryLoadingButton,
} from 'components';
import { selectLoader } from 'base/auth/selectors';
import { IResetPasswordForm } from 'pages/reset-password/interface';

interface ResetPasswordFormProps {
  onSubmit: SubmitHandler<IResetPasswordForm>;
}

const ResetPasswordForm = ({ onSubmit }: ResetPasswordFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IResetPasswordForm>();

  const loading = useSelector(selectLoader);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="form-item">
        <Controller
          control={control}
          name="newPassword"
          render={({ field }) => <PrimaryTextField {...field} label="New password" isPassword />}
        />
        <ErrorMessage>{errors?.newPassword?.message}</ErrorMessage>
        <ErrorMessage>{errors?.verificationCode?.message}</ErrorMessage>
        <ErrorMessage>{errors?.name?.message}</ErrorMessage>
      </div>

      <div className="form-item">
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <PrimaryTextField {...field} label="Confirm password" isPassword />
          )}
        />
        <ErrorMessage>{errors?.confirmPassword?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <PrimaryLoadingButton loading={loading} size="large" fullWidth type="submit">
          Save new password
        </PrimaryLoadingButton>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
