/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/article/view-article'] || initialState;

export const selectViewArticleState = createSelector([selectDomain], (ViewArticleState) => ViewArticleState);
export const selectArticleStateLoader = createSelector([selectDomain], (ViewArticleState) => ViewArticleState.stateLoading);
export const selectLoader = createSelector([selectDomain], (ViewArticleState) => ViewArticleState.loading);
export const selectArticles = createSelector([selectDomain], (ViewArticleState) => ViewArticleState.articles);
export const selectEntId = createSelector([selectDomain], (ViewArticleState) => ViewArticleState.entId);
