const API = {
  GET_SESSIONS_BY_ID: {
    path: '/session/summary',
    method: 'GET',
  },
  GET_SESSIONS_BY_EXPERT: {
    path: '/session/search',
    method: 'GET',
  },
  POST_SESSION_URL: {
    path: '/meeting/generate-signed-url',
    method: 'POST',
  },
  POST_UPDATE_SESSION: {
    path: '/session/update',
    method: 'POST',
  },
  DELETE_SESSION: {
    path: '/session/delete',
    method: 'DELETE',
  },
  POST_RESEND_INVITE: {
    path: '/invitations/resend',
    method: 'POST',
  },
  POST_CANCEL_SESSION: {
    path: '/session/cancel',
    method: 'POST',
  },
};
export default API;
