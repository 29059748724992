/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import {
  Grid, Tabs, Tab, Box, CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { OnboardDashboardLayout } from 'components/templates';
import { selectPermission } from 'base/auth/selectors';
import { Error } from '@mui/icons-material';
import OnboardChargesModalView from './components/charges/components/charges/charges-view';
import OnboardGeneralInformationModalView from './components/general-information/components/general-information/general-information-view';
import { onboardEntAccActions } from './slice';
import {
  selectIsOnBoardingDone,
  selectStateLoader, selectStatus,
} from './selector';
import OnboardSitePreferencesModalView from './components/site-preferences/components/site-preferences/site-preferences-view';
import OnboardContentModelView from './components/content/components/content/content-view';
import OnboardAdminsModalView from './components/admins/components/admins/admins-view';
import OnboardExpertsModalView from './components/experts/components/experts/experts-view';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`onboard-ent-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OnboardEntAcc = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isOnBoardingDone = useSelector(selectIsOnBoardingDone);
  const topContent = (
    <Grid container alignItems="center">
      <Grid item xs={10}>
        <Grid container alignItems="center">
          <Grid item>
            <h3>Let's set up your account</h3>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  const status = useSelector(selectStatus);
  const stateLoading = useSelector(selectStateLoader);
  const userPermission = useSelector(selectPermission);

  React.useEffect(() => {
    dispatch(onboardEntAccActions.setId(id as string));
    dispatch(onboardEntAccActions.getEntAcc());
    dispatch(onboardEntAccActions.getAdminByEnt());
    dispatch(onboardEntAccActions.getExpertByEnt());
  }, []);

  React.useEffect(() => {
    if (isOnBoardingDone) {
      window.location.pathname = '/';
    }
  }, [isOnBoardingDone]);

  // checking user whether a group admin and onboarding is still not done
  if (!isOnBoardingDone && (userPermission.length === 1 && userPermission[0] === 'groups')) {
    return (
      <div>
        <OnboardDashboardLayout topContent={undefined}>
          <Grid container flexDirection="column" justifyContent="center" alignItems="center" sx={{ margin: 'auto', height: '80vh' }}>
            <Error color="warning" style={{ fontSize: 80, marginBottom: 16 }} />
            <h1 style={{ margin: 0 }}>
              This organization is not yet set up on Slocoach.
            </h1>
            <h1 style={{ margin: 0 }}>
              Please contact your admin to complete the setup and gain access to this page.
            </h1>
          </Grid>
        </OnboardDashboardLayout>
      </div>
    );
  }

  return (
    <div>
      <OnboardDashboardLayout topContent={topContent}>
        {(stateLoading) ? <Grid container justifyContent="center" alignItems="center" sx={{ margin: 'auto', height: '80vh' }}><CircularProgress /></Grid> : (
          <>
            <Grid container justifyContent="space-between" sx={{ width: '100%', marginTop: '1rem' }}>
              <Grid item xs={12}>
                <Box>
                  <Routes>
                    <Route path="*" />
                  </Routes>
                  <Tabs value={Math.min(status, 6)}>
                    <Tab
                      className={status >= 1 ? 'tab completed' : 'tab'}
                      label="1. General information"
                      {...a11yProps(0)}
                      disabled={(status > 6)}
                      icon={status >= 1 ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => dispatch(onboardEntAccActions.setStatus(0))}
                    />
                    <Tab
                      className={status >= 2 ? 'tab completed' : 'tab'}
                      label="2. Site preferences"
                      {...a11yProps(1)}
                      disabled={(status > 6) || (status < 1)}
                      icon={status >= 2 ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => dispatch(onboardEntAccActions.setStatus(1))}
                    />
                    <Tab
                      className={status >= 3 ? 'tab completed' : 'tab'}
                      label="3. Content"
                      {...a11yProps(2)}
                      disabled={(status > 6) || (status < 2)}
                      icon={status >= 3 ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => dispatch(onboardEntAccActions.setStatus(2))}
                    />
                    <Tab
                      className={status >= 4 ? 'tab completed' : 'tab'}
                      label="4. Payments"
                      {...a11yProps(3)}
                      disabled={(status > 6) || (status < 3)}
                      icon={status >= 4 ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => dispatch(onboardEntAccActions.setStatus(3))}
                    />
                    <Tab
                      className={status >= 5 ? 'tab completed' : 'tab'}
                      label="5. Admins"
                      {...a11yProps(4)}
                      disabled={(status > 6) || (status < 4)}
                      icon={status >= 5 ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => dispatch(onboardEntAccActions.setStatus(4))}
                    />
                    <Tab
                      className={status >= 6 ? 'tab completed' : 'tab'}
                      label="6. Experts"
                      {...a11yProps(5)}
                      disabled={(status > 6) || (status < 5)}
                      icon={status >= 6 ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => dispatch(onboardEntAccActions.setStatus(5))}
                    />
                  </Tabs>
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-start" sx={{ width: '100%', marginTop: '1rem' }}>
              <Grid item xs={6.5}>
                <TabPanel value={status} index={0}>
                  <OnboardGeneralInformationModalView />
                </TabPanel>
                <TabPanel value={status} index={1}>
                  <OnboardSitePreferencesModalView />
                </TabPanel>
                <TabPanel value={status} index={2}>
                  <OnboardContentModelView />
                </TabPanel>
                <TabPanel value={status} index={3}>
                  <OnboardChargesModalView />
                </TabPanel>
                <TabPanel value={status} index={4}>
                  <OnboardAdminsModalView />
                </TabPanel>
                <TabPanel value={status} index={5}>
                  <OnboardExpertsModalView />
                </TabPanel>
              </Grid>
            </Grid>
          </>
        )}

      </OnboardDashboardLayout>
    </div>
  );
};

export default OnboardEntAcc;
