import * as React from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { PrimarySelect, PrimaryTable } from 'components';
import { IOption } from 'interfaces';
import { getOptionsFromEnum } from 'utils/data-utils';
import { AuthorityLevels } from 'config';
import { selectUser } from 'base/auth/selectors';
import { AdminDocument } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/interface';
import { onboardEntAccActions } from '../../../../slice';

interface EntAdminProps {
  entAdmins: AdminDocument[];
  loading: boolean;
  onDelete: (row: AdminDocument) => void;
}

export default function EntAdminTable({ entAdmins, loading, onDelete }: EntAdminProps) {
  const currentAdmin = useSelector(selectUser);
  type Row = typeof entAdmins[number];

  const authorityLevelOptions: IOption[] = getOptionsFromEnum(AuthorityLevels);

  const dispatch = useDispatch();

  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'firstName',
        headerName: 'Name',
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <div>
            {params.row.firstName}
            {' '}
            {params.row.lastName}
          </div>
        ),
      },
      {
        field: 'email',
        headerName: 'Email address',
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'attributes',
        headerName: 'Authority Level',
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <PrimarySelect
            label=""
            value={params.row.attributes.authorityLevel}
            onChange={(e: { target: { value: string; }; }) => {
              dispatch(onboardEntAccActions.changeAdminAuthorityLevel({
                id: params.row.id,
                authorityLevel: e.target.value,
              }));
            }}
            options={authorityLevelOptions}
            placeholder="Select Authority level"
          />
        ),
      },
      {
        field: 'actions',
        headerName: '',
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <IconButton
            onClick={() => onDelete(params.row)}
            disabled={(params.row.email === currentAdmin.email)}
          >
            <ClearIcon sx={{ color: (params.row.email === currentAdmin.email) ? 'grey' : 'red' }} />
          </IconButton>
        ),
      },
    ],
    [],
  );

  return (
    <PrimaryTable disableSelectionOnClick rows={entAdmins} columns={columns} loading={loading} />
  );
}
