/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { UploadIcon } from 'assets/icons';
import './index.scss';
import handleContentDataUpload, { extensionToType, uploadConfig } from 'utils/file-uploader';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { PrimaryLoadingOutlinedButton } from 'components/molecules';
import ProgressBar from 'components/molecules/progress-bar';
import { isStreamableVideoAvailable } from 'utils/data-utils';
import ContentMediaView from '../content-media-view';

interface FileDragUploaderProps {
  textLabel:string,
  handleFile: (res:UploadResponse)=> void,
  currentContentUrl:string,
  scenario:UploadScenarios,
  uploadAnotherText:string,
  currentMediaType:string,
  currentContentName?: string,
}

const FileDragUploader = ({
  handleFile, textLabel, currentContentUrl, scenario,
  uploadAnotherText, currentMediaType, currentContentName,
}:FileDragUploaderProps) => {
  const hiddenFileInput:any = React.useRef(null);
  const [contentUrl, setContentUrl] = React.useState(currentContentUrl);
  const [mediaType, setMediaType] = React.useState(currentMediaType);
  const [uploading, setUploading] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [fileName, setFileName] = React.useState(currentContentName);
  const [allowedExtension, setAllowedExtension] = React.useState('');

  const onUploadProgress = (event: any) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleFileWrapper = async (event:any) => {
    setUploading(true);
    const res = await handleContentDataUpload(
      event,
      currentContentUrl,
      scenario,
      onUploadProgress,
    );

    setTimeout(async () => {
      handleFile(res);
      setFileName(res.fileData?.fileName || `${res.name}${res.extension}`);
      setMediaType(extensionToType.get(`.${res.extension}`) as string);
      if (res.category === 'video') {
        setContentUrl(res.url);
        setUploading(false);
        setProcessing(true);
        await isStreamableVideoAvailable(res.url);
        setProcessing(false);
      } else {
        setContentUrl(res.url);
        setUploading(false);
      }
    }, 3000);
  };
    // drag state
  const [dragActive, setDragActive] = React.useState(false);

  // handle drag events
  const handleDrag = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleDrop = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileWrapper(e);
    }
  };
  // triggers when file is selected with click
  const handleChange = (e:any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFileWrapper(e);
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  React.useEffect(() => {
    const updatedAllowedExtension = (uploadConfig.get(scenario)?.allowedExtensions as string[]).join(',');
    setAllowedExtension(updatedAllowedExtension);
  }, [scenario]);
  return (
    <>
      <Box />
      {uploading ? (
        <Box className="file-upload-on-upload">
          <div style={{ textAlign: 'center' }}>
            {processing ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>Your video is processing..</Typography>
              </Box>
            ) : (
              <>
                <ProgressBar progress={progress} />
                <div>
                  {textLabel}
                </div>
              </>

            )}

          </div>
        </Box>
      ) : (!contentUrl || contentUrl === ''
        ? (
          <Box
            onDragEnter={handleDrag}
            className={dragActive ? 'drag-active' : ''}
          >
            <input type="file" id="input-file-upload" accept={allowedExtension} onChange={handleChange} ref={hiddenFileInput} />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
              <div style={{ textAlign: 'center' }}>
                <UploadIcon />
                <div>
                  {textLabel}
                </div>
              </div>
            </label>

            { dragActive && <input type="file" id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} /> }
          </Box>
        ) : (
          <>
            <div className="file-upload-content-media-view">
              <ContentMediaView url={contentUrl} mediaType={mediaType} fileName={fileName} />
            </div>

            <div>
              <input type="file" id="input-file-upload" accept={allowedExtension} onChange={handleChange} ref={hiddenFileInput} />
              <PrimaryLoadingOutlinedButton sx={{ padding: '0.5rem 0' }} onClick={handleClick} loading={uploading}>
                <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
                {uploadAnotherText}

              </PrimaryLoadingOutlinedButton>
            </div>
          </>
        )
      )}
    </>

  );
};

FileDragUploader.defaultProps = {
  currentContentName: '',
};

export default FileDragUploader;
