/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpertAccountType } from 'config';
import {
  CreateExpertState, CreateExpertStep, ICreateExpertInformationForm,
} from './interface';

export const initialState: CreateExpertState = {

  loading: false,
  status: CreateExpertStep.profileDetails,
  expertInformation: {
    accountType: ExpertAccountType.public,
    subscriptionFee: 0,
    subscriptionFrequency: ' ',
    firstName: ' ',
    lastName: ' ',
    categories: [],
    bio: '',
    email: '',
    profileImgUrl: '',
  },
  bankDetails: {
    bankName: '',
    accountNumber: '',
    branchName: '',
    accountHolder: '',

  },

};

export const createExpertSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/create-expert',
  initialState,
  reducers: {
    createExpert(state: CreateExpertState, action: PayloadAction<ICreateExpertInformationForm>) {
      state.loading = true;
      state.expertInformation = action.payload;
    },
    createExpertSucceeded(state: CreateExpertState) {
      state.loading = false;
      state.status = CreateExpertStep.successful;
    },
    createExpertFailed(state: CreateExpertState, _action: PayloadAction<any>) {
      state.loading = false;
      state.status = CreateExpertStep.profileDetails;
    },
    // AddExpertInformation(
    //   state: CreateExpertState,
    //   action: PayloadAction<ICreateExpertInformationForm>,
    // ) {
    //   state.expertInformation = action.payload;
    //   state.status = CreateExpertStep.bankDetails;
    // },
    setStatus(state:CreateExpertState, action:PayloadAction<number>) {
      state.status = action.payload;
    },

    reset: () => initialState,

  },
});

export const { actions: createExpertActions } = createExpertSlice;
