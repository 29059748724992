import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { Label } from 'components';
import { SessionType } from 'config';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ISessionAvatarWithAction } from '../../../../../../interface';
import { selectFilteredSessions, selectSessionLoader } from '../../../../../view-sessions/selector';
import { viewSessionsActions } from '../../../../../view-sessions/slice';
import { ICreateSessionForm } from '../../../../interface';
import { SelectSessionModal } from '../../../select-modals';
import { SelectedSessionTable } from '../../../selected-tables';

const CoursePrerequisites: React.FC = () => {
  const {
    control,
  } = useFormContext<ICreateSessionForm>();

  const { sessionId: id } = useParams();
  const dispatch = useDispatch();

  const { fields: prerequisites, append, remove } = useFieldArray({ name: 'prerequisites', control });

  const filteredSession = useSelector(selectFilteredSessions).result;
  const totalSessions = useSelector(selectFilteredSessions).total;
  const sessionLoading = useSelector(selectSessionLoader);
  const isPrequisite = true;

  const searchSession = (keyword: string, page: number) => {
    dispatch(viewSessionsActions.getSessions({
      keyword,
      page: page + 1,
      limit: 3,
      publicOnly: true,
      status: 'ACTIVE',
      withoutDeleted: true,
      type: [SessionType.course].toString(),
      isPrequisite,
      currentSessionId: id,
    }));
  };

  const updateSessions = (sessionList: ISessionAvatarWithAction[]) => {
    // eslint-disable-next-line max-len
    const addedSessions = sessionList.filter((i) => !prerequisites.map((x) => x._id).includes(i.id));
    // eslint-disable-next-line max-len
    const removedSessions = prerequisites.filter((i) => !sessionList.map((x) => x.id).includes(i._id!));
    if (addedSessions.length > 0) {
      append(sessionList.filter((i) => !prerequisites.map((x) => x._id).includes(i.id)));
    }

    removedSessions.forEach((i) => {
      remove(prerequisites.findIndex((session) => session._id === i._id));
    });
  };

  const handleSessionRemove = (sessionId: string) => {
    if (prerequisites.find((session) => session.id === sessionId)) {
      remove(prerequisites.findIndex((session) => session.id === sessionId));
    }
  };

  // eslint-disable-next-line max-len
  const memoizedPreqrequisites = useMemo(() => prerequisites.map((i) => ({ ...i, id: i._id, isAdded: true })) as any, [prerequisites]);

  return (
    <div className="form-item">
      <Label>Set prerequisites</Label>
      {prerequisites.length > 0 && (
        <>
          <Typography variant="h4">Courses added</Typography>
          <SelectedSessionTable
            key={JSON.stringify(prerequisites)}
            items={prerequisites as any}
            handelRemove={handleSessionRemove}
          />
        </>
      )}
      <div>
        <SelectSessionModal
          filteredSession={filteredSession}
          total={totalSessions}
          loading={sessionLoading}
          selectedSessions={memoizedPreqrequisites}
          handleUpdate={updateSessions}
          searchFunction={searchSession}
          isPrequisite={isPrequisite}
          label={(
            <div className="inline-wrapper">
              <AddIcon />
              Add course
              {' '}
            </div>
          )}
          title="Add course prerequisite"
          subTitle="Select courses that you wish to add as prerequisite to this course."
        />
      </div>
    </div>
  );
};

export default CoursePrerequisites;
