export enum CreateEntAccStep {
  generalInformation,
  userAllocation,
  charges,
  successful,
}
export interface IActiveRequest {
  active:boolean
}
export interface ICreateGeneralInformationForm {
  name :string,
  entUrl:string,
  email:string,
  country:string,
  city:string,
  slocoachAdmin:string,
  currency:string,
}
export interface IAddUserAllocationForm {
  maxNoOfGroups:number,
  maxNoOfCoaches:number,
  maxNoOfEndUsers:number
}
export interface IAddChargesForm {
  adminFee:number
}

export interface ICreateTenantPayload extends IAddChargesForm {
  resolve: any;
  reject: any;
}

export interface CreateEntAccState {
  active: boolean;
  loading:boolean;
  status:number;
  generalInformationForm:ICreateGeneralInformationForm;
  userAllocationForm:IAddUserAllocationForm;
  chargesForm:IAddChargesForm
}
