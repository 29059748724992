const API = {
  POST_SIGN_IN: {
    path: '/auth/sign-in',
    method: 'POST',
  },
  POST_SIGN_OUT: {
    path: '/auth/logout',
    method: 'POST',
  },
  POST_RESET_PASSWORD: {
    path: '/auth/change-password',
    method: 'POST',
  },
  POST_RESET_TEMP_PASSWORD: {
    path: '/auth/sign-in',
    method: 'POST',
  },
  GET_USER_DETAILS: {
    path: '/auth/admin/me',
    method: 'GET',
  },
  GET_ENT_ADMIN_ACC_DETAILS: {
    path: '/ent-admin/details',
    method: 'GET',
  },
  GET_ENT_ACC: {
    path: '/ent-account/details',
    method: 'GET',
  },
  POST_FORGOT_PASSWORD: {
    path: '/auth/forgot-password',
    method: 'POST',
  },
  POST_REFRESH_TOKEN: {
    path: '/auth/refresh-token',
    method: 'POST',
  },
};

export default API;
