import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { IEditFeedbackForm, IFeedBack } from 'pages/dashboard/components/feedback/interface';
import { ClosableModal } from 'components';
import { viewFeedbackActions } from 'pages/dashboard/components/feedback/slice';
import feedbackFormValidation from '../../validation/feedback-form-validation';
import FeedbackModalForm from '../feedback-modal-form';

interface FeedbackModalProps {
  feedback:IFeedBack,
  open:boolean
  handleClose:()=>void
}

export default function FeedbackModal({ open, feedback, handleClose }:FeedbackModalProps) {
  const dispatch = useDispatch();
  const methods = useForm<IEditFeedbackForm>({
    defaultValues: {
      status: feedback.status,
      adminComment: feedback.adminComment,
    },
    resolver: yupResolver(feedbackFormValidation),
    shouldFocusError: true,
  });

  const onSubmit: SubmitHandler<IEditFeedbackForm> = (data) => {
    dispatch(viewFeedbackActions.editFeedback({ ...feedback, ...data }));
  };
  return (

    <ClosableModal
      open={open}
      title={`Feedback ${feedback.feedbackId}`}
      onClose={handleClose}
      subTitle=""
    >
      <FormProvider {...methods}>
        <FeedbackModalForm
          onSubmit={onSubmit}
          feedback={feedback}
          closeModal={handleClose}
        />
      </FormProvider>
    </ClosableModal>
  );
}
