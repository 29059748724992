import { Route, Routes } from 'react-router-dom';
import ROUTES from 'constants/route';
import { SignInView } from 'pages/sign-in/components';
import { ResetPasswordView } from 'pages/reset-password/components';
import { ResetTempPasswordView } from 'pages/reset-temp-password/components';
import { ForgotPasswordView } from 'pages/forgot-password/components';
import { AuthWrapper } from 'base/auth/components';
import {
  Feedback,
  CreateEntAccount,
  EntAccount,
  EntAccountEdit,
  Overview,
  OnboardEntAcc,
  Plugins,
} from 'pages/dashboard';
import PreviewArticle from 'pages/dashboard/components/ent-account/components/edit-account/components/articles/components/create-article/components/article-information/preview-article';
import UnauthorizedView from 'pages/unauthorized';
import AuthorizationWrapper from 'base/auth/components/authorized-wrapper';
import { Role } from 'interfaces/user-roles';
import CreateSession from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/sessions/components/create-session';

// @TODO: This approach is good. But the issue when public routes are increase then array
// will be growing. Another thing is you have used 4 lines to stop the duplication of "Route".
const routers = [
  {
    path: ROUTES.LOGIN,
    component: SignInView,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    component: ResetPasswordView,
  },
  {
    path: ROUTES.RESET_TEMP_PASSWORD,
    component: ResetTempPasswordView,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPasswordView,
  },
  {
    path: ROUTES.HEALTH,
    component: () => <p>App Health ok</p>,
  },
  {
    path: ROUTES.UNAUTHORIZED,
    component: UnauthorizedView,
  },
];

const protectedRouters = [
  {
    path: ROUTES.DASHBOARD,
    component: Overview,
  },
  {
    path: ROUTES.CREATE_ENT_ACCOUNT,
    component: CreateEntAccount,
  },
  {
    path: ROUTES.ENT_EDIT,
    component: EntAccountEdit,
  },
  {
    path: ROUTES.FEEDBACK,
    component: Feedback,
  },
  {
    path: ROUTES.ARTICLE_PREVIEW,
    component: PreviewArticle,
  },
  {
    path: ROUTES.CREATE_COURSE_ONLY_SESSION,
    component: CreateSession,
    props: { courseOnlySession: true },
  },
  {
    path: ROUTES.ONBOARD_ENT_ACCOUNT,
    component: OnboardEntAcc,
  },
  {
    path: ROUTES.PLUGINS,
    component: Plugins,
  },
];

const protectedSuperAdminRouters = [
  {
    path: ROUTES.ENT_ACCOUNT,
    component: EntAccount,
  },
];

const Router = () => (
  <Routes>
    {routers.map((route) => (
      <Route element={<route.component />} key={route.path} path={route.path} />
    ))}
    {protectedRouters.map((route) => (
      <Route
        element={(
          <AuthWrapper>
            <route.component {...route.props} />
          </AuthWrapper>
        )}
        key={route.path}
        path={route.path}
      />
    ))}
    {protectedSuperAdminRouters.map((route) => (
      <Route
        element={(
          <AuthorizationWrapper roles={[Role.SuperAdmin]}>
            <route.component />
          </AuthorizationWrapper>
        )}
        key={route.path}
        path={route.path}
      />
    ))}
  </Routes>
);

export default Router;
