import * as yup from 'yup';

const courseGoalsFormValidation = yup.object().shape({
  goals: yup.array().of(yup.object().shape({
    title: yup.string().trim().max(50).label('Name'),
  })),

});

export default courseGoalsFormValidation;
