/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/edit-expert/news-feed'] || initialState;

export const selectNewsFeedState = createSelector([selectDomain], (NewsFeedState) => NewsFeedState);
export const selectNews = createSelector([selectDomain], (NewsFeedState) => NewsFeedState.news);
export const selectNewsLoader = createSelector([selectDomain], (NewsFeedState) => NewsFeedState.stateLoading);
export const selectLoading = createSelector([selectDomain], (NewsFeedState) => NewsFeedState.loading);
export const selectExpertId = createSelector([selectDomain], (NewsFeedState) => NewsFeedState.expertId);
export const selectNewsfeedsTotal = createSelector([selectDomain], (NewsFeedState) => NewsFeedState.total);
