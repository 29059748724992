const API = {
  POST_UPDATE_EXPERT: {
    path: '/expert/update-profile',
    method: 'POST',
  },
  GET_EXPERT: {
    path: '/expert/details',
    method: 'GET',
  },
  GET_EXPERT_USERS: {
    path: '/expert/users',
    method: 'GET',
  },
  DELETE_EXPERT_CHECK: {
    path: '/user-delete/steps',
    method: 'POST',
  },
  DELETE_EXPERT_CHECK_STATUS: {
    path: '/user-delete/status',
    method: 'POST',
  },
  DELETE_EXPERT: {
    path: '/user-delete',
    method: 'DELETE',
  },
  RECOVER_EXPERT: {
    path: '/account-recover',
    method: 'POST',
  },
  GET_CATEGORIES: {
    path: '/categories',
    method: 'GET',
  },
  POST_ASSIGN_CATEGORIES: {
    path: '/categories/assign',
    method: 'POST',
  },
};
export default API;
