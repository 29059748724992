/* eslint-disable react/destructuring-assignment */
import {
  Box, CircularProgress, CircularProgressProps, Typography,
} from '@mui/material';
import React from 'react';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <CircularProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(
            props.value,
          )}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const ProgressBar = ({ progress } : { progress: number }) => (
  <CircularProgressWithLabel value={progress} />
);

export default ProgressBar;
