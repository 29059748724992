import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { onboardEntAccActions } from '../../../../../slice';
import OnboardExpertsModalForm from '../experts-form';

const OnboardExpertsModalView = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(() => {
    dispatch(onboardEntAccActions.isOnboarding());
  }, [dispatch]);

  const methods = useForm<any>({
    defaultValues: {},
    shouldFocusError: true,
  });
  return (
    <Grid container justifyContent="center">
      <Grid xs item>
        <FormProvider {...methods}>
          <OnboardExpertsModalForm onSubmit={onSubmit} />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default OnboardExpertsModalView;
