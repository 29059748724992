import * as React from 'react';
import './index.scss';
import {
  Grid, IconButton, AppBar, Avatar, Menu, MenuItem, Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authSliceActions } from 'base/auth/slice';
import logoUrl from 'assets/images/SLOCOACH_White_Logo.png';
import { selectEntAccId, selectUser } from 'base/auth/selectors';
import useAuthorization from 'base/auth/hooks/use-authorization';
import { Role } from 'interfaces/user-roles';

const TopBar = () => {
  const { isAuthorized } = useAuthorization();
  const [anchorElDropDown, setAnchorElDropDown] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const signOut = React.useCallback(() => dispatch(authSliceActions.signOut()), [dispatch]);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDropDown(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElDropDown(null);
  };
  const user = useSelector(selectUser);
  const entId = useSelector(selectEntAccId);
  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: 'black',
        zIndex: 0.25,
        padding: 12,
        width: '100%',
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
        <Grid item xs sx={{ textAlign: 'left', display: 'inline-block', verticalAlign: 'middle' }}>
          <img src={logoUrl} alt="slocoach-logo" style={{ verticalAlign: 'middle' }} />
        </Grid>

        <Grid item container xs md={4} xl={6} sx={{ justifyContent: 'center' }}>
          <Grid item className="topbar-link-items">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'topbar-link-navs-active' : 'topbar-link-navs')}
            >
              <Typography variant="body1">Overview</Typography>
            </NavLink>

          </Grid>
          {isAuthorized([Role.SuperAdmin]) ? (
            <Grid item className="topbar-link-items">
              <NavLink
                to="/ent-account"
                className={({ isActive }) => (isActive ? 'topbar-link-navs-active' : 'topbar-link-navs')}
              >
                <Typography variant="body1">Enterprise accounts</Typography>
              </NavLink>

            </Grid>
          ) : (
            <Grid item className="topbar-link-items">
              <NavLink
                to={`/ent-account/edit/${entId}/general-information`}
                className={({ isActive }) => (isActive ? 'topbar-link-navs-active' : 'topbar-link-navs')}
              >
                <Typography variant="body1">Account</Typography>
              </NavLink>

            </Grid>
          )}
          <Grid item className="topbar-link-items">
            <NavLink
              to="/feedback"
              className={({ isActive }) => (isActive ? 'topbar-link-navs-active' : 'topbar-link-navs')}
            >
              <Typography variant="body1">Feedback</Typography>
            </NavLink>

          </Grid>
        </Grid>
        <Grid item xs container sx={{ justifyContent: 'flex-end', paddingRight: '1.5rem' }} alignItems="center">
          {/* <Grid item>
            <IconButton
              sx={{
                p: 0,
                color: 'white',
              }}
              aria-label="Save"
            >
              <NotificationsIcon sx={{
                height: '2rem', width: '2rem', padding: '0.3rem',
              }}
              />
            </IconButton>
          </Grid> */}
          <Grid item sx={{ paddingRight: '2rem' }}>
            <Grid container direction="row">
              <Grid item sx={{ mr: '0.7rem' }}>
                <Avatar src={user?.profileImgUrl} sx={{ width: '2.5rem', height: '2.5rem' }} />
              </Grid>
              <Grid item>
                <Grid item sx={{ fontSize: '0.8rem' }}>
                  <b>
                    {' '}
                    {user?.firstName}
                    {' '}
                    {user?.lastName}
                  </b>
                </Grid>
                <Grid item sx={{ fontSize: '0.8rem' }}>
                  {user?.email}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: 'white' }}>
              <ArrowDropDownIcon sx={{ height: '2rem', width: '2rem', padding: '0.3rem' }} />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElDropDown}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElDropDown)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <Typography onClick={() => signOut()} textAlign="center">logout</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default TopBar;
