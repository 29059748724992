enum FileExtensions {
  jpg = '.jpg',
  jpeg = '.jpeg',
  png = '.png',
  mp4 = '.mp4',
  gif = '.gif',
  woff = '.woff',
  woff2 = '.woff2',
  pdf = '.pdf',
  mkv = '.mkv',
  mov = '.mov',
  wmv = '.wmv',
  flv = '.flv',
  webm = '.webm',
  zip = '.zip',
}

export default FileExtensions;
