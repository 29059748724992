import { IFiltered } from 'interfaces/api-response';

export enum ModalStates {
  close = 'CLOSE',
  addContent = 'ADD_CONTENT',
  viewContent = 'VIEW_CONTENT',
  deleteContent = 'DELETE_CONTENT',
}

export interface IContentAvatar {
  _id:string,
  id?:string,
  thumbnail:string,
  title: string;
  fileType:string,
  fileCategory:string,
  description:string,
  contentUrl:string,
}
export interface IContentAvatarWithAction extends IContentAvatar {
  isAdded:boolean
}

export interface ICreateContentModalForm {
  contentUrl:string,
  title:string,
  description:string,
  fileType:string,
  fileName:string,
  path:string,
  fileCategory:string,
  isExternalFile:boolean,
}
export interface IDeleteContent {
  id:string,
  contentUrl:string,
}

export interface ContentDocument {
  _id:string
  expertId: string;
  thumbnail:string,
  title: string;
  description: string;
  fileType:string,
  path:string,
  fileCategory:string,
  contentUrl:string,
}
export interface IContent extends ContentDocument {
  modalState:string
}

export interface IContentFilterPayload {
  keyword: string;
  page: number;
  limit: number;
  sortBy: string;
  type?:string[];
}

export interface ViewContentState {
  stateLoading:boolean,
  filteredContent:IFiltered<IContent>,
  expertId:string,
  loading:boolean,
  modal:string,
}
export interface IOpenModal {
  id:string,
  state:ModalStates
}
