/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltered } from 'interfaces/api-response';
import { isUserDeleted } from 'utils/data-utils';
import { Role } from 'interfaces/user-roles';
import {
  ViewExpertState, IExpertRequest, ModalStates, IOpenModal,
  IPaginateFilterPayload,
} from './interface';
import { ExpertDocument } from '../../interface';

export const initialState: ViewExpertState = {
  experts: [],
  filteredExperts: { result: [], total: 0 },
  stateLoading: false,
  entId: '',
  loading: false,
};

export const viewExpertSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/view-expert',
  initialState,
  reducers: {
    getExperts(state:ViewExpertState) {
      state.stateLoading = true;
    },
    getExpertsSucceeded(state:ViewExpertState, action:PayloadAction<IExpertRequest>) {
      state.experts = action.payload.experts.map((expert) => (
        {
          ...expert,
          modalState: ModalStates.close,
          isDeleted: isUserDeleted(expert, Role.Expert),
        }));
      state.stateLoading = false;
    },
    getExpertsFailed(state:ViewExpertState) {
      state.stateLoading = false;
    },
    getFilteredExperts(state:ViewExpertState, _action:PayloadAction<IPaginateFilterPayload>) {
      state.stateLoading = true;
    },
    getFilteredExpertsSucceeded(
      state:ViewExpertState,
      action:PayloadAction<IFiltered<ExpertDocument>>,
    ) {
      state.filteredExperts.result = action.payload?.result.map((expert) => (
        {
          ...expert,
          modalState: ModalStates.close,
          isDeleted: isUserDeleted(expert, Role.Expert),
        }));
      state.filteredExperts.total = action.payload?.total;
      state.stateLoading = false;
    },
    getFilteredExpertsFailed(state:ViewExpertState) {
      state.stateLoading = false;
    },
    setEntId(state:ViewExpertState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    openModal(state:ViewExpertState, action:PayloadAction<IOpenModal>) {
      const temp = state.filteredExperts.result;
      temp.forEach((expert) => {
        if (expert.id === action.payload.id) {
          expert.modalState = action.payload.state;
        }
      });
      state.filteredExperts.result = [...temp];
    },
    closeModal(state:ViewExpertState, action:PayloadAction<string>) {
      const temp = state.filteredExperts.result;
      temp.forEach((expert) => {
        if (expert.id === action.payload) {
          expert.modalState = ModalStates.close;
        }
      });
      state.filteredExperts.result = [...temp];
    },
    resetPassword(state: ViewExpertState, _action:PayloadAction<string>) {
      state.loading = true;
    },
    resetPasswordSucceeded(state: ViewExpertState, action:PayloadAction<string>) {
      const temp = state.filteredExperts.result;
      temp.forEach((expert) => {
        if (expert.id === action.payload) {
          expert.modalState = ModalStates.close;
        }
      });
      state.filteredExperts.result = [...temp];
      state.loading = false;
    },
    resetPasswordFailed(state: ViewExpertState) {
      state.loading = false;
    },
  },
});

export const { actions: viewExpertActions } = viewExpertSlice;
