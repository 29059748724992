import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { selectId } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import { viewContentActions } from './slice';
import API from './constants';
import { selectExpertId } from './selector';
import {
  ContentDocument, IContentFilterPayload,
  ICreateContentModalForm, IDeleteContent,
} from './interface';

export function* getContentGenerator({ payload }:PayloadAction<IContentFilterPayload>): any {
  try {
    const expertId = yield select(selectExpertId);
    const entId = yield select(selectId);
    if (expertId && entId) {
      const response: IRestApiResponse = yield call(
        request,
        { path: `${API.GET_ALL_EXPERT_CONTENT_BY_EXPERT.path}/${entId}/${expertId}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&sortBy=${payload.sortBy}&type=${payload.type?.toString()}`, method: API.GET_ALL_EXPERT_CONTENT_BY_EXPERT.method },
        null,
        true,
      );
      if (response.statusCode === 200) {
        yield put(viewContentActions.getContentSucceeded(response.data));
      } else {
        yield put(viewContentActions.getContentFailed());
      }
    }
    throw new Error('Expert or ent account not defined');
  } catch (error) {
    yield put(viewContentActions.getContentFailed());
  }
}

export function* createContentGenerator({ payload }:PayloadAction<ICreateContentModalForm>): any {
  try {
    const expertId = yield select(selectExpertId);
    const entId = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.CREATE_EXPERT_CONTENT,
      { ...payload, expertId, entId },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewContentActions.createContentSucceeded(response.data as ContentDocument));
    } else {
      yield put(viewContentActions.createContentFailed());
    }
  } catch (error) {
    yield put(viewContentActions.createContentFailed());
  }
}
export function* deleteContentGenerator({ payload }:PayloadAction<IDeleteContent>): any {
  try {
    const expertId = yield select(selectExpertId);
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_CONTENT,
      { ...payload, expertId },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewContentActions.deleteContentSucceeded(payload.id));
    } else {
      yield put(viewContentActions.deleteContentFailed());
    }
  } catch (error) {
    yield put(viewContentActions.deleteContentFailed());
  }
}

export function* viewContentSaga() {
  yield all([takeLatest(
    viewContentActions.getContent.type,
    getContentGenerator,
  ), takeLatest(
    viewContentActions.createContent.type,
    createContentGenerator,
  ),
  takeLatest(
    viewContentActions.deleteContent.type,
    deleteContentGenerator,
  )]);
}

export default viewContentSaga;
