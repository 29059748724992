/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isUserDeleted } from 'utils/data-utils';
import { Role } from 'interfaces/user-roles';
import { ModalStates } from '../../../../interface';
import {
  IDeleteUser, IOpenModal, IUserPaginateFilterPayload, IUserRequest, ViewUserState,
} from './interface';

export const initialState: ViewUserState = {
  stateLoading: false,
  filteredUsers: { result: [], total: 0 },
  entId: '',
  loading: false,
};

export const viewUserSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/user/view-user',
  initialState,
  reducers: {
    viewFilteredUser(state: ViewUserState, _action:PayloadAction<IUserPaginateFilterPayload>) {
      state.stateLoading = true;
    },
    viewFilteredUserSucceeded(state: ViewUserState, action:PayloadAction<IUserRequest>) {
      state.filteredUsers.result = action.payload.result.map((user) => ({
        ...user,
        modalState: ModalStates.close,
        isDeleted: isUserDeleted(user, Role.User),
      }));
      state.filteredUsers.total = action.payload.total;
      state.stateLoading = false;
    },
    viewFilteredUserFailed(state:ViewUserState) {
      state.stateLoading = false;
    },
    setEntId(state : ViewUserState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    openModal(state:ViewUserState, action:PayloadAction<IOpenModal>) {
      const temp = state.filteredUsers.result;
      temp.forEach((user) => {
        if (user.id === action.payload.id) {
          user.modalState = action.payload.state;
        }
      });
      state.filteredUsers.result = [...temp];
    },
    closeModal(state:ViewUserState, action:PayloadAction<string>) {
      const temp = state.filteredUsers.result;
      temp.forEach((user) => {
        if (user.id === action.payload) {
          user.modalState = ModalStates.close;
        }
      });
      state.filteredUsers.result = [...temp];
    },

    deleteUser(state: ViewUserState, _action:PayloadAction<IDeleteUser>) {
      state.loading = true;
    },
    deleteUserSucceeded(state: ViewUserState, action:PayloadAction<string>) {
      const temp = state.filteredUsers.result.filter((user) => user.id !== action.payload);
      state.filteredUsers.result = [...temp];
      state.loading = false;
    },
    deleteUserFailed(state: ViewUserState) {
      state.loading = false;
    },
    resetPassword(state: ViewUserState, _action:PayloadAction<string>) {
      state.loading = true;
    },
    resetPasswordSucceeded(state: ViewUserState, action:PayloadAction<string>) {
      const temp = state.filteredUsers.result;
      temp.forEach((user) => {
        if (user.id === action.payload) {
          user.modalState = ModalStates.close;
        }
      });
      state.filteredUsers.result = [...temp];
      state.loading = false;
    },
    resetPasswordFailed(state: ViewUserState) {
      state.loading = false;
    },
  },
});

export const { actions: viewUserActions } = viewUserSlice;
