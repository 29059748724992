import { Box, Grid } from '@mui/material';
import Link from '@mui/material/Link';
import { DashboardLayout, PrimaryButton } from 'components';
import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ROUTE from 'constants/route';
import { ROUTE as LOCAL_ROUTES } from './constants';

const PluginsRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<h1>hello..</h1>} />
    <Route path={LOCAL_ROUTES.CREATE_PLUGIN} element={<h1>create</h1>} />
    <Route path={LOCAL_ROUTES.EDIT_PLUGIN} element={<h1>edit</h1>} />
  </Routes>
);

const PluginsView: React.FC = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Home
    </Link>,
    <Link underline="hover" key="2" color="text.primary" href={ROUTE.PLUGINS}>
      Plugins
    </Link>,
  ];

  const topContent = (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <h1>Plugins</h1>
      <NavLink style={{ textDecoration: 'none' }} to={`${ROUTE.PLUGINS.replace('/*', '')}${LOCAL_ROUTES.CREATE_PLUGIN}`}>
        <PrimaryButton size="large">
          <AddIcon sx={{ marginRight: '5px' }} />
          Create new
        </PrimaryButton>
      </NavLink>
    </Box>
  );

  return (
    <div>
      <DashboardLayout breadcrumbs={breadcrumbs} topContent={topContent}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ margin: '1rem 0' }}
        >
          <PluginsRoutes />
        </Grid>
      </DashboardLayout>
    </div>
  );
};

export default PluginsView;
