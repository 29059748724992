const API = {
  CREATE_EXPERT_CONTENT: {
    path: '/expert-content/create',
    method: 'POST',
  },
  GET_EXPERT_CONTENT: {
    path: '/expert-content/details',
    method: 'GET',
  },
  GET_ALL_EXPERT_CONTENT_BY_EXPERT: {
    path: '/expert-content/sort-search',
    method: 'GET',
  },
  DELETE_CONTENT: {
    path: '/expert-content/delete',
    method: 'DELETE',
  },

};
export default API;
