const API = {
  GET_FEEDBACK: {
    path: '/feedback/admin/search',
    method: 'GET',
  },
  POST_FEEDBACK: {
    path: '/feedback/update',
    method: 'POST',
  },
  GET_FEEDBACK_BY_ENT: {
    path: '/feedback/details/ent/:id',
    method: 'GET',
  },
};
export default API;
