const API = {
  GET_NEWS_FEED_BY_EXPERT: {
    path: '/newsfeed/details/expert',
    method: 'GET',
  },
  POST_PIN_NEWS_FEED: {
    path: '/newsfeed/pin-newsfeed',
    method: 'POST',
  },
  DELETE_NEWS_FEED: {
    path: '/newsfeed/delete',
    method: 'DELETE',
  },
};
export default API;
