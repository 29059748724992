import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { IOnboardAdminModalForm } from '../../../../../interface';
import { selectAdminForm } from '../../../../../selector';
import { onboardEntAccActions } from '../../../../../slice';
import OnboardAdminsModalForm from '../admins-form';

const OnboardAdminsModalView = () => {
  const dispatch = useDispatch();
  const adminsForm = useSelector(selectAdminForm);
  const onSubmit: SubmitHandler<any> = useCallback(
    (data) => {
      dispatch(onboardEntAccActions.setStatus(5));
    },
    [dispatch],
  );
  const methods = useForm<IOnboardAdminModalForm>({
    defaultValues: {
      admins: adminsForm.admins,
    },
    shouldFocusError: true,
  });

  return (
    <Grid container justifyContent="center">
      <Grid xs item>
        <FormProvider {...methods}>
          <OnboardAdminsModalForm onSubmit={onSubmit} />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default OnboardAdminsModalView;
