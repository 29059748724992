/* eslint-disable react/require-default-props */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';

export const CalenderIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <g id="calendar">
      <path id="Vector" d="M0,0H16V16H0Z" fill="none" opacity="0" />
      <path id="Vector-2" data-name="Vector" d="M9.141,1.54V.5a.5.5,0,0,0-1,0v1H3.807V.5a.5.5,0,0,0-1,0V1.54A2.832,2.832,0,0,0,0,4.373a.335.335,0,0,0,.333.353h11.28a.331.331,0,0,0,.333-.353A2.832,2.832,0,0,0,9.141,1.54Z" transform="translate(2.026 0.833)" fill="currentColor" />
      <path id="Vector-3" data-name="Vector" d="M11.333,0H.667A.669.669,0,0,0,0,.667V4.773A2.99,2.99,0,0,0,3.333,8.107H8.667A2.99,2.99,0,0,0,12,4.773V.667A.669.669,0,0,0,11.333,0ZM4.14,5.58a1.05,1.05,0,0,1-.1.08.5.5,0,0,1-.12.06.424.424,0,0,1-.12.04.9.9,0,0,1-.133.013.664.664,0,0,1-.253-.053.688.688,0,0,1-.22-.14.676.676,0,0,1,0-.947.688.688,0,0,1,.22-.14.637.637,0,0,1,.387-.04.424.424,0,0,1,.12.04.5.5,0,0,1,.12.06l.1.08a.676.676,0,0,1,0,.947Zm0-2.333a.676.676,0,0,1-.947,0,.676.676,0,0,1,0-.947.666.666,0,0,1,.727-.14.622.622,0,0,1,.22.14.676.676,0,0,1,0,.947ZM6.473,5.58a.669.669,0,0,1-.947-.947.669.669,0,0,1,.947.947Zm0-2.333-.1.08a.5.5,0,0,1-.12.06.424.424,0,0,1-.12.04A.9.9,0,0,1,6,3.44a.66.66,0,0,1-.667-.667A.7.7,0,0,1,5.527,2.3a.622.622,0,0,1,.22-.14.666.666,0,0,1,.727.14.676.676,0,0,1,0,.947ZM8.807,5.58a.669.669,0,0,1-.947-.947.669.669,0,0,1,.947.947Zm0-2.333-.1.08a.5.5,0,0,1-.12.06.424.424,0,0,1-.12.04,1,1,0,0,1-.133.013.66.66,0,0,1-.667-.667A.7.7,0,0,1,7.86,2.3a.688.688,0,0,1,.22-.14.637.637,0,0,1,.387-.04.424.424,0,0,1,.12.04.5.5,0,0,1,.12.06l.1.08a.676.676,0,0,1,0,.947Z" transform="translate(2 6.56)" fill="currentColor" />
    </g>
  </SvgIcon>
);

export const PlayIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} height="18px" width="18px" viewBox="0 0 18 18">
    <path
      id="Vector"
      d="M0,0H20V20H0Z"
      transform="translate(20 20) rotate(180)"
      fill="none"
      opacity="0"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M0,2.13V10.1a2.13,2.13,0,0,0,3.192,1.842L6.65,9.955l3.458-2a2.12,2.12,0,0,0,0-3.675l-3.458-2L3.192.288A2.127,2.127,0,0,0,0,2.13Z"
      transform="translate(4.415 3.887)"
      fill="#fff"
    />
  </SvgIcon>
);

export const GoogleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="15.66" height="15.999" viewBox="0 0 15.66 15.999">
    <g id="Google__G__Logo" transform="translate(26.754 -39.239)">
      <path
        id="Path_5579"
        data-name="Path 5579"
        d="M-7.094,50.752a9.269,9.269,0,0,0-.127-1.513h-7.533v3.007h4.313a3.714,3.714,0,0,1-1.6,2.387v2h2.573A7.822,7.822,0,0,0-7.094,50.752Z"
        transform="translate(-4 -3.333)"
        fill="#4285f4"
      />
      <path
        id="Path_5580"
        data-name="Path 5580"
        d="M-18.324,60a7.62,7.62,0,0,0,5.287-1.94l-2.573-2a4.826,4.826,0,0,1-2.713.773,4.778,4.778,0,0,1-4.487-3.307h-2.653v2.06A7.988,7.988,0,0,0-18.324,60Z"
        transform="translate(-0.43 -4.763)"
        fill="#34a853"
      />
      <path
        id="Path_5581"
        data-name="Path 5581"
        d="M-23.241,50.972a4.642,4.642,0,0,1-.253-1.527,4.823,4.823,0,0,1,.253-1.527v-2.06h-2.653a7.907,7.907,0,0,0-.86,3.587,7.907,7.907,0,0,0,.86,3.587Z"
        transform="translate(0 -2.207)"
        fill="#fbbc05"
      />
      <path
        id="Path_5582"
        data-name="Path 5582"
        d="M-18.324,42.406a4.34,4.34,0,0,1,3.067,1.2l2.28-2.28a7.649,7.649,0,0,0-5.347-2.087,7.988,7.988,0,0,0-7.14,4.413l2.653,2.06A4.778,4.778,0,0,1-18.324,42.406Z"
        transform="translate(-0.43)"
        fill="#ea4335"
      />
    </g>
  </SvgIcon>
);

export const LockIcon = () => (
  <SvgIcon height="18px" width="18px" viewBox="0 0 18 18">
    <g id="vuesax_bold_lock" data-name="vuesax/bold/lock" transform="translate(-172 -252)">
      <path
        id="Vector"
        d="M2.257,1.128A1.128,1.128,0,1,1,1.128,0,1.128,1.128,0,0,1,2.257,1.128Z"
        transform="translate(178.872 261.337)"
        fill="#b2b2b2"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M10.77,4.981V4.154C10.77,2.368,10.34,0,6.615,0S2.461,2.368,2.461,4.154v.827C.609,5.213,0,6.152,0,8.461v1.23c0,2.712.827,3.539,3.539,3.539H9.692c2.712,0,3.539-.827,3.539-3.539V8.461C13.231,6.152,12.622,5.213,10.77,4.981ZM6.615,11.074a2,2,0,1,1,2-2A2,2,0,0,1,6.615,11.074ZM3.539,4.922H3.387V4.154c0-1.938.549-3.228,3.228-3.228s3.228,1.29,3.228,3.228v.774h-6.3Z"
        transform="translate(173.385 253.385)"
        fill="#b2b2b2"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H16V16H0Z"
        transform="translate(188 268) rotate(180)"
        fill="none"
        opacity="0"
      />
    </g>
  </SvgIcon>
);

export const RemoveEmailTagIcon = () => (
  <SvgIcon height="16x" width="16px" viewBox="0 0 16 16">
    <g id="Group_11352" data-name="Group 11352" transform="translate(-0.402)">
      <rect
        id="Rectangle_6996"
        data-name="Rectangle 6996"
        width="16"
        height="16"
        transform="translate(0.402)"
        fill="none"
      />
      <g id="Group_10682" data-name="Group 10682" transform="translate(4.525 4.123)">
        <path
          id="Vector"
          d="M0,8.246,8.246,0"
          fill="none"
          stroke="#b2b2b2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M8.246,8.246,0,0"
          fill="none"
          stroke="#b2b2b2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </SvgIcon>
);

export const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <g
      id="vuesax_linear_search-normal"
      data-name="vuesax/linear/search-normal"
      transform="translate(-428 -188)"
    >
      <g id="search-normal" transform="translate(428 188)">
        <path
          id="Vector"
          d="M11.083,5.542A5.542,5.542,0,1,1,5.542,0,5.542,5.542,0,0,1,11.083,5.542Z"
          transform="translate(1.167 1.167)"
          fill="none"
          stroke="#b2b2b2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M1.167,1.167,0,0"
          transform="translate(11.667 11.667)"
          fill="none"
          stroke="#b2b2b2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H14V14H0Z"
          transform="translate(0 0)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </SvgIcon>
);

export const GiftCardIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 34 34">
    <g id="Group_2381" data-name="Group 2381" transform="translate(-278)">
      <circle
        id="Ellipse_470"
        data-name="Ellipse 470"
        cx="17"
        cy="17"
        r="17"
        transform="translate(278)"
        fill="#9d8336"
        opacity="0.2"
      />
      <g id="vuesax_bold_tag" data-name="vuesax/bold/tag" transform="translate(-270 -436)">
        <g id="tag" transform="translate(556 444)">
          <path
            id="Vector"
            d="M12.8,4.445l-3.4-3.4A3.563,3.563,0,0,0,6.7,0l-3.75.18A2.9,2.9,0,0,0,.184,2.937L0,6.687a3.591,3.591,0,0,0,1.042,2.7l3.4,3.4a3.585,3.585,0,0,0,5.063,0L12.8,9.492A3.554,3.554,0,0,0,12.8,4.445ZM5.051,7.2a2.16,2.16,0,1,1,2.16-2.16A2.161,2.161,0,0,1,5.051,7.2Z"
            transform="translate(2.074 2.08)"
            fill="#9d8336"
          />
          <path id="Vector-2" data-name="Vector" d="M0,0H18V18H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const MeatballIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g id="Group_1768" data-name="Group 1768" transform="translate(7350 6100)">
      <path
        id="Vector"
        d="M2.083,0A2.083,2.083,0,1,0,4.167,2.083,2.089,2.089,0,0,0,2.083,0Z"
        transform="translate(-7347.917 -6092.083)"
        fill="none"
        stroke="#9d8336"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M2.083,0A2.083,2.083,0,1,0,4.167,2.083,2.089,2.089,0,0,0,2.083,0Z"
        transform="translate(-7336.25 -6092.083)"
        fill="none"
        stroke="#9d8336"
        strokeWidth="1.5"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M2.083,0A2.083,2.083,0,1,0,4.167,2.083,2.089,2.089,0,0,0,2.083,0Z"
        transform="translate(-7342.083 -6092.083)"
        fill="none"
        stroke="#9d8336"
        strokeWidth="1.5"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M0,0H20V20H0Z"
        transform="translate(-7350 -6100)"
        fill="none"
        opacity="0"
      />
    </g>
  </SvgIcon>
);

export const TrashIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
    <g id="vuesax_bold_trash" data-name="vuesax/bold/trash" transform="translate(-108 -188)">
      <g id="trash" transform="translate(108 188)">
        <path
          id="Vector"
          d="M14.118,2.985c-1.208-.12-2.415-.21-3.63-.277V2.7l-.165-.975C10.21,1.035,10.045,0,8.29,0H6.325C4.578,0,4.413.99,4.293,1.717l-.157.96c-.7.045-1.395.09-2.092.158l-1.53.15A.561.561,0,1,0,.618,4.1l1.53-.15a59.8,59.8,0,0,1,11.865.158h.06a.568.568,0,0,0,.562-.51A.575.575,0,0,0,14.118,2.985Z"
          transform="translate(1.685 0.937)"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M11.1.293A.948.948,0,0,0,10.419,0H.939A.936.936,0,0,0,.257.293.966.966,0,0,0,0,1L.467,8.692c.082,1.14.187,2.565,2.8,2.565H8.087c2.617,0,2.723-1.417,2.805-2.565l.465-7.687A.972.972,0,0,0,11.1.293Z"
          transform="translate(3.321 5.813)"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H18V18H0Z"
          transform="translate(18 18) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </SvgIcon>
);

export const UploadIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
    <g id="vuesax_bold_export" data-name="vuesax/bold/export" transform="translate(-492 -444)">
      <g id="export" transform="translate(492 444)">
        <path
          id="Vector"
          d="M14.355,0H8.063V4.11a.563.563,0,0,1-1.125,0V0H.645A.64.64,0,0,0,0,.645a7.174,7.174,0,0,0,7.5,7.5A7.174,7.174,0,0,0,15,.645.64.64,0,0,0,14.355,0Z"
          transform="translate(1.5 7.425)"
          fill="#9d8336"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M3.263,1.918l1.17,1.17a.562.562,0,0,0,.795-.8L3.09.163a.566.566,0,0,0-.8,0L.165,2.3A.589.589,0,0,0,0,2.7a.556.556,0,0,0,.165.4.566.566,0,0,0,.8,0l1.17-1.17V4.993H3.255V1.918Z"
          transform="translate(6.3 2.432)"
          fill="#9d8336"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H18V18H0Z"
          transform="translate(18 18) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </SvgIcon>
);
export const GalleryIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <g
      id="vuesax_linear_gallery"
      data-name="vuesax/linear/gallery"
      transform="translate(-365 -244)"
    >
      <g id="gallery" transform="translate(365 244)">
        <path
          id="Vector"
          d="M6.952,19.863h5.959c4.966,0,6.952-1.986,6.952-6.952V6.952C19.863,1.986,17.877,0,12.911,0H6.952C1.986,0,0,1.986,0,6.952v5.959C0,17.877,1.986,19.863,6.952,19.863Z"
          transform="translate(2.068 2.068)"
          fill="none"
          stroke="#b2b2b2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M4.137,2.068A2.068,2.068,0,1,1,2.068,0,2.068,2.068,0,0,1,4.137,2.068Z"
          transform="translate(6.952 5.959)"
          fill="none"
          stroke="#b2b2b2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,7.19,4.889,3.767a2.161,2.161,0,0,1,2.618.145l.327.3a2.141,2.141,0,0,0,2.8,0L14.757.52a2.141,2.141,0,0,1,2.8,0L19.17,1.968"
          transform="translate(2.761 11.83)"
          fill="none"
          stroke="#b2b2b2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
      </g>
    </g>
  </SvgIcon>
);

// eslint-disable-next-line unused-imports/no-unused-vars
export const QuestionIcon = React.forwardRef((props: SvgIconProps, ref) => (
  <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
    <circle id="Ellipse_413" data-name="Ellipse 413" cx="7" cy="7" r="7" fill="#f2f2f2" />
    <path
      id="Path_5571"
      data-name="Path 5571"
      d="M1.532-3.047,1.488-4.883h.5a4.786,4.786,0,0,0,1.017-.1,1.384,1.384,0,0,0,.733-.389,1.186,1.186,0,0,0,.274-.846,1.112,1.112,0,0,0-.317-.84,1.162,1.162,0,0,0-.842-.306A1.154,1.154,0,0,0,2-7.059,1.119,1.119,0,0,0,1.7-6.23H.372A2.3,2.3,0,0,1,.656-7.42a2.087,2.087,0,0,1,.859-.823,2.836,2.836,0,0,1,1.351-.3,2.892,2.892,0,0,1,1.3.278,2.135,2.135,0,0,1,.886.8,2.341,2.341,0,0,1,.323,1.263,2.242,2.242,0,0,1-.334,1.268,2.013,2.013,0,0,1-.908.745A3.5,3.5,0,0,1,2.8-3.938l-.033.89Zm.624,2.5a.854.854,0,0,1-.629-.245.8.8,0,0,1-.246-.59.815.815,0,0,1,.246-.6.854.854,0,0,1,.629-.245.836.836,0,0,1,.624.245.824.824,0,0,1,.241.6.811.811,0,0,1-.241.59A.836.836,0,0,1,2.155-.544Z"
      transform="translate(4.328 11.545)"
    />
  </SvgIcon>
));

export const EditIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g id="edit-2">
      <path
        id="Vector"
        d="M15.524,1.285H.621A.637.637,0,0,1,0,.643.637.637,0,0,1,.621,0h14.9a.637.637,0,0,1,.621.643A.637.637,0,0,1,15.524,1.285Z"
        transform="translate(1.928 17.001)"
        fill="#9d8336"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M6.13,1.263c-1.662-1.662-3.29-1.7-4.995,0L.1,2.3a.357.357,0,0,0-.086.343A6.964,6.964,0,0,0,4.751,7.38a.43.43,0,0,0,.1.017.343.343,0,0,0,.248-.1L6.13,6.258A3.541,3.541,0,0,0,7.39,3.782,3.528,3.528,0,0,0,6.13,1.263Z"
        transform="translate(9.603 1.719)"
        fill="#9d8336"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M10.381,4.7c-.248-.12-.488-.24-.72-.377-.188-.111-.368-.231-.548-.36a5.173,5.173,0,0,1-.48-.368,1.045,1.045,0,0,1-.146-.129A7.246,7.246,0,0,1,7.6,2.57a1.044,1.044,0,0,1-.129-.154,4.983,4.983,0,0,1-.36-.471,4.7,4.7,0,0,1-.334-.505c-.137-.231-.257-.463-.377-.7C6.285.48,6.191.231,6.106,0L.725,5.381a1.031,1.031,0,0,0-.24.471L.022,9.133a1.756,1.756,0,0,0,.437,1.5,1.705,1.705,0,0,0,1.2.463,1.873,1.873,0,0,0,.308-.026l3.29-.463a.954.954,0,0,0,.471-.24l5.381-5.381C10.869,4.9,10.638,4.806,10.381,4.7Z"
        transform="translate(2.993 4.875)"
        fill="#9d8336"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M0,0H20V20H0Z"
        transform="translate(20 20) rotate(180)"
        fill="none"
        opacity="0"
      />
    </g>
  </SvgIcon>
);

export const PinIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="19.907" height="20" viewBox="0 0 19.907 20">
    <g id="Group_10871" data-name="Group 10871" transform="translate(-394.059 -341.966)">
      <rect
        id="Rectangle_7031"
        data-name="Rectangle 7031"
        width="19.907"
        height="19.907"
        transform="translate(394.059 342.059)"
        fill="none"
      />
      <path
        id="Icon_metro-pin"
        data-name="Icon metro-pin"
        d="M19.8,17.639l-6.752-3.854a1.743,1.743,0,0,0-2.367.626,1.7,1.7,0,0,0,.634,2.34l6.751,3.854a1.742,1.742,0,0,0,2.367-.627,1.7,1.7,0,0,0-.633-2.339Zm-5.568-4.167,5.251,3,1.786-4.914L17.639,9.486l-3.411,3.986ZM10.356,26.1l5.023-6.033-2.251-1.285L10.356,26.1ZM23.435,8.838l-4.5-2.569a1.307,1.307,0,0,0-1.775.47,1.276,1.276,0,0,0,.476,1.755l4.5,2.569a1.307,1.307,0,0,0,1.775-.47,1.277,1.277,0,0,0-.476-1.755Z"
        transform="translate(387.337 335.87)"
        fill="#9d8336"
      />
    </g>
  </SvgIcon>
);

export const MessageIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g id="vuesax_bold_message" data-name="vuesax/bold/message" transform="translate(-684 -188)">
      <g id="message" transform="translate(684 188)">
        <path
          id="Vector"
          d="M12.419,0H4.14A4.132,4.132,0,0,0,0,4.123V9.9a4.132,4.132,0,0,0,4.14,4.123H5.382a.952.952,0,0,1,.662.331L7.286,16a1.176,1.176,0,0,0,1.987,0l1.242-1.648a.839.839,0,0,1,.662-.331h1.242A4.132,4.132,0,0,0,16.559,9.9V4.123A4.132,4.132,0,0,0,12.419,0ZM4.968,8.279A.828.828,0,1,1,5.8,7.451.825.825,0,0,1,4.968,8.279Zm3.312,0a.828.828,0,1,1,.828-.828A.825.825,0,0,1,8.28,8.279Zm3.312,0a.828.828,0,1,1,.828-.828A.825.825,0,0,1,11.591,8.279Z"
          transform="translate(1.72 1.72)"
          fill="#b2b2b2"
        />
        <path id="Vector-2" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0" />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          transform="translate(20 20) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </SvgIcon>
);

export const SendIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g id="vuesax_bold_send-2" data-name="vuesax/bold/send-2" transform="translate(-300 -316)">
      <g id="send-2" transform="translate(300 316)">
        <path
          id="Vector"
          d="M11.365.332,3.809,2.842C-1.27,4.54-1.27,7.31,3.809,9l2.242.745L6.8,11.987c1.69,5.079,4.468,5.079,6.158,0L15.473,4.44C16.594,1.051,14.753-.8,11.365.332Zm.268,4.5-3.18,3.2a.626.626,0,0,1-.887,0,.631.631,0,0,1,0-.887l3.18-3.2a.627.627,0,1,1,.887.887Z"
          transform="translate(2.099 2.104)"
          fill="#b2b2b2"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          transform="translate(20 20) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </SvgIcon>
);

export const HearIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="19.907" height="20" viewBox="0 0 19.907 20">
    <g id="vuesax_bold_heart" data-name="vuesax/bold/heart" transform="translate(-236 -188)">
      <g id="heart" transform="translate(236 188)">
        <path
          id="Vector"
          d="M12.131,0A4.653,4.653,0,0,0,8.4,1.885,4.659,4.659,0,0,0,0,4.724a9.074,9.074,0,0,0,.437,2.8c1.327,4.226,5.419,6.752,7.443,7.445a1.821,1.821,0,0,0,1.042,0c2.025-.693,6.116-3.22,7.443-7.445a9.074,9.074,0,0,0,.437-2.8A4.7,4.7,0,0,0,12.131,0Z"
          transform="translate(1.599 2.479)"
          fill="#b2b2b2"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          transform="translate(20 20) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </SvgIcon>
);

export const ClipboardIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g
      id="vuesax_bold_clipboard"
      data-name="vuesax/bold/clipboard"
      transform="translate(-684 -188)"
    >
      <path
        id="Vector"
        d="M9.928.735,6.02.118C2.761-.39,1.253.71.736,3.968L.12,7.877c-.333,2.133.017,3.517,1.225,4.358a6.127,6.127,0,0,0,2.625.925l3.908.617c3.258.508,4.767-.592,5.283-3.85l.608-3.908a7.978,7.978,0,0,0,.108-1.725C13.77,2.21,12.545,1.143,9.928.735ZM5.22,6.127A1.763,1.763,0,1,1,6.978,4.368,1.766,1.766,0,0,1,5.22,6.127Z"
        transform="translate(685.647 189.665)"
        fill="#9d8336"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M13.425,4.717l-1.25,3.758c-1.042,3.133-2.708,3.967-5.842,2.925L2.575,10.15A5.916,5.916,0,0,1,0,8.667a8.544,8.544,0,0,0,1.825.475l3.917.617a9.745,9.745,0,0,0,1.5.125c2.75,0,4.225-1.483,4.733-4.675L12.583,1.3A7.581,7.581,0,0,0,12.7,0C13.967,1.042,14.15,2.525,13.425,4.717Z"
        transform="translate(687.658 194.508)"
        fill="#9d8336"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H20V20H0Z"
        transform="translate(704 208) rotate(180)"
        fill="none"
        opacity="0"
      />
    </g>
  </SvgIcon>
);

export const SquareMenuIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g id="vuesax_bold_menu" data-name="vuesax/bold/menu" transform="translate(-236 -256)">
      <g id="menu" transform="translate(236 256)">
        <path id="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0" />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M4.969,2.485A2.485,2.485,0,1,1,2.485,0,2.485,2.485,0,0,1,4.969,2.485Z"
          transform="translate(11.093 3.426)"
          fill="#b2b2b2"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M4.969,2.485A2.485,2.485,0,1,1,2.485,0,2.485,2.485,0,0,1,4.969,2.485Z"
          transform="translate(3.938 3.426)"
          fill="#b2b2b2"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M4.969,2.485A2.485,2.485,0,1,1,2.485,0,2.485,2.485,0,0,1,4.969,2.485Z"
          transform="translate(11.093 11.605)"
          fill="#b2b2b2"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M4.969,2.485A2.485,2.485,0,1,1,2.485,0,2.485,2.485,0,0,1,4.969,2.485Z"
          transform="translate(3.938 11.605)"
          fill="#b2b2b2"
        />
      </g>
    </g>
  </SvgIcon>
);

export const MonitorIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g id="vuesax_bold_monitor" data-name="vuesax/bold/monitor" transform="translate(-424 -190)">
      <g id="monitor" transform="translate(424 190)">
        <path
          id="Vector"
          d="M12.967,0H3.675A3.681,3.681,0,0,0,0,3.675V9.258a3.676,3.676,0,0,0,3.675,3.675h3.2a.836.836,0,0,1,.833.833v.808a.836.836,0,0,1-.833.833H4.858a.629.629,0,0,0,0,1.258h6.958a.625.625,0,0,0,0-1.25H9.8a.836.836,0,0,1-.833-.833v-.808a.836.836,0,0,1,.833-.833h3.175A3.676,3.676,0,0,0,16.65,9.267V3.683A3.7,3.7,0,0,0,12.967,0Z"
          transform="translate(1.667 1.667)"
          fill="#9d8336"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          transform="translate(20 20) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </SvgIcon>
);
export const ClipboardTextIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g
      id="vuesax_bold_clipboard-text"
      data-name="vuesax/bold/clipboard-text"
      transform="translate(-108 -252)"
    >
      <g id="clipboard-text" transform="translate(108 252)">
        <path
          id="Vector"
          d="M5.492,0H1.575A1.572,1.572,0,0,0,0,1.567V2.35A1.565,1.565,0,0,0,1.567,3.917H5.492A1.565,1.565,0,0,0,7.058,2.35V1.567A1.56,1.56,0,0,0,5.492,0Z"
          transform="translate(6.467 1.667)"
          fill="#9d8336"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M11.625.615A2.415,2.415,0,0,1,9.217,3.023H5.3A2.415,2.415,0,0,1,2.892.615.622.622,0,0,0,1.975.073,3.726,3.726,0,0,0,0,3.365v7.842a3.734,3.734,0,0,0,3.725,3.725h7.067a3.734,3.734,0,0,0,3.725-3.725V3.365A3.726,3.726,0,0,0,12.542.073.622.622,0,0,0,11.625.615ZM7.575,10.723H3.925a.625.625,0,0,1,0-1.25h3.65a.625.625,0,0,1,0,1.25ZM9.758,7.39H3.925a.625.625,0,0,1,0-1.25H9.758a.625.625,0,0,1,0,1.25Z"
          transform="translate(2.742 3.402)"
          fill="#9d8336"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          transform="translate(20 20) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </SvgIcon>
);

export const OutlineArrowSwapIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
    <g
      id="vuesax_outline_arrow-swap"
      data-name="vuesax/outline/arrow-swap"
      transform="translate(-684 -252)"
    >
      <g id="arrow-swap" transform="translate(684 252)">
        <path
          id="Vector"
          d="M4.8,5.423a.618.618,0,0,1-.442-.183L.181,1.065A.625.625,0,1,1,1.065.181L5.24,4.356a.629.629,0,0,1,0,.883A.655.655,0,0,1,4.8,5.423Z"
          transform="translate(2.71 12.285)"
          fill="#9d8336"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M.625,15.417A.63.63,0,0,1,0,14.792V.625A.63.63,0,0,1,.625,0,.63.63,0,0,1,1.25.625V14.792A.63.63,0,0,1,.625,15.417Z"
          transform="translate(6.883 2.292)"
          fill="#9d8336"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M4.8,5.423a.618.618,0,0,1-.442-.183L.181,1.065A.625.625,0,0,1,1.065.181L5.24,4.356a.629.629,0,0,1,0,.883A.618.618,0,0,1,4.8,5.423Z"
          transform="translate(11.877 2.294)"
          fill="#9d8336"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M.625,15.417A.63.63,0,0,1,0,14.792V.625A.63.63,0,0,1,.625,0,.63.63,0,0,1,1.25.625V14.792A.624.624,0,0,1,.625,15.417Z"
          transform="translate(11.867 2.292)"
          fill="#9d8336"
        />
        <path id="Vector-5" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0" />
      </g>
    </g>
  </SvgIcon>
);

export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32">
    <g id="Group_12582" data-name="Group 12582" transform="translate(-0.402)">
      <rect
        id="Rectangle_7264"
        data-name="Rectangle 7264"
        width="32"
        height="32"
        transform="translate(0.402)"
        fill="none"
      />
      <g id="Group_12214" data-name="Group 12214" transform="translate(8.647 8.246)">
        <path
          id="Vector"
          d="M0,16.492,16.493,0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M16.493,16.492,0,0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </SvgIcon>
);

export const CloseSquareIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32">
    <g id="close-square" transform="translate(0 0)">
      <path
        id="Vector"
        d="M1,9.543A.989.989,0,0,1,.29,9.25a1.006,1.006,0,0,1,0-1.413L7.837.29A1,1,0,0,1,9.25,1.7L1.7,9.25A.967.967,0,0,1,1,9.543Z"
        transform="translate(11.23 11.23)"
        fill="currentColor"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M8.543,9.543a.989.989,0,0,1-.707-.293L.29,1.7A1,1,0,0,1,1.7.29L9.25,7.837a1.006,1.006,0,0,1,0,1.413A.989.989,0,0,1,8.543,9.543Z"
        transform="translate(11.23 11.23)"
        fill="currentColor"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M18.333,28.667h-8C3.093,28.667,0,25.573,0,18.333v-8C0,3.093,3.093,0,10.333,0h8c7.24,0,10.333,3.093,10.333,10.333v8C28.667,25.573,25.573,28.667,18.333,28.667ZM10.333,2C4.187,2,2,4.187,2,10.333v8c0,6.147,2.187,8.333,8.333,8.333h8c6.147,0,8.333-2.187,8.333-8.333v-8C26.667,4.187,24.48,2,18.333,2Z"
        transform="translate(1.667 1.667)"
        fill="currentColor"
      />
      <path id="Vector-4" data-name="Vector" d="M0,0H32V32H0Z" fill="none" opacity="0" />
    </g>
  </SvgIcon>
);

export const TranslateIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <g id="vuesax_bold_translate" data-name="vuesax/bold/translate" transform="translate(-748 -316)">
      <g id="translate" transform="translate(748 316)">
        <path id="Vector" d="M0,0H20V20H0Z" transform="translate(20 20) rotate(180)" fill="none" opacity="0" />
        <path id="Vector-2" data-name="Vector" d="M5.781,0H2.519A2.222,2.222,0,0,0,0,2.519V5.79C0,7.508.834,8.342,2.519,8.3H5.79A2.158,2.158,0,0,0,8.3,5.781V2.519C8.342.834,7.508,0,5.781,0Zm.067,6.474a2.463,2.463,0,0,1-1.577-.6,3.086,3.086,0,0,1-1.819.6.626.626,0,1,1,0-1.251A1.935,1.935,0,0,0,4.271,3.837H2.453a.626.626,0,1,1,0-1.251H3.529a.62.62,0,0,1,1.235,0H5.84a.626.626,0,0,1,0,1.251H5.564a3.336,3.336,0,0,1-.442,1.118,1.217,1.217,0,0,0,.726.267.626.626,0,0,1,0,1.251Z" transform="translate(1.668 1.668)" fill="#9d8336" />
        <path id="Vector-3" data-name="Vector" d="M6.465,7.091A6.476,6.476,0,0,1,0,.626.63.63,0,0,1,.626,0a.63.63,0,0,1,.626.626,5.223,5.223,0,0,0,4.029,5.08l-.225-.375a.627.627,0,0,1,1.076-.642l.876,1.46a.622.622,0,0,1,.008.626A.652.652,0,0,1,6.465,7.091Z" transform="translate(1.043 11.866)" fill="#9d8336" />
        <path id="Vector-4" data-name="Vector" d="M6.471,7.091a.63.63,0,0,1-.626-.626,5.223,5.223,0,0,0-4.029-5.08l.225.375A.627.627,0,0,1,.965,2.4L.089.943A.622.622,0,0,1,.08.317.652.652,0,0,1,.631,0,6.476,6.476,0,0,1,7.1,6.465.63.63,0,0,1,6.471,7.091Z" transform="translate(11.861 1.043)" fill="#9d8336" />
        <path id="Vector-5" data-name="Vector" d="M4.238,0A4.238,4.238,0,1,0,8.476,4.238,4.239,4.239,0,0,0,4.238,0ZM6.307,6.248a.629.629,0,0,1-.843-.284l-.142-.284H3.162l-.142.284a.628.628,0,0,1-.559.342A.631.631,0,0,1,1.9,5.4L3.687,1.835a.634.634,0,0,1,.559-.342.627.627,0,0,1,.559.35L6.59,5.406A.636.636,0,0,1,6.307,6.248Z" transform="translate(9.856 9.864)" fill="#9d8336" />
        <path id="Vector-6" data-name="Vector" d="M0,.909H.909L.45,0Z" transform="translate(13.649 13.391)" fill="#9d8336" />
      </g>
    </g>
  </SvgIcon>
);

export const BoldTickCirlcIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" />
  </SvgIcon>
);
