/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/admin/view-admin'] || initialState;

export const selectViewAdminState = createSelector([selectDomain], (ViewAdminState) => ViewAdminState);
export const selectAdminStateLoader = createSelector([selectDomain], (ViewAdminState) => ViewAdminState.stateLoading);
export const selectDeleteCheckStatus = createSelector([selectDomain], (ViewAdminState) => ViewAdminState.deleteCheckStatus);
export const selectDeleteCheckId = createSelector([selectDomain], (ViewAdminState) => ViewAdminState.deleteCheckId);
export const selectLoader = createSelector([selectDomain], (ViewAdminState) => ViewAdminState.loading);
export const selectAdmins = createSelector([selectDomain], (ViewAdminState) => ViewAdminState.admins);
export const selectAdminList = createSelector([selectDomain], (ViewAdminState) => ViewAdminState.adminList);
export const selectEntId = createSelector([selectDomain], (ViewAdminState) => ViewAdminState.entId);
export const selectDeleteCheck = createSelector([selectDomain], (viewAdminState) => viewAdminState.deleteCheck);
