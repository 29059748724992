const API = {
  POST_CREATE_USER_BY_ADMIN: {
    path: '/user/admin-create',
    method: 'POST',
  },
  POST_CREATE_USER: {
    path: '/user/create',
    method: 'POST',
  },
  POST_UPDATE_USER: {
    path: '/user/update',
    method: 'POST',
  },
  POST_RESET_PASSWORD: {
    path: '/user/force-reset',
    method: 'POST',
  },
  GET_USER: {
    path: '/user/details',
    method: 'GET',
  },
  GET_USER_BY_ENT: {
    path: '/user/search',
    method: 'GET',
  },
  DELETE_USER: {
    path: '/user-delete',
    method: 'DELETE',
  },
};

export default API;
