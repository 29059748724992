/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import './index.scss';
import {
  Grid, IconButton, AppBar, Avatar, Menu, MenuItem, Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import logoUrl from 'assets/images/SLOCOACH_White_Logo.png';
import { authSliceActions } from 'base/auth/slice';
import { selectUser } from 'base/auth/selectors';

const OnboardTopBar = () => {
  const [anchorElDropDown, setAnchorElDropDown] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const signOut = React.useCallback(() => dispatch(authSliceActions.signOut()), [dispatch]);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDropDown(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElDropDown(null);
  };
  const user = useSelector(selectUser);
  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: 'black',
        zIndex: 0.25,
        padding: 12,
        width: '100%',
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
        <Grid item xs sx={{ textAlign: 'left', display: 'inline-block', verticalAlign: 'middle' }}>
          <img src={logoUrl} alt="slocoach-logo" style={{ verticalAlign: 'middle' }} />
        </Grid>
        <Grid item xs container sx={{ justifyContent: 'flex-end', paddingRight: '1.5rem' }} alignItems="center">
          {/* <Grid item>
            <IconButton
              sx={{
                color: 'white',
              }}
              aria-label="Save"
            >
              <NotificationsIcon sx={{
                height: '2rem', width: '2rem', paddingRight: '0.3rem', paddingBottom: '0.5rem',
              }}
              />
            </IconButton>
          </Grid> */}
          <Grid item sx={{ paddingRight: '2rem' }}>
            <Grid container direction="row">
              <Grid item sx={{ mr: '0.7rem' }}>
                <Avatar src={user?.profileImgUrl} />
              </Grid>
              <Grid item>
                <Grid item sx={{ fontSize: '0.8rem' }}>
                  <b>
                    {user?.firstName}
                    {' '}
                    {user?.lastName}
                  </b>
                </Grid>
                <Grid item sx={{ fontSize: '0.8rem' }}>
                  {user?.email}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: 'white' }}>
              <ArrowDropDownIcon />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElDropDown}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElDropDown)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <Typography onClick={() => signOut()} textAlign="center">logout</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default OnboardTopBar;
