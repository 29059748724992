const API = {
  GET_PUGINS_BY_ENT: {
    path: '/plugins/tenant',
    method: 'GET',
  },
  MODIFY_PLUGIN_STATUS: {
    path: '/plugins/:id/status',
    method: 'POST',
  },
};

export default API;
