import {
  IExpert, IGroup, IGuardian, IPurchasedSession, IBadge,
} from '../../interface';

export enum ModalStates {
  delete = 'DELETE',
  close = 'CLOSE',
  generalInformation = 'GENERAL_INFORMATION',
  resetPassword = 'RESET_PASSWORD',
}

export interface IGeneralInformationModalForm {
  firstName: string;
  lastName: string;
  profileImgUrl: string;
  email: string;
  bio: string;
  country: string;
  height: string;
  weight: string;
  bmi: string;
  guardian?: IGuardian

}

export interface EditUserState {
  loading: boolean;
  stateLoading: boolean;
  isDeleted:boolean,
  id: string;
  modal: string;
  generalInformation: IGeneralInformationModalForm;
  sessionsPurchased:IPurchasedSession[];
  subscriptedExperts:IExpert[];
  subscriptedGroups:IGroup[];
  followingExperts:IExpert[];
  followingGroups:IGroup[]
  badges:IBadge[]
}
