import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';

const onboardGeneralInformationFormValidation = yup.object().shape({
  name: yup.string().trim().required('Name of enterprise is a required field'),
  entUrl: yup.string().trim().required('Enterprise URL is a required field'),
  email: yup
    .string()
    .trim()
    .required('Email is a required field')
    .test(
      'is-valid',
      (message) => `${message.path} is invalid`,
      (value) => (value ? isEmail(value) : new yup.ValidationError('Invalid value')),
    ),
  currency: yup.string().trim().required('Currency is a required field'),
  country: yup.string().trim().required('Country is a required field'),
  city: yup.string().trim().required('City is a required field'),
  facebookUrl: yup
    .string()
    .trim()
    .optional()
    .url('Facebook URL must be a valid URL (eg: https://facebook.com)')
    .max(160, 'Facebook URL must be at most 160 characters'),
  instagramUrl: yup
    .string()
    .trim()
    .optional()
    .url('Instagram URL must be a valid URL (eg: https://www.instagram.com/)')
    .max(160, 'Instagram URL must be at most 160 characters'),
  linkedinUrl: yup
    .string()
    .trim()
    .optional()
    .url('LinkedIn URL must be a valid URL (eg: https://www.linkedin.com/)')
    .max(160, 'LinkedIn URL must be at most 160 characters'),
  youtubeUrl: yup
    .string()
    .trim()
    .optional()
    .url('YouTube URL must be a valid URL (eg: https://www.youtube.com/)')
    .max(160, 'YouTube URL must be at most 160 characters'),
  twitterUrl: yup
    .string()
    .trim()
    .optional()
    .url('X formerly twitter URL must be a valid URL (eg: https://x.com)')
    .max(160, 'X formerly twitter URL must be at most 160 characters'),
  categories: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required('Category name is a required field')
          .max(40, 'Category name must be at most 40 characters')
          .matches(
            /^[a-zA-Z0-9 /&*+-]*$/,
            'Only following special characters are allowed: /, &, *, -, + ',
          ),
      }),
    )
    .test('unique-category', 'Category names must be unique', (value) => {
      if (!value) return true;
      const categoryNames = value.map((category) => category.name);
      return new Set(categoryNames).size === categoryNames.length;
    }),
  skills: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .label('Skill')
        .max(40, 'Skill name must be at most 40 characters')
        .matches(
          /^[a-zA-Z0-9 /&*+-]*$/,
          'Only following special characters are allowed: /, &, *, -, + ',
        ),
    }),
  ),
});
export default onboardGeneralInformationFormValidation;
