import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { selectId } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import { viewSessionsActions } from './slice';
import API from './constants';
import { selectExpertId } from './selector';
import {
  IDeleteSession, IPaginateFilterPayload, ModalStates,
} from './interface';

export function* getSessionsGenerator({ payload }:PayloadAction<IPaginateFilterPayload>): any {
  const { isPrequisite, currentSessionId } = payload;

  try {
    const id = yield select(selectExpertId);
    if (!id) {
      return;
    }
    const entId = yield select(selectId);

    let apiPath = { path: `${API.GET_SESSIONS_BY_EXPERT.path}/${entId}/${id}?keyword=${payload?.keyword || ''}&page=${payload?.page || ''}&publicOnly=${payload.publicOnly || ''}&withoutDeleted=${payload.withoutDeleted || ''}&limit=${payload?.limit || ''}&type=${payload?.type || 'All'}&status=${payload?.status || 'ACTIVE'}&sortBy=${payload?.sortBy || ''} `, method: API.GET_SESSIONS_BY_EXPERT.method };

    if (isPrequisite) {
      apiPath = { path: `${API.GET_PREQUISITE_SEARCH.path}/${entId}/${id}?keyword=${payload?.keyword || ''}&page=${payload?.page || ''}&publicOnly=${payload.publicOnly || ''}&withoutDeleted=${payload.withoutDeleted || ''}&limit=${payload?.limit || ''}&type=${payload?.type || 'All'}&status=${payload?.status || 'ACTIVE'}&sortBy=${payload?.sortBy || ''}&currentSessionId=${currentSessionId || ''}`, method: API.GET_PREQUISITE_SEARCH.method };
    }

    const response: IRestApiResponse = yield call(
      request,
      apiPath,
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewSessionsActions.getSessionsSucceeded(
        response.data,
      ));
    } else {
      yield put(viewSessionsActions.getSessionsFailed());
    }
  } catch (error) {
    yield put(viewSessionsActions.getSessionsFailed());
  }
}
export function* deleteSessionGenerator({ payload }:PayloadAction<IDeleteSession>): any {
  try {
    const { id, reasonForDeletion, linkedSessionId } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_SESSION,
      { id, reasonForDeletion, linkedSessionId },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewSessionsActions.deleteSessionSucceeded());
      yield put(viewSessionsActions.openModal({ id, state: ModalStates.deleteComplete }));
    } else {
      yield put(viewSessionsActions.deleteSessionFailed());
    }
  } catch (error) {
    yield put(viewSessionsActions.deleteSessionFailed());
  }
}

export function* viewSessionsSaga() {
  yield all([takeLatest(
    viewSessionsActions.getSessions.type,
    getSessionsGenerator,
  ), takeLatest(
    viewSessionsActions.deleteSession.type,
    deleteSessionGenerator,
  )]);
}

export default viewSessionsSaga;
