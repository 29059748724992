import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  Grid, Divider, Collapse,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ErrorMessage,
  Label,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimaryOutlinedButton,
  PrimarySelect,
  PrimaryTextField,
  StatusTag,
} from 'components';

import {
  IEditFeedbackForm, IFeedBack,
} from 'pages/dashboard/components/feedback/interface';
import { getOptionsFromEnum } from 'utils/data-utils';
import { IOption } from 'interfaces';
import { selectLoader } from 'pages/dashboard/components/feedback/selector';
import { FeedbackStatus, FeedbackReferenceType, FeedbackType } from 'config';

interface FeedbackModalFormProps {
  onSubmit: SubmitHandler<IEditFeedbackForm>;
  feedback:IFeedBack
  closeModal:()=>void
}
// show more button if the feedback is too long

const FeedbackModalForm = ({ onSubmit, feedback, closeModal }: FeedbackModalFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IEditFeedbackForm>();
  const loading = useSelector(selectLoader);
  const [expanded, setExpanded] = React.useState(false);
  const statusOptions: IOption[] = getOptionsFromEnum(FeedbackStatus);
  const handleShowMore = () => {
    setExpanded(!expanded);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="form-item">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Label>Status</Label>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <PrimarySelect {...field} label="" options={statusOptions} placeholder="Select Status" />
              )}
            />
          </Grid>
        </Grid>

        <ErrorMessage>{errors?.status?.message}</ErrorMessage>
      </div>
      {feedback.feedbackType === FeedbackReferenceType.SESSION && (
      <>
        <Grid container className="form-item">
          <Grid item xs={4}><Label>Session name</Label></Grid>

          <Grid item>
            {feedback.reference}
          </Grid>
        </Grid>
        <Divider style={{ width: '100%' }} className="form-item" />
      </>
      )}

      <Grid container className="form-item">
        <Grid item xs={4}><Label>Enterprise client</Label></Grid>

        <Grid item>
          {feedback.entId?.name}
        </Grid>
      </Grid>
      <Divider style={{ width: '100%' }} className="form-item" />
      <Grid container className="form-item">
        <Grid item xs={4}><Label>Raised by</Label></Grid>

        <Grid item>
          {feedback.raisedBy?.email}
        </Grid>
      </Grid>
      <Divider style={{ width: '100%' }} className="form-item" />
      <Grid container className="form-item">
        <Grid item xs={4}><Label>Feedback given date</Label></Grid>

        <Grid item>
          { moment(feedback.createdAt).format('Do MMM YYYY')}
        </Grid>
      </Grid>
      <Divider style={{ width: '100%' }} className="form-item" />
      <Grid container className="form-item">
        <Grid item xs={4}><Label>Feedback Type</Label></Grid>

        <Grid item>
          <StatusTag
            status={feedback.feedbackType}
            color={(feedback.feedbackType === FeedbackType.BUG) ? '#e93e3e'
              : (feedback.feedbackType === FeedbackType.COMPLAINT) ? '#FFB302' : '#000000'}
          />

        </Grid>
      </Grid>
      <Divider style={{ width: '100%' }} className="form-item" />
      <Grid container className="form-item">
        <Grid item xs={4}><Label>Feedback</Label></Grid>

        <Grid item>
          <Collapse in={expanded} collapsedSize={100}>
            {feedback.feedback}
          </Collapse>
          {feedback.feedback?.length > 100 && (
          <PrimaryOutlinedButton onClick={handleShowMore} style={{ marginTop: '-0.5rem', marginLeft: '-0.5rem' }}>
            {expanded ? 'Show Less' : 'Show More'}
          </PrimaryOutlinedButton>
          )}

        </Grid>
      </Grid>
      <div className="form-item">
        <Controller
          control={control}
          name="adminComment"
          render={({ field }) => <PrimaryTextField {...field} label="Admin comment" multiline rows={3} />}
        />
        <ErrorMessage>{errors?.adminComment?.message}</ErrorMessage>
      </div>

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={closeModal}>Cancel</PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} fullWidth type="submit">
            Save
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

export default FeedbackModalForm;
