/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/group/create-group'] || initialState;

export const selectCreateGroup = createSelector([selectDomain], (CreateGroupState) => CreateGroupState);
export const selectStateLoader = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.stateLoading);
export const selectSubStateLoader = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.subStateLoading);
export const selectLoader = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.loading);
export const selectModalState = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.modalState);
export const selectIsActive = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.isActive);
export const selectIsPartialSucceeded = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.isPartialSucceeded);
export const selectGroupAdmin = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.groupAdmin);
export const selectStatus = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.status);
export const selectGroupInformation = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.groupInformation);
export const selectExperts = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.experts);
export const selectSessions = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.sessions);
export const selectContent = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.contents);
export const selectArticles = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.articles);
export const selectUsers = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.users);
export const selectFilteredExperts = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.filteredExperts);
export const selectFilteredSessions = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.filteredSessions);
export const selectFilteredArticles = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.filteredArticles);
export const selectFilteredContent = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.filteredContent);
export const selectInvitationFailedUsers = createSelector([selectDomain], (CreateGroupState) => CreateGroupState.invitationFailedUsers);
