/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupTypes, GroupVisibilities } from 'config';
import {
  IArticleItemData,
  IExpertItemData,
  IFilteredArticles,
  IFilteredExperts,
  IFilteredSessions,
  ISessionItemData,
  ModalStates,
} from '../../interface';
import {
  CreateGroupState,
  IGroupInformation,
  IGroupSuccessResult,
  IPaginateFilterPayload,
} from './interface';

export const initialState: CreateGroupState = {
  loading: false,
  status: 0,
  stateLoading: false,
  isPartialSucceeded: false,
  subStateLoading: false,
  modalState: '',
  isActive: true,
  groupAdmin: '',
  groupInformation: {
    isActive: true,
    currency: 'AUD',
    subscriptionFee: 0,
    subscriptionFrequency: '',
    groupType: GroupTypes.public,
    groupVisibility: GroupVisibilities.public,
    title: '',
    subTitle: '',
    description: '',
    bannerImgUrl: '',
    sponsorContent: [],
  },
  experts: [],
  sessions: [],
  articles: [],
  users: [],
  contents: [],
  filteredExperts: { result: [], total: 0 },
  filteredSessions: { result: [], total: 0 },
  filteredArticles: { result: [], total: 0 },
  filteredContent: { result: [], total: 0 },
  invitationFailedUsers: [],
};

export const createGroupSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/create-group',
  initialState,
  reducers: {
    setStatus(state: CreateGroupState, action: PayloadAction<number>) {
      state.status = action.payload;
    },
    createGroup(state: CreateGroupState, action: PayloadAction<string[]>) {
      state.users = action.payload;
      state.loading = true;
    },
    createGroupPartialSucceeded(
      state: CreateGroupState,
      action: PayloadAction<IGroupSuccessResult>,
    ) {
      state.invitationFailedUsers = action.payload.failedUsers;
      state.isPartialSucceeded = true;
      state.loading = false;
      state.status = 5;
    },
    createGroupSucceeded(state: CreateGroupState) {
      state.loading = false;
      state.status = 6;
    },
    createGroupFailed(state: CreateGroupState) {
      state.loading = false;
      state.status = 5;
    },
    addGroupInformation(state: CreateGroupState, action: PayloadAction<IGroupInformation>) {
      state.groupInformation = action.payload;
      state.status = 1;
    },
    setGroupAdmin(state: CreateGroupState, action: PayloadAction<string>) {
      state.groupAdmin = action.payload;
    },
    addExperts(state: CreateGroupState, action: PayloadAction<IExpertItemData[]>) {
      state.experts = action.payload;
      if (state.groupInformation.groupType !== GroupTypes.public) {
        state.status = 5;
      } else {
        state.status = 2;
      }
    },
    addSessions(state: CreateGroupState, action: PayloadAction<ISessionItemData[]>) {
      state.sessions = action.payload;
      state.status = 3;
    },
    addContent(state: CreateGroupState, action: PayloadAction<ISessionItemData[]>) {
      state.contents = action.payload;
      state.status = 4;
    },
    addArticles(state: CreateGroupState, action: PayloadAction<IArticleItemData[]>) {
      state.articles = action.payload;
      state.status = 5;
    },
    openModal(state: CreateGroupState, action: PayloadAction<string>) {
      state.modalState = action.payload;
    },
    closeModal(state: CreateGroupState) {
      state.modalState = ModalStates.close;
    },
    getFilteredExperts(state: CreateGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredExpertsSucceeded(state: CreateGroupState, action: PayloadAction<IFilteredExperts>) {
      state.filteredExperts = action.payload;
      state.subStateLoading = false;
    },
    getFilteredExpertsFailed(state: CreateGroupState) {
      state.subStateLoading = false;
    },
    getFilteredSessions(state: CreateGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredSessionsSucceeded(
      state: CreateGroupState,
      action: PayloadAction<IFilteredSessions>,
    ) {
      state.filteredSessions = action.payload;
      state.subStateLoading = false;
    },
    getFilteredSessionsFailed(state: CreateGroupState) {
      state.subStateLoading = false;
    },

    getFilteredContent(state: CreateGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredContentSucceeded(state: CreateGroupState, action: PayloadAction<IFilteredSessions>) {
      state.filteredContent = action.payload;
      state.subStateLoading = false;
    },
    getFilteredContentFailed(state: CreateGroupState) {
      state.subStateLoading = false;
    },
    getFilteredArticles(state: CreateGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredArticlesSucceeded(
      state: CreateGroupState,
      action: PayloadAction<IFilteredArticles>,
    ) {
      state.filteredArticles = action.payload;
      state.subStateLoading = false;
    },
    getFilteredArticlesFailed(state: CreateGroupState) {
      state.subStateLoading = false;
    },
    updateActive(state: CreateGroupState) {
      state.isActive = !state.isActive;
    },
    reset: () => initialState,
  },
});

export const { actions: createGroupActions } = createGroupSlice;
