/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGiftCardFilterPayload,
  IGiftCardRequest, IDeleteGiftCard, IOpenModal, ModalStates, ViewGiftCardState, IActivateGiftCard,
} from './interface';

export const initialState: ViewGiftCardState = {
  stateLoading: false,
  loading: false,
  GiftCards: { result: [], total: 0 },
  entId: '',

};

export const viewGiftCardSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/GiftCard/view-GiftCard',
  initialState,
  reducers: {
    viewGiftCard(state: ViewGiftCardState, _action:PayloadAction<IGiftCardFilterPayload>) {
      state.stateLoading = true;
    },
    viewGiftCardSucceeded(state: ViewGiftCardState, action:PayloadAction<IGiftCardRequest>) {
      state.GiftCards.total = action.payload.total;
      state.GiftCards.result = action.payload.result.map((GiftCard) => ({
        ...GiftCard, modalState: ModalStates.close,
      }));
      state.stateLoading = false;
    },
    viewGiftCardFailed(state: ViewGiftCardState) {
      state.stateLoading = false;
    },
    setEntId(state: ViewGiftCardState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    openModal(state:ViewGiftCardState, action:PayloadAction<IOpenModal>) {
      const temp = state.GiftCards.result;
      temp.forEach((GiftCard) => {
        if (GiftCard._id === action.payload.id) {
          GiftCard.modalState = action.payload.state;
        }
      });
      state.GiftCards.result = [...temp];
    },
    closeModal(state:ViewGiftCardState, action:PayloadAction<string>) {
      const temp = state.GiftCards.result;
      temp.forEach((GiftCard) => {
        if (GiftCard._id === action.payload) {
          GiftCard.modalState = ModalStates.close;
        }
      });
      state.GiftCards.result = [...temp];
    },
    activateGiftCard(state: ViewGiftCardState, _action:PayloadAction<IActivateGiftCard>) {
      state.loading = true;
    },
    activateGiftCardSucceeded(state: ViewGiftCardState, action:PayloadAction<string>) {
      const temp = state.GiftCards.result;
      temp.forEach((GiftCard) => {
        if (GiftCard._id === action.payload) {
          GiftCard.isActive = !GiftCard.isActive;
          GiftCard.modalState = ModalStates.close;
        }
      });
      state.GiftCards.result = [...temp];
      state.loading = false;
    },
    activateGiftCardFailed(state: ViewGiftCardState) {
      state.loading = false;
    },
    deleteGiftCard(state: ViewGiftCardState, _action:PayloadAction<IDeleteGiftCard>) {
      state.loading = true;
    },
    deleteGiftCardSucceeded(state: ViewGiftCardState, action:PayloadAction<string>) {
      const temp = state.GiftCards.result;
      temp.forEach((GiftCard) => {
        if (GiftCard._id === action.payload) {
          GiftCard.deleted = true;
          GiftCard.modalState = ModalStates.close;
        }
      });
      state.GiftCards.result = [...temp];
      state.loading = false;
    },
    deleteGiftCardFailed(state: ViewGiftCardState) {
      state.loading = false;
    },
  },
});

export const { actions: viewGiftCardActions } = viewGiftCardSlice;
