/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/admin/edit-admin'] || initialState;

export const selectEditAdmin = createSelector([selectDomain], (EditAdminState) => EditAdminState);
export const selectStateLoader = createSelector([selectDomain], (EditAdminState) => EditAdminState.stateLoading);
export const selectLoader = createSelector([selectDomain], (EditAdminState) => EditAdminState.loading);
export const selectDeleteCheckStatus = createSelector([selectDomain], (EditAdminState) => EditAdminState.deleteCheckStatus);
export const selectDeleteCheckId = createSelector([selectDomain], (EditAdminState) => EditAdminState.deleteCheckId);
export const selectId = createSelector([selectDomain], (EditAdminState) => EditAdminState.id);
export const selectModalState = createSelector([selectDomain], (EditAdminState) => EditAdminState.modal);
export const selectGeneralInformation = createSelector([selectDomain], (EditAdminState) => EditAdminState.generalInformation);
export const selectAuthorityLevel = createSelector([selectDomain], (EditAdminState) => EditAdminState.authorityLevel);
export const selectDeleteCheck = createSelector([selectDomain], (EditAdminState) => EditAdminState.deleteCheck);
export const selectIsDeleted = createSelector([selectDomain], (EditAdminState) => EditAdminState.isDeleted);
