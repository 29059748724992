/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/base/core'] || initialState;

export const selectCoreState = createSelector([selectDomain], (coreState) => coreState);
export const selectSnack = createSelector([selectDomain], (coreState) => coreState.snack);
export const selectExchangeRates = createSelector([selectDomain], (coreState) => coreState.exchangeRates);
export const selectWindowIsDisabled = createSelector([selectDomain], (coreState) => coreState.windowIsDisabled);
