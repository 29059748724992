const API = {
  POST_CREATE_GIFT_CARD: {
    path: '/voucher/create',
    method: 'POST',
  },
  PUT_UPDATE_GIFT_CARD: {
    path: '/voucher/update',
    method: 'PUT',
  },
  GET_GIFT_CARDS_BY_ENT: {
    path: '/voucher/admin/search',
    method: 'GET',
  },
  GET_GIFT_CARD_BY_ID: {
    path: '/user-voucher/analytics',
    method: 'GET',
  },
  DELETE_GIFT_CARD: {
    path: '/voucher/delete',
    method: 'DELETE',
  },

};

export default API;
