/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'interfaces';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/view-feedback'] || initialState;

export const selectViewFeedbackState = createSelector([selectDomain], (ViewFeedbackState) => ViewFeedbackState);
export const selectFeedback = createSelector([selectDomain], (ViewFeedbackState) => ViewFeedbackState.feedbacks);
export const selectFeedbacksLoader = createSelector([selectDomain], (ViewFeedbackState) => ViewFeedbackState.feedbacksLoading);
export const selectLoader = createSelector([selectDomain], (ViewFeedbackState) => ViewFeedbackState.loading);
export const selectTotalResolved = createSelector([selectDomain], (ViewFeedbackState) => ViewFeedbackState.totalResolved);
export const selectTotalPending = createSelector([selectDomain], (ViewFeedbackState) => ViewFeedbackState.totalPending);
export const selectTotalPickedUp = createSelector([selectDomain], (ViewFeedbackState) => ViewFeedbackState.totalPickedUp);
