import {
  all,
  call, delay, put, race, select, take, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { Role } from 'interfaces/user-roles';
import { viewAdminActions } from './slice';
import API from '../../constant';
import {
  IAdminFilterPayload, IDeleteAdmin,
} from './interface';
import { selectDeleteCheckId } from './selector';

export function* viewAllAdminGenerator() {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.GET_ALL_ADMINS,
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewAdminActions.viewAllAdminSucceeded(response.data));
    } else {
      yield put(viewAdminActions.viewAllAdminFailed());
    }
  } catch (error) {
    yield put(viewAdminActions.viewAllAdminFailed());
  }
}

export function* viewAdminGenerator({ payload }:
PayloadAction<IAdminFilterPayload>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_ADMIN_BY_ENT.path}/?keyword=${payload.keyword}&status=${payload.status}&page=${payload.page}&limit=${payload.limit}&authorityLevel=${payload.authorityLevel}&withDeleted=${payload.withDeleted || 'false'}`, method: API.GET_ADMIN_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewAdminActions.viewAdminSucceeded(response.data));
    } else {
      yield put(viewAdminActions.viewAdminFailed());
    }
  } catch (error) {
    yield put(viewAdminActions.viewAdminFailed());
  }
}

export function* resetPasswordGenerator({ payload }:PayloadAction<string>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.RESET_PASSWORD,
      { id: payload },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewAdminActions.resetPasswordSucceeded(payload));
    } else {
      yield put(viewAdminActions.resetPasswordFailed());
    }
  } catch (error) {
    yield put(viewAdminActions.resetPasswordFailed());
  }
}
export function* deleteAdminCheckStatusPollingWorker(): any {
  let i = 0;
  while (i < 11) {
    try {
      const jobId = yield select(selectDeleteCheckId);
      const response: IRestApiResponse = yield call(
        request,
        API.DELETE_ADMIN_CHECK_STATUS,
        { jobId },
        true,
      );
      i += 1;
      if (response.statusCode === 200) {
        yield put(viewAdminActions.deleteAdminCheckSucceeded(response.data?.returnvalue));
        yield put(viewAdminActions.cancelPollingWork());
      } else {
        yield delay(2500); // 2500 ms
      }
    } catch (error) {
      yield put(viewAdminActions.deleteAdminCheckFailed());
    }
  }
}

export function* deleteAdminCheckStatusWatchWorker() {
  yield race({
    task: call(deleteAdminCheckStatusPollingWorker),
    cancel: take(viewAdminActions.cancelPollingWork),
  });
}

export function* deleteAdminCheckGenerator({ payload }:PayloadAction<string>): any {
  try {
    const userId = payload;
    const role = Role.EntAccountAdmin;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_ADMIN_CHECK,
      { userId, role },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewAdminActions.setDeleteCheckId(response.data?.id));
      // start polling
      yield call(deleteAdminCheckStatusWatchWorker);
    } else {
      yield put(viewAdminActions.deleteAdminCheckFailed());
    }
  } catch (error) {
    yield put(viewAdminActions.deleteAdminCheckFailed());
  }
}

export function* deleteAdminGenerator({ payload }:PayloadAction<IDeleteAdmin>): any {
  try {
    const { userId, newResourceOwner } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_ADMIN,
      { userId, newResourceOwner },
      true,
    );
    if (response.statusCode === 202) {
      yield put(viewAdminActions.deleteAdminSucceeded(userId));
    } else {
      yield put(viewAdminActions.deleteAdminFailed());
    }
  } catch (error) {
    yield put(viewAdminActions.deleteAdminFailed());
  }
}

export function* viewAdminSaga() {
  yield all([takeLatest(
    viewAdminActions.viewAllAdmin.type,
    viewAllAdminGenerator,
  ), takeLatest(
    viewAdminActions.viewAdmin.type,
    viewAdminGenerator,
  ), takeLatest(
    viewAdminActions.resetPassword.type,
    resetPasswordGenerator,
  ),
  takeLatest(
    viewAdminActions.deleteAdminCheck.type,
    deleteAdminCheckGenerator,
  ), takeLatest(
    viewAdminActions.deleteAdmin.type,
    deleteAdminGenerator,
  )]);
}

export default viewAdminSaga;
