import * as React from 'react';

import { useDispatch } from 'react-redux';
import { GridRowId, GridColumns, GridActionsCellItem } from '@mui/x-data-grid';
import { StatusTag, PrimaryTable } from 'components';
import { formattedDate } from 'utils/data-utils';
import { FeedbackType, FeedbackStatus } from 'config';
import { IFeedBack } from '../../interface';
import { viewFeedbackActions } from '../../slice';
import FeedbackModal from '../feedback-modal';

interface FeedbackTableProps {
  feedbacks: IFeedBack[],
  loading:boolean,
  total: number,
  page: number
  setPage: (page: number) => void,
}

export default function FeedbacksTable({
  feedbacks, loading, total, setPage, page,
}:FeedbackTableProps) {
  type Row = typeof feedbacks[number];
  const dispatch = useDispatch();
  const view = React.useCallback(
    (id: GridRowId) => () => {
      dispatch(
        viewFeedbackActions.openModal(id as string),
      );
    },
    [],
  );

  const handleClose = (id:string) => { dispatch(viewFeedbackActions.closeModal(id)); };
  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'feedbackId',
        headerName: 'Feedback ID',
        headerAlign: 'right',
        align: 'right',
        flex: 0.5,
      },
      {
        field: 'feedbackType',
        headerName: 'Feedback type',
        flex: 0.5,
        headerAlign: 'right',
        align: 'center',
        renderCell: (params) => (
          <div>
            <StatusTag
              status={params.value}
              color={(params.value === FeedbackType.BUG) ? '#e93e3e'
                : (params.value === FeedbackType.COMPLAINT) ? '#FFB302' : '#000000'}
            />
          </div>
        ),
      },
      {
        field: 'feedback',
        headerName: 'Feedback',
        flex: 1,
        headerAlign: 'right',
        align: 'center',
        renderCell: (params) => (
          <>
            <div style={{
              whiteSpace: 'nowrap',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            >
              {params.value}

            </div>
            <FeedbackModal
              feedback={params.row}
              open={params.row.modalState}
              handleClose={() => handleClose(params.id as string)}
            />
          </>

        ),

      },
      {
        field: 'raisedBy',
        headerName: 'Raised by',
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (<div>{params.row.raisedBy?.email}</div>),
      },
      {
        field: 'entId',
        headerName: 'Tenant Name',
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (<div>{params.row.entId?.name}</div>),
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params) => (
          <StatusTag
            status={params.value}
            color={(params.value === FeedbackStatus.RESOLVED) ? '#63c32d'
              : (params.value === FeedbackStatus.PENDING) ? '#e93e3e' : '#FFB302'}
          />

        ),
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
      },

      {
        field: 'createdAt',
        headerName: 'Raised date',
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <div>{formattedDate(params.value)}</div>

        ),

      },

      {
        field: 'actions',
        type: 'actions',
        flex: 0.5,
        align: 'right',
        getActions: ({ id }) => [
          <GridActionsCellItem
            label="View"
            onClick={view(id)}
            showInMenu
          />,

        ],
      },
    ],
    [view],
  );

  return (
    <PrimaryTable
      rows={feedbacks}
      columns={columns}
      loading={loading}
      rowCount={total}
      page={page}
      pageSize={25}
      rowsPerPageOptions={[25]}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      paginationMode="server"
      getRowId={(row) => row._id}
      onRowClick={(params) => dispatch(
        viewFeedbackActions.openModal(params.id as string),
      )}
    />
  );
}
