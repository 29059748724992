const API = {
  GET_REVIEWS_BY_EXPERT: {
    path: '/review/expert',
    method: 'GET',
  },
  DELETE_REVIEW: {
    path: '/review/admin-delete',
    method: 'POST',
  },
};
export default API;
