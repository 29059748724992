import {
  call, put, select, all, takeLatest,
} from 'redux-saga/effects';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { CategoryResourceType } from 'config';
import { createExpertActions } from './slice';
import API from './constants';
import { selectBankDetails, selectExpertInformation } from './selector';
import { selectGeneralInformation, selectId } from '../../../../selector';

function* assignCategories(resourceId: string, categories: string[]) {
  yield call(
    request,
    API.POST_ASSIGN_CATEGORIES,
    {
      resourceId,
      categoryId: categories,
      resourceType: CategoryResourceType.EXPERT,
    },
  );
}

export function* createExpertGenerator(): any {
  try {
    const expertInformationForm = yield select(selectExpertInformation);
    const bankDetailsForm = yield select(selectBankDetails);
    const entId = yield select(selectId);
    const { entUrl } = yield select(selectGeneralInformation);
    const {
      accountType,
      subscriptionFee,
      subscriptionFrequency,
      firstName,
      lastName,
      profileImgUrl,
      email,
      bio,
      sport,
      categories,
    } = expertInformationForm;
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_EXPERT,
      {
        entId,
        entUrl,
        firstName,
        lastName,
        email,
        bio,
        profileImgUrl,
        attributes: {
          isActive: true,
          accountType,
          subscriptionFee,
          subscriptionFrequency,
          sport,
          category: '',
          ...bankDetailsForm,
        },

      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(createExpertActions.createExpertSucceeded());
      yield assignCategories(response?.data?.user?.id, categories);
    } else {
      yield put(createExpertActions.createExpertFailed('Unable to create expert account'));
    }
  } catch (error) {
    yield put(createExpertActions.createExpertFailed('Unable to get create expert account'));
  }
}

export function* createExpertSaga() {
  yield all([takeLatest(
    createExpertActions.createExpert.type,
    createExpertGenerator,
  )]);
}

export default createExpertSaga;
