import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Chip,
} from '@mui/material';
import { CloseIcon } from 'assets/icons';

const PrimaryChipStyled = styled(Chip)(({ theme }) => ({
  opacity: 1,
  backgroundColor: '#F2F2F2',
  borderRadius: '6px',
  borderColor: theme.palette.primary.light,
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '7px 5px',
  height: 'auto',
  '& .MuiChip-avatar': {
    width: 30,
    height: 30,
    marginLeft: '3px',
    marginRight: '-3px',
    borderWidth: 1,
    borderStyle: 'solid',
    color: theme.palette.primary.main,
  },
  '& .MuiChip-label': {
    fontSize: '14px',
    fontWeight: 600,
    marginTop: '2px',
  },
  '& .MuiChip-deleteIcon': {
    fontSize: '16px',
    color: theme.palette.text.primary,
    marginLeft: '10px',
    marginRight: 0,
  },
  '& .MuiChip-deleteIcon:hover': {
    color: theme.palette.text.primary,
  },
}));

const PrimaryChip = (props : any) => (
  <PrimaryChipStyled deleteIcon={<CloseIcon />} {...props} />
);

export default PrimaryChip;
