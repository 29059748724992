/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CoreState, IExchangeRates, ISetMessageAction } from './interface';

export const initialState: CoreState = {
  snack: {
    message: '',
    success: false,
    open: false,
  },
  exchangeRates: {
    USD: 1,
    AUD: 1,
    GBP: 1,
  },
  windowIsDisabled: false,

};

export const coreSlice = createSlice({
  name: 'feature/base/core',
  initialState,
  reducers: {
    setMessage(state:CoreState, action: PayloadAction<ISetMessageAction>) {
      state.snack.message = action.payload.message;
      state.snack.success = action.payload.success;
      state.snack.open = true;
    },
    setWindowsDisabled(state:CoreState, action:PayloadAction<boolean>) {
      state.windowIsDisabled = action.payload;
    },
    closeSnackbar(state:CoreState) {
      state.snack.open = false;
    },
    getExchangeRates() {

    },
    getExchangeRatesSucceeded(state:CoreState, action:PayloadAction<IExchangeRates>) {
      state.exchangeRates = action.payload;
    },
    getExchangeRatesFailed() {

    },
  },
});

export const { actions: coreSliceActions } = coreSlice;
