import { Typography } from '@mui/material';
import { PrimaryTextField, SubTitle } from 'components';
import { IPlugin } from 'pages/dashboard/components/ent-account/components/edit-account/components/plugins/interface';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ICreateSessionForm } from '../../../../interface';

interface ISessionPluginsProps {
  plugins: IPlugin[];
}

interface IPluginConfigItemProps {
  plugin: IPlugin;
}

const PluginConfigItem: React.FC<IPluginConfigItemProps> = ({ plugin }) => {
  const { control } = useFormContext<ICreateSessionForm>();

  const formItems = plugin.pluginConfig.filter((i) => i.type === 'input');

  return (
    <div>
      <Typography sx={{ mb: 1 }}>{plugin.name}</Typography>
      {formItems.map((item) => (
        <div className="form-item" key={item.name}>
          <Controller
            control={control}
            // @ts-ignore
            name={`metaData.${plugin.key}.${item.key}`}
            render={({ field }) => <PrimaryTextField {...field} label={item.label} />}
          />
        </div>
      ))}
    </div>
  );
};

const SessionPlugins: React.FC<ISessionPluginsProps> = ({ plugins }) => (
  <div className="form-item">
    <SubTitle>Plugins</SubTitle>

    {plugins.map((plugin: IPlugin) => (
      <PluginConfigItem key={plugin.id} plugin={plugin} />
    ))}
  </div>
);

export default SessionPlugins;
