import { IBaseUserAvatar } from 'interfaces/base-user';
import { IGiftCardDocument } from '../../interface';

export enum ModalStates {
  close = 'close',
  delete = 'delete',
  active = 'active',
}
export interface IActive {
  isActive:boolean
}
export interface IUserVoucher {
  voucher: string;
  purchasedBy:IBaseUserAvatar ;
  redeemedBy:IBaseUserAvatar ;
  email: string;
  stripeVoucherCode: string;
  isGifted: boolean;
  isRedeemed: boolean;
  purchasedAt: Date;
  expireAt: Date;
  redeemedAt: Date;
}

export interface ViewGiftCardUsersState {
  loading:boolean,
  stateLoading:boolean,
  id:string,
  userVouchers:{ result :IUserVoucher[], total:number },
  modal:string,
  giftCard:IGiftCardDocument,
}

export interface IGiftCardUserFilterPayload {
  email: string;
  page: number;
  limit: number;
}

export interface IUserVoucherResponse {
  voucher:IGiftCardDocument,
  result:IUserVoucher[],
  total:number
}
