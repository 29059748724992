/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltered } from 'interfaces/api-response';
import {
  ViewSessionsState, IPaginateFilterPayload, ModalStates, IOpenModal, IDeleteSession,
} from './interface';
import { SessionDocument } from '../../interface';

export const initialState: ViewSessionsState = {
  filteredSessions: { result: [], total: 0 },
  stateLoading: false,
  expertId: '',
  entId: '',
  loading: false,
};

export const viewSessionsSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-sessions',
  initialState,
  reducers: {
    getSessions(state:ViewSessionsState, _action:PayloadAction<IPaginateFilterPayload>) {
      state.stateLoading = true;
    },
    getSessionsSucceeded(
      state:ViewSessionsState,
      action:PayloadAction<IFiltered<SessionDocument>>,
    ) {
      state.filteredSessions.result = action.payload?.result?.map((
        session,
      ) => ({ ...session, modalState: ModalStates.close })) || [];
      state.filteredSessions.total = action.payload.total;
      state.stateLoading = false;
    },
    getSessionsFailed(state:ViewSessionsState) {
      state.stateLoading = false;
    },
    setExpertId(state:ViewSessionsState, action: PayloadAction<string>) {
      state.expertId = action.payload;
    },
    setEntId(state:ViewSessionsState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    openModal(state:ViewSessionsState, action:PayloadAction<IOpenModal>) {
      const temp = state.filteredSessions.result;
      temp.forEach((session) => {
        if (session.id === action.payload.id) {
          session.modalState = action.payload.state;
        }
      });
      state.filteredSessions.result = [...temp];
    },
    closeModal(state:ViewSessionsState, action:PayloadAction<string>) {
      const temp = state.filteredSessions.result;
      temp.forEach((session) => {
        if (session.id === action.payload) {
          session.modalState = ModalStates.close;
        }
      });
      state.filteredSessions.result = [...temp];
    },
    deleteSession(state: ViewSessionsState, _action:PayloadAction<IDeleteSession>) {
      state.loading = true;
    },
    deleteSessionComplete(state:ViewSessionsState, action:PayloadAction<string>) {
      const temp = state.filteredSessions.result;
      temp.forEach((session) => {
        if (session.id === action.payload) {
          session.deleted = true;
        }
      });
      state.filteredSessions.result = [...temp];
    },
    deleteSessionSucceeded(state: ViewSessionsState) {
      state.loading = false;
    },
    deleteSessionFailed(state: ViewSessionsState) {
      state.loading = false;
    },
  },
});

export const { actions: viewSessionsActions } = viewSessionsSlice;
