/* eslint-disable no-underscore-dangle */
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import generalInformationFormValidation from '../../validation/general-information-from-validation';
import { createEntAccActions } from '../../../../slice';
import { ICreateGeneralInformationForm } from '../../../../interface';
import { selectGeneralInformationForm } from '../../../../selector';
import GeneralInformationForm from '../general-information-form';

const GeneralInformationView = () => {
  const dispatch = useDispatch();
  const generalInformationForm = useSelector(selectGeneralInformationForm);
  const methods = useForm<ICreateGeneralInformationForm>({
    defaultValues: {
      name: generalInformationForm.name,
      entUrl: generalInformationForm.entUrl,
      email: generalInformationForm.email,
      currency: generalInformationForm.currency,
      country: generalInformationForm.country,
      city: generalInformationForm.city,
      slocoachAdmin: generalInformationForm.slocoachAdmin,
    },
    resolver: yupResolver(generalInformationFormValidation),
    shouldFocusError: true,
  });
  const onSubmit: SubmitHandler<ICreateGeneralInformationForm> = useCallback((data) => {
    dispatch(createEntAccActions.AddGeneralInformation(data));
  }, [dispatch]);
  return (
    <Grid container justifyContent="center">
      <Grid xs item>
        <FormProvider {...methods}>
          <GeneralInformationForm onSubmit={onSubmit} />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default GeneralInformationView;
