import * as React from 'react';
import {
  Controller, SubmitHandler, useFormContext,
} from 'react-hook-form';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  ErrorMessage,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimaryTextField,
} from 'components';
import { selectLoader } from '../../../../../selector';

interface OnboardCreateExpertFormProps {
  onSubmit: SubmitHandler<any>;
  closeModal: () => void;
}

const OnboardCreateExpertModalForm = ({ onSubmit, closeModal }: OnboardCreateExpertFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<any>();
  const loading = useSelector(selectLoader);
  const handleForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)();
  };
  return (
    <form onSubmit={handleForm}>
      <h3 className="form-item">Invite expert</h3>
      <div className="form-item">
        <Controller
          name="email"
          control={control}
          render={({ field }) => <PrimaryTextField {...field} label="Email address" />}
        />
      </div>
      <ErrorMessage>{errors?.email?.message}</ErrorMessage>
      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={closeModal}>
            Cancel
          </PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} fullWidth type="submit">
            Add
          </PrimaryLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default OnboardCreateExpertModalForm;
