import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';

const onboardAddNewExpertModalFormValidation = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is a required field')
    .test(
      'is-valid',
      (message) => `${message.path} is invalid`,
      (value) => (value ? isEmail(value) : new yup.ValidationError('Invalid value')),
    ),

});
export default onboardAddNewExpertModalFormValidation;
