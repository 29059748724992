import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Typography } from '@mui/material';

interface GrowthDisplayProps {
  up:boolean,
  value:number
}

const GrowthDisplay = ({ up, value }:GrowthDisplayProps) => (
  <div className="inline-wrapper">
    {up ? <ArrowDropUp sx={{ color: '#63C32D' }} /> : <ArrowDropDown sx={{ color: '#E93E3E' }} /> }
    <Typography variant="body1" sx={{ fontSize: '0.75rem', color: up ? '#63C32D' : '#E93E3E' }}>
      {value}
    </Typography>
  </div>
);

export default GrowthDisplay;
