export enum CurrencyTypes {
  usd = 'USD',
  aud = 'AUD',
  gbp = 'GBP',
}
export enum CurrencySymbols {
  'USD' = '$',
  'GBP' = '£',
  'AUD' = 'A$',
}
