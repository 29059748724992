import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  Divider, FormControlLabel, FormGroup, Grid, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  ErrorMessage,
  FileUploader,
  Label,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimarySelect,
  PrimarySwitch,
  PrimaryTextField,
} from 'components';

import { AuthorityLevels } from 'config';
import { IOption } from 'interfaces';
import { getOptionsFromEnum } from 'utils/data-utils';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { selectLoader } from '../../../../../selector';

interface OnboardCreateAdminModalFormProps {
  onSubmit: SubmitHandler<any>;
  closeModal: () => void;
}

const OnboardCreateAdminModalForm = (
  { onSubmit, closeModal }: OnboardCreateAdminModalFormProps,
) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue, setError, clearErrors, watch,
  } = useFormContext<any>();

  const watchAuthorityLevel = watch('authorityLevel');
  const authorityLevelOptions: IOption[] = getOptionsFromEnum(AuthorityLevels);
  const loading = useSelector(selectLoader);

  const handleProfileImg = (res: UploadResponse) => {
    clearErrors('profileImgUrl');
    setValue('profileImgUrl', res.url);
    if (res.statusCode !== 201) {
      setError('profileImgUrl', { type: 'custom', message: res.message });
    }
  };
  const handleProfileImgRemove = () => {
    setValue('profileImgUrl', '');
  };
  const handleForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={handleForm}>
      <h4 className="form-item">Add new admin</h4>
      <div className="form-item">
        <FileUploader
          handleFile={handleProfileImg}
          currentContentUrl=""
          scenario={UploadScenarios.adminProfile}
          handleRemove={handleProfileImgRemove}
          currentMediaType="image"
        />
        <ErrorMessage>{errors?.profileImgUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => <PrimaryTextField {...field} label="First name" />}
        />
        <ErrorMessage>{errors?.firstName?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => <PrimaryTextField {...field} label="Last name" />}
        />
        <ErrorMessage>{errors?.lastName?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="email"
          render={({ field }) => <PrimaryTextField {...field} label="Email address" />}
        />
        <ErrorMessage>{errors?.email?.message}</ErrorMessage>
      </div>

      <Label>Authority Level</Label>
      <div className="form-item">
        <Controller
          control={control}
          name="authorityLevel"
          render={({ field }) => (
            <PrimarySelect
              {...field}
              label=""
              options={authorityLevelOptions}
              placeholder="Select Authority level"
            />
          )}
        />
        <ErrorMessage>{errors?.authorityLevel?.message}</ErrorMessage>
      </div>
      {watchAuthorityLevel === AuthorityLevels.CLIENT_ADMIN && (
      <>
        <Typography variant="h4" className="form-item">
          Select roles
        </Typography>
        {/* <div className="form-item">
          <Controller
            control={control}
            name="accessRecordings"
            render={({ field: { onChange, value, name } }) => (
              <Grid container alignItems="center" justifyContent="space-between">
                <Label> Access recordings</Label>
                <FormGroup>
                  <FormControlLabel
                    control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                    label=""
                  />
                </FormGroup>

              </Grid>
            )}
          />
          <ErrorMessage>{errors?.accessRecordings?.message}</ErrorMessage>
          <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
        </div> */}
        <div className="form-item">
          <Controller
            control={control}
            name="authenticateComplaints"
            render={({ field: { onChange, value, name } }) => (
              <Grid container alignItems="center" justifyContent="space-between">
                <Label>
                  Authenticate Complaints
                </Label>
                <FormGroup>
                  <FormControlLabel
                    control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                    label=""
                  />
                </FormGroup>
              </Grid>
            )}
          />
          <ErrorMessage>{errors?.authenticateComplaints?.message}</ErrorMessage>
          <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
        </div>
        <div className="form-item">
          <Controller
            control={control}
            name="moderateArticles"
            render={({ field: { value, onChange, name } }) => (
              <Grid container alignItems="center" justifyContent="space-between">
                <Label> Moderate Articles</Label>
                <FormGroup>
                  <FormControlLabel
                    control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                    label=""
                  />
                </FormGroup>
              </Grid>
            )}
          />
          <ErrorMessage>{errors?.moderateArticles?.message}</ErrorMessage>
          <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
        </div>
        {/* <div className="form-item">
          <Controller
            control={control}
            name="observeSessions"
            render={({ field: { value, onChange, name } }) => (
              <Grid container alignItems="center" justifyContent="space-between">
                <Label> Observe Sessions</Label>
                <FormGroup>
                  <FormControlLabel
                    control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                    label=""
                  />
                </FormGroup>
              </Grid>
            )}
          />
          <ErrorMessage>{errors?.observeSessions?.message}</ErrorMessage>
          <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
        </div> */}
        <div className="form-item">
          <Controller
            control={control}
            name="accessClasses"
            render={({ field: { name, value, onChange } }) => (
              <Grid container alignItems="center" justifyContent="space-between">
                <Label> Access Classes</Label>
                <FormGroup>
                  <FormControlLabel
                    control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                    label=""
                  />
                </FormGroup>
              </Grid>
            )}
          />
          <ErrorMessage>{errors?.accessClasses?.message}</ErrorMessage>
          <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
        </div>
      </>
      )}
      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={closeModal}>
            Cancel
          </PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton fullWidth type="submit" loading={loading}>
            Add & continue
          </PrimaryLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default OnboardCreateAdminModalForm;
