/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewsFeedState, FetchNewsfeedFilterPayload, INewsfeed } from './interface';

export const initialState: NewsFeedState = {
  news: [],
  stateLoading: false,
  loading: false,
  expertId: '',
  total: 0,
};

export const newsFeedSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/edit-expert/news-feed',
  initialState,
  reducers: {
    getNewsFeed(
      state:NewsFeedState,
      action?: PayloadAction<FetchNewsfeedFilterPayload | undefined>,
    ) {
      state.loading = false;
      state.stateLoading = true;
    },
    getNewsFeedSucceeded(
      state:NewsFeedState,
      action:PayloadAction<{ news: INewsfeed[]; total: number }>,
    ) {
      state.news = action.payload.news;
      state.total = action.payload.total;
      state.stateLoading = false;
    },
    getNewsFeedFailed(state:NewsFeedState) {
      state.stateLoading = false;
    },
    setExpertId(state:NewsFeedState, action: PayloadAction<string>) {
      state.expertId = action.payload;
    },
    deleteNews(state:NewsFeedState, _action: PayloadAction<string>) {
      state.loading = true;
    },
    deleteNewsSucceeded(state:NewsFeedState, action: PayloadAction<string>) {
      const temp = state.news.filter((newsItem) => newsItem._id !== action.payload);
      state.news = [...temp];
      state.loading = false;
    },
    deleteNewsFailed(state:NewsFeedState) {
      state.loading = false;
    },
    pinNews(state:NewsFeedState, _action: PayloadAction<string>) {
      state.loading = true;
    },
    pinNewsFailed(state:NewsFeedState) {
      state.loading = false;
    },
  },
});

export const { actions: newsFeedActions } = newsFeedSlice;
