/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/base/auth'] || initialState;

export const selectAuth = createSelector([selectDomain], (authState) => authState);
export const selectUser = createSelector([selectDomain], (authState) => authState.user);
export const selectLoader = createSelector([selectDomain], (authState) => authState.loading);
export const selectAccessToken = createSelector([selectDomain], (authState) => authState.accessToken);
export const selectIsSlocoachSuperUser = createSelector([selectDomain], (authState) => authState.isSuperAdmin);
export const selectEntAccId = createSelector([selectDomain], (authState) => authState.entId);
export const selectRole = createSelector([selectDomain], (authState) => authState.role);
export const selectPermission = createSelector([selectDomain], (authState) => authState.permission);
export const selectCallbackPath = createSelector([selectDomain], (authState) => authState.callbackPath);
