/* eslint-disable react/destructuring-assignment */
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PRIMARY_COLOR, PRIMARY_LITE_COLOR } from 'constants/style';

const PrimaryButtonStyles = styled(Button)({
  backgroundColor: PRIMARY_COLOR,
  borderRadius: '5px',
  textTransform: 'none',
  fontWeight: '500',
  '&:hover': {
    color: 'white',
    backgroundColor: '#7D682B',
  },
});

const PrimaryLiteButtonStyles = styled(Button)({
  backgroundColor: PRIMARY_LITE_COLOR,
  color: PRIMARY_COLOR,
  borderRadius: '5px',
  border: '1px',
  borderColor: PRIMARY_COLOR,
  textTransform: 'none',
  fontWeight: '600',
  '&:hover': {
    color: '#9D8336',
    backgroundColor: '#D8CDAE',
  },
});

const PrimaryButtonRoundedStyles = styled(Button)({
  backgroundColor: PRIMARY_COLOR,
  borderRadius: '100px',
  textTransform: 'none',
  fontWeight: '600',
  '&:hover': {
    color: 'white',
    backgroundColor: '#7D682B',
  },
});
const PrimaryButtonOutlinedStyles = styled(Button)({
  color: PRIMARY_COLOR,
  backgroundColor: 'inherit',
  borderRadius: '100px',
  textTransform: 'none',
  fontWeight: '600',
  '&:hover': {
    color: '#7D682B',
    backgroundColor: 'white',
  },
});

const PrimaryLoadingButtonStyles = styled(LoadingButton)({
  backgroundColor: PRIMARY_COLOR,
  borderRadius: '5px',
  textTransform: 'none',
  fontWeight: '600',
  '&:hover': {
    color: 'white',
    backgroundColor: '#7D682B',
  },
});

const PrimaryLoadingButtonOutlinedStyles = styled(LoadingButton)({
  color: PRIMARY_COLOR,
  backgroundColor: 'inherit',
  borderRadius: '100px',
  textTransform: 'none',
  fontWeight: '600',
  '&:hover': {
    color: '#7D682B',
    backgroundColor: 'white',
  },
});

const IconLoadingButtonStyles = styled(LoadingButton)(({ theme }) => ({
  fontSize: '0.875rem',
  textTransform: 'none',
  fontWeight: 700,
  height: 'auto',
  minWidth: '64px',
  padding: '4px 5px',
  backgroundColor: 'transparent',
  border: 0,
  display: 'flex',
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
    borderColor: theme.palette.grey.A400,
    backgroundColor: 'transparent',
  },
}));
// @TODO: Here you have to create two components.
// And file names should like this way :- primary-button.
// And please use Must use destructuring props assignment
// According to your changes please change index.ts file as well.

export const PrimaryButton = (props: any) => (
  <PrimaryButtonStyles {...props} variant="contained" disableElevation>
    {props.children}
  </PrimaryButtonStyles>
);

export const PrimaryLiteButton = (props: any) => (
  <PrimaryLiteButtonStyles {...props} variant="contained" disableElevation>
    {props.children}
  </PrimaryLiteButtonStyles>
);

export const PrimaryRoundedButton = (props: any) => (
  <PrimaryButtonRoundedStyles {...props} variant="contained" disableElevation>
    {props.children}
  </PrimaryButtonRoundedStyles>
);

export const PrimaryOutlinedButton = (props: any) => (
  <PrimaryButtonOutlinedStyles sx={{ padding: '0.5rem' }} {...props} variant="contained" disableElevation>
    {props.children}
  </PrimaryButtonOutlinedStyles>
);
export const PrimaryLoadingButton = (props:any) => (
  <PrimaryLoadingButtonStyles {...props} variant="contained" disableElevation>
    {props.children}
  </PrimaryLoadingButtonStyles>
);

export const PrimaryLoadingOutlinedButton = (props:any) => (
  <PrimaryLoadingButtonOutlinedStyles {...props} variant="contained" disableElevation>
    {props.children}
  </PrimaryLoadingButtonOutlinedStyles>
);

export const IconLoadingButton = (props:any) => (
  <IconLoadingButtonStyles {...props} variant="contained" disableElevation>
    {props.children}
  </IconLoadingButtonStyles>
);
