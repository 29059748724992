import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { viewArticleActions } from './slice';
import API from '../../constant';
import { selectEntId } from './selector';
import {
  IArticleFilterPayload, IPublishArticle,
} from './interface';

export function* viewArticleGenerator({ payload }:PayloadAction<IArticleFilterPayload>): any {
  try {
    const id = yield select(selectEntId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_ARTICLE_BY_ENT.path}/${id}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&sortBy=${payload.sortBy}`, method: API.GET_ARTICLE_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewArticleActions.viewArticleSucceeded(response.data));
    } else {
      yield put(viewArticleActions.viewArticleFailed());
    }
  } catch (error) {
    yield put(viewArticleActions.viewArticleFailed());
  }
}
export function* deleteArticleGenerator({ payload }:PayloadAction<string>): any {
  try {
    const id = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_ARTICLE,
      { id },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewArticleActions.deleteArticleSucceeded(payload));
      yield put(viewArticleActions.closeModal(payload));
    } else {
      yield put(viewArticleActions.deleteArticleFailed());
    }
  } catch (error) {
    yield put(viewArticleActions.deleteArticleFailed());
  }
}
export function* publishArticleGenerator({ payload }:PayloadAction<IPublishArticle>): any {
  try {
    const { id, isActive } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_ARTICLE,
      { id, isActive },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewArticleActions.publishArticleSucceeded(id));
      yield put(viewArticleActions.closeModal(payload.id));
    } else {
      yield put(viewArticleActions.publishArticleFailed());
    }
  } catch (error) {
    yield put(viewArticleActions.publishArticleFailed());
  }
}

export function* viewArticleSaga() {
  yield all([takeLatest(
    viewArticleActions.viewArticle.type,
    viewArticleGenerator,
  ), takeLatest(
    viewArticleActions.publishArticle.type,
    publishArticleGenerator,
  ), takeLatest(
    viewArticleActions.deleteArticle.type,
    deleteArticleGenerator,
  )]);
}

export default viewArticleSaga;
