import Radio, { RadioProps } from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

const BpIcon = styled('span')({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#9D8336',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#9D8336',
  },
});

const PrimaryRadio = (props: RadioProps) => (
  <Radio
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
      },
    }}
    disableRipple
    icon={<BpIcon />}
    checkedIcon={<BpCheckedIcon />}
    color="default"
    {...props}
  />
);

export default PrimaryRadio;
