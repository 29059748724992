/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateUserState, ICreateBulkUsers, ICreateUser, ModalStates,
} from './interface';

export const initialState: CreateUserState = {
  loading: false,
  modal: ModalStates.close,
};

export const createUserSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/admin/create-user',
  initialState,
  reducers: {
    createUser(state: CreateUserState, _action: PayloadAction<ICreateUser>) {
      state.loading = true;
    },
    createUsers: (state: CreateUserState, action: PayloadAction<ICreateBulkUsers>) => {
      state.loading = true;
    },
    createUserSucceeded(state: CreateUserState) {
      state.loading = false;
      state.modal = ModalStates.close;
    },
    createUserFailed(state: CreateUserState) {
      state.loading = false;
    },
    openModal(state: CreateUserState, action: PayloadAction<string>) {
      state.modal = action.payload;
    },
    closeModal(state: CreateUserState) {
      state.modal = ModalStates.close;
    },
    reset: () => initialState,
  },
});

export const { actions: createUserActions } = createUserSlice;
