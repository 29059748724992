export enum SessionType {
  digitalSession = 'Digital session',
  liveSession = 'LIVE session',
  liveClass = 'LIVE class',
  course = 'Course',
  content = 'Content only',
}
export enum LiveClassTypes {
  interactive = 'Interactive',
  streaming = 'Streaming',
}

export enum SessionLanguages {
  english = 'English',
}

export enum CourseTypes {
  lsm = 'lms',
  interactive = 'interactive',
}
export enum SessionAudienceTypes {
  public = 'public',
  private = 'private',
  subscription = 'subscription',
  subscriptionOnly = 'subscription only',
  courseOnly = 'course only',
}

export enum SessionFrequencyTypes {
  oneTime = 'One time',
  everyDay = 'Every Day',
  everyWeek = 'Every Week',
  everyMonth = 'Every month',
}

export enum DaysOfTheWeek {
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  sunday = 'Sunday',
}

export enum FormSubmissionTypes {
  undefined = 'undefined',
  before = 'before',
  after = 'after',

}
