/* eslint-disable react/no-array-index-key */
import { Grid, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import {
  ErrorMessage,
  PrimaryCheckbox, PrimaryOutlinedButton, PrimarySwitch,
  PrimaryTimePicker,
} from 'components';
import { CloseSquareIcon } from 'assets/icons';
import { IAvailableTime } from '../../../../../../interface';
import { selectSessionInformation } from '../../../../selector';

interface AvailableTimeProps {
  handleChange: (availableTime: IAvailableTime) => void
  initialValue:IAvailableTime,
  errors:any
}

export default function AvailableTime({ handleChange, initialValue, errors }: AvailableTimeProps) {
  const { sessionDuration } = useSelector(selectSessionInformation);
  const [availableTime,
    setAvailableTime] = React.useState<IAvailableTime>(
    initialValue,
  );

  const handleIsAvailable = () => {
    const temp = availableTime.times;
    temp[0].from = '';
    temp[0].to = '';
    setAvailableTime({
      ...availableTime,
      isAvailable: !availableTime.isAvailable,
      times: [temp[0]],
    });
  };

  const handleAllDay = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const temp = availableTime.times;
    temp[0].from = '08:00:00';
    temp[0].to = '17:00:00';
    setAvailableTime({ ...availableTime, times: [temp[0]], isAllDay: checked });
  };

  const handleToTime = (index: number, value: string) => {
    const temp = availableTime.times;
    temp[index].to = value;
    setAvailableTime({ ...availableTime, times: [...temp] });
  };

  const handleFromTime = (index: number, value: string) => {
    const temp = availableTime.times;
    temp[index].from = value;
    setAvailableTime({ ...availableTime, times: [...temp] });
  };

  const addTimeSlot = () => {
    const temp = availableTime.times;
    temp.push({ to: '', from: '' });
    setAvailableTime({ ...availableTime, times: [...temp] });
  };

  const removeTimeSlot = (index: number) => {
    const temp = availableTime.times.filter((time, arrIndex) => arrIndex !== index);
    setAvailableTime({ ...availableTime, times: [...temp] });
  };

  React.useEffect(() => {
    handleChange(availableTime);
  }, [availableTime]);

  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={1}>
          <PrimarySwitch checked={availableTime.isAvailable} onChange={handleIsAvailable} />
        </Grid>
        {availableTime.isAvailable ? (
          <Grid item xs>
            <Grid container alignItems="center">
              <Grid item>
                <Grid container>
                  <Grid item xs>
                    <Grid container direction="column">
                      <Grid item>
                        {availableTime.times.map((time, index) => (
                          <Grid container alignItems="center" spacing={2} key={index}>
                            <Grid item xs={5}>
                              <PrimaryTimePicker
                                value={moment(time.from, 'HH:mm:ss')}
                                onChange={(newValue: any) => {
                                  handleFromTime(index, moment(newValue).format('HH:mm:ss'));
                                  handleToTime(index, moment(newValue).add(sessionDuration, 'minutes').format('HH:mm:ss'));
                                }}
                              />
                              <ErrorMessage>
                                {errors?.times ? errors?.times[index]?.from?.message : ''}
                              </ErrorMessage>
                            </Grid>
                            <Grid item xs><Typography variant="body1" sx={{ margin: 'auto', textAlign: 'center' }}>TO</Typography></Grid>
                            <Grid item xs={5}>
                              <PrimaryTimePicker
                                minTime={moment(time.from, 'HH:mm:ss').add(sessionDuration, 'minutes')}
                                value={moment(time.to, 'HH:mm:ss')}
                                onChange={(newValue: any) => {
                                  handleToTime(index, moment(newValue).format('HH:mm:ss'));
                                }}
                              />
                              <ErrorMessage>
                                {errors?.times ? errors?.times[index]?.to?.message : ''}
                              </ErrorMessage>
                            </Grid>
                            <Grid item>

                              {index > 0 && (
                              <IconButton aria-label="remove-time-slot" sx={{ p: '5px' }} onClick={() => { removeTimeSlot(index); }}>
                                <CloseSquareIcon sx={{ color: 'primary.main' }} />
                              </IconButton>

                              )}
                            </Grid>
                          </Grid>

                        ))}

                      </Grid>
                      <Grid item>
                        <PrimaryOutlinedButton sx={{ paddingLeft: '0', marginTop: '0.5rem' }} onClick={addTimeSlot} disabled={availableTime.isAllDay}>
                          <AddIcon sx={{ marginRight: '0.5rem' }} />
                          Add another time slot
                        </PrimaryOutlinedButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <PrimaryCheckbox
                      disabled={availableTime.times.length > 1}
                      checked={availableTime.isAllDay}
                      onChange={handleAllDay}
                    />
                    Available all day
                  </Grid>
                </Grid>

              </Grid>

            </Grid>
          </Grid>
        ) : ' '}
      </Grid>

    </div>
  );
}
