/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
import { memo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuthorization from 'base/auth/hooks/use-authorization';
import { Role } from 'interfaces/user-roles';
import { Permissions } from 'config';

interface Props {
  children: any;
  roles?:Role[]
  permissions?:Permissions[]
}

const AuthorizationWrapper = memo(({
  children, roles = undefined,
  permissions = undefined,
}: Props) => {
  const { isAuthorized } = useAuthorization();
  const location = useLocation();

  return isAuthorized(roles, permissions) ? (
    children
  ) : (
    <Navigate to="/unauthorized" replace state={{ path: location.pathname }} />
  );
});

export default AuthorizationWrapper;
