const API = {
  POST_CREATE_ARTICLE: {
    path: '/article/create',
    method: 'POST',
  },
  POST_UPDATE_ARTICLE: {
    path: '/article/update',
    method: 'POST',
  },
  GET_ARTICLE: {
    path: '/article/details',
    method: 'GET',
  },
  GET_ARTICLE_BY_ENT: {
    path: '/article/search',
    method: 'GET',
  },
  DELETE_ARTICLE: {
    path: '/article/delete',
    method: 'DELETE',
  },
  GET_GROUPS_BY_EXPERT: {
    path: '/group/details/expert',
    method: 'GET',
  },
  GET_CATEGORIES: {
    path: '/categories',
    method: 'GET',
  },
  POST_ASSIGN_CATEGORIES: {
    path: '/categories/assign',
    method: 'POST',
  },
};

export default API;
