import { memo } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import shadows, { Shadows } from '@mui/material/styles/shadows';
import {
  ERROR_COLOR, INFO_COLOR, PRIMARY_COLOR, PRIMARY_LITE_COLOR, SUCCESS_COLOR, WARNING_COLOR,
} from 'constants/style';

interface Props {
  children: any;
}

const ThemeWrapper = memo(({ children }: Props) => {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        light: '#FFFFFF',
        main: PRIMARY_COLOR,
      },
      secondary: {
        light: '#FFFFFF',
        main: PRIMARY_LITE_COLOR,
      },
      text: {
        primary: '#000000',
        secondary: '#999999',
        disabled: '#CDCDCD',
      },
      action: {
        active: '#FFFFFF',
        hover: '#FFFFFF',
        selected: '#FFFFFF',
        disabled: '#FFFFFF',
        disabledBackground: '#CDCDCD',
      },
      background: {
        paper: '#FFFFFF',
        default: '#FFFFFF',
      },

      error: {
        main: ERROR_COLOR,
      },
      success: {
        main: SUCCESS_COLOR,
      },
      info: {
        main: INFO_COLOR,
      },
      warning: {
        main: WARNING_COLOR,
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: [
        'Urbanist',
        'sans-serif',
      ].join(','),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontSize: '2rem',
        fontWeight: 'bold',
        lineHeight: '44px',
        letterSpacing: 0,
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        lineHeight: '33px',
        letterSpacing: 0,
      },
      h3: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        lineHeight: '27px',
        letterSpacing: 0,
      },
      h4: {
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '22px',
        letterSpacing: 0,
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: 0,
        color: '#181F26',
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 600,
        letterSpacing: 0,
        color: '#333333',
        lineHeight: '17px',
      },
      subtitle1: {
        fontSize: '0.875',
        fontWeight: 500,
        lineHeight: '17px',
        letterSpacing: '2.59px',
        color: '#181F26',
        textTransform: 'uppercase',
      },

    },
    zIndex: {
      appBar: 997,
      drawer: 998,
    },
    shadows: shadows.map(() => 'none') as Shadows,
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          /* latin-ext */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqrbS10ig.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqlbS0.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          /* latin-ext */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqrbS10ig.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqlbS0.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          /* latin-ext */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqrbS10ig.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqlbS0.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          /* latin-ext */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqrbS10ig.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqlbS0.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          /* latin-ext */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqrbS10ig.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
            font-family: 'Urbanist';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/urbanist/v8/L0x-DF02iFML4hGCyMqlbS0.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
        `,
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: '#000',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            ':before': {
              background: 'none',
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: '15px !important',
            paddingRight: '15px !important',
          },
        },
      },

    },
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
});
export default ThemeWrapper;
