interface ENV {
  BACKEND_API: string;
  APP_DOMAIN: string;
  APP_NAME: string;
  USER_HUB_DOMAIN:string,
}

const ENVIRONMENT: ENV = {
  BACKEND_API: process.env?.REACT_APP_BACKEND_API || '',
  APP_DOMAIN: process.env?.REACT_APP_APP_DOMAIN || '',
  APP_NAME: process.env?.REACT_APP_APP_NAME || '',
  USER_HUB_DOMAIN: process.env?.REACT_APP_USER_HUB_DOMAIN || '',
};
export default ENVIRONMENT;
