export enum FeedbackStatus {
  PENDING = 'Pending',
  PICKED_UP = 'Picked Up',
  RESOLVED = 'Resolved',
}

export enum FeedbackReferenceType {
  SESSION = 'session',
  GENERAL = 'general',
}

export enum FeedbackType {
  BUG = 'Bug',
  IMPROVEMENT = 'Improvement',
  COMPLAINT = 'Complaint',
}
