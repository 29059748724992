/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Popover,
  Typography,
  Box,
  Grid,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { CustomOutlineInputField } from '../primary-text-field';

interface SearchBarProps {
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string;
  name?: string;
  placeholder?: string;
  hints?: { key: string; value: string }[];
}

const SearchBar = React.forwardRef(
  ({
    onChange, onBlur, value, name, placeholder, hints,
  }: SearchBarProps, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        <CustomOutlineInputField
          onChange={onChange ?? undefined}
          startAdornment={(
            <InputAdornment position="start">
              <SearchIcon sx={{ color: '#E5E5E5' }} />
            </InputAdornment>
          )}
          type="text"
          size="small"
          onBlur={onBlur ?? undefined}
          value={value ?? undefined}
          name={name ?? undefined}
          ref={ref ?? undefined}
          placeholder={placeholder ?? undefined}
          fullWidth
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onFocus={handlePopoverOpen}
        />

        {hints && (
          <Popover
            id="mouse-over-popover"
            open={open}
            onClose={handlePopoverClose}
            anchorEl={anchorEl}
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'right', vertical: -10 }}
            PaperProps={{
              sx: {
                overflow: 'visible',
                backgroundColor: '#FFF',
                width: anchorEl ? `${anchorEl.offsetWidth}px` : 'auto',
                borderRadius: '5px',
                maxWidth: 'none',
                border: '1px solid #E5E5E5',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 8,
                  width: 10,
                  height: 10,
                  backgroundColor: 'inherit',
                  transform: 'translateY(-50%) rotate(45deg)',
                  boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
                },
              },
            }}
          >
            <Box
              sx={{
                padding: 2,
              }}
            >
              <Grid container spacing={1}>
                {hints.map((hint) => (
                  <Grid item xs={20} sm={10} key={hint.key}>
                    <code>{hint.key}</code>
                    <Typography component="span">
                      {' '}
                      {hint.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Popover>
        )}
      </>
    );
  },
);

export default SearchBar;
