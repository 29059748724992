const API = {
  GET_ACTIVITY: {
    path: '/analytics/activity',
    method: 'GET',
  },
  GET_TOP_PERFORMING_SESSIONS: {
    path: '/analytics/top-sessions',
    method: 'GET',
  },
  GET_UNIQUES_USERS: {
    path: '/analytics/unique-users',
    method: 'GET',
  },
  GET_FEEDBACK: {
    path: '/analytics/feedback',
    method: 'GET',
  },
  GET_USER_SIGN_IN_ANALYTICS: {
    path: '/analytics/users',
    method: 'GET',
  },
  GET_ALL_ENT_ACCOUNTS: {
    path: '/ent-account/all',
    method: 'GET',
  },

};
export default API;
