/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewReviewsState, IReviewsRequest, IDeleteReview } from './interface';

export const initialState: ViewReviewsState = {
  reviews: [],
  averageRating: 0,
  stateLoading: false,
  loading: false,
  expertId: '',
  total: 0,
};

export const viewReviewsSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/edit-expert/view-reviews',
  initialState,
  reducers: {
    getReviews(state:ViewReviewsState) {
      state.stateLoading = true;
    },
    getReviewsSucceeded(state:ViewReviewsState, action:PayloadAction<IReviewsRequest>) {
      state.reviews = action.payload.result;
      state.total = action.payload.total;
      let totalRating = 0;
      action.payload.result.forEach((review) => {
        totalRating += review.rating;
      });
      state.averageRating = totalRating / action.payload.total;
      state.stateLoading = false;
    },
    getReviewsFailed(state:ViewReviewsState) {
      state.stateLoading = false;
    },
    deleteReview(state: ViewReviewsState, _action:PayloadAction<IDeleteReview>) {
      state.loading = true;
    },
    deleteReviewSucceeded(state: ViewReviewsState, action:PayloadAction<string>) {
      const temp = state.reviews.filter((review) => review._id !== action.payload);
      state.reviews = [...temp];
      state.loading = false;
    },
    deleteReviewFailed(state: ViewReviewsState) {
      state.loading = false;
    },
    setExpertId(state:ViewReviewsState, action: PayloadAction<string>) {
      state.expertId = action.payload;
    },
  },
});

export const { actions: viewReviewsActions } = viewReviewsSlice;
