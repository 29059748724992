/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActivitiesRequest, IEntAccountRequest, IFeedbackRequest, ISeries,
  ITopSessionsRequest, IUniqueUsersRequest, IUserSignInAnalyticsRequest, OverviewState,
} from './interface';

const series:ISeries = {
  total: 0,
  pastTotal: 0,
  countSeries: [],
  timeSeries: [],
};
export const initialState: OverviewState = {
  stateLoading: false,
  topSessionsLoading: false,
  activitiesLoading: false,
  entAccountsLoading: false,
  feedbackLoading: false,
  userSignInAnalytics: {
    users: series,
    experts: series,
    admins: series,
    totalRepeated: 0,
    totalPastRepeated: 0,
  },
  uniqueUsers: { total: 0, pastTotal: 0 },
  feedback: {
    resolved: 0, pending: 0, pickedUp: 0, total: 0, pastTotal: 0,
  },
  topPerformanceSessions: [],
  activities: [],
  entAccounts: [],
  entId: '',
  filterOption: '',

};

export const overviewSlice = createSlice({
  name: 'feature/overview',
  initialState,
  reducers: {
    getUserSignInAnalytics(state: OverviewState) {
      state.stateLoading = true;
    },
    getUserSignInAnalyticsSucceeded(
      state: OverviewState,
      action:PayloadAction<IUserSignInAnalyticsRequest>,
    ) {
      state.userSignInAnalytics = action.payload.userSignInAnalytics;
      state.stateLoading = false;
    },
    getUserSignInAnalyticsFailed(state: OverviewState) {
      state.stateLoading = false;
      state.userSignInAnalytics = initialState.userSignInAnalytics;
    },
    getUniqueUsers(state: OverviewState) {
      state.stateLoading = true;
    },
    getUniqueUsersSucceeded(
      state: OverviewState,
      action:PayloadAction<IUniqueUsersRequest>,
    ) {
      state.uniqueUsers = action.payload.uniqueUsers;
      state.stateLoading = false;
    },
    getUniqueUsersFailed(state: OverviewState) {
      state.stateLoading = false;
      state.uniqueUsers = initialState.uniqueUsers;
    },
    getActivities(state: OverviewState) {
      state.activitiesLoading = true;
    },
    getActivitiesSucceeded(state: OverviewState, action:PayloadAction<IActivitiesRequest>) {
      state.activities = action.payload.activities;
      state.activitiesLoading = false;
    },
    getActivitiesFailed(state: OverviewState) {
      state.activities = initialState.activities;
      state.activitiesLoading = false;
    },
    getEntAccounts(state: OverviewState) {
      state.activitiesLoading = true;
    },
    getEntAccountsSucceeded(state: OverviewState, action:PayloadAction<IEntAccountRequest>) {
      state.entAccounts = action.payload.entAccounts;
      state.entAccountsLoading = false;
    },
    getEntAccountsFailed(state: OverviewState) {
      state.stateLoading = false;
    },
    getTopPerformingSessions(state: OverviewState) {
      state.topSessionsLoading = true;
    },
    getTopPerformingSessionsSucceeded(
      state: OverviewState,
      action:PayloadAction<ITopSessionsRequest>,
    ) {
      state.topPerformanceSessions = action.payload.topPerformingSessions;
      state.topSessionsLoading = false;
    },
    getTopPerformingSessionsFailed(state: OverviewState) {
      state.topSessionsLoading = false;
      state.topPerformanceSessions = initialState.topPerformanceSessions;
    },
    getFeedback(state: OverviewState) {
      state.feedbackLoading = true;
    },
    getFeedbackSucceeded(
      state: OverviewState,
      action:PayloadAction<IFeedbackRequest>,
    ) {
      state.feedback = action.payload.feedback;
      state.feedbackLoading = false;
    },
    getFeedbackFailed(state: OverviewState) {
      state.feedbackLoading = false;
      state.feedback = initialState.feedback;
    },
    setEntId(state: OverviewState, action:PayloadAction<string>) {
      state.entId = action.payload;
    },
    setFilterOption(state: OverviewState, action:PayloadAction<string>) {
      state.filterOption = action.payload;
    },

  },
});

export const { actions: overviewActions } = overviewSlice;
