/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/onboard-ent-acc'] || initialState;

export const selectId = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.id);
export const selectGeneralInformationForm = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.generalInformationForm);
export const selectSitePreferenceForm = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.sitePreferencesForm);
export const selectContentForm = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.contentForm);
export const selectChargesForm = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.chargesForm);
export const selectAdminForm = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.adminForm);
export const selectStatus = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.status);
export const selectLoader = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.loading);
export const selectStateLoader = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.stateLoading);
export const selectStateExpertLoader = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.stateExpertLoading);
export const selectStateAdminLoader = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.stateAdminLoading);
export const selectActive = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.active);
export const selectModal = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.modal);
export const selectExpertForm = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.expertForm);
export const selectOnboard = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState);
export const selectIsOnBoardingDone = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.isOnBoardingDone);
export const selectStripeAccountCompleted = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.stripeAccountCompleted);
export const selectStripeConnectUrl = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.stripeConnectUrl);
export const selectEntUrl = createSelector([selectDomain], (OnboardEntAccState) => OnboardEntAccState.entUrl);
