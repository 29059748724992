import { IFiltered } from 'interfaces/api-response';
import { SessionDocument } from '../../interface';

export enum ModalStates {
  deletePending = 'DELETE_PENDING',
  deleteComplete = 'DELETE_COMPLETE',
  close = 'CLOSE',
}

export interface ISession extends SessionDocument {
  modalState:string
}

export interface ViewSessionsState {
  stateLoading:boolean,
  loading:boolean,
  filteredSessions:IFiltered<ISession>,
  expertId:string,
  entId:string,
}

export interface IPaginateFilterPayload {
  keyword?: string;
  page?: number;
  limit?: number;
  status?:string,
  type?:string,
  sortBy?:string
  publicOnly?:boolean,
  withoutDeleted?:boolean,
  isPrequisite?: boolean;
  currentSessionId?: string;
}
export interface IDeleteSession {
  id:string, reasonForDeletion:string, linkedSessionId:string
}
export interface IFilterStateAction {
  payload: IPaginateFilterPayload;
  type: string;
}
export interface IOpenModal {
  id:string,
  state:string
}
