import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IOnboardChargesModalForm } from '../../../../../interface';
import { selectChargesForm, selectLoader } from '../../../../../selector';
import OnboardChargesModalForm from '../charges-form';
import { onboardEntAccActions } from '../../../../../slice';
import onboardChargeModalFormValidation from '../validation/charges-form-validation';

const OnboardChargesModalView = () => {
  const dispatch = useDispatch();
  const charges = useSelector(selectChargesForm);
  const loading = useSelector(selectLoader);
  const methods = useForm<IOnboardChargesModalForm>({
    defaultValues: {
      bankDetails: charges.bankDetails,
      charges: charges.charges,
    },
    resolver: yupResolver(onboardChargeModalFormValidation),
    shouldFocusError: true,
  });
  const onSubmit: SubmitHandler<IOnboardChargesModalForm> = useCallback(
    (formData) => {
      dispatch(onboardEntAccActions.editCharges(formData));
    },
    [dispatch],
  );

  return (
    <Grid container justifyContent="center">
      <Grid xs item>
        <FormProvider {...methods}>
          <OnboardChargesModalForm onSubmit={onSubmit} loading={loading} />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default OnboardChargesModalView;
