/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import SessionFeeForm from '../session-fees-form';
import sessionFeeFormValidation from '../../validation/session-fees-form-validation';
import { ISessionFeeForm } from '../../../../interface';
import { selectSessionFee, selectSessionInformation } from '../../../../selector';
import { createSessionActions } from '../../../../slice';

interface SessionFeeViewProps {
  editMode:boolean
}
export default function SessionFeeView({ editMode }:SessionFeeViewProps) {
  const dispatch = useDispatch();
  const sessionFee = useSelector(selectSessionFee);
  const { sessionType, audienceType, isObserverAllowed } = useSelector(selectSessionInformation);

  const methods = useForm<ISessionFeeForm>({
    defaultValues: {
      ...sessionFee,
    },
    resolver: yupResolver(sessionFeeFormValidation),
    context: { sessionType, audienceType, isObserverAllowed },
    shouldFocusError: true,
  });

  const onSubmit:
  SubmitHandler<ISessionFeeForm> = React.useCallback((data) => {
    if (editMode) {
      dispatch(createSessionActions.editSession(data));
    } else {
      dispatch(createSessionActions.createSession(data));
    }
  }, [dispatch]);
  return (
    <div>
      <FormProvider {...methods}>
        <SessionFeeForm
          onSubmit={onSubmit}
          editMode={editMode}
        />
      </FormProvider>
    </div>
  );
}
