import * as yup from 'yup';
import FontOptions from 'config/font-options';
import { ContentImageDataValidation } from 'helpers/validations';

const onboardSitePreferencesModalFormValidation = yup.object().shape({
  isFontDefault: yup.string().trim().required('Font family is a required field'),
  headerFont: yup
    .object()
    .nullable()
    .notRequired()
    .when('isDefault', {
      is: FontOptions.custom,
      then: yup
        .object()
        .shape({
          name: yup.string().trim().required('Header font is required'),
          url: yup.string().trim().required('Header font is required'),
        }),
    }),
  bodyFont: yup
    .object()
    .nullable()
    .notRequired()
    .when('isDefault', {
      is: FontOptions.custom,
      then: yup
        .object()
        .shape({
          name: yup.string().trim().required('Header font is required'),
          url: yup.string().trim().required('Header font is required'),
        }),
    }),
  colors: yup.object().shape({
    primaryColor: yup.string().trim().required('Primary Color is a required field').matches(/^#([0-9a-f]{3}){1,2}$/i, 'Color should be a hex value'),
    secondaryColor: yup.string().trim().required('Secondary Color is a required field').matches(/^#([0-9a-f]{3}){1,2}$/i, 'Color should be a hex value'),
  }),
  headerTemplate: yup.string().trim().required('Header template is a required field'),
  sponsorContent: yup.array().of(ContentImageDataValidation),
  promotionalContent: yup.object().shape({
    url: yup.string().trim().required('Image is a required field'),
  }),
});
export default onboardSitePreferencesModalFormValidation;
