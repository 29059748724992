/* eslint-disable react/require-default-props */
import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  DataGrid, DataGridProps,
} from '@mui/x-data-grid';
import './index.scss';
import { MeatballIcon } from 'assets/icons';
import { Box } from '@mui/material';

const CustomNoRowsOverlay = () => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box> No data available</Box>
  </Box>
);
const PrimaryTable = ({
  className, rows, columns, loading,
  hideFooter, ...props
}: DataGridProps) => (
  <div style={{ width: '100%' }} className={className}>
    <DataGrid
      {...props}
      sx={{ '& .MuiDataGrid-columnHeaderTitle': { fontWeight: '600' } }}
      rows={rows}
      autoHeight
      disableColumnMenu
      rowHeight={70}
      columns={columns}
      loading={loading}
      hideFooter={hideFooter}
      components={{
        ColumnSortedAscendingIcon: ArrowDropUpIcon,
        ColumnSortedDescendingIcon: ArrowDropDownIcon,
        MoreActionsIcon: MeatballIcon,
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
    />
  </div>
);

export default PrimaryTable;
