/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Grid } from '@mui/material';
import { PrimaryChip } from 'components';
import { ExpertDocument } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/interface';

interface EntExpertProps {
  entExperts: ExpertDocument[];
  onDelete: (expert: ExpertDocument) => void;
}

const EntExpertGrid = ({ entExperts, onDelete }: EntExpertProps) => (
  <div>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {entExperts.map((expert, index) => (
        <Grid item key={index}>
          <PrimaryChip
            index={0}
            label={expert.email}
            variant="outlined"
            onDelete={() => onDelete(expert)}
          />

        </Grid>
      ))}
    </Grid>
  </div>
);

export default EntExpertGrid;
