/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActivateEntAcc,
  IDeleteEntAcc,
  IEntAccountFilterPayload,
  IEntAccountRequest,
  IOpenModal,
  IPinEntAcc,
  ISCAdminRequest,
  ModalStates,
  ViewEntAccountState,
} from './interface';

export const initialState: ViewEntAccountState = {
  entAccountsLoading: false,
  scAdminsLoading: false,
  loading: false,
  entAccounts: { result: [], total: 0 },
  totalActive: 0,
  totalInactive: 0,
  scAdmins: [],
};

export const viewEntAccSlice = createSlice({
  name: 'feature/ent-acc/view-ent-acc',
  initialState,
  reducers: {
    viewEntAcc(state: ViewEntAccountState, _action: PayloadAction<IEntAccountFilterPayload>) {
      state.entAccountsLoading = true;
    },
    viewEntAccSucceeded(state: ViewEntAccountState, action: PayloadAction<IEntAccountRequest>) {
      state.totalActive = action.payload.active;
      state.totalInactive = action.payload.inactive;
      state.entAccounts.result = action.payload.result.map((entAccount) => ({
        ...entAccount,
        modal: ModalStates.close,
      }));
      state.entAccounts.total = action.payload.total;
      state.entAccountsLoading = false;
    },
    viewEntAccFailed(state: ViewEntAccountState) {
      state.entAccountsLoading = false;
    },
    editPinnedEnt(state: ViewEntAccountState, _action: PayloadAction<IPinEntAcc>) {
      state.loading = true;
    },
    editPinnedEntSucceeded(state: ViewEntAccountState, action: PayloadAction<IPinEntAcc>) {
      const temp = state.entAccounts.result;
      temp.forEach((entAcc) => {
        if (entAcc._id === action.payload.id) {
          entAcc.isDefault = action.payload.isDefault;
        }
      });
      state.loading = false;
    },
    editPinnedEntFailed(state: ViewEntAccountState) {
      state.loading = false;
    },

    getSCAdmins(state: ViewEntAccountState) {
      state.scAdminsLoading = true;
    },
    getSCAdminsSucceeded(state: ViewEntAccountState, action: PayloadAction<ISCAdminRequest>) {
      state.scAdmins = action.payload.scAdmins;
      state.scAdminsLoading = false;
    },
    getSCAdminsFailed(state: ViewEntAccountState) {
      state.scAdminsLoading = false;
    },
    openModal(state: ViewEntAccountState, action: PayloadAction<IOpenModal>) {
      const temp = state.entAccounts.result;
      temp.forEach((entAcc) => {
        if (entAcc._id === action.payload.id) {
          entAcc.modal = action.payload.state;
        }
      });
      state.entAccounts.result = [...temp];
    },
    closeModal(state: ViewEntAccountState, action: PayloadAction<string>) {
      const temp = state.entAccounts.result;
      temp.forEach((entAcc) => {
        if (entAcc._id === action.payload) {
          entAcc.modal = ModalStates.close;
        }
      });
      state.entAccounts.result = [...temp];
    },
    editActiveEntAccount(state: ViewEntAccountState, _action: PayloadAction<IActivateEntAcc>) {
      state.loading = true;
    },
    editActiveEntAccSucceeded(state: ViewEntAccountState, action: PayloadAction<IActivateEntAcc>) {
      const temp = state.entAccounts.result;
      temp.forEach((entAcc) => {
        if (entAcc._id === action.payload.id) {
          entAcc.active = action.payload.active;
        }
      });
      state.entAccounts.result = [...temp];
      state.loading = false;
    },
    editActiveEntAccFailed(state: ViewEntAccountState) {
      state.loading = false;
    },

    deleteEntAccount(state: ViewEntAccountState, _action: PayloadAction<IDeleteEntAcc>) {
      state.loading = true;
    },
    deleteEntAccSucceeded(state: ViewEntAccountState, action: PayloadAction<string>) {
      const temp = state.entAccounts.result.filter((entAcc) => entAcc._id !== action.payload);
      state.entAccounts.result = [...temp];
      state.loading = false;
    },
    deleteEntAccFailed(state: ViewEntAccountState) {
      state.loading = false;
    },
  },
});

export const { actions: viewEntAccActions } = viewEntAccSlice;
