const API = {
  GET_ENT_ACC: {
    path: '/ent-account/search',
    method: 'GET',
  },
  GET_GET_SC_ADMIN: {
    path: '/sc-admin/all',
    method: 'GET',
  },
  DELETE_ENT_ACC: {
    path: '/ent-account/delete',
    method: 'DELETE',
  },
  POST_ENT_ACC: {
    path: '/ent-account/update',
    method: 'POST',
  },
};
export default API;
