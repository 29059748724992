/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/group/edit-group'] || initialState;

export const selectEditGroup = createSelector([selectDomain], (EditGroupState) => EditGroupState);
export const selectStateLoader = createSelector([selectDomain], (EditGroupState) => EditGroupState.stateLoading);
export const selectLoader = createSelector([selectDomain], (EditGroupState) => EditGroupState.loading);
export const selectSubStateLoader = createSelector([selectDomain], (EditGroupState) => EditGroupState.subStateLoading);
export const selectActive = createSelector([selectDomain], (EditGroupState) => EditGroupState.isActive);
export const selectId = createSelector([selectDomain], (EditGroupState) => EditGroupState.id);
export const selectEntId = createSelector([selectDomain], (EditGroupState) => EditGroupState.entId);
export const selectDelete = createSelector([selectDomain], (EditGroupState) => EditGroupState.deleted);
export const selectModalState = createSelector([selectDomain], (EditGroupState) => EditGroupState.modalState);
export const selectGeneralInformation = createSelector([selectDomain], (EditGroupState) => EditGroupState.generalInformation);
export const selectAccountType = createSelector([selectDomain], (EditGroupState) => EditGroupState.accountType);
export const selectSponsorContent = createSelector([selectDomain], (EditGroupState) => EditGroupState.sponsorContent);
export const selectExperts = createSelector([selectDomain], (EditGroupState) => EditGroupState.experts);
export const selectSessions = createSelector([selectDomain], (EditGroupState) => EditGroupState.sessions);
export const selectContent = createSelector([selectDomain], (EditGroupState) => EditGroupState.content);
export const selectArticles = createSelector([selectDomain], (EditGroupState) => EditGroupState.articles);
export const selectUsers = createSelector([selectDomain], (EditGroupState) => EditGroupState.users);
export const selectFilteredExperts = createSelector([selectDomain], (EditGroupState) => EditGroupState.filteredExperts);
export const selectFilteredSessions = createSelector([selectDomain], (EditGroupState) => EditGroupState.filteredSessions);
export const selectFilteredArticles = createSelector([selectDomain], (EditGroupState) => EditGroupState.filteredArticles);
export const selectFilteredContent = createSelector([selectDomain], (EditGroupState) => EditGroupState.filteredContent);
export const selectSessionIds = createSelector([selectDomain], (EditGroupState) => EditGroupState.sessionIds);
export const selectContentIds = createSelector([selectDomain], (EditGroupState) => EditGroupState.contentIds);
export const selectExpertIds = createSelector([selectDomain], (EditGroupState) => EditGroupState.expertIds);
export const selectArticleIds = createSelector([selectDomain], (EditGroupState) => EditGroupState.articleIds);
