import {
  all,
  call, put, takeLatest,
} from 'redux-saga/effects';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { overviewActions } from './slice';
import API from './constants';

export function* getEntAccountsGenerator(): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.GET_ALL_ENT_ACCOUNTS,
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(overviewActions.getEntAccountsSucceeded({ entAccounts: response.data }));
    } else {
      yield put(overviewActions.getEntAccountsFailed());
    }
  } catch (error) {
    yield put(overviewActions.getEntAccountsFailed());
  }
}
export function* overviewSaga() {
  yield all([
    takeLatest(overviewActions.getEntAccounts.type, getEntAccountsGenerator)]);
}

export default overviewSaga;
