/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltered } from 'interfaces/api-response';
import {
  CreateArticleState, ICreateArticleForm, IPaginateFilterPayload,
} from './interface';
import { IGroupAvatar } from '../../../groups/interface';

export const initialState: CreateArticleState = {

  loading: false,
  isActive: false,
  stateLoading: false,
  subStateLoading: false,
  isAuthorActive: true,
  status: 0,
  entId: '',
  articleId: '',
  article: {
    title: '',
    url: '',
    highlight: '',
    author: {
      id: '',
      lastName: '',
      firstName: '',
      email: '',
      profileImgUrl: '',
      attributes: {
        bio: '', isActive: false, rating: 0, noOfRatings: 0, category: '',
      },
    },
    highlightMedia: {
      _id: '',
      thumbnail: '',
      title: '',
      fileType: '',
      fileCategory: '',
      description: '',
      contentUrl: '',
    },
    assignedGroups: [],
    lead: '',
    body: '',
    products: [],
    experts: [],
    contents: [],
    categories: [],
  },
  filteredGroups: { result: [], total: 0 },
};

export const createArticleSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/create-article',
  initialState,
  reducers: {
    createArticle(state: CreateArticleState, action:PayloadAction<ICreateArticleForm>) {
      state.loading = true;
      state.article = action.payload;
    },
    createArticleSucceeded(state: CreateArticleState) {
      state.article = initialState.article;
      state.status = 1;
      state.loading = false;
    },
    createArticleFailed(state: CreateArticleState) {
      state.loading = false;
    },
    editArticle(state: CreateArticleState, action:PayloadAction<ICreateArticleForm>) {
      state.loading = true;
      state.article = action.payload;
    },
    editArticleSucceeded(state: CreateArticleState) {
      state.article = initialState.article;
      state.status = 1;
      state.loading = false;
    },
    editArticleFailed(state: CreateArticleState) {
      state.loading = false;
    },
    editActive(state:CreateArticleState, action:PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
    setForPreview(state: CreateArticleState, action:PayloadAction<ICreateArticleForm>) {
      state.article = action.payload;
      state.stateLoading = false;
    },
    setEntId(state:CreateArticleState, action:PayloadAction<string>) {
      state.entId = action.payload;
    },
    setAuthorActive(state:CreateArticleState, action:PayloadAction<boolean>) {
      state.isAuthorActive = action.payload;
    },
    getArticle(state: CreateArticleState, action:PayloadAction<string>) {
      state.articleId = action.payload;
      state.stateLoading = true;
      state.status = 0;
    },
    getArticleFailed(state: CreateArticleState) {
      state.articleId = initialState.articleId;
      state.stateLoading = false;
    },
    getFilteredGroups(state: CreateArticleState, _action:PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredGroupsSucceeded(
      state: CreateArticleState,
      action: PayloadAction<IFiltered<IGroupAvatar>>,
    ) {
      state.filteredGroups = action.payload;
      state.subStateLoading = false;
    },
    getFilteredGroupsFailed(state: CreateArticleState) {
      state.subStateLoading = false;
    },
    reset: (state) => ({ ...initialState, entId: state.entId }),

  },
});

export const { actions: createArticleActions } = createArticleSlice;
