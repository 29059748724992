import * as yup from 'yup';

const onboardContentModalFormValidation = yup.object().shape({
  landingPageContent: yup.object().shape({
    tagLine: yup.string().max(250).trim(),
    title: yup.string().max(48).trim(),
    body: yup.string().trim().max(300).label('Body Copy'),
  }),
  groupLandingPageContent: yup.object().shape({
    title: yup.string().max(75).trim(),
    body: yup.string().max(300).trim(),
  }),
  expertLandingPageContent: yup.object().shape({
    title: yup.string().max(75).trim(),
    body: yup.string().max(300).trim(),
  }),
  sessionLandingPageContent: yup.object().shape({
    title: yup.string().max(75).trim(),
    body: yup.string().max(300).trim(),
  }),
  articleLandingPageContent: yup.object().shape({
    title: yup.string().max(75).trim(),
    body: yup.string().max(300).trim(),
  }),
  contentLandingPageContent: yup.object().shape({
    title: yup.string().max(75).trim(),
    body: yup.string().max(300).trim(),
  }),
});
export default onboardContentModalFormValidation;
