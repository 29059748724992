import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { viewReviewsActions } from './slice';
import { selectExpertId } from './selector';
import API from './constants';
import { IDeleteReview } from './interface';

export function* getReviewsGenerator(): any {
  try {
    const id = yield select(selectExpertId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_REVIEWS_BY_EXPERT.path}/${id}?limit=9`, method: API.GET_REVIEWS_BY_EXPERT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewReviewsActions.getReviewsSucceeded(response.data));
    } else {
      yield put(viewReviewsActions.getReviewsFailed());
    }
  } catch (error) {
    yield put(viewReviewsActions.getReviewsFailed());
  }
}
export function* deleteReviewGenerator({ payload }:PayloadAction<IDeleteReview>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_REVIEW,
      payload,
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewReviewsActions.deleteReviewSucceeded(payload.id));
    } else {
      yield put(viewReviewsActions.deleteReviewFailed());
    }
  } catch (error) {
    yield put(viewReviewsActions.deleteReviewFailed());
  }
}

export function* viewReviewsSaga() {
  yield all([takeLatest(
    viewReviewsActions.getReviews.type,
    getReviewsGenerator,
  ), takeLatest(
    viewReviewsActions.deleteReview.type,
    deleteReviewGenerator,
  )]);
}

export default viewReviewsSaga;
