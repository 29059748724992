/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'interfaces';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/overview'] || initialState;

export const selectOverviewState = createSelector([selectDomain], (OverviewState) => OverviewState);
export const selectEntId = createSelector([selectDomain], (OverviewState) => OverviewState.entId);
export const selectStateLoader = createSelector([selectDomain], (OverviewState) => OverviewState.stateLoading);
export const selectTopSessionLoader = createSelector([selectDomain], (OverviewState) => OverviewState.topSessionsLoading);
export const selectActivitiesLoader = createSelector([selectDomain], (OverviewState) => OverviewState.activitiesLoading);
export const selectEntAccountsLoader = createSelector([selectDomain], (OverviewState) => OverviewState.entAccountsLoading);
export const selectFeedbackLoader = createSelector([selectDomain], (OverviewState) => OverviewState.feedbackLoading);
export const selectUserSignInAnalytics = createSelector([selectDomain], (OverviewState) => OverviewState.userSignInAnalytics);
export const selectUniqueUsers = createSelector([selectDomain], (OverviewState) => OverviewState.uniqueUsers);
export const selectFilterOption = createSelector([selectDomain], (OverviewState) => OverviewState.filterOption);
export const selectTopPerformanceSessions = createSelector([selectDomain], (OverviewState) => OverviewState.topPerformanceSessions);
export const selectActivities = createSelector([selectDomain], (OverviewState) => OverviewState.activities);
export const selectEntAccounts = createSelector([selectDomain], (OverviewState) => OverviewState.entAccounts);
export const selectFeedback = createSelector([selectDomain], (OverviewState) => OverviewState.feedback);
