import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link as NavLink, useParams } from 'react-router-dom';
import { PrimaryButton, PrimaryLiteButton } from 'components';
import './index.scss';

import { selectGeneralInformation } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import { SessionAudienceTypes } from 'config';
import { selectActive as selectIsExpertActive } from '../../../../../../selector';
import { selectActive, selectCourseOnlySessionMeta, selectSessionInformation } from '../../selector';
import { createSessionActions } from '../../slice';
import { CourseOnlySessionStatus } from '../../interface';

interface SuccessfulViewProps {
  editMode: boolean,
}

const SuccessfulView = ({ editMode }: SuccessfulViewProps) => {
  const { id, expertId } = useParams();
  const dispatch = useDispatch();
  const generalEntInformation = useSelector(selectGeneralInformation);
  const isActive = useSelector(selectActive);
  const isExpertActive = useSelector(selectIsExpertActive);
  const { audienceType } = useSelector(selectSessionInformation);
  const { status } = useSelector(selectCourseOnlySessionMeta);
  const sessionPath = `/ent-account/edit/${id}/experts/edit-expert/${expertId}/sessions`;
  const createAnotherSession = () => {
    dispatch(createSessionActions.reset());
  };
  const addToCourse = () => {
    dispatch(createSessionActions.setCourseOnlySessionMetaStatus(CourseOnlySessionStatus.done));
  };
  const keepEditing = () => {
    dispatch(createSessionActions.setCourseOnlySessionMetaStatus(CourseOnlySessionStatus.editing));
    dispatch(createSessionActions.setStatus(0));
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', addToCourse);

    return () => {
      window.removeEventListener('beforeunload', addToCourse);
    };
  }, []);

  return (
    <div>
      <div className="successful-view-icon">
        <CheckCircleIcon fontSize="large" />
      </div>
      {audienceType === SessionAudienceTypes.courseOnly ? (
        <>
          <h3 className="successful-view-heading">
            Your session has been successfully
            {' '}
            {(status === CourseOnlySessionStatus.editing || editMode) ? ' updated.' : 'added to your course.'}
            {' '}

          </h3>
          <div className="successful-view-body">
            <Typography variant="body1">
              You can now close this tab and proceed to complete creating your course.
              session

            </Typography>
          </div>
        </>

      ) : (
        <>
          <h3 className="successful-view-heading">
            You have successfully
            {' '}
            {editMode ? ' updated the' : 'created a new'}
            {' '}
            session
          </h3>
          <div className="successful-view-body">
            <Typography variant="body1">
              The session for
              {' '}
              {generalEntInformation.name}
              {' '}
              has been
              {' '}
              {editMode ? ' updated' : 'created'}
              {' '}
              successfully.

            </Typography>
            {(!isExpertActive && isActive) && (
              <Typography variant="body1" sx={{ color: 'red', fontWeight: '600', marginTop: '0.5rem' }}>
                Please activate expert to publish sessions

              </Typography>
            )}

          </div>
        </>

      )}
      {audienceType === SessionAudienceTypes.courseOnly ? (
        <>
          {' '}
          <Grid container spacing={3}>
            <Grid item>
              <NavLink style={{ textDecoration: 'none' }} to={sessionPath} onClick={addToCourse}>
                <PrimaryButton>
                  Ok
                </PrimaryButton>
              </NavLink>
            </Grid>

            <Grid item>
              <PrimaryLiteButton onClick={keepEditing}>
                Back to editing this session
                {' '}
              </PrimaryLiteButton>

            </Grid>
          </Grid>
        </>

      ) : (
        <>
          {' '}
          <Grid container spacing={3}>
            {editMode ? '' : (
              <Grid item>
                <PrimaryButton onClick={createAnotherSession}>
                  Create another session
                </PrimaryButton>
              </Grid>
            )}

            <Grid item>
              <NavLink style={{ textDecoration: 'none' }} to={sessionPath} onClick={createAnotherSession}>
                <PrimaryLiteButton>Back to all sessions</PrimaryLiteButton>
              </NavLink>
            </Grid>
          </Grid>
        </>

      )}

    </div>
  );
};

export default SuccessfulView;
