import * as yup from 'yup';
import { EmailValidation, PasswordValidation } from 'helpers/validations';

const ResetPasswordFormValidation = yup.object().shape({
  verificationCode: yup.string().trim().required('Code is a required field'),
  name: EmailValidation(),
  newPassword: PasswordValidation(),
  confirmPassword: yup.string().trim().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
export default ResetPasswordFormValidation;
