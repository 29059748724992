import { SubmitHandler } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { Label, PrimaryButton, PrimaryLoadingButton } from 'components';
import { AuthorityLevels } from 'config';
import CSVFileDragUploader from 'components/organisms/csv-file-drag-uploader';
import { AdminDocument } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/interface';
import OnboardCreateAdminModal from '../../add-new-admin-modal/add-new-admin-modal-view';
import { onboardEntAccActions } from '../../../../../slice';
import { selectAdminForm, selectLoader } from '../../../../../selector';
import EntAdminTable from '../../admin-table';
// import React from 'react';
// import { AdminDocument } from 'pages/dashboard/components/ent-account/interface';
// import { selectAdminForm, selectLoader } from '../../../../../selector';

interface OnboardAdminsModalFormProps {
  onSubmit: SubmitHandler<any>;
}

const OnboardAdminsModalForm = ({ onSubmit }: OnboardAdminsModalFormProps) => {
  const { admins } = useSelector(selectAdminForm);

  const adminLoading = useSelector(selectLoader);
  const dispatch = useDispatch();

  const onCsvTempleteDownloadHandler = () => {
    const data = [
      ['John', 'Doe', 'john@gmail.com', AuthorityLevels.GROUP_ADMIN],
      ['Jane', 'Doe', 'jane@gmail.com', AuthorityLevels.CLIENT_ADMIN],
      ['Sam', 'Smith', 'sam@gmail.com', AuthorityLevels.GROUP_ADMIN],
    ];

    let csv = 'Firstname,Lastname,Email,Authority\n';

    data.forEach((row) => {
      csv += row.join(',');
      csv += '\n';
    });

    const anchor = document.createElement('a');
    anchor.href = `data:attachment/csv,${encodeURI(csv)}`;
    anchor.target = '_self';
    anchor.rel = 'noopener';
    anchor.download = 'admin_csv_template.csv';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const onUploadCsvHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (!file) {
      // eslint-disable-next-line no-param-reassign
      event.target.files = null;
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      return;
    }

    if (file.type !== 'text/csv') {
      // eslint-disable-next-line no-param-reassign
      event.target.files = null;
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      return;
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      complete(results) {
        const header = results.data.shift() as string[];
        if (
          header?.[0].toLowerCase() !== 'firstname'
          || header?.[1].toLowerCase() !== 'lastname'
          || header?.[2].toLowerCase() !== 'email'
          || header?.[3].toLowerCase() !== 'authority'
        ) {
          return;
        }

        const data = results.data as [string, string, string, string][];

        const mappedData = data.map((row) => ({
          firstname: row[0],
          lastname: row[1],
          email: row[2],
          authority: row[3],
        }));

        dispatch(onboardEntAccActions.createAdmins(mappedData));

        // eslint-disable-next-line no-param-reassign
        event.target.files = null;
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      },
    });
  };

  const onDeleteHandler = async (row: AdminDocument) => {
    dispatch(onboardEntAccActions.deleteAdmin(row));
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-item">
        <div style={{ textAlign: 'left' }}>
          <h3>Add admins</h3>
          <Label>
            Admins will have access to this dashboard and they will be able to access recordings,
            authenticate complaints, modarate articles, observe sessions and access classes based on
            their access levels.
          </Label>
        </div>
      </div>
      <div className="form-item">
        <OnboardCreateAdminModal index={0} />
      </div>
      <div className="form-item">
        <EntAdminTable entAdmins={admins} loading={adminLoading} onDelete={onDeleteHandler} />
      </div>
      <div>
        <h3 className="form-item">
          To add multiple admins at once, download the CSV template below and, fill it in according
          to the specific fields, and re-upload
        </h3>
      </div>
      <div className="form-item">
        <PrimaryButton startIcon={<AddIcon />} onClick={onCsvTempleteDownloadHandler}>
          Download CSV template
        </PrimaryButton>
      </div>
      <div className="form-item">
        <CSVFileDragUploader
          textLabel="Upload the CSV file here"
          onChange={onUploadCsvHandler}
        />
      </div>
      <div>
        <PrimaryLoadingButton size="large" type="submit" variant="contained">
          Save & continue
        </PrimaryLoadingButton>
      </div>
    </form>
  );
};

export default OnboardAdminsModalForm;
