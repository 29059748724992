import * as React from 'react';
import {
  Controller, SubmitHandler, useFormContext,
} from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  ErrorMessage,
  Label,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimaryTextField,
} from 'components';

import { ICategoryModalForm } from '../../../../../interface';

interface CategoryModalFormProps {
  onSubmit: SubmitHandler<any>;
  closeModal: () => void;
  index: number;
}

const OnboardCategoryModalForm = ({ onSubmit, closeModal, index }: CategoryModalFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ICategoryModalForm>();
  const handleForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={handleForm}>
      <h3 className="form-item">Add new category</h3>
      <div className="form-item">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Label>
              <i>
                Category #
                {index}
              </i>
            </Label>
          </Grid>
        </Grid>
        <Controller
          name="name"
          control={control}
          render={({ field }) => <PrimaryTextField {...field} label="Category name" />}
        />
      </div>
      <ErrorMessage>{errors?.name?.message}</ErrorMessage>
      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={closeModal}>
            Cancel
          </PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton fullWidth type="submit">
            Add
          </PrimaryLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default OnboardCategoryModalForm;
