/* eslint-disable react/prop-types */
import React from 'react';
import DragHandle from './drag-handle';

const StaticTableRow = ({ row }:any) => (
  <tr className="static-table-row" {...row.getRowProps()}>
    {row.cells.map((cell:any, i:any) => {
      if (i === 0) {
        return (
          <td className="static-data" {...cell.getCellProps()}>
            <DragHandle isDragging />
            <span>{cell.render('Cell')}</span>
          </td>
        );
      }
      return (
        <td className="static-data" {...cell.getCellProps()}>
          {cell.render('Cell')}
        </td>
      );
    })}
  </tr>
);
export default StaticTableRow;
