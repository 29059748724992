/* eslint-disable max-len */
import axios from 'axios';
import {
  ContentData, IUploadConfig,
  UploadRequestMetaData, UploadResponse, UploadScenarios,
} from 'interfaces/file-uploader';
import { IRestApiResponse } from 'interfaces';
import { FileExtensions } from 'config';
import { uniqueIdGenerate } from './data-utils';
import { request } from './request';
import store from '../store';

const mbSize = 1000000;

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT = 'document',
  FOLDER = 'folder',
  FONT = 'font',
  COMPRESSED_FILE = 'compressed-file',
}

export const videoExtensions = [
  FileExtensions.mp4,
  FileExtensions.flv,
  FileExtensions.mkv,
  FileExtensions.webm,
  FileExtensions.mov,
  FileExtensions.wmv,
];
export const imageExtensions = [FileExtensions.jpg, FileExtensions.jpeg,
  FileExtensions.png, FileExtensions.gif];
export const documentExtensions = [FileExtensions.pdf];
export const fontExtensions = [FileExtensions.woff, FileExtensions.woff2];
export const compressItemsExtensions = [FileExtensions.zip];

export const uploadConfig = new Map<number, IUploadConfig>([
  [UploadScenarios.entFontUpload, { allowedExtensions: [...fontExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.expertVideo, { allowedExtensions: [...videoExtensions], sizeLimit: 3072 * mbSize }],
  [UploadScenarios.entLogo, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.entSponsor, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.entPromotional, { allowedExtensions: [...imageExtensions, ...videoExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.adminProfile, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.expertProfile, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.expertSponsor, { allowedExtensions: [...imageExtensions], sizeLimit: 500 * mbSize }],
  [UploadScenarios.expertBadge, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.expertContent, { allowedExtensions: [...imageExtensions, ...videoExtensions, ...documentExtensions], sizeLimit: 3072 * mbSize }],
  [UploadScenarios.articleBanner, { allowedExtensions: [...imageExtensions], sizeLimit: 100 * mbSize }],
  [UploadScenarios.sessionBanner, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.groupBanner, { allowedExtensions: [...imageExtensions], sizeLimit: 100 * mbSize }],
  [UploadScenarios.formBadge, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.scorm, { allowedExtensions: [FileExtensions.zip], sizeLimit: 500 * mbSize }],
  [UploadScenarios.ctaBackground, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.textBlock, { allowedExtensions: [...imageExtensions], sizeLimit: 30 * mbSize }],
  [UploadScenarios.sessionHighlightMedia, { allowedExtensions: [...imageExtensions, ...videoExtensions], sizeLimit: 30 * mbSize }],
]);

export const extensionToType = new Map<string, string>([

]);
videoExtensions.forEach((video) => { extensionToType.set(video, 'video'); });
imageExtensions.forEach((image) => { extensionToType.set(image, 'image'); });
documentExtensions.forEach((pdf) => { extensionToType.set(pdf, 'pdf'); });
fontExtensions.forEach((font) => { extensionToType.set(font, 'font'); });
compressItemsExtensions.forEach((item) => {
  extensionToType.set(item, FileType.COMPRESSED_FILE);
});
/**
 * @param  {any} e  File change event
 * @param  {string} url Current url of the content data object
 * @return UploadResponse
 */
const handleContentDataUpload = async (
  e: any,
  url: string,
  scenario: UploadScenarios,
  onUploadProgress?: (event: any) => void,
): Promise<UploadResponse> => {
  try {
    const state = store.getState();
    const fileUploaded = e.target.files[0] || e.dataTransfer.files[0];
    let action = '';
    const content: ContentData = {
      action: '', type: '', file: '', fileName: '', url, extension: '',
    };
    const UPLOAD_PATH: UploadRequestMetaData = { path: '/pre-signed-url/create-url', method: 'POST' };
    const config = uploadConfig.get(scenario);
    if (!config) {
      return {
        name: '', url, extension: '', statusCode: 400, message: 'Invalid configuration', category: '',
      };
    }

    if (fileUploaded) {
      if (url === '') {
        action = 'new';
      } else {
        action = 'update';
      }
      action = 'new';
      content.file = fileUploaded;
      content.action = action;
      content.extension = fileUploaded.name.split('.').pop().toLowerCase();
      content.fileName = `${uniqueIdGenerate()}`;

      if (!config.allowedExtensions.includes(`.${content.extension}`)) {
        return {
          name: '', url, extension: '', statusCode: 400, message: `Only ${config.allowedExtensions.join(', ')} file extensions are allowed`, category: '',
        };
      }
      if (config.sizeLimit < content.file.size) {
        return {
          name: '',
          url,
          extension: '',
          statusCode: 400,
          message: `File is larger than the recommended size ${config.sizeLimit / mbSize} MB`,
          category: '',
        };
      }

      content.type = extensionToType.get(`.${content.extension}`) as string;
      const fileData: {
        fileName: string;
        fileSize: number;
        tenantId: string;
      } = {
        fileName: fileUploaded.name,
        fileSize: fileUploaded.size,
        tenantId: state['feature/ent-acc/edit-ent-acc'].id || state['feature/ent-acc/onboard-ent-acc'].id,
      };

      try {
        const res: IRestApiResponse = await request(UPLOAD_PATH, fileData, true, false);
        if (res.statusCode === 201) {
          const {
            uniqueFileName, presignedPost, contentType, publicUrl, acl, fileCategory,
          } = res.data;
          const formData = {
            ...presignedPost.fields,
            'Content-Type': contentType,
            acl,
            file: fileUploaded,
          };
          await axios.post(presignedPost.url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
          });

          return {
            name: uniqueFileName,
            url: res.statusCode === 201 ? publicUrl : '',
            extension: content.extension,
            category: fileCategory,
            fileData: {
              fileName: fileData.fileName,
              size: fileData.fileSize,
            },
            statusCode: res.statusCode,
            message: res.message,
          };
        }
      } catch (error) {
        return {
          name: content.fileName,
          url,
          extension: content.extension,
          category: content.type,
          fileData: {
            fileName: fileData.fileName,
            size: fileData.fileSize,
          },
          statusCode: 400,
          message: (error as Error).message,
        };
      }
    }
    return {
      name: content.fileName, url, extension: content.extension, category: content.type, statusCode: 400, message: '',
    };
  } catch (error) {
    return {
      name: '', url, extension: '', statusCode: 400, message: '', category: '',
    };
  }
};

/**
 * @param  {any} e  File change event
 * @param  {string} url Current url of the content data object that needs to be deleted
 * @return void
 */
export const handleContentDataDelete = async (url: string, scenario: UploadScenarios)
: Promise<void> => {
  const content: ContentData = {
    action: 'delete', type: '', file: '', fileName: '', url, extension: '',
  };

  if (!url || !scenario) {
    return;
  }

  const UPLOAD_PATH: UploadRequestMetaData = { path: '', method: 'POST' };
  if (scenario === UploadScenarios.entSponsor
    || scenario === UploadScenarios.entPromotional
    || scenario === UploadScenarios.expertSponsor
    || scenario === UploadScenarios.expertProfile
    || scenario === UploadScenarios.expertVideo) {
    UPLOAD_PATH.path = '/ent-account/upload';
  } else {
    return;
  }
  await request(UPLOAD_PATH, content, true, true);
};
export default handleContentDataUpload;
