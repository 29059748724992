import React from 'react';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  PrimaryOutlinedButton, ClosableModal, PrimaryLiteButton, PrimaryLoadingButton,
  ErrorMessage, FileDragUploader, FileUploader,
} from 'components';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { extensionToType } from 'utils/file-uploader';
import { IWaitingMedia } from '../../../../interface';

interface WaitingRoomMediaProps {
  selectedMedia?: IWaitingMedia;
  handleAdd: (content: IWaitingMedia) => void;
}

const WaitingRoomMedia = ({ selectedMedia, handleAdd }: WaitingRoomMediaProps) => {
  const [open, setOpen] = React.useState(false);
  // handle modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [isMediaAdded, setIsMediaAdded] = React.useState(false);
  const [content, setContent] = React.useState<IWaitingMedia>({
    contentUrl: '', thumbnailUrl: '', fileName: '', fileType: '',
  });
  const [uploadRes, setUploadRes] = React.useState<UploadResponse>({
    url: '', extension: '', name: '', statusCode: 0, message: '', category: '',
  });

  const handleUpload = (res: UploadResponse) => {
    setUploadRes(res);
    setContent({
      contentUrl: res.url, thumbnailUrl: '', fileName: res.name, fileType: extensionToType.get(`.${res.extension}`) as string,
    });
  };

  const handleFileUpload = (res: UploadResponse) => {
    setUploadRes(res);
    setContent({
      contentUrl: res.url, thumbnailUrl: '', fileName: res.name, fileType: extensionToType.get(`.${res.extension}`) as string,
    });
    handleAdd({
      contentUrl: res.url, thumbnailUrl: '', fileName: res.name, fileType: extensionToType.get(`.${res.extension}`) as string,
    });
  };

  const handleFileRemove = () => {
    setContent({
      contentUrl: '', thumbnailUrl: '', fileName: '', fileType: '',
    });
    handleAdd({
      contentUrl: '', thumbnailUrl: '', fileName: '', fileType: '',
    });
    setIsMediaAdded(false);
  };

  const handleAddWrapper = () => {
    if (uploadRes.statusCode === 201) {
      handleAdd(content);
      setIsMediaAdded(true);
      handleClose();
    }
  };

  React.useEffect(() => {
    if (selectedMedia?.contentUrl) {
      setContent(selectedMedia);
      setIsMediaAdded(true);
    }
  }, [selectedMedia]);

  return (
    <div>
      {!isMediaAdded ? (
        <>
          <PrimaryOutlinedButton
            onClick={handleOpen}
            startIcon={<AddIcon />}
          >
            Add content
          </PrimaryOutlinedButton>
          <ClosableModal
            open={open}
            onClose={handleClose}
            title="Upload waiting room media"
            subTitle=""
            modalClass="select-item-modal"
          >
            <div className="form-item">
              <FileDragUploader
                textLabel="click or drag a file to this area to upload"
                handleFile={handleUpload}
                scenario={UploadScenarios.expertContent}
                currentContentUrl={content.contentUrl}
                uploadAnotherText="Upload another content"
                currentMediaType={content.fileType}
              />
              {uploadRes?.statusCode !== 201 ? <ErrorMessage>{uploadRes?.message}</ErrorMessage> : ''}

            </div>

            <Grid container spacing={3}>
              <Grid item xs>
                <PrimaryLiteButton fullWidth onClick={handleClose}>Cancel</PrimaryLiteButton>
              </Grid>
              <Grid item xs>
                <PrimaryLoadingButton loading={false} fullWidth onClick={handleAddWrapper}>
                  Add & continue
                </PrimaryLoadingButton>
              </Grid>

            </Grid>
          </ClosableModal>

        </>
      )
        : (
          <FileUploader
            handleFile={handleFileUpload}
            handleRemove={handleFileRemove}
            currentContentUrl={content.contentUrl}
            scenario={UploadScenarios.expertContent}
            currentMediaType={content.fileType}
          />
        )}

    </div>
  );
};

WaitingRoomMedia.defaultProps = {
  selectedMedia: {
    url: '', extension: '', name: '', statusCode: 0, message: '', category: '',
  },
};

export default WaitingRoomMedia;
