/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/create-expert'] || initialState;

export const selectCreateExpert = createSelector([selectDomain], (CreateExpertState) => CreateExpertState);
export const selectLoader = createSelector([selectDomain], (CreateExpertState) => CreateExpertState.loading);
export const selectStatus = createSelector([selectDomain], (CreateExpertState) => CreateExpertState.status);
export const selectExpertInformation = createSelector([selectDomain], (CreateExpertState) => CreateExpertState.expertInformation);
export const selectBankDetails = createSelector([selectDomain], (CreateExpertState) => CreateExpertState.bankDetails);
