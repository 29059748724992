import * as yup from 'yup';

const checkTotal = (value: any, textContext: yup.TestContext) => {
  const { forExpert, adminFee, forEntClient } = textContext.parent;
  if (adminFee + forEntClient + forExpert !== 100) {
    return false;
  }
  return true;
};
const onboardChargeModalFormValidation = yup.object().shape({
  charges: yup.object().shape({
    adminFee: yup.number().required('Admin charge is a required field').positive('The admin charge should be a positive number').integer('Admin charge should be a integer')
      .min(0, 'Admin Charge should be equal or greater than 0')
      .label('Admin fee')
      .test('adminFee', 'Total sum should be equal to 100', checkTotal),
    forExpert: yup.number().required('Expert charge is a required field').positive('The expert charge should be a positive number').integer('Expert charge should be a integer')
      .min(0, 'Expert Charge should be equal or greater  than 0')
      .typeError('Expert charge is a required field')
      .label('Expert fee')
      .test('forExpert', 'Total sum should be equal to 100', checkTotal),

    forEntClient: yup.number().required('Enterprise charge is a required field').positive('The enterprise charge should be a positive number').integer('Enterprise charge should be a integer')
      .min(0, 'Enterprise Charge should be equal or greater  than 0')
      .typeError('Enterprise charge is a required field')
      .label('Client fee')
      .test('adminFee', 'Total sum should be equal to 100', checkTotal),
  }),
  // bankDetails: yup.object().shape({
  //   accountName: yup.string().trim().required('Account Name is a required field')
  //     .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  //   accountNumber: yup.string().trim().required('Account number is a required field'),
  //   bankName: yup.string().trim().required('Bank Number is a required field')
  //     .matches(/^[aA-zZ,0-9\s]+$/, 'Only alphabets and numbers are allowed for this field '),
  //   branchName: yup.string().trim().required('Branch is a required field')
  //     .matches(/^[aA-zZ,0-9\s]+$/, 'Only alphabets and numbers are allowed for this field '),
  // }),
});
export default onboardChargeModalFormValidation;
