import './index.scss';
import { NavLink } from 'react-router-dom';
import { PrimaryButton, Title } from 'components';
import { BaseLayout } from 'components/templates';

const UnauthorizedView = () => (
  <BaseLayout>
    <div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <Title>Unauthorized</Title>
      </div>
      <br />
      <NavLink style={{ textDecoration: 'none' }} to="/">
        <PrimaryButton size="large" fullWidth>
          Back to home
        </PrimaryButton>
      </NavLink>
    </div>
  </BaseLayout>

);

export default UnauthorizedView;
