/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import Cookies from 'js-cookie';

export const getAuthToken = (): string | null => Cookies.get('session') ?? null;
export const getRefreshToken = (): string | null => Cookies.get('refreshToken') ?? null;
const domain = window.location.hostname;
const subDomain = '.' + window.location.hostname.split('.').reverse()[1] + '.' + window.location.hostname.split('.').reverse()[0];

export const setAuthToken = (token: string) => {
  Cookies.set('session', token, {
    path: '/',
    domain,
    expires: 1, // 1 day
  });
  Cookies.set('session-ws-admin', token, {
    path: '/',
    domain: subDomain,
    expires: 1, // 1 day
  });
};
export const setRefreshToken = (refreshToken: string) => {
  Cookies.set('refreshToken', refreshToken, {
    path: '/',
    domain,
    expires: 1, // 1 day
  });
  Cookies.set('refreshToken-ws-admin', refreshToken, {
    path: '/',
    domain: subDomain,
    expires: 1, // 1 day
  });
};

export const clearAuthToken = () => {
  Cookies.remove('session', {
    path: '/',
    domain,
  });
  Cookies.remove('refreshToken', {
    path: '/',
    domain,
  });
  Cookies.remove('session-ws-admin', {
    path: '/',
    domain: subDomain,
  });
  Cookies.remove('refreshToken-ws-admin', {
    path: '/',
    domain: subDomain,
  });
};
