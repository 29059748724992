import {
  call, put, all, takeLatest, select,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { createAdminActions } from './slice';
import API from '../../constant';
import { ICreateAdminModalForm } from './interface';
import { selectGeneralInformation } from '../../../../selector';
import { viewAdminActions } from '../view-admins/slice';

export function* createAdminGenerator({ payload }: PayloadAction<ICreateAdminModalForm>): any {
  try {
    const {
      email, authorityLevel, profileImgUrl, firstName, lastName,
    } = payload;
    const roles = Object.keys(payload).filter(
      (el: string) => payload[el as keyof ICreateAdminModalForm] === true,
    );
    const { entUrl } = yield select(selectGeneralInformation);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_ADMIN,
      {
        entUrl,
        profileImgUrl,
        firstName,
        lastName,
        email,
        attributes: { authorityLevel },
        permissions: roles,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(createAdminActions.createAdminSucceeded());
      yield put(viewAdminActions.viewAdmin({
        keyword: '', page: 1, limit: 25, authorityLevel: 'All', status: 'ACTIVE',
      }));
    } else {
      yield put(createAdminActions.createAdminFailed());
    }
  } catch (error) {
    yield put(createAdminActions.createAdminFailed());
  }
}

export function* createAdminSaga() {
  yield all([takeLatest(createAdminActions.createAdmin.type, createAdminGenerator)]);
}

export default createAdminSaga;
