import {
  all,
  call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { viewEntAccActions } from './slice';
import API from './constants';
import {
  IActivateEntAcc, IDeleteEntAcc,
  IEntAccountFilterPayload, IPinEntAcc,
} from './interface';

export function* viewEntAccGenerator({ payload }:PayloadAction<IEntAccountFilterPayload>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_ENT_ACC.path}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&status=${payload.status || 'active'}&slocoachAdmin=${payload.scAdmin}`, method: API.GET_ENT_ACC.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewEntAccActions.viewEntAccSucceeded(response.data));
    } else {
      yield put(viewEntAccActions.viewEntAccFailed());
    }
  } catch (error) {
    yield put(viewEntAccActions.viewEntAccFailed());
  }
}

export function* getSCAdminsGenerator(): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.GET_GET_SC_ADMIN,
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewEntAccActions.getSCAdminsSucceeded({ scAdmins: response.data }));
    } else {
      yield put(viewEntAccActions.getSCAdminsFailed());
    }
  } catch (error) {
    yield put(viewEntAccActions.getSCAdminsFailed());
  }
}

export function* editActiveGenerator({ payload }:PayloadAction<IActivateEntAcc>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewEntAccActions.editActiveEntAccSucceeded(payload));
      yield put(viewEntAccActions.closeModal(payload.id));
    } else {
      yield put(viewEntAccActions.editActiveEntAccFailed());
    }
  } catch (error) {
    yield put(viewEntAccActions.editActiveEntAccFailed());
  }
}

export function* deleteEntAccGenerator({ payload }:PayloadAction<IDeleteEntAcc>): any {
  try {
    const { id, email } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_ENT_ACC,
      { id, email },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewEntAccActions.deleteEntAccSucceeded(id));
      yield put(viewEntAccActions.closeModal(payload.id));
    } else {
      yield put(viewEntAccActions.deleteEntAccFailed());
    }
  } catch (error) {
    yield put(viewEntAccActions.deleteEntAccFailed());
  }
}

export function* editPinnedEntAccountGenerator({ payload }:PayloadAction<IPinEntAcc>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewEntAccActions.editPinnedEntSucceeded(payload));
      yield put(viewEntAccActions.closeModal(payload.id));
    } else {
      yield put(viewEntAccActions.editPinnedEntFailed());
    }
  } catch (error) {
    yield put(viewEntAccActions.editPinnedEntFailed());
  }
}

export function* viewEntAccSaga() {
  yield all([takeLatest(
    viewEntAccActions.viewEntAcc.type,
    viewEntAccGenerator,
  ), takeLatest(viewEntAccActions.getSCAdmins.type, getSCAdminsGenerator),
  takeLatest(viewEntAccActions.editActiveEntAccount.type, editActiveGenerator),
  takeLatest(viewEntAccActions.deleteEntAccount.type, deleteEntAccGenerator),
  takeLatest(viewEntAccActions.editPinnedEnt.type, editPinnedEntAccountGenerator)]);
}

export default viewEntAccSaga;
