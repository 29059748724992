const API = {
  POST_CREATE_GROUP: {
    path: '/group/create',
    method: 'POST',
  },
  GET_EXPERTS_BY_ENT: {
    path: '/expert/search',
    method: 'GET',
  },
  GET_SESSIONS_BY_ENT: {
    path: '/session/search',
    method: 'GET',
  },
  GET_ARTICLES_BY_ENT: {
    path: '/article/groups',
    method: 'GET',
  },

};
export default API;
