import {
  call, put, all, takeLatest, select, delay, race, take,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { authSliceActions } from 'base/auth/slice';
import { selectUser } from 'base/auth/selectors';
import { Role } from 'interfaces/user-roles';
import { AuthorityLevels, Permissions } from 'config';
import { editAdminActions } from './slice';
import API from '../../constant';
import { IAuthorityLevelModalForm, IGeneralInformationModalForm } from './interface';
import { selectDeleteCheckId, selectGeneralInformation, selectId } from './selector';
import {
  selectGeneralInformation as selectEntGeneralInformation,
  selectId as selectEntId,
} from '../../../../selector';
import { AdminDocument } from '../../interface';
import { editEntAccActions } from '../../../../slice';

export function* editGeneralInformationGenerator({
  payload,
}: PayloadAction<IGeneralInformationModalForm>): any {
  try {
    const id = yield select(selectId);
    const { entUrl } = yield select(selectEntGeneralInformation);
    const { email } = yield select(selectGeneralInformation);
    const authUser = yield select(selectUser);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_ADMIN,
      {
        id,
        entUrl,
        accountType: Role.EntAccountAdmin,
        ...payload,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editAdminActions.getAdminSucceeded(response.data as AdminDocument));
      yield put(editEntAccActions.getEntAcc());
      yield put(editAdminActions.closeModal());
      if (email !== (response.data as AdminDocument).email && authUser.email === email) {
        yield put(authSliceActions.signOut());
      }
    } else {
      yield put(editAdminActions.editGeneralInformationFailed());
    }
  } catch (error) {
    yield put(editAdminActions.editGeneralInformationFailed());
  }
}

export function* editAuthorityLevelGenerator({
  payload,
}: PayloadAction<IAuthorityLevelModalForm>): any {
  try {
    const id = yield select(selectId);
    const permissions = Object.keys(payload).filter(
      (el: string) => payload[el as keyof IAuthorityLevelModalForm] === true,
    );

    if (payload.authorityLevel === AuthorityLevels.CLIENT_ADMIN) {
      permissions.push(
        ...[
          Permissions.generalInformation,
          Permissions.groups,
          Permissions.sessions,
          Permissions.experts,
          Permissions.users,
          Permissions.admins,
        ],
      );
    }

    const response: IRestApiResponse = yield call(
      request,
      API.PATCH_PERMISSIONS_ADMIN,
      {
        id,
        accountType: Role.EntAccountAdmin,
        authorityLevel: payload.authorityLevel,
        permissions,
      },
      true,
    );
    if (response.statusCode === 200) {
      yield put(editAdminActions.editAuthorityLevelSucceeded({ ...payload }));
    } else {
      yield put(editAdminActions.editAuthorityLevelFailed());
    }
  } catch (error) {
    yield put(editAdminActions.editAuthorityLevelFailed());
  }
}

export function* getAdminGenerator(): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_ADMIN.path}/${id}`, method: API.GET_ADMIN.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editAdminActions.getAdminSucceeded(response.data));
    } else {
      yield put(editAdminActions.getAdminFailed());
    }
  } catch (error) {
    yield put(editAdminActions.getAdminFailed());
  }
}
export function* deleteAdminCheckStatusPollingWorker(): any {
  while (true) {
    try {
      const jobId = yield select(selectDeleteCheckId);
      const response: IRestApiResponse = yield call(
        request,
        API.DELETE_ADMIN_CHECK_STATUS,
        { jobId },
        true,
      );
      if (response.statusCode === 200) {
        yield put(editAdminActions.deleteAdminCheckSucceeded(response.data?.returnvalue));
        yield put(editAdminActions.cancelPollingWork());
      } else {
        yield delay(2500); // 2500 ms
      }
    } catch (error) {
      yield put(editAdminActions.deleteAdminCheckFailed());
    }
  }
}

export function* deleteAdminCheckStatusWatchWorker() {
  yield race({
    task: call(deleteAdminCheckStatusPollingWorker),
    cancel: take(editAdminActions.cancelPollingWork),
  });
}
export function* deleteAdminCheckGenerator(): any {
  try {
    const userId = yield select(selectId);
    const role = Role.EntAccountAdmin;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_ADMIN_CHECK,
      { userId, role },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editAdminActions.setDeleteCheckId(response.data?.id));
      // start polling
      yield call(deleteAdminCheckStatusWatchWorker);
    } else {
      yield put(editAdminActions.deleteAdminCheckFailed());
    }
  } catch (error) {
    yield put(editAdminActions.deleteAdminCheckFailed());
  }
}

export function* deleteAdminGenerator({ payload }: PayloadAction<string>): any {
  try {
    const userId = yield select(selectId);
    const entId = yield select(selectEntId);
    const newResourceOwner = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_ADMIN,
      { userId, newResourceOwner },
      true,
    );
    if (response.statusCode === 202) {
      yield put(editAdminActions.deleteAdminSucceeded());
      window.location.pathname = `/ent-account/edit/${entId}/admins`;
    } else {
      yield put(editAdminActions.deleteAdminFailed());
    }
  } catch (error) {
    yield put(editAdminActions.deleteAdminFailed());
  }
}

export function* recoverAdminGenerator(): any {
  try {
    const userId = yield select(selectId);
    const role = Role.EntAccountAdmin;
    const response: IRestApiResponse = yield call(
      request,
      API.RECOVER_ADMIN,
      { userId, role },
      true,
    );
    if (response.statusCode === 202) {
      yield put(editAdminActions.recoverAdminSucceeded());
    } else {
      yield put(editAdminActions.recoverAdminFailed());
    }
  } catch (error) {
    yield put(editAdminActions.recoverAdminFailed());
  }
}

export function* editAdminSaga() {
  yield all([
    takeLatest(editAdminActions.editGeneralInformation.type, editGeneralInformationGenerator),
    takeLatest(editAdminActions.editAuthorityLevel.type, editAuthorityLevelGenerator),
    takeLatest(editAdminActions.getAdmin.type, getAdminGenerator),
    takeLatest(editAdminActions.deleteAdminCheck.type, deleteAdminCheckGenerator),
    takeLatest(editAdminActions.deleteAdmin.type, deleteAdminGenerator),
    takeLatest(editAdminActions.recoverAdmin.type, recoverAdminGenerator),
  ]);
}

export default editAdminSaga;
