/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserDeleteCheckRequest, PollingRequestStatus } from 'interfaces/base-user';
import { Role } from 'interfaces/user-roles';
import { isUserDeleted } from 'utils/data-utils';
import {
  IAdmin,
  IAdminFilterPayload,
  IAdminRequest,
  IDeleteAdmin,
  IOpenModal,
  ModalStates,
  ViewAdminState,
} from './interface';

export const initialState: ViewAdminState = {
  stateLoading: false,
  loading: false,
  adminListLoading: false,
  deleteCheckStatus: PollingRequestStatus.success,
  deleteCheckId: '',
  deleteCheck: {
    roles: [],
    actions: [],
    blockers: [],
    toBeDeleted: [],
  },
  admins: { result: [], total: 0 },
  adminList: [],
  entId: '',
};

export const viewAdminSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/admin/view-admin',
  initialState,
  reducers: {
    viewAllAdmin(state: ViewAdminState) {
      state.adminListLoading = true;
    },
    viewAllAdminSucceeded(state: ViewAdminState, action: PayloadAction<IAdmin[]>) {
      state.adminList = action.payload;
      state.adminListLoading = false;
    },
    viewAllAdminFailed(state: ViewAdminState) {
      state.adminListLoading = false;
    },
    viewAdmin(state: ViewAdminState, _action: PayloadAction<IAdminFilterPayload>) {
      state.stateLoading = true;
    },
    viewAdminSucceeded(state: ViewAdminState, action: PayloadAction<IAdminRequest>) {
      state.admins.total = action.payload.total;
      state.admins.result = action.payload.result.map((admin) => ({
        ...admin,
        modalState: ModalStates.close,
        isDeleted: isUserDeleted(admin, Role.EntAccountAdmin),
      }));
      state.stateLoading = false;
    },
    viewAdminFailed(state: ViewAdminState) {
      state.stateLoading = false;
    },
    setEntId(state: ViewAdminState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    setDeleteCheckStatus(state: ViewAdminState, action: PayloadAction<PollingRequestStatus>) {
      state.deleteCheckStatus = action.payload;
    },
    setDeleteCheckId(state: ViewAdminState, action: PayloadAction<string>) {
      state.deleteCheckId = action.payload;
    },
    cancelPollingWork(state: ViewAdminState) {},
    openModal(state: ViewAdminState, action: PayloadAction<IOpenModal>) {
      const temp = state.admins.result;
      temp.forEach((admin) => {
        if (admin.id === action.payload.id) {
          admin.modalState = action.payload.state;
        }
      });
      state.admins.result = [...temp];
    },
    closeModal(state: ViewAdminState, action: PayloadAction<string>) {
      const temp = state.admins.result;
      temp.forEach((admin) => {
        if (admin.id === action.payload) {
          admin.modalState = ModalStates.close;
        }
      });
      state.admins.result = [...temp];
    },
    resetPassword(state: ViewAdminState, _action: PayloadAction<string>) {
      state.loading = true;
    },
    resetPasswordSucceeded(state: ViewAdminState, action: PayloadAction<string>) {
      const temp = state.admins.result;
      temp.forEach((admin) => {
        if (admin.id === action.payload) {
          admin.modalState = ModalStates.close;
        }
      });
      state.admins.result = [...temp];
      state.loading = false;
    },
    resetPasswordFailed(state: ViewAdminState) {
      state.loading = false;
    },
    deleteAdminCheck(state: ViewAdminState, action: PayloadAction<string>) {
      const temp = state.admins.result;
      temp.forEach((admin) => {
        if (admin.id === action.payload) {
          admin.modalState = ModalStates.delete;
        }
      });
      state.admins.result = [...temp];
      state.deleteCheckStatus = PollingRequestStatus.pending;
    },
    deleteAdminCheckSucceeded(
      state: ViewAdminState,
      action: PayloadAction<IUserDeleteCheckRequest>,
    ) {
      state.deleteCheck = action.payload;
      state.deleteCheckStatus = PollingRequestStatus.success;
    },
    deleteAdminCheckFailed(state: ViewAdminState) {
      state.deleteCheckStatus = PollingRequestStatus.error;
    },
    deleteAdmin(state: ViewAdminState, _action: PayloadAction<IDeleteAdmin>) {
      state.loading = true;
    },
    deleteAdminSucceeded(state: ViewAdminState, action: PayloadAction<string>) {
      const temp = state.admins.result;
      temp.forEach((admin) => {
        if (admin.id === action.payload) {
          admin.modalState = ModalStates.close;
          admin.isDeleted = true;
        }
      });
      state.admins.result = [...temp];
      state.loading = false;
    },
    deleteAdminFailed(state: ViewAdminState) {
      state.loading = false;
    },
  },
});

export const { actions: viewAdminActions } = viewAdminSlice;
