import { IFiltered } from 'interfaces/api-response';

export enum ModalStates {
  open = 'OPEN',
  close = 'CLOSE',
  delete = 'DELETE',
  successful = 'SUCCESSFUL',
}

export enum FormTypes {
  INTERACTIVE_FORM = 'Interactive form',
  LMS_FORM = 'LMS form',
  SCORM_FORM = 'SCORM form',
}

export enum FormBuilderVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export enum FormAvailabilityType {
  PROCESSING = 'Processing',
  READY = 'Ready to use',
  UNAVAILABLE = 'Unavailable',
}

export interface ISaveFormModalForm {
  id?: string;
  formType: string;
  formTitle: string;
  availability: string;
  formDescription: string;
  fileName: string,
  scormFileUrl: string,
  marksEnabled: boolean;
  displayMarksUntilMarkingComplete: boolean;
  passMark: number;
  badgeImgUrl: string;
  badgeTitle: string;
  badgeDescription: string;
  resolve?: any;
  reject?: any;
}

export interface IFormAvatar {
  _id: string,
  formType: string;
  formTitle: string,
  formDescription: string,
  badgeImgUrl: string;
}

export interface IFormAvatarWithAction extends IFormAvatar {
  isAdded: boolean
}
export interface IFormDocument {
  _id: string,
  displayMarksUntilMarkingComplete: boolean;
  marksEnabled: boolean,
  formType: string;
  passMark: number;
  badgeImgUrl: string;
  badgeTitle: string;
  badgeDescription: string;
  formConfig: string,
  answerData: string,
  createdAt: string;
  updatedAt: string
  formTitle: string,
  formDescription: string,
  fileName: string,
  scormFileUrl: string,
  availability: string,
  version: FormBuilderVersion;
}

export interface IForm extends IFormDocument {
  modalState: string;
  meta: {
    apiStatus: {
      message: string;
      status: string;
    }
  }
}

export interface ISaveScormFormModalFormAction {
  payload: ISaveFormModalForm,
  type: any
}

export interface IDeleteForm {
  id: string,
  bannerImgUrl: string,
}

export interface IOpenModal {
  id: string,
  state: string,
}

export interface ViewFormsState {
  stateLoading: boolean;
  loading: boolean;
  status: number;
  filteredForms: IFiltered<IForm>;
  formSettings: ISaveFormModalForm;
  formId: string;
  formConfig: string,
  answerData: string,
  modal: ModalStates;
  initiatedBy: string,
  formConfigModal: ModalStates;
}

export interface IFormFilterPayload {
  keyword: string;
  page: number;
  limit: number;
  sortBy: string;
  formType?: string;
}
