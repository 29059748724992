/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IArticleFilterPayload,
  IArticleRequest, IOpenModal, IPublishArticle, ModalStates, ViewArticleState,
} from './interface';

export const initialState: ViewArticleState = {
  stateLoading: false,
  articles: { result: [], total: 0 },
  entId: '',
  loading: false,

};

export const viewArticleSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/article/view-article',
  initialState,
  reducers: {
    viewArticle(state:ViewArticleState, _action:PayloadAction<IArticleFilterPayload>) {
      state.stateLoading = true;
    },
    viewArticleSucceeded(state:ViewArticleState, action:PayloadAction<IArticleRequest>) {
      state.articles.result = action.payload.result.map((article) => ({
        ...article, modalState: ModalStates.close,
      }));
      state.articles.total = action.payload.total;
      state.stateLoading = false;
    },
    viewArticleFailed(state:ViewArticleState) {
      state.stateLoading = false;
    },
    setEntId(state:ViewArticleState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    deleteArticle(state:ViewArticleState, _action: PayloadAction<string>) {
      state.loading = false;
    },
    deleteArticleSucceeded(state:ViewArticleState, action: PayloadAction<string>) {
      const temp = state.articles.result.filter((article) => article._id !== action.payload);
      state.articles.result = [...temp];
      state.loading = false;
    },
    deleteArticleFailed(state:ViewArticleState) {
      state.loading = true;
    },
    publishArticle(state:ViewArticleState, _action: PayloadAction<IPublishArticle>) {
      state.loading = true;
    },
    publishArticleSucceeded(state:ViewArticleState, action: PayloadAction<string>) {
      const temp = state.articles.result;
      temp.forEach((article) => {
        if (article._id === action.payload) {
          article.isActive = !article.isActive;
        }
      });
      state.articles.result = [...temp];
      state.loading = false;
    },
    publishArticleFailed(state:ViewArticleState) {
      state.loading = false;
    },
    openModal(state:ViewArticleState, action:PayloadAction<IOpenModal>) {
      const temp = state.articles.result;
      temp.forEach((article) => {
        if (article._id === action.payload.id) {
          article.modalState = action.payload.state;
        }
      });
      state.articles.result = [...temp];
    },
    closeModal(state:ViewArticleState, action:PayloadAction<string>) {
      const temp = state.articles.result;
      temp.forEach((article) => {
        if (article._id === action.payload) {
          article.modalState = ModalStates.close;
        }
      });
      state.articles.result = [...temp];
    },

  },
});

export const { actions: viewArticleActions } = viewArticleSlice;
