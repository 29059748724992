/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/edit-expert/content'] || initialState;

export const selectExpertContent = createSelector([selectDomain], (ExpertContentState) => ExpertContentState);
export const selectStateLoader = createSelector([selectDomain], (ExpertContentState) => ExpertContentState.stateLoading);
export const selectLoader = createSelector([selectDomain], (ExpertContentState) => ExpertContentState.loading);
export const selectExpertId = createSelector([selectDomain], (ExpertContentState) => ExpertContentState.expertId);
export const selectFilteredContent = createSelector([selectDomain], (ExpertContentState) => ExpertContentState.filteredContent);
export const selectModalState = createSelector([selectDomain], (ExpertContentState) => ExpertContentState.modal);
