import { IUserDeleteCheckRequest, PollingRequestStatus } from 'interfaces/base-user';

export enum ModalStates {
  authorityLevel = 'AUTHORITY_LEVEL',
  generalInformation = 'GENERAL_INFORMATION',
  delete = 'DELETE',
  close = 'CLOSE',
  recover = 'RECOVER',
}

export interface IGeneralInformationModalForm {
  firstName:string,
  lastName:string,
  email:string
  profileImgUrl:string
}

export interface IAuthorityLevelModalForm {
  authorityLevel:string,
  accessRecordings:boolean,
  authenticateComplaints:boolean,
  articles:boolean,
  observeSessions:boolean,
  accessClasses:boolean,
}

export interface EditAdminState {
  generalInformation : IGeneralInformationModalForm,
  authorityLevel :IAuthorityLevelModalForm,
  loading:boolean,
  stateLoading:boolean,
  isDeleted:boolean,
  deleteCheckStatus:PollingRequestStatus,
  deleteCheckId:string,
  deleteCheck: IUserDeleteCheckRequest,
  id:string,
  modal:string
}
