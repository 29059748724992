/* eslint-disable react/require-default-props */
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import {
  Box, CircularProgress, Grid, InputBase,
} from '@mui/material';
import { IOption } from 'interfaces';
import { Label } from 'components/molecules';
import './index.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: 'none',
    padding: '0 0.8rem',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}));

interface PrimaryMultiSelectProps {
  value:string[];
  onChange:any;
  name?: string;
  label?: string;
  innerLabel?: string;
  options: IOption[];
  placeholder?: string;
  loading?:boolean;
  renderValue?: ((value: string[]) => React.ReactNode) | undefined;

}
const PrimaryMultiSelect = React.forwardRef(({
  options, name, label, innerLabel, placeholder, loading, value, onChange, renderValue,
}:PrimaryMultiSelectProps, ref) => {
  const getValue = (selectedId:string[]) => options.filter((item) => (
    selectedId.includes(item.id as string)
  )).map((item) => (item.text)).join(', ');

  return (
    <Box>
      <div>

        <Label>{label}</Label>

        <Grid
          container
          className="primary-multi-select-wrapper"
        >
          {loading ? <div style={{ margin: 'auto' }}><CircularProgress size={20} /></div> : (
            <>
              <Grid>
                <InputLabel>
                  <Label>{innerLabel}</Label>
                </InputLabel>
              </Grid>

              <Grid sx={{ flex: 2 }}>
                <FormControl fullWidth>
                  <Select
                    ref={ref}
                    multiple
                    displayEmpty
                    name={name}
                    value={value}
                    onChange={onChange}
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    renderValue={renderValue === undefined
                      ? ((selected) => getValue(selected)) : renderValue}
                  >
                    <MenuItem disabled value="">
                      <div style={{ color: '#B2B2B2' }}>{placeholder}</div>
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        <Checkbox checked={value.indexOf(option.value as string) > -1} />
                        <ListItemText primary={option.text} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) }
        </Grid>

      </div>
    </Box>

  );
});
export default PrimaryMultiSelect;
