export enum CreateExpertStep {
  profileDetails,
  bankDetails,
  successful,
}

export interface ICreateExpertInformationForm {
  accountType:string,
  subscriptionFee: number;
  subscriptionFrequency: string;
  firstName: string;
  lastName: string;
  profileImgUrl: string;
  email: string;
  bio: string;
  categories: string[];
}

export interface IAddBankDetailsForm {
  accountNumber:string,
  accountHolder:string,
  bankName:string,
  branchName:string,
}
export interface CreateExpertState {
  loading:boolean,
  status:number,
  expertInformation: ICreateExpertInformationForm,
  bankDetails:IAddBankDetailsForm
}
