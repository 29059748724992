/* eslint-disable max-len */
import React, { useState } from 'react';
import './index.scss';
import {
  IconButton, InputAdornment, OutlinedInput, FilledInput,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PRIMARY_LITE_COLOR } from 'constants/style';
import { Label } from '../custom-typography';

export const CustomInputFieldStyle = {
  opacity: 1,
  backgroundColor: '#FFF',
  border: '0px solid #E5E5E5',
  borderRadius: '5px',
  outline: 'none',
  // marginTop: '5px',
  '& .MuiOutlinedInput-input': {
    // padding: '10px 0px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    '& fieldset': {
      borderColor: '#D4D8F6',
      padding: '5px 10px 5px 10px',
    },
    '&:hover fieldset': {
      borderColor: '#767a93',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#767a93',
    },
  },
};
export const CustomFilledInputFieldStyle = {
  opacity: 1,
  backgroundColor: PRIMARY_LITE_COLOR,
  border: '0px solid #E5E5E5',
  borderRadius: '5px',
  outline: 'none',
  marginTop: '5px',
  '& .MuiFilledInput-input': {
    padding: '10px 14px',
  },
  '& .MuiFilledInput-root': {
    borderRadius: '10px',
    '& fieldset': {
      borderColor: '#D4D8F6',
      padding: '5px 10px 5px 10px',
    },
    '&:hover fieldset': {
      borderColor: '#767a93',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
    },
  },
};

export const CustomOutlineInputField = styled(OutlinedInput)(CustomInputFieldStyle);
export const CustomFilledInputField = styled(FilledInput)(CustomFilledInputFieldStyle);

const PrimaryTextField = React.forwardRef(
  ({
    onChange, onBlur, onKeyDown, onFocus, value, name, label, placeholder, isPassword = false, multiline = false, disabled, startAdornment, type = 'text', rows = 1, testId = '', max,
  }: any, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <>
        <Label htmlFor={name}>
          {label}
        </Label>
        {isPassword ? (
          <CustomOutlineInputField
            onChange={onChange ?? undefined}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  sx={{ color: '#9e9e9e' }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
            type={showPassword ? 'text' : 'password'}
            onBlur={onBlur ?? undefined}
            onKeyDown={onKeyDown ?? undefined}
            value={value ?? undefined}
            name={name ?? undefined}
            inputRef={ref ?? undefined}
            placeholder={placeholder ?? undefined}
            fullWidth
            size="small"
            disabled={disabled}
            id={`${name}`}
            inputProps={{
              'data-testid': testId ?? '',
              maxLength: max ?? undefined,
            }}

          />
        ) : (
          <CustomOutlineInputField
            onChange={onChange ?? undefined}
            onBlur={onBlur ?? undefined}
            onFocus={onFocus ?? undefined}
            onKeyDown={onKeyDown ?? undefined}
            value={value ?? undefined}
            name={name ?? undefined}
            inputRef={ref ?? undefined}
            size="small"
            placeholder={placeholder ?? undefined}
            fullWidth
            multiline={multiline}
            rows={rows ?? undefined}
            disabled={disabled}
            startAdornment={startAdornment ?? undefined}
            type={type}
            id={`${name}`}
            inputProps={{
              'data-testid': testId ?? '',
              maxLength: max ?? undefined,
            }}
          />
        )}
      </>
    );
  },
);

export const PrimaryFilledTextField = React.forwardRef(
  ({
    onChange, onBlur, onFocus, value, name, label, placeholder,
    multiline = false, startAdornment, testId = '', max,
  }: any, ref) => (
    <>
      <Label htmlFor={name}>{label}</Label>
      <CustomFilledInputField
        onChange={onChange ?? undefined}
        onBlur={onBlur ?? undefined}
        onFocus={onFocus ?? undefined}
        value={value ?? undefined}
        name={name ?? undefined}
        inputRef={ref ?? undefined}
        placeholder={placeholder ?? undefined}
        fullWidth
        multiline={multiline}
        size="small"
        startAdornment={startAdornment ?? undefined}
        id={`${name}`}
        inputProps={{
          'data-testid': testId ?? '',
          maxLength: max ?? undefined,
        }}
      />
    </>
  ),
);

export default PrimaryTextField;
