export interface CreateUserState {
  loading: boolean;
  modal: string;
}

export interface ICreateUser {
  email: string;
}
export interface ICreateBulkUsers {
  emails:string[]
}
export enum ModalStates {
  close = 'CLOSE',
  createUser = 'CREATE_USER',
  createUsers = 'CREATE_USERS',
}
