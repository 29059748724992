/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import {
  ClosableModal,
  PrimaryChip,
  PrimaryChipsSlider,
  PrimaryLiteButton, PrimaryLoadingButton, PrimaryOutlinedButton, PrimaryTable, SearchBar,
} from 'components';
import { ClipboardIcon, MonitorIcon } from 'assets/icons';
import { BlockType } from '@surge-global-engineering/form-builder-v2';
import { UploadScenarios } from 'interfaces/file-uploader';
import AddContentModal from '../../../../../../content/components/add-content-modal';
import { IContentAvatar, IContentAvatarWithAction } from '../../../../../../content/interface';

interface SelectContentModalProps {
  selectedContents:IContentAvatarWithAction[] | undefined,
  filteredContent:IContentAvatar[],
  total:number,
  loading:boolean,
  handleUpdate:(contentList:IContentAvatarWithAction[])=>void,
  searchFunction:(keyword:string, page:number)=>void,
  maxSelect?:number,
  selectOne?:boolean
  title?:string,
  subTitle?:string,
  modalState?:boolean | undefined,
  setModelState?:(value:boolean)=>void | undefined
  confirmationLoading?:boolean,
  isExternal?:boolean,
  blockType?: BlockType,
  scenario?: UploadScenarios;
  disabled?: boolean;
}

const SelectContentModal = ({
  selectedContents, filteredContent, total, loading, handleUpdate, searchFunction, title = 'Select Content', subTitle = '',
  selectOne = false, maxSelect = 0, modalState = undefined, setModelState = undefined,
  confirmationLoading = false, isExternal = false, blockType, scenario, disabled,
}:SelectContentModalProps) => {
  type Row = typeof contents[number];

  // initiate states
  const [open, setOpen] = React.useState<boolean>(modalState || false);
  const [keyword, setKeyword] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [selectedItems, setSelectedItems] = React.useState<IContentAvatarWithAction[] | undefined>(
    selectedContents,
  );
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [contents, setContents] = React.useState<IContentAvatarWithAction[]>(
    filteredContent?.map((content) => ({
      ...content,
      isAdded: (selectedIds.includes(content.id as string)),
    })),
  );

  // handle modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (setModelState) { setModelState(false); }
    setOpen(false);
  };

  // handle search
  const handleSearchChange = (event:any) => {
    setKeyword(event.target.value);
  };

  // handle mutations
  const handleRemoveItem = (id:string) => {
    setSelectedItems((prev) => {
      if (prev) {
        return (
          [...prev.filter((content) => content.id !== id)]);
      }
      return [];
    });
  };

  const addContent = (id:string) => {
    const temp = [...contents];
    const index = temp.findIndex(((item:IContentAvatarWithAction) => item.id === id));
    temp[index].isAdded = true;

    // updating the chips
    const tempSelectItems = selectOne ? [temp[index]] : [...(selectedItems || []), temp[index]];
    setSelectedItems(tempSelectItems);
  };

  // update mutations
  const handleUpdateWrapper = () => {
    handleUpdate(selectedItems || []);
    if (!setModelState) { handleClose(); }
  };

  // configure table
  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'title',
        headerName: 'Media Title',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sx={{ mr: 0.7 }}>
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      mr: 1,
                      backgroundColor: 'background.default',
                      color: 'primary.main',
                      borderWidth: 1,
                      borderColor: 'primary.light',
                      borderStyle: 'solid',
                    }}
                    src={params.row.thumbnail}
                  >
                    <MonitorIcon sx={{ fontSize: 16 }} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h4" sx={{ fontWeight: '400' }}>{params.value}</Typography>
                </Grid>

              </Grid>
            </Grid>

          </Grid>

        ),
      },
      {
        field: 'fileType', headerName: 'File Type', flex: 0.5, headerAlign: 'left', align: 'left',
      },

      {
        field: 'isAdded',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        flex: 0.5,
        renderCell: (params) => (
          <div>
            {params.value ? (
              <PrimaryOutlinedButton>
                Added
              </PrimaryOutlinedButton>
            ) : (
              <>
                {selectedItems && maxSelect > 0 && selectedItems.length < maxSelect && (
                  <PrimaryOutlinedButton onClick={() => { addContent(params.row._id); }}>
                    Add
                  </PrimaryOutlinedButton>
                )}
                {maxSelect === 0 && (
                  <PrimaryOutlinedButton onClick={() => { addContent(params.row._id); }}>
                    Add
                  </PrimaryOutlinedButton>
                )}
              </>

            )}

          </div>
        ),
      },

    ],
    [addContent, selectedItems, maxSelect],
  );

  React.useEffect(() => {
    if (open) {
      searchFunction(keyword, page);
    }
  }, [open]);

  React.useEffect(() => {
    searchFunction(keyword, page);
  }, [keyword, page]);

  React.useEffect(() => {
    if (filteredContent) {
      const temp = filteredContent?.map((content) => ({
        ...content,
        isAdded: (selectedIds.includes(content.id as string)),
      }));
      setContents(JSON.parse(JSON.stringify(temp)) as IContentAvatarWithAction[]);
    }
  }, [filteredContent, selectedIds]);

  React.useEffect(() => {
    if (selectedItems) {
      setSelectedIds(selectedItems.map((content:IContentAvatarWithAction) => content.id as string));
    }
  }, [selectedItems]);

  React.useEffect(() => {
    setSelectedItems(selectedContents);
  }, [selectedContents]);

  // keep the external modal state sync with the internal modal state
  React.useEffect(() => {
    if (modalState !== undefined) { setOpen(modalState); }
  }, [modalState]);

  React.useEffect(() => {
    if (setModelState) { setModelState(open); }
  }, [open]);

  return (
    <>
      {!isExternal && (
      <PrimaryOutlinedButton
        onClick={handleOpen}
        disabled={disabled}
        startIcon={<ClipboardIcon />}
      >
        Add media
      </PrimaryOutlinedButton>
      )}
      <ClosableModal
        open={open}
        onClose={handleClose}
        title={title}
        subTitle={subTitle}
        modalClass="select-item-modal"
      >

        <div className="form-item">
          <SearchBar
            value={keyword}
            onChange={handleSearchChange}
            placeholder="Search by media name"
          />
        </div>
        <div className="form-item">
          {selectedItems && selectedItems.length > 0 ? (
            <PrimaryChipsSlider>
              {selectedItems.map((item) => (
                <PrimaryChip
                  key={item.id}
                  avatar={(
                    <Avatar
                      sx={{
                        backgroundColor: 'background.default',
                        borderColor: 'primary.light',
                      }}
                      src={item.thumbnail}
                    />
                  )}
                  label={item.title}
                  variant="outlined"
                  onDelete={() => { handleRemoveItem(item.id as string); }}
                />

              ))}
            </PrimaryChipsSlider>

          ) : ''}
        </div>
        <div className="form-item">
          <PrimaryTable
            disableSelectionOnClick
            rows={contents}
            columns={columns}
            loading={loading}
            page={page}
            pageSize={3}
            rowsPerPageOptions={[3]}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            rowCount={total}
            paginationMode="server"
            getRowId={(row) => row.id}
          />
        </div>
        <div style={{ float: 'left' }}>
          <AddContentModal blockType={blockType} scenario={scenario} />
        </div>

        <Grid container spacing={3}>
          <Grid item xs>
            <PrimaryLiteButton fullWidth onClick={handleClose}>Cancel</PrimaryLiteButton>
          </Grid>
          <Grid item xs>
            <PrimaryLoadingButton
              loading={confirmationLoading}
              fullWidth
              onClick={handleUpdateWrapper}
            >
              Add & continue
            </PrimaryLoadingButton>
          </Grid>

        </Grid>
      </ClosableModal>

    </>

  );
};

export default SelectContentModal;
