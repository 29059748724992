/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateGiftCardState, ICreateGiftCardModalForm,
} from './interface';

export const initialState: CreateGiftCardState = {
  loading: false,
  modal: false,
};

export const createGiftCardSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/GiftCard/create-GiftCard',
  initialState,
  reducers: {
    createGiftCard(state: CreateGiftCardState, _action:PayloadAction<ICreateGiftCardModalForm>) {
      state.loading = true;
    },
    createGiftCardSucceeded(state: CreateGiftCardState) {
      state.loading = false;
      state.modal = false;
    },
    createGiftCardFailed(state: CreateGiftCardState) {
      state.loading = false;
    },
    openModal(state: CreateGiftCardState) {
      state.modal = true;
    },
    closeModal(state: CreateGiftCardState) {
      state.modal = false;
    },
    reset: () => initialState,

  },
});

export const { actions: createGiftCardActions } = createGiftCardSlice;
