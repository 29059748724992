import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { selectLoader } from 'base/auth/selectors';
import {
  BlackLabel, PrimaryLabel, PrimaryLink, PrimaryTextField, ErrorMessage, PrimaryLoadingButton,
} from 'components';
import { authSliceActions } from 'base/auth/slice';

interface SignInFormProps {
  onSubmit: SubmitHandler<any>;
}

const SignInForm = ({ onSubmit }: SignInFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);
  const location = useLocation();
  const callbackPath = new URLSearchParams(location.search).get('callbackPath');

  React.useEffect(() => {
    dispatch(authSliceActions.setCallbackPath(callbackPath || ''));
  }, [callbackPath]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="sign-in-form">
      <Controller
        control={control}
        name="name"
        render={({ field }) => <PrimaryTextField {...field} label="Email" />}
      />
      <ErrorMessage>{errors?.name?.message}</ErrorMessage>
      <div className="form-item">
        <Controller
          control={control}
          name="password"
          render={({ field }) => <PrimaryTextField {...field} label="Password" isPassword testId="password" />}
        />
        <ErrorMessage>{errors?.password?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <PrimaryLoadingButton loading={loading} size="large" fullWidth type="submit">
          Sign in
        </PrimaryLoadingButton>
      </div>
      <div style={{ textAlign: 'center' }}>
        <BlackLabel>Forgot Password? </BlackLabel>
        <PrimaryLink to="/forgot-password">
          <PrimaryLabel>Reset Now</PrimaryLabel>
        </PrimaryLink>
      </div>
    </form>
  );
};

export default SignInForm;
