/* eslint-disable react/require-default-props */
import * as React from 'react';
import Box from '@mui/material/Box';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PrimaryButton, PrimaryOutlinedButton } from 'components';
import { CourseTypes } from 'config';
import SessionTypeModalForm from '../session-type-modal-form';
import sessionTypeModalFormValidation from '../validation/session-type-form-validation';
import { ISessionTypeModalForm } from '../../../../../interface';
import { createSessionActions } from '../../../slice';

interface SessionTypeModalViewProps {
  inner:boolean
  courseOnlySession:boolean
  courseType?:CourseTypes
}

export default function SessionTypeModalView({
  inner,
  courseOnlySession, courseType = CourseTypes.lsm,
}:SessionTypeModalViewProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const methods = useForm<ISessionTypeModalForm>({
    defaultValues: {
      sessionType: '',

    },
    resolver: yupResolver(sessionTypeModalFormValidation),
    shouldFocusError: true,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<ISessionTypeModalForm> = React.useCallback((data) => {
    dispatch(createSessionActions.reset());
    dispatch(createSessionActions.setSessionType(data.sessionType));
    if (!courseOnlySession) {
      const createSessionPath = `${location.pathname.split('sessions')[0]}sessions/create`;
      navigate(`${createSessionPath}/${data.sessionType.toLocaleLowerCase().replace(' ', '-')}`);
    }

    handleClose();
  }, [dispatch]);

  return (
    <>
      {inner ? (
        <PrimaryOutlinedButton onClick={handleOpen}>
          Change session type
        </PrimaryOutlinedButton>
      ) : (
        <PrimaryButton size="large" fullWidth onClick={handleOpen}>
          <AddIcon sx={{ margin: '0 0.5rem' }} />
          Add new session
        </PrimaryButton>
      )}

      <Modal
        open={open}

      >
        <Box className="modal-scrollable">
          <IconButton aria-label="close" sx={{ float: 'right' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <FormProvider {...methods}>
            <SessionTypeModalForm
              courseOnlySession={courseOnlySession}
              onSubmit={onSubmit}
              closeModal={handleClose}
              courseType={courseType}

            />
          </FormProvider>

        </Box>
      </Modal>
    </>
  );
}
