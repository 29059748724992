import React from 'react';
import { Grid } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import Papa from 'papaparse';
import {
  Label,
  PrimaryButton,
  PrimaryLoadingButton,
  PrimaryTable,
} from 'components';
import CSVFileDragUploader from 'components/organisms/csv-file-drag-uploader';
import { ExpertDocument } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/interface';
import OnboardCreateExpertModal from '../../add-new-expert-modal/add-new-expert-modal-view';
import { selectExpertForm, selectLoader } from '../../../../../selector';
import { onboardEntAccActions } from '../../../../../slice';
import EntExpertGrid from '../../experts-grid';

interface OnboardExpertsModalFormProps {
  onSubmit: () => void;
}

type Row = { email:string };

const OnboardExpertsModalForm = ({ onSubmit }: OnboardExpertsModalFormProps) => {
  const [userEmails, setUserEmails] = React.useState<string[]>([]);

  const dispatch = useDispatch();

  const { experts } = useSelector(selectExpertForm);
  const loading = useSelector(selectLoader);
  const onCsvTemplateDownloadHandler = () => {
    const data = [['john@gmail.com'], ['sam@gmail.com'], ['jane@gmail.com']];

    let csv = 'email\n';

    data.forEach((row) => {
      csv += row.join(',');
      csv += '\n';
    });

    const anchor = document.createElement('a');
    anchor.href = `data:attachment/csv,${encodeURI(csv)}`;
    anchor.target = '_self';
    anchor.rel = 'noopener';
    anchor.download = 'experts_csv_template.csv';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const onUploadCsvHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (!file) {
      // eslint-disable-next-line no-param-reassign
      event.target.files = null;
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      return;
    }

    if (file.type !== 'text/csv') {
      // eslint-disable-next-line no-param-reassign
      event.target.files = null;
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      return;
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      complete(results) {
        const header = results.data.shift() as string[];
        if (header?.[0].toLowerCase() !== 'email') {
          // eslint-disable-next-line no-param-reassign
          event.target.files = null;
          // eslint-disable-next-line no-param-reassign
          event.target.value = '';
          return;
        }

        const data = results.data as [string][];

        const mappedData = data.map((row) => ({
          isActive: true,
          email: row[0],
        }));

        dispatch(onboardEntAccActions.inviteExperts(mappedData));

        setUserEmails(mappedData.map((i) => i.email));

        // eslint-disable-next-line no-param-reassign
        event.target.files = null;
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      },
    });
  };

  const onDeleteHandler = (expert: ExpertDocument) => {
    dispatch(onboardEntAccActions.deleteExpert(expert));
  };

  const columns = React.useMemo<GridColumns<Row>>(() => [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (param) => (
        <Grid container justifyContent="space-between">
          <Grid item>{param.value}</Grid>
        </Grid>
      ),
    },
  ], []);

  return (
    <>
      <div className="form-item">
        <div style={{ textAlign: 'left' }}>
          <h4>Add experts</h4>
          <Label>Experts will have access to the expert hub</Label>
        </div>
      </div>
      <div className="form-item">
        <EntExpertGrid entExperts={experts} onDelete={onDeleteHandler} />
      </div>
      <div className="form-item">
        <OnboardCreateExpertModal index={experts.length} />
      </div>
      <div>
        <h3 className="form-item">
          To add multiple experts at once, download the CSV template below and, fill it in according
          to the specific fields, and re-upload
        </h3>
      </div>
      <div className="form-item">
        <PrimaryButton startIcon={<AddIcon />} onClick={onCsvTemplateDownloadHandler}>
          Download CSV template
        </PrimaryButton>
      </div>

      {userEmails.length > 0 ? (
        <PrimaryTable
          className="form-item"
          disableSelectionOnClick
          rows={userEmails.map((email, index) => ({ email, id: index }))}
          columns={columns}
        />
      ) : (
        <div className="form-item">
          <CSVFileDragUploader
            textLabel="Upload the CSV file here"
            onChange={onUploadCsvHandler}
          />
        </div>
      )}

      <div>
        <PrimaryLoadingButton loading={loading} onClick={onSubmit} size="large" variant="contained">
          Save & get started
        </PrimaryLoadingButton>
      </div>
    </>
  );
};

export default OnboardExpertsModalForm;
