/* eslint-disable no-param-reassign */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { Close } from '@mui/icons-material';
import { Avatar, Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
  ClipboardIcon,
  ClipboardTextIcon, MonitorIcon, OutlineArrowSwapIcon, SquareMenuIcon,
} from 'assets/icons';
import {
  CustomRichTextField,
  ErrorMessage, PrimaryFilledTextField, PrimaryOutlinedButton,
} from 'components';
import { IMessageEvent, MessageType } from 'interfaces';
import { coreSliceActions } from 'base/core/slice';
import { CourseTypes, SessionType } from 'config';
import { Controller, useForm } from 'react-hook-form';
import { selectFilteredContent, selectLoader } from '../../../../../../../content/selector';
import {
  IModule, IBadge, ISessionFormAvatarWithAction, ISessionAvatarWithAction,
} from '../../../../../../interface';
import { SelectSessionModal, SelectContentModal } from '../../../select-modals';
import { SelectedSessionTable, SelectedContentTable } from '../../../selected-tables';
import ViewBadge from '../../../view-badge';
import { selectFilteredForms, selectStateLoader } from '../../../../../../../forms/selector';
import ConfigFormsModal from '../../../select-modals/config-form-modal';
import SelectFormModal from '../../../select-modals/select-form-modal';
import SelectedFormTable from '../../../selected-tables/selected-form-table';
import { selectSessionInformation } from '../../../../selector';
import { FormTypes } from '../../../../../../../forms/interface';
import { viewFormsActions } from '../../../../../../../forms/slice';
import { selectId } from '../../../../../../../../selector';
import { IContentAvatarWithAction } from '../../../../../../../content/interface';
import { viewContentActions } from '../../../../../../../content/slice';
import { selectFilteredSessions, selectSessionLoader } from '../../../../../view-sessions/selector';
import { viewSessionsActions } from '../../../../../view-sessions/slice';
import { createSessionActions } from '../../../../slice';
import { CourseOnlySessionStatus } from '../../../../interface';

interface ModuleProps {
  handleChange: (index: number, module: IModule) => void
  index: number,
  removeModule: (index: number) => void
  errors: any,
  moduleInit: IModule
}

export default function Module({
  index, handleChange, removeModule, errors, moduleInit,
}: ModuleProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const formExpertId = useSelector(selectId);

  const filteredSession = useSelector(selectFilteredSessions).result;
  const totalSessions = useSelector(selectFilteredSessions).total;
  const sessionLoading = useSelector(selectSessionLoader);

  const filteredContent = useSelector(selectFilteredContent).result;
  const totalContent = useSelector(selectFilteredContent).total;
  const contentLoading = useSelector(selectLoader);

  const filteredForm = useSelector(selectFilteredForms).result;
  const formTotal = useSelector(selectFilteredForms).total;
  const formLoading = useSelector(selectStateLoader);
  const { courseType, numberOfSessions } = useSelector(selectSessionInformation);
  const { control } = useForm();
  const [module,
    setModule] = React.useState<IModule>(
    moduleInit,
  );
  const [expand, setExpand] = React.useState<boolean>(false);

  const [selectedSessions, setSelectedSessions] = React.useState<ISessionAvatarWithAction[]>(
    moduleInit.session.map((session) => ({
      ...session, isAdded: true,
    })),
  );

  const [selectedContents, setSelectedContents] = React.useState<IContentAvatarWithAction[]>(
    moduleInit.content.map((content) => ({
      ...content, isAdded: true,
    })),
  );

  const [selectedForms, setSelectedForms] = React.useState<ISessionFormAvatarWithAction[]>(
    moduleInit.form.map((form) => ({
      ...form, isAdded: true,
    })),
  );
  const [openConfigFormModal, setOpenConfigFormModal] = React.useState(false);

  // parent child window relationship
  let childWindow = null;

  // handle module name
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setModule({
    ...module,
    name: event.target.value,
  });

  // handle module description
  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => setModule({
    ...module,
    description: event.target.value,
  });

  // handle session selection
  const updateSessions = (sessionList: ISessionAvatarWithAction[]) => {
    setSelectedSessions(sessionList);
  };
  const handleSessionRemove = (id: string) => {
    setSelectedSessions((prev) => ([...prev.filter((session) => (session.id !== id))]));
  };
  const searchSession = (keyword: string, page: number) => {
    dispatch(viewSessionsActions.getSessions({
      keyword,
      page: page + 1,
      limit: 3,
      publicOnly: true,
      status: 'ACTIVE',
      withoutDeleted: true,
      type: (courseType === CourseTypes.lsm) ? [SessionType.content].toString()
        : [SessionType.content, SessionType.liveClass, SessionType.liveSession].toString(),
    }));
  };

  // handle content selection
  const updateContents = (contentList: IContentAvatarWithAction[]) => {
    setSelectedContents(contentList);
  };
  const handleContentRemove = (contentId: string) => {
    setSelectedContents((prev) => ([...prev.filter((content) => (content._id !== contentId))]));
  };

  const searchContent = (keyword: string, page: number) => {
    dispatch(viewContentActions.getContent({
      keyword,
      page: (page + 1),
      limit: 3,
      type: ['image', 'video', 'document'],
      sortBy: 'uploaded',
    }));
  };

  // handle forms selection
  const updateForms = (formList: ISessionFormAvatarWithAction[]) => {
    setSelectedForms(formList);
    if (formList.filter((form) => (form.isAdded)).length > 0) {
      // setOpenConfigFormModal(true);
    }
  };
  const handleFormRemove = (formId: string) => {
    setSelectedForms((prev) => ([...prev.filter((form) => (form.id !== formId))]));
  };

  const handleConfigFormModalCancel = () => {
    setOpenConfigFormModal((preValue) => !preValue);
  };
  const searchForm = (keyword: string, page: number) => {
    if (formExpertId) {
      dispatch(viewFormsActions.getFilteredForms({
        keyword,
        sortBy: 'Default',
        page: page + 1,
        limit: 3,
        formType: (courseType === CourseTypes.lsm)
          ? FormTypes.LMS_FORM : '',
      }));
    }
  };
  // handle badges
  const handleRemoveWrapper = () => {
    removeModule(index);
  };
  const handleBadge = (newBadge: IBadge) => {
    setModule({ ...module, badge: newBadge });
  };
  const handleBadgeRemove = () => {
    setModule({ ...module, badge: { badgeTitle: '', badgeDescription: '', badgeImgUrl: '' } });
  };

  // handle course only sessions
  const addCourseOnlySession = (moduleIndex: number) => {
    const createCourseOnlySessionPath = `${location.pathname.split('sessions')[0]}sessions/create/course-only-session?courseType=${courseType}&moduleIndex=${moduleIndex}&courseCount=${numberOfSessions}`;
    dispatch(createSessionActions.setCourseOnlySessionMetaStatus(CourseOnlySessionStatus.creating));
    childWindow = window.open(createCourseOnlySessionPath, '_blank');
  };

  // capture child window events
  React.useEffect(() => {
    const handleMessageFromChild = (event: any) => {
      if (event.origin !== window.origin) return;
      const eventData: IMessageEvent = event.data;

      if (eventData?.type === MessageType.formSubmit && eventData?.data?.moduleIndex === index) {
        if (selectedSessions.filter((session) => (session.id
          === eventData?.data?.id)).length === 0) {
          updateSessions([...selectedSessions, { ...eventData?.data, isAdded: true }]);
        } else {
          updateSessions(selectedSessions.map((session) => {
            if (session.id === eventData?.data?.id) {
              return { ...eventData?.data, isAdded: true };
            }
            return session;
          }));
        }
      }
      if (eventData?.type === MessageType.windowOpen) {
        dispatch(
          coreSliceActions.setWindowsDisabled(true),
        );
      }
      if (eventData?.type === MessageType.windowClose) {
        dispatch(
          coreSliceActions.setWindowsDisabled(false),
        );
        childWindow = null;
      }
    };

    window.addEventListener('message', handleMessageFromChild);
    window.addEventListener('beforeunload', (event) => { event.returnValue = true; });

    return () => {
      window.removeEventListener('message', handleMessageFromChild);
      window.removeEventListener('beforeunload', (event) => { event.returnValue = true; });
    };
  }, [selectedSessions]);

  React.useEffect(() => {
    handleChange(index, module);
  }, [module]);

  // updating the selected sessions in the module
  React.useEffect(() => {
    setModule({
      ...module,
      session: selectedSessions.map((session) => {
        const { isAdded, ...remainP } = session; return remainP;
      }),
    });
  }, [selectedSessions]);

  // updating the selected contents in the module
  React.useEffect(() => {
    setModule({
      ...module,
      content: selectedContents.map((content) => {
        const { isAdded, ...remainP } = content; return remainP;
      }),
    });
  }, [selectedContents]);

  // updating the selected forms in the module
  React.useEffect(() => {
    setModule({
      ...module,
      form: selectedForms.map((form) => {
        const { isAdded, ...remainP } = form; return remainP;
      }),
    });
  }, [selectedForms]);

  React.useEffect(() => {
    searchForm('', 0);
  }, []);

  return (
    <div style={{
      background: '#FFF',
      border: '1px solid #E5E5E5',
      padding: '0 2rem',
      borderRadius: '5px',
    }}
    >
      <SquareMenuIcon sx={{ marginLeft: '-2rem' }} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">
            Module #
            {' '}
            {index + 1}
          </Typography>

        </Grid>
        <Grid item xs="auto" alignItems="center" sx={{ color: '#E93E3E' }}>
          <Grid container alignItems="center">
            <Close />
            <PrimaryOutlinedButton onClick={handleRemoveWrapper}>Remove </PrimaryOutlinedButton>
          </Grid>

        </Grid>
      </Grid>
      <div style={{ width: '80%' }} className="form-item">
        <PrimaryFilledTextField placeholder="Name this module" testId="create-session-module-name" value={module.name} onChange={handleName} />
        <ErrorMessage>
          {errors?.modules ? errors?.modules[index]?.name?.message : ''}

        </ErrorMessage>
        {!expand && (
          <ErrorMessage>
            {errors?.modules ? errors?.modules[index]?.session?.message : ''}

          </ErrorMessage>
        )}

      </div>
      {expand ? (
        <div>
          <div style={{ width: '80%' }} className="form-item">
            <Controller
              control={control}
              name={`modules.${index}.description`}
              render={({ field }) => (
                <CustomRichTextField
                  {...field}
                  label="Description"
                  value={module.description}
                  onChange={(value: any) => setModule({ ...module, description: value })}
                  options={{ options: ['inline', 'colorPicker', 'remove'], inline: { options: ['bold', 'italic', 'underline'] } }}
                  defaultValue={moduleInit.description}
                  editorStyle={{ minHeight: '110px' }}
                  placeholder="Description of this module"
                />
              )}
            />

            <ErrorMessage>
              {errors?.modules ? errors?.modules[index]?.description?.message : ''}

            </ErrorMessage>

          </div>
          <div className="form-item">
            {selectedSessions.length > 0 ? (
              <>
                <Typography variant="h4">Sessions</Typography>
                <SelectedSessionTable
                  key={JSON.stringify(selectedSessions)}
                  items={selectedSessions}
                  handelRemove={handleSessionRemove}
                />
              </>
            ) : ''}
            <div>

              <SelectSessionModal
                filteredSession={filteredSession}
                total={totalSessions}
                loading={sessionLoading}
                selectedSessions={selectedSessions}
                handleUpdate={updateSessions}
                searchFunction={searchSession}
                addNewSession={() => addCourseOnlySession(index)}
                addNewSessionLabel="Create a course only Session"
              />
            </div>

          </div>
          <div className="form-item">
            {selectedForms.length > 0 ? (
              <>
                <Typography variant="h4" sx={{ mb: '0.5rem' }}>Form</Typography>
                <SelectedFormTable
                  items={selectedForms}
                  handelRemove={handleFormRemove}
                />
              </>
            ) : ''}
            <div>
              <SelectFormModal
                selectedForms={selectedForms}
                filteredForm={filteredForm}
                total={formTotal}
                loading={formLoading}
                handleUpdate={updateForms}
                searchFunction={searchForm}
              />
              <ConfigFormsModal
                key={JSON.stringify(selectedForms)}
                open={openConfigFormModal}
                handleClose={handleConfigFormModalCancel}
                selectedItems={selectedForms}
                handleUpdate={updateForms}
              />
            </div>

          </div>

          <div className="form-item">
            {selectedContents.length > 0 ? (
              <SelectedContentTable
                items={selectedContents}
                handelRemove={handleContentRemove}
              />
            ) : ''}
            <div>

              <SelectContentModal
                title="Select Media"
                filteredContent={filteredContent}
                selectedContents={selectedContents}
                handleUpdate={updateContents}
                searchFunction={searchContent}
                total={totalContent}
                loading={contentLoading}
              />
            </div>
          </div>
          <ErrorMessage>
            {errors?.modules ? errors?.modules[index]?.session?.message : ''}

          </ErrorMessage>
          <div>
            <ViewBadge handleBadge={handleBadge} handleBadgeRemove={handleBadgeRemove} badge={module.badge} formTitle={`Add module #${index + 1} badge`} />
            <ErrorMessage>
              {errors?.modules ? errors?.modules[index]?.badge?.title?.message : ''}

            </ErrorMessage>
          </div>
          <div style={{ textAlign: 'right' }}>

            <div className="inline-wrapper">
              <OutlineArrowSwapIcon className="inline-item" />
              <PrimaryOutlinedButton className="inline-item" onClick={() => setExpand(false)}>Compress</PrimaryOutlinedButton>
            </div>
          </div>
        </div>
      ) : (
        <Grid container alignItems="center" justifyContent="space-between" className="form-item">
          <Grid item>
            <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
              {module.session.length ? (
                <Grid item className="inline-wrapper">
                  <MonitorIcon className="inline-item" />
                  <div className="inline-item">
                    {module.session.length}
                    {' '}
                    sessions
                  </div>
                </Grid>
              ) : ''}

              {module.form.length ? (
                <Grid item className="inline-wrapper">
                  <ClipboardTextIcon className="inline-item" />
                  <div className="inline-item">
                    {module.form.length}
                    {' '}
                    forms
                  </div>
                </Grid>
              ) : ''}
              {module.content.length ? (
                <Grid item className="inline-wrapper">
                  <ClipboardIcon className="inline-item" />
                  <div className="inline-item">
                    {module.content.length}
                    {' '}
                    content
                  </div>
                </Grid>
              ) : ''}
              {module.badge.badgeImgUrl !== '' ? (
                <Grid item className="inline-wrapper">
                  <Avatar src={module.badge.badgeImgUrl} sx={{ width: 40, height: 40 }} className="inline-item" />
                  <Typography variant="h4" className="inline-item">
                    Module badge included
                  </Typography>

                </Grid>
              ) : ''}

            </Grid>
          </Grid>
          <Grid item className="inline-wrapper">
            <OutlineArrowSwapIcon className="inline-item" />
            <PrimaryOutlinedButton className="inline-item" onClick={() => setExpand(true)}>Expand</PrimaryOutlinedButton>
          </Grid>
        </Grid>
      )}

    </div>
  );
}
