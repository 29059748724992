/* eslint-disable import/prefer-default-export */

import { IBaseUserDocument } from 'interfaces/base-user';
import { Actions, ResourceTypes } from 'config';
import { ISession } from '../ent-account/components/edit-account/components/groups/interface';
import { IGroup } from '../ent-account/components/edit-account/components/users/interface';

export enum AnalyticsTypes {
  TOP_PERFORMING_SESSIONS = 'TOP_PERFORMING_SESSIONS',
  ACTIVITY = 'ACTIVITY',
  USERS = 'USERS',
  UNIQUE_USERS = 'UNIQUE_USERS',
  FEEDBACK = 'FEEDBACK',

}

export interface IWebsocketResponse {
  type:AnalyticsTypes,
  response:any
  connected?:boolean
}
export interface IEntAccount {
  _id: string;
  id: string;
  name: string;
}
export interface ISeries {
  total:number,
  pastTotal:number,
  countSeries:number[]
  timeSeries :string[]
}
export interface IFeedback {
  resolved:number,
  pending:number,
  pickedUp:number,
  total:number,
  pastTotal:number
}
export interface IUserSignInAnalytics {
  users:ISeries,
  experts:ISeries,
  admins:ISeries,
  totalRepeated:number,
  totalPastRepeated:number,
}
export interface ITopPerformingSessions {
  meta:ISession,
  expert:string,
  views:number,
  purchases:number
}
export interface IActivity {
  id:string,
  action:Actions,
  resourceType:ResourceTypes,
  resourceMeta:ISession | IBaseUserDocument | IGroup
  resourceOwnerMeta:IBaseUserDocument
  executedByMeta:IBaseUserDocument,
  executedAt:string
}
export interface IUniqueUsers {
  total:number,
  pastTotal:number,
}

export interface OverviewState {
  entId:string,
  stateLoading:boolean,
  topSessionsLoading:boolean,
  activitiesLoading:boolean,
  entAccountsLoading:boolean,
  feedbackLoading:boolean,
  filterOption:string,
  userSignInAnalytics:IUserSignInAnalytics,
  uniqueUsers:IUniqueUsers,
  activities:IActivity[],
  topPerformanceSessions: ITopPerformingSessions[]
  entAccounts:IEntAccount[]
  feedback:IFeedback

}
export interface IUserSignInAnalyticsRequest {
  userSignInAnalytics: IUserSignInAnalytics
}
export interface ITopSessionsRequest {
  topPerformingSessions: ITopPerformingSessions[]
}
export interface IActivitiesRequest {
  activities: IActivity[]
}

export interface IEntAccountRequest {
  entAccounts:IEntAccount[]
}
export interface IUniqueUsersRequest {
  uniqueUsers:IUniqueUsers
}

export interface IFeedbackRequest {
  feedback:IFeedback
}
