import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { Role } from 'interfaces/user-roles';
import API from './constants';
import { selectId } from './selector';
import { selectId as selectEntId, selectGeneralInformation as selectEntGeneralInformation } from '../../../../selector';
import { IGeneralInformationModalForm } from './interface';
import { editUserActions } from './slice';

export function* editGeneralInformationGenerator({
  payload,
}: PayloadAction<IGeneralInformationModalForm>): any {
  try {
    const id = yield select(selectId);
    const { entUrl } = yield select(selectEntGeneralInformation);
    const {
      firstName, lastName, profileImgUrl, email, bio,
      country, height, weight, bmi, guardian,
    } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_USER,
      {
        firstName,
        lastName,
        profileImgUrl,
        email,
        bio,
        country,
        id,
        entUrl,
        attributes: {
          height, weight, guardian, bmi,
        },
        accountType: Role.User,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editUserActions.getUserSucceeded(response.data));
      yield put(editUserActions.closeModal());
    } else {
      yield put(editUserActions.editGeneralInformationFailed());
    }
  } catch (error) {
    yield put(editUserActions.editGeneralInformationFailed());
  }
}
export function* getUserGenerator(): any {
  try {
    const id = yield select(selectId);
    const entId = yield select(selectEntId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_USER.path}/${id}`, method: API.GET_USER.method },
      null,
      true,
      false,
      undefined,
      entId,
    );
    if (response.statusCode === 200) {
      yield put(editUserActions.getUserSucceeded(response.data));
    } else {
      yield put(editUserActions.getUserFailed());
    }
  } catch (error) {
    yield put(editUserActions.getUserFailed());
  }
}

export function* deleteUserGenerator(): any {
  try {
    const userId = yield select(selectId);
    const entId = yield select(selectEntId);
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_USER,
      { userId },
      true,
    );
    if (response.statusCode === 202) {
      yield put(editUserActions.deleteUserSucceeded());
      window.location.pathname = `/ent-account/edit/${entId}/users`;
    } else {
      yield put(editUserActions.deleteUserFailed());
    }
  } catch (error) {
    yield put(editUserActions.deleteUserFailed());
  }
}

export function* editUserSaga() {
  yield all([
    takeLatest(editUserActions.editGeneralInformation.type, editGeneralInformationGenerator),
    takeLatest(editUserActions.deleteUser.type, deleteUserGenerator),
    takeLatest(editUserActions.getUser.type, getUserGenerator),
  ]);
}

export default editUserSaga;
