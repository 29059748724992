import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  ErrorMessage,
  PrimaryLoadingButton,
  PrimaryNumericField,
} from 'components';
import '../../index.scss';
import { IAddChargesForm } from '../../../../interface';

interface Props {
  onSubmit: SubmitHandler<any>;
  loading:boolean
}

const ChargeForm = (props: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IAddChargesForm>();

  const { onSubmit, loading } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item"> Charges</h3>
      <div className="form-item">
        <Controller
          control={control}
          name="adminFee"
          render={({ field }) => (
            <PrimaryNumericField
              {...field}
              min={0}
              max={100}
              label="Admin fee %"
            />
          )}
        />
        <ErrorMessage>{errors?.adminFee?.message}</ErrorMessage>
      </div>
      <div>
        <PrimaryLoadingButton loading={loading} size="large" type="submit" variant="contained">
          Create enterprise account
        </PrimaryLoadingButton>
      </div>

    </form>
  );
};

export default ChargeForm;
