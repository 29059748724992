import React from 'react';
import {
  Box, Grid, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryLiteButton, PrimaryLoadingButton, PrimaryModal } from 'components/molecules';
import './index.scss';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  loading: boolean;
  children: React.ReactNode;
  onSubmit:()=>void,
  primaryButtonValue:string,
  success:boolean,
  modalClass?: string;
  disabled?:boolean
}

const ConfirmationModal = ({
  open, onClose, title = '', loading = false, children, modalClass, onSubmit, primaryButtonValue, success, disabled = false,
}: ConfirmationModalProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <PrimaryModal open={open} onClose={onClose} modalClass={modalClass}>
      <Box className="confirmation-modal-view">
        <div className="inline-wrapper modal-header">
          <div className="inline-item">
            <Typography variant="h3" component="h3" sx={{ mb: 1 }}>{title}</Typography>
          </div>
          <IconButton aria-label="close" onClick={handleClose} className="inline-item">
            <CloseIcon />
          </IconButton>

        </div>

        <div className="close-modal-body">
          {children}
        </div>
        <Grid container spacing={3}>
          <Grid item xs>
            <PrimaryLiteButton
              fullWidth
              onClick={handleClose}
            >
              Cancel

            </PrimaryLiteButton>
          </Grid>
          <Grid item xs>
            <PrimaryLoadingButton disabled={disabled} loading={loading} onClick={onSubmit} fullWidth sx={{ backgroundColor: success ? '#63C32D' : 'red' }}>
              {primaryButtonValue}
            </PrimaryLoadingButton>
          </Grid>

        </Grid>
      </Box>
    </PrimaryModal>
  );
};

ConfirmationModal.defaultProps = {
  modalClass: '',
  disabled: false,
};

export default ConfirmationModal;
