import * as React from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  ErrorMessage,
  Label,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimaryTextField,
} from 'components';
import FileDragUploader from 'components/organisms/file-drag-uploader';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { ISponsorContent } from 'pages/dashboard/components/ent-account/interface';

interface SponsorLogoModalFormProps {
  onSubmit: SubmitHandler<any>;
  closeModal: () => void;
  index: number;
}

const OnboardSponsorLogoModalForm = (
  { onSubmit, closeModal, index }: SponsorLogoModalFormProps,
) => {
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<ISponsorContent>();

  const handleForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)();
  };
  const handleUpload = (res: UploadResponse) => {
    clearErrors('url');
    setValue('url', res.url);
    if (res.statusCode !== 201) {
      setError('url', { type: 'custom', message: res.message });
    }
  };

  return (
    <form onSubmit={handleForm}>
      <h3 className="form-item">Upload sponsor logo</h3>
      <div className="form-item">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Label>
              <i>
                Sponsor logo #
                {index}
              </i>
            </Label>
          </Grid>
        </Grid>
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <FileDragUploader
              textLabel="Click or drag a file to this area to upload"
              handleFile={handleUpload}
              currentContentUrl=""
              scenario={UploadScenarios.entSponsor}
              uploadAnotherText=""
              currentMediaType=""
            />
          )}
        />
      </div>
      <ErrorMessage>{errors?.contentUrl?.message}</ErrorMessage>
      <div className="form-item">
        <Controller
          name="contentUrl"
          control={control}
          render={({ field }) => <PrimaryTextField {...field} label="Sponsor url" />}
        />
        <ErrorMessage>{errors?.url?.message}</ErrorMessage>
      </div>
      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={closeModal}>
            Cancel
          </PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton fullWidth type="submit">
            Add & continue
          </PrimaryLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default OnboardSponsorLogoModalForm;
