import * as yup from 'yup';

const addContentsModalFormValidation = yup.object().shape({
  contentUrl: yup.string().trim().required(' Media  is a required field'),
  title: yup.string().trim().required('Title  is a required field'),
  description: yup.string().trim(),
  fileType: yup.string().trim().required('Media is a required field'),
  fileName: yup.string().trim().required('Media is a required field'),
});
export default addContentsModalFormValidation;
