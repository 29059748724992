import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { createGiftCardActions } from './slice';
import API from '../../constant';
import { ICreateGiftCardModalForm } from './interface';
import { viewGiftCardActions } from '../view-gift-cards/slice';

export function* createGiftCardGenerator({ payload }:
PayloadAction<ICreateGiftCardModalForm>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_GIFT_CARD,
      {
        isActive: true,
        amountWithCurrency: payload.amountWithCurrency.map(
          (item) => ({ ...item, amount: item.amount.toString() }),
        ),
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(createGiftCardActions.createGiftCardSucceeded());
      yield put(viewGiftCardActions.viewGiftCard({
        keyword: '', page: 1, limit: 25, status: 'All',
      }));
    } else {
      yield put(createGiftCardActions.createGiftCardFailed());
    }
  } catch (error) {
    yield put(createGiftCardActions.createGiftCardFailed());
  }
}

export function* createGiftCardSaga() {
  yield all([takeLatest(createGiftCardActions.createGiftCard.type, createGiftCardGenerator)]);
}

export default createGiftCardSaga;
