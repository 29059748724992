import {
  Avatar,
  Card, CardContent, Grid, Typography,
} from '@mui/material';
import DOMPurify from 'isomorphic-dompurify';
import { CalenderIcon } from 'assets/icons';
import { PrimaryButton, StatusTag } from 'components';
import { ISessionAvatar } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/sessions/interface';
import { IExpertAvatar } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/interface';
import { getCurrencySymbol } from 'utils/data-utils';
import './index.scss';
import { SessionType } from 'config';

interface SessionProps {
  session:ISessionAvatar
  entName:string,
  author:IExpertAvatar
}

const Session = ({ session, entName, author }:SessionProps) => {
  const discountedFee = (parseFloat(session.fee || '0')
  * (100 - parseFloat(session.discount || '0'))) / 100;
  return (
    <Card variant="outlined" sx={{ mb: '1rem', p: '1rem', backgroundColor: '#FFF' }}>
      <CardContent>
        <Grid container spacing={3} justifyContent="space-between" alignItems="center" className="form-item">
          <Grid item className="inline-wrapper">
            <Typography variant="h2">{session?.title}</Typography>
            <div className="inline-item" style={{ marginRight: '1rem' }}>
              <StatusTag status={session?.sessionType} success />
            </div>
            <Typography variant="subtitle1" className="inline-item">{session?.audienceType.toUpperCase()}</Typography>

          </Grid>

          <Grid item>
            <Typography variant="body1" sx={{ color: 'primary.main', fontWeight: '700' }}>{session?.language}</Typography>
          </Grid>
        </Grid>
        <Typography variant="h4">About session</Typography>
        <div className="form-item">
          {session?.description && (<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(session?.description) || '' }} />)}
        </div>
        <Grid container className="form-item">
          <Grid item xs={4}>
            <Grid container alignItems="center">
              <Grid item sx={{ mr: '0.7rem' }}>
                <Avatar src={author?.profileImgUrl} sx={{ width: 50, height: 50 }} />
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="h4">
                    {' '}
                    {author?.firstName}
                    {' '}
                    {author?.lastName}
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {' '}
                    {entName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid item className="inline-wrapper">
            <CalenderIcon sx={{ color: 'primary.main', mr: 1 }} />
            <Typography variant="body1">
              {session.sessionType === SessionType.digitalSession && 'This session will begin once it is booked'}
              {session.sessionType === SessionType.liveSession && 'This session will be booked by the user'}
              {session.sessionType === SessionType.content && 'N/A'}
              {session.sessionType === SessionType.liveClass && 'This session will be booked by the user'}
              {session.sessionType === SessionType.course && 'This course will begin once it is purchased by user'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <PrimaryButton>
              Enroll to this
              {' '}
              {session?.sessionType}
            </PrimaryButton>
          </Grid>
          <Grid item>
            <div className="inline-wrapper">

              <h2>
                {session?.discount !== '0' ? `${getCurrencySymbol(session?.currency ?? 'AUD')}${discountedFee}` : `${getCurrencySymbol(session?.currency ?? 'AUD')}${session?.fee}`}
              </h2>
              {(session?.discount !== '0') ? (
                <h2 className="discount-text">

                  {`${getCurrencySymbol(session?.currency ?? 'AUD')}${session?.fee}`}
                </h2>
              ) : ''}

            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default Session;
