import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  Divider, FormControlLabel, FormGroup, Grid,
} from '@mui/material';
import {
  ErrorMessage,
  Label,
  PrimaryLoadingButton,
  PrimarySwitch,
  PrimaryTextField,
} from 'components';
import { IOnboardContentModalForm } from '../../../../../interface';

interface OnboardContentModalFormProps {
  onSubmit: SubmitHandler<any>;
  loading: boolean;
}

const OnboardContentModalForm = ({ onSubmit, loading }: OnboardContentModalFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IOnboardContentModalForm>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item">Landing page</h3>
      <div className="form-item">
        <h4>Tagline</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="landingPageContent.tagLine"
          render={({ field }) => (
            <PrimaryTextField
              {...field}
              label="This will be used on the hero section as tagline on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.landingPageContent?.tagLine?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Title</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="landingPageContent.title"
          render={({ field }) => (
            <PrimaryTextField
              {...field}
              label="This will be used on the hero section as title on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.landingPageContent?.title?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Body copy</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="landingPageContent.body"
          render={({ field }) => (
            <PrimaryTextField
              multiline
              rows={4}
              {...field}
              label="This will be used on the hero section as body paragraph on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.landingPageContent?.body?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Customize landing page sections</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="landingPageSections.groups"
          render={({ field: { onChange, value, name } }) => (
            <Grid container alignItems="center" justifyContent="space-between">
              <Label> Groups</Label>
              <FormGroup>
                <FormControlLabel
                  control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                  label=""
                />
              </FormGroup>
            </Grid>
          )}
        />
        <ErrorMessage>{errors?.landingPageSections?.groups?.message}</ErrorMessage>
        <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="landingPageSections.sessions"
          render={({ field: { onChange, value, name } }) => (
            <Grid container alignItems="center" justifyContent="space-between">
              <Label>Sessions</Label>
              <FormGroup>
                <FormControlLabel
                  control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                  label=""
                />
              </FormGroup>
            </Grid>
          )}
        />
        <ErrorMessage>{errors?.landingPageSections?.sessions?.message}</ErrorMessage>
        <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="landingPageSections.featured"
          render={({ field: { value, onChange, name } }) => (
            <Grid container alignItems="center" justifyContent="space-between">
              <Label> Content sessions</Label>
              <FormGroup>
                <FormControlLabel
                  control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                  label=""
                />
              </FormGroup>
            </Grid>
          )}
        />
        <ErrorMessage>{errors?.landingPageSections?.featured?.message}</ErrorMessage>
        <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="landingPageSections.experts"
          render={({ field: { value, onChange, name } }) => (
            <Grid container alignItems="center" justifyContent="space-between">
              <Label> Experts</Label>
              <FormGroup>
                <FormControlLabel
                  control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                  label=""
                />
              </FormGroup>
            </Grid>
          )}
        />
        <ErrorMessage>{errors?.landingPageSections?.experts?.message}</ErrorMessage>
        <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="landingPageSections.articles"
          render={({ field: { name, value, onChange } }) => (
            <Grid container alignItems="center" justifyContent="space-between">
              <Label> Articles</Label>
              <FormGroup>
                <FormControlLabel
                  control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                  label=""
                />
              </FormGroup>
            </Grid>
          )}
        />
        <ErrorMessage>{errors?.landingPageSections?.articles?.message}</ErrorMessage>
        <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
      </div>

      <div className="form-item">
        <Controller
          control={control}
          name="landingPageSections.testimonials"
          render={({ field: { name, value, onChange } }) => (
            <Grid container alignItems="center" justifyContent="space-between">
              <Label> Testimonials</Label>
              <FormGroup>
                <FormControlLabel
                  control={<PrimarySwitch checked={value} onChange={onChange} name={name} />}
                  label=""
                />
              </FormGroup>
            </Grid>
          )}
        />
        <ErrorMessage>{errors?.landingPageSections?.testimonials?.message}</ErrorMessage>
        <Divider style={{ width: '100%' }} sx={{ marginTop: '0.5rem' }} />
      </div>
      <h3 className="form-item">Group landing page</h3>
      <div className="form-item">
        <h4>Title</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="groupLandingPageContent.title"
          render={({ field }) => (
            <PrimaryTextField
              {...field}
              label="This will be used on the hero section as title on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.groupLandingPageContent?.title?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Body copy</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="groupLandingPageContent.body"
          render={({ field }) => (
            <PrimaryTextField
              multiline
              rows={4}
              {...field}
              label="This will be used on the hero section as body paragraph on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.groupLandingPageContent?.body?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">Expert landing page</h3>
      <div className="form-item">
        <h4>Title</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="expertLandingPageContent.title"
          render={({ field }) => (
            <PrimaryTextField
              {...field}
              label="This will be used on the hero section as title on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.expertLandingPageContent?.title?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Body copy</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="expertLandingPageContent.body"
          render={({ field }) => (
            <PrimaryTextField
              multiline
              rows={4}
              {...field}
              label="This will be used on the hero section as body paragraph on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.expertLandingPageContent?.body?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">Session landing page</h3>
      <div className="form-item">
        <h4>Title</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="sessionLandingPageContent.title"
          render={({ field }) => (
            <PrimaryTextField
              {...field}
              label="This will be used on the hero section as title on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.sessionLandingPageContent?.title?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Body copy</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="sessionLandingPageContent.body"
          render={({ field }) => (
            <PrimaryTextField
              multiline
              rows={4}
              {...field}
              label="This will be used on the hero section as body paragraph on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.sessionLandingPageContent?.body?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">Content landing page</h3>
      <div className="form-item">
        <h4>Title</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="contentLandingPageContent.title"
          render={({ field }) => (
            <PrimaryTextField
              {...field}
              label="This will be used on the hero section as title on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.contentLandingPageContent?.title?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Body copy</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="contentLandingPageContent.body"
          render={({ field }) => (
            <PrimaryTextField
              multiline
              rows={4}
              {...field}
              label="This will be used on the hero section as body paragraph on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.contentLandingPageContent?.body?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">Article landing page</h3>
      <div className="form-item">
        <h4>Title</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="articleLandingPageContent.title"
          render={({ field }) => (
            <PrimaryTextField
              {...field}
              label="This will be used on the hero section as title on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.articleLandingPageContent?.title?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Body copy</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="articleLandingPageContent.body"
          render={({ field }) => (
            <PrimaryTextField
              multiline
              rows={4}
              {...field}
              label="This will be used on the hero section as body paragraph on your website"
            />
          )}
        />
        <ErrorMessage>{errors?.articleLandingPageContent?.body?.message}</ErrorMessage>
      </div>
      <div>
        <PrimaryLoadingButton size="large" type="submit" variant="contained" loading={loading}>
          Save & continue
        </PrimaryLoadingButton>
      </div>
    </form>
  );
};

export default OnboardContentModalForm;
