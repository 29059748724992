/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/edit-expert/session/create-session'] || initialState;

export const selectCreateSession = createSelector([selectDomain], (CreateSessionState) => CreateSessionState);
export const selectStateLoader = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.stateLoading);
export const selectSubStateLoader = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.subStateLoading);
export const selectLoader = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.loading);
export const selectStatus = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.status);
export const selectEntId = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.entId);
export const selectExpertId = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.expertId);
export const selectSessionId = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.sessionId);
export const selectActive = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.isActive);
export const selectSessionInformation = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.sessionInformation);
export const selectSessionDetails = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.sessionDetails);
export const selectSessionFee = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.sessionFee);
export const selectLiveAvailability = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.liveAvailability);
export const selectLiveClassFrequency = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.liveClassFrequency);
export const selectCourseModules = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.courseModules);
export const selectCourseGoals = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.courseGoals);
export const selectFilteredGroups = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.filteredGroups);
export const selectInvitations = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.invitations);
export const selectModalState = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.modalState);
export const selectCourseOnlySessionMeta = createSelector([selectDomain], (CreateSessionState) => CreateSessionState.courseOnlySessionMeta);
