import * as yup from 'yup';
import { PasswordValidation } from 'helpers/validations';

const ResetTempPasswordFormValidation = yup.object().shape({
  password: yup.string().trim().required('Temporary Password is a required field'),
  name: yup.string().trim().required('Email address is a required field'),
  newPassword: PasswordValidation(),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
export default ResetTempPasswordFormValidation;
