import * as React from 'react';
import Box from '@mui/material/Box';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { PrimaryLoadingOutlinedButton } from 'components';
import { AdminDocument } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/interface';
import { selectModal } from '../../../../../selector';
import { onboardEntAccActions } from '../../../../../slice';
import OnboardCreateExpertModalForm from '../add-new-expert-modal-form';
import { ModalStates } from '../../../../../interface';
import onboardAddNewExpertModalFormValidation from '../validation/add-new-expert-modal-form-valitation';

interface OnboardCreateExpertModalProps {
  index: number;
}

const OnboardCreateExpertModal = ({ index }: OnboardCreateExpertModalProps) => {
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const methods = useForm<AdminDocument>({
    defaultValues: {
      email: '',
    },
    shouldFocusError: true,
    resolver: yupResolver(onboardAddNewExpertModalFormValidation),
  });

  const handleOpen = () => {
    methods.reset();
    dispatch(onboardEntAccActions.openModal(ModalStates.addExpert));
  };
  const handleClose = () => {
    dispatch(onboardEntAccActions.closeModal());
  };
  const onSubmit: SubmitHandler<any> = React.useCallback(
    (data) => {
      dispatch(onboardEntAccActions.inviteExpert(data));
    },
    [dispatch],
  );

  return (
    <Grid container justifyContent="flex-start">
      <Grid item>
        <PrimaryLoadingOutlinedButton sx={{ padding: '0.5rem 0' }} onClick={handleOpen}>
          <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
          Add new expert
        </PrimaryLoadingOutlinedButton>
      </Grid>

      <Modal open={modal === ModalStates.addExpert}>
        <Box className="modal-scrollable">
          <IconButton aria-label="close" sx={{ float: 'right' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <FormProvider {...methods}>
            <OnboardCreateExpertModalForm onSubmit={onSubmit} closeModal={handleClose} />
          </FormProvider>
        </Box>
      </Modal>
    </Grid>
  );
};
export default OnboardCreateExpertModal;
