export enum GroupTypes {
  public = 'public',
  private = 'private',
  subscription = 'subscription',
}

export enum GroupVisibilities {
  public = 'public',
  hidden = 'hidden',
}

export enum GroupSubscriptionFrequency {
  monthly = 'monthly',
  annually = 'annually',
  oneTime = 'onetime',
}

export enum RequestStatus {
  pending = 'pending',
  accepted = 'approved',
  rejected = 'rejected',
  none = 'none',
}
