/* eslint-disable no-var */
import React from 'react';
import { Label } from '../custom-typography';
import { CustomOutlineInputField } from '../primary-text-field';

const PrimaryNumericField = React.forwardRef(
  ({
    onChange, onBlur, onFocus, value, name, label, placeholder,
    disabled, startAdornment, min = 0, max, testId = '', decimalAllowed = false,
  }: any, ref) => {
    const onKeyDown = (e: any) => {
      if (e?.code === 'ArrowDown' || e?.code === 'ArrowUp') {
        e.preventDefault();
      }
    };
    const onKeyPress = (e: any) => {
      if (!decimalAllowed && (e.key === '.' || e.key === ',')) {
        e.preventDefault();
      }
      if (e?.key === '-' || e?.key === '+' || e?.key === 'e' || e?.key === 'E') {
        e.preventDefault();
      }
    };
    const onWheel = (e: any) => {
      e.target.blur();
    };
    const onChangeWrapper = (e: any) => {
      const originalInput = e.target.value;
      let input = originalInput;

      // Handle leading zeros
      if (input.startsWith('0') && input.length > 1 && input[1] !== '.') {
        input = input.substring(1);
      }

      // Handle non-decimal input
      if (!decimalAllowed || !/^[0-9]*\.?[0-9]*$/.test(input)) {
        input = input.replace(/[.,]/g, ''); // Remove any decimal points if not allowed or if input isn't in decimal format
      }

      let numberValue = parseFloat(input);

      if (Number.isNaN(numberValue)) numberValue = 0;

      if (numberValue > max) numberValue = max;
      if (numberValue < min) numberValue = min;

      // Only adjust the displayed input value if it doesn't match the original input
      // or if it falls outside the min/max constraints.
      if (input !== originalInput || numberValue !== parseFloat(originalInput)) {
        e.target.value = numberValue.toString();
      }

      if (onChange) {
        const updatedEvent = {
          ...e,
          target: { ...e.target, value: numberValue },
        };
        onChange(updatedEvent);
      }
    };

    const inputMode = decimalAllowed ? 'decimal' : 'numeric';
    const step = decimalAllowed ? '0.01' : '1';
    const pattern = decimalAllowed ? '[0-9]*[.,]?[0-9]*' : '[0-9]*';
    return (
      <>
        <Label htmlFor={name}>
          {label}
        </Label>

        <CustomOutlineInputField
          onChange={onChangeWrapper}
          onBlur={onBlur ?? undefined}
          onFocus={onFocus ?? undefined}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onWheel={onWheel}
          value={value ?? undefined}
          name={name ?? undefined}
          inputRef={ref ?? undefined}
          size="small"
          placeholder={placeholder ?? undefined}
          fullWidth
          disabled={disabled}
          startAdornment={startAdornment ?? undefined}
          type="number"
          id={`${name}`}
          inputProps={{
            inputMode,
            pattern,
            step,
            min,
            max,
            'data-testid': testId ?? '',
          }}
        />

      </>
    );
  },
);

export default PrimaryNumericField;
