/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/edit-expert/view-forms'] || initialState;

export const selectViewFormsState = createSelector(
  [selectDomain],
  (ViewFormsState) => ViewFormsState,
);
export const selectFilteredForms = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.filteredForms);
export const selectStateLoader = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.stateLoading);
export const selectFormId = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.formId);
export const selectLoader = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.loading);
export const selectModal = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.modal);
export const selectInitiatedBy = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.initiatedBy);
export const selectFormSettings = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.formSettings);
export const selectFormConfig = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.formConfig);
export const selectAnswerData = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.answerData);
export const selectStatus = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.status);
export const selectFormConfigModal = createSelector([selectDomain], (ViewFormsState) => ViewFormsState.formConfigModal);
