import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { Role } from 'interfaces/user-roles';
import { viewUserActions } from './slice';
import API from '../../constant';
import { selectId, selectGeneralInformation } from '../../../../selector';
import {
  IDeleteUser, IUserPaginateFilterPayload,
} from './interface';

export function* viewUserGenerator({ payload }: PayloadAction<IUserPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    if (id) {
      const response: IRestApiResponse = yield call(
        request,
        { path: `${API.GET_USER_BY_ENT.path}/${id}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&withDeleted=${payload.withDeleted || 'false'}`, method: API.GET_USER_BY_ENT.method },
        null,
        true,
      );
      if (response.statusCode === 200) {
        yield put(viewUserActions.viewFilteredUserSucceeded(response.data));
      } else {
        yield put(viewUserActions.viewFilteredUserFailed());
      }
    } else {
      throw Error('Ent Id is not defined');
    }
  } catch (error) {
    yield put(viewUserActions.viewFilteredUserFailed());
  }
}

export function* deleteUserGenerator({ payload }:PayloadAction<IDeleteUser>): any {
  try {
    const { userId } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_USER,
      { userId },
      true,
    );
    if (response.statusCode === 202) {
      yield put(viewUserActions.deleteUserSucceeded(userId));
    } else {
      yield put(viewUserActions.deleteUserFailed());
    }
  } catch (error) {
    yield put(viewUserActions.deleteUserFailed());
  }
}
export function* resetPasswordGenerator({ payload }:PayloadAction<string>): any {
  try {
    const { entUrl } = yield select(selectGeneralInformation);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_RESET_PASSWORD,
      { id: payload, entUrl, accountType: Role.User },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewUserActions.resetPasswordSucceeded(payload));
    } else {
      yield put(viewUserActions.resetPasswordFailed());
    }
  } catch (error) {
    yield put(viewUserActions.resetPasswordFailed());
  }
}

export function* viewUserSaga() {
  yield all([takeLatest(
    viewUserActions.viewFilteredUser.type,
    viewUserGenerator,
  ), takeLatest(
    viewUserActions.deleteUser.type,
    deleteUserGenerator,
  ), takeLatest(
    viewUserActions.resetPassword.type,
    resetPasswordGenerator,
  ),
  ]);
}

export default viewUserSaga;
