import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { newsFeedActions } from './slice';
import { selectExpertId } from './selector';
import API from './constants';
import { FetchNewsfeedFilterPayload } from './interface';

export function* getNewsFeedGenerator({ payload }:PayloadAction <FetchNewsfeedFilterPayload>): any {
  try {
    const id = yield select(selectExpertId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_NEWS_FEED_BY_EXPERT.path}/${id}/?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&sortBy=${payload.sortBy}`, method: API.GET_NEWS_FEED_BY_EXPERT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      const { result: news, total } = response.data;
      yield put(newsFeedActions.getNewsFeedSucceeded({ news, total }));
    } else {
      yield put(newsFeedActions.getNewsFeedFailed());
    }
  } catch (error) {
    yield put(newsFeedActions.getNewsFeedFailed());
  }
}
export function* pinNewsFeedGenerator({ payload }:PayloadAction<string>): any {
  try {
    const id = payload;
    const expertId = yield select(selectExpertId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.POST_PIN_NEWS_FEED.path}/?expertId=${expertId}`, method: API.POST_PIN_NEWS_FEED.method },
      { id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(newsFeedActions.getNewsFeed({
        keyword: '',
        page: 1,
        limit: 18,
        sortBy: 'Default',
      }));
    } else {
      yield put(newsFeedActions.pinNewsFailed());
    }
  } catch (error) {
    yield put(newsFeedActions.pinNewsFailed());
  }
}

export function* deleteNewsFeedGenerator({ payload }:PayloadAction<string>): any {
  try {
    const id = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_NEWS_FEED,
      { id },
      true,
    );
    if (response.statusCode === 200) {
      yield put(newsFeedActions.deleteNewsSucceeded(id));
    } else {
      yield put(newsFeedActions.deleteNewsFailed());
    }
  } catch (error) {
    yield put(newsFeedActions.deleteNewsFailed());
  }
}

export function* newsFeedSaga() {
  yield all([takeLatest(newsFeedActions.getNewsFeed.type, getNewsFeedGenerator),
    takeLatest(newsFeedActions.pinNews.type, pinNewsFeedGenerator),
    takeLatest(newsFeedActions.deleteNews.type, deleteNewsFeedGenerator)]);
}

export default newsFeedSaga;
