import Switch from '@mui/material/Switch';
import React from 'react';
import { styled } from '@mui/material/styles';

const CustomSwitch = styled(Switch)({
  width: 42,
  height: 26,
  padding: 0,
  margin: '0 0.5rem',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: '#A1A1A1',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: '#fff',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,

  },
});

interface PrimarySwitchProps {
  onBlur?: React.FocusEventHandler<HTMLButtonElement> ;
  onChange?:(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void ;
  checked?:boolean,
  name?:string,
  disabled?:boolean;
}
const PrimarySwitch = React.forwardRef(({
  onChange, onBlur, checked, name, disabled,
}:PrimarySwitchProps) => (
  <CustomSwitch
    size="medium"
    onChange={onChange ?? undefined}
    onBlur={onBlur ?? undefined}
    checked={checked}
    name={name ?? undefined}
    disabled={disabled}
  />
));

PrimarySwitch.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  checked: false,
  name: '',
  disabled: false,
};

export default PrimarySwitch;
