/* eslint-disable no-nested-ternary */
import {
  CircularProgress, Divider, Grid, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { IBaseUserDocument } from 'interfaces/base-user';
import { ISession } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/interface';
import { IGroup } from 'pages/dashboard/components/ent-account/components/edit-account/components/users/interface';
import { Actions, ResourceTypes } from 'config';
import { selectActivities, selectActivitiesLoader } from '../../selector';
import ActivityCard from '../activity-card';

const Activity = () => {
  const activitiesLoader = useSelector(selectActivitiesLoader);
  const activities = useSelector(selectActivities);

  console.log(activities);
  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      {(activitiesLoader) ? <Grid container justifyContent="center" alignItems="center" sx={{ margin: 'auto', height: 'calc(100vh-114px)' }}><CircularProgress /></Grid> : (
        activities ? activities?.map((activity) => (
          <>
            <ActivityCard date={activity.executedAt}>
              <Typography variant="body1">
                {(activity.action === Actions.create
                && activity.resourceType === ResourceTypes.session) && (
                <>
                  Expert
                  {' '}
                  <b>
                    {activity.resourceOwnerMeta?.firstName}
                    {' '}
                    {activity.resourceOwnerMeta?.lastName}
                  </b>
                  {' '}
                  has created
                  {' '}
                  <span style={{ color: '#9D8336', fontWeight: 'bold' }}>{(activity.resourceMeta as ISession)?.title}</span>
                  {' '}
                  session.
                </>
                ) }
                {(activity.action === Actions.create
                && activity.resourceType === ResourceTypes.group) && (
                <>
                  Expert
                  {' '}
                  <b>
                    {activity.resourceOwnerMeta?.firstName}
                    {' '}
                    {activity.resourceOwnerMeta?.lastName}
                  </b>
                  {' '}
                  has created
                  {' '}
                  <span style={{ color: '#9D8336', fontWeight: 'bold' }}>{(activity.resourceMeta as IGroup)?.title}</span>
                  {' '}
                  group.
                </>
                ) }
                {(activity.action === Actions.purchase
                && (activity.resourceType === ResourceTypes.session
                  || activity.resourceType === ResourceTypes.contentOnly)) && (
                  <>
                    User
                    {' '}
                    <b>
                      {activity.executedByMeta?.firstName}
                      {' '}
                      {activity.executedByMeta?.lastName}
                    </b>
                    {' '}
                    has purchased a session of
                    {' '}
                    <span style={{ color: '#9D8336', fontWeight: 'bold' }}>{(activity.resourceMeta as ISession)?.title}</span>
                    {' '}
                    by
                    {' '}
                    <b>
                      {activity.resourceOwnerMeta?.firstName}
                      {' '}
                      {activity.resourceOwnerMeta?.lastName}
                    </b>
                    {' '}
                    .
                  </>
                ) }
                {(activity.action === Actions.create
                && activity.resourceType === ResourceTypes.expert) && (
                <>
                  Admin
                  {' '}
                  <b>
                    {activity.executedByMeta?.firstName}
                    {' '}
                    {activity.executedByMeta?.lastName}
                  </b>
                  {' '}
                  has added a new expert
                  {' '}
                  <b>
                    {(activity.resourceMeta as IBaseUserDocument)?.firstName}
                    {' '}
                    {(activity.resourceMeta as IBaseUserDocument)?.lastName}
                  </b>
                  .
                </>
                ) }
                {(activity.action === Actions.signUp
                && activity.resourceType === ResourceTypes.user) && (
                <>
                  User
                  {' '}
                  <b>
                    {activity.executedByMeta?.firstName}
                    {' '}
                    {activity.executedByMeta?.lastName}
                  </b>
                  {' '}
                  signed up.

                </>
                ) }

              </Typography>

            </ActivityCard>
            <Divider style={{ width: '100%' }} />
          </>

        )) : ''
      )}

    </div>

  );
};
export default Activity;
