import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  ErrorMessage,
  PrimaryLoadingButton,
  PrimaryNumericField,
  SliderInput,
} from 'components';
import '../../index.scss';
import { IAddUserAllocationForm } from '../../../../interface';

interface Props {
  onSubmit: SubmitHandler<any>;
}
const noOfCoachesMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5000,
    label: '5000',
  },
];
const noOfGroupsMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 500,
    label: '500',
  },
];
const UserAllocationForm = (props: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IAddUserAllocationForm>();
  const { onSubmit } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item"> User allocation</h3>

      <div className="form-item">
        <Controller
          control={control}
          name="maxNoOfCoaches"
          render={({ field }) => <SliderInput max={5000} min={0} step={1} defaultValue={85} marks={noOfCoachesMarks} {...field} type="number" label="Maximum number of experts" />}
        />
        <ErrorMessage>{errors?.maxNoOfCoaches?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="maxNoOfGroups"
          render={({ field }) => <SliderInput max={500} min={0} step={1} defaultValue={85} marks={noOfGroupsMarks} {...field} type="number" label="Maximum number of groups" />}
        />
        <ErrorMessage>{errors?.maxNoOfGroups?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="maxNoOfEndUsers"
          render={({ field }) => (
            <PrimaryNumericField
              {...field}
              label="Maximum number of end users"

            />
          )}
        />
        <ErrorMessage>{errors?.maxNoOfEndUsers?.message}</ErrorMessage>
      </div>

      <div>
        <PrimaryLoadingButton size="large" type="submit" variant="contained">
          Save & continue
        </PrimaryLoadingButton>
      </div>
    </form>
  );
};

export default UserAllocationForm;
