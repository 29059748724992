/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc'] || initialState;

export const selectEditEntAcc = createSelector([selectDomain], (EditEntAccState) => EditEntAccState);
export const selectStateLoader = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.stateLoading);
export const selectLoader = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.loading);
export const selectSubStateLoader = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.subStateLoading);
export const selectId = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.id);
export const selectActive = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.active);
export const selectModalState = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.modal);
export const selectStripeAccountCompleted = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.stripeAccountCompleted);
export const selectStripeConnectUrl = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.stripeConnectUrl);
export const selectGeneralInformation = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.generalInformation);
export const selectGeography = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.geography);
export const selectSocialMedia = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.socialMedia);
export const selectCategories = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.categories);
export const selectSkills = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.skills);

export const selectGiftCards = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.giftCards);
export const selectCharges = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.charges);
export const selectBankDetails = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.bankDetails);

export const selectWebLogoUrl = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.webLogoUrl);
export const selectEntLogoUrl = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.entLogoUrl);

export const selectUserAllocation = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.userAllocation);

export const selectHeaderTemplate = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.headerTemplate);
export const selectPromotionalContent = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.promotionalContent);
export const selectSponsorsLogo = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.sponsorContent);
export const selectWebsiteColors = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.colors);
export const selectWebsiteFonts = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.websiteFonts);

export const selectSessionLandingPage = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.sessionLandingPageContent);

export const selectLandingPageSections = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.landingPageSections);
export const selectLandingPage = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.landingPageContent);
export const selectGroupLandingPage = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.groupLandingPageContent);
export const selectExpertLandingPage = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.expertLandingPageContent);
export const selectContentLandingPage = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.contentLandingPageContent);
export const selectArticlesLandingPage = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.articlesLandingPageContent);
export const selectSessionsLandingPage = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.sessionLandingPageContent);
export const selectTermsAndConditionsPage = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.termsAndConditionsPage);
export const selectLandingCtaContent = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.landingCtaContent);

export const selectSessions = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.sessions);
export const selectContentOnlySessions = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.contentOnlySessions);
export const selectExperts = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.experts);
export const selectGroups = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.groups);
export const selectArticles = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.articles);

export const selectFilteredSessions = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.filteredSessions);
export const selectFilteredContentOnlySessions = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.filteredContentOnlySessions);
export const selectFilteredExperts = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.filteredExperts);
export const selectFilteredGroups = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.filteredGroups);
export const selectFilteredArticles = createSelector([selectDomain], (EditEntAccState) => EditEntAccState.filteredArticles);
