import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, Tabs, Tab, Box,
} from '@mui/material';
import Link from '@mui/material/Link';
import {
  useNavigate, Route,
  Routes,
} from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormControlLabel from '@mui/material/FormControlLabel';
import './index.scss';
import { PrimaryLiteButton, PrimarySwitch } from 'components';
import { DashboardLayout } from 'components/templates';
import GeneralInformationView from './components/general-information';
import UserAllocationView from './components/user-allocation';
import ChargesView from './components/charges';
import { selectActive, selectStatus } from './selector';
import { createEntAccActions } from './slice';
import { CreateEntAccStep } from './interface';

interface TabPanelProps {
  children: React.ReactNode;
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`create-ent-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CreateEntAcc = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateActive = () => {
    dispatch(createEntAccActions.updateActive());
  };
  const cancel = () => {
    dispatch(createEntAccActions.reset());
    navigate('/ent-account');
  };
  const active = useSelector(selectActive);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" fontWeight="bold" href="/">
      Home
    </Link>,
    <Link underline="hover" key="2" color="inherit" fontWeight="bold" href="/ent-account/">
      Enterprise accounts
    </Link>,
    <Link underline="hover" key="2" color="text.primary" fontWeight="bold" href="/ent-account/create-ent-account">
      Create enterprise account
    </Link>,
  ];

  const status = useSelector(selectStatus);
  const topContent = (
    <Grid container alignItems="center">
      <Grid item xs={10}>
        <Grid container alignItems="center">
          <Grid item>
            <h3>Create enterprise account</h3>
          </Grid>
          {status !== CreateEntAccStep.successful
            && (
              <Grid item sx={{ marginLeft: '3rem' }}>
                <FormGroup>
                  <FormControlLabel
                    control={<PrimarySwitch checked={active} onChange={updateActive} />}
                    label="Active"
                  />
                </FormGroup>
              </Grid>
            )}
        </Grid>
      </Grid>
      <Grid item xs sx={{ textAlign: 'right', marginBottom: '0.3rem' }}>
        {status !== CreateEntAccStep.successful
          && (
            <PrimaryLiteButton size="small" onClick={cancel}><b>Cancel</b></PrimaryLiteButton>
          )}
      </Grid>
    </Grid>
  );
  // checking the status in unmounting
  React.useEffect(() => () => {
    if (status >= CreateEntAccStep.successful) { dispatch(createEntAccActions.reset()); }
  }, [status]);

  return (
    <div>
      <DashboardLayout breadcrumbs={breadcrumbs} topContent={topContent}>
        <Grid container justifyContent="start" sx={{ width: '100%', marginTop: '1rem' }}>
          <Grid item xs={6}>

            <Box sx={{ width: '92rem' }}>
              <Routes>
                <Route path="*" />
              </Routes>
              <Tabs value={Math.min(status, CreateEntAccStep.charges)}>

                <Tab
                  className={(status >= CreateEntAccStep.userAllocation) ? 'ent-tab completed' : 'ent-tab'}
                  label="1. General information"
                  {...a11yProps(0)}
                  disabled={(status > CreateEntAccStep.charges)}
                  icon={(status >= CreateEntAccStep.userAllocation) ? <CheckCircleIcon /> : ''}
                  iconPosition="start"
                  onClick={() => dispatch(createEntAccActions.setStatus(
                    CreateEntAccStep.generalInformation,
                  ))}
                />
                <Tab
                  className={(status >= CreateEntAccStep.charges) ? 'ent-tab completed' : 'ent-tab'}
                  label="2. User allocation"
                  {...a11yProps(1)}
                  disabled={(status > CreateEntAccStep.charges)
                     || (status < CreateEntAccStep.userAllocation)}
                  icon={(status >= CreateEntAccStep.charges) ? <CheckCircleIcon /> : ''}
                  iconPosition="start"
                  onClick={() => dispatch(createEntAccActions.setStatus(
                    CreateEntAccStep.userAllocation,
                  ))}
                />
                <Tab
                  className={(status >= CreateEntAccStep.successful) ? 'ent-tab completed' : 'ent-tab'}
                  label="3. Charges"
                  {...a11yProps(2)}
                  disabled={(status > CreateEntAccStep.charges)
                    || (status < CreateEntAccStep.charges)}
                  icon={(status >= CreateEntAccStep.successful) ? <CheckCircleIcon /> : ''}
                  iconPosition="start"
                  onClick={() => dispatch(createEntAccActions.setStatus(CreateEntAccStep.charges))}

                />

              </Tabs>
            </Box>
            <TabPanel value={status} index={CreateEntAccStep.generalInformation}>
              <GeneralInformationView />
            </TabPanel>
            <TabPanel value={status} index={CreateEntAccStep.userAllocation}>
              <UserAllocationView />
            </TabPanel>
            <TabPanel
              value={Math.min(
                status,
                CreateEntAccStep.charges,
              )}
              index={CreateEntAccStep.charges}
            >
              <ChargesView />
            </TabPanel>

          </Grid>

        </Grid>
      </DashboardLayout>
    </div>
  );
};

export default CreateEntAcc;
