/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-session'] || initialState;

export const selectViewSessionState = createSelector([selectDomain], (ViewSessionState) => ViewSessionState);
export const selectSession = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.session);
export const selectSessionLoader = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.stateLoading);
export const selectLoader = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.loading);
export const selectSessionId = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.sessionId);
export const selectSessionUrl = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.sessionUrl);
export const selectInvitePeople = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.invitePeople);
export const selectFilteredSessions = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.filteredSessions);
export const selectSubStateLoader = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.subStateLoading);
export const selectModalState = createSelector([selectDomain], (viewSessionState) => viewSessionState.modalState);
