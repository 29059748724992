/* eslint-disable no-underscore-dangle */
import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { SessionAudienceTypes, SessionType } from 'config';
import API from './constants';

import { createGroupActions } from './slice';
import { selectId } from '../../../../selector';
import {
  selectContent,
  selectExperts, selectGroupAdmin, selectGroupInformation,
  selectIsActive, selectSessions, selectUsers, selectArticles,
} from './selector';
import { IArticleItemData, IExpertItemData, ISessionItemData } from '../../interface';
import { IPaginateFilterPayload } from './interface';

export function* createGroupGenerator(): any {
  try {
    const entId = yield select(selectId);
    const isActive = yield select(selectIsActive);
    const groupInformation = yield select(selectGroupInformation);
    const groupAdmin = yield select(selectGroupAdmin);
    const articles = yield select(selectArticles);
    const sessions = yield select(selectSessions);
    const content = yield select(selectContent);
    const experts = (yield select(selectExperts)).map((expert:IExpertItemData) => ({
      expert: expert.id,
      articles: articles.filter(
        (article:IArticleItemData) => article.author === expert.id,
      ).map((article:IArticleItemData) => ({ article: article.id, isVisible: true })),
      sessions: sessions.filter(
        (session:ISessionItemData) => session.expertId === expert.id,
      ).map((session:ISessionItemData) => ({ session: session.id, isVisible: true })),
      contents: content.filter(
        (contentItem:ISessionItemData) => contentItem.expertId === expert.id,
      ).map((contentItem:ISessionItemData) => ({ content: contentItem.id, isVisible: true })),
    }));
    const invitePeople = (yield select(selectUsers));
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_GROUP,
      {
        entId,
        isActive,
        ...groupInformation,
        subscriptionFee: groupInformation.subscriptionFee.toString(),
        groupAdmin,
        experts,
        invitePeople,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(createGroupActions.createGroupSucceeded());
    } else if (response.statusCode === 207) {
      yield put(createGroupActions.createGroupPartialSucceeded(response.data));
    } else {
      yield put(createGroupActions.createGroupFailed());
    }
  } catch (error) {
    yield put(createGroupActions.createGroupFailed());
  }
}
export function* getFilteredSessionsGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const expertIds = [...(yield select(selectExperts)).map((expert:IExpertItemData) => expert.id)];
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_SESSIONS_BY_ENT.path}/${id}/${[...expertIds]}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&status=${payload.status}&withoutDeleted=${payload.withoutDeleted || 'true'}&publicOnly=true&audienceType=${SessionAudienceTypes.public}&type=${[SessionType.digitalSession, SessionType.course, SessionType.liveClass, SessionType.liveSession].toString()}`, method: API.GET_SESSIONS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(createGroupActions.getFilteredSessionsSucceeded(response.data));
    } else {
      yield put(createGroupActions.getFilteredSessionsFailed());
    }
  } catch (error) {
    yield put(createGroupActions.getFilteredSessionsFailed());
  }
}
export function* getFilteredContentGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const expertIds = [...(yield select(selectExperts)).map((expert:IExpertItemData) => expert.id)];
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_SESSIONS_BY_ENT.path}/${id}/${[...expertIds]}?type=${SessionType.content}&keyword=${payload.keyword}&status=${payload.status}&withoutDeleted=${payload.withoutDeleted || 'true'}&publicOnly=true&page=${payload.page}&limit=${payload.limit}&audienceType=${SessionAudienceTypes.public}`, method: API.GET_SESSIONS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(createGroupActions.getFilteredContentSucceeded(response.data));
    } else {
      yield put(createGroupActions.getFilteredContentFailed());
    }
  } catch (error) {
    yield put(createGroupActions.getFilteredContentFailed());
  }
}
export function* getFilteredExpertsGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_EXPERTS_BY_ENT.path}/${id}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&category=${payload.category}&status=${payload.status}&withDeleted=${!payload.withoutDeleted || 'false'}`, method: API.GET_EXPERTS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(createGroupActions.getFilteredExpertsSucceeded(response.data));
    } else {
      yield put(createGroupActions.getFilteredExpertsFailed());
    }
  } catch (error) {
    yield put(createGroupActions.getFilteredExpertsFailed());
  }
}

export function* getFilteredArticlesGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const expertIds = [...(yield select(selectExperts)).map((expert:IExpertItemData) => expert.id)];
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_ARTICLES_BY_ENT.path}/?experts=${[...expertIds]}&keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}`, method: API.GET_ARTICLES_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(createGroupActions.getFilteredArticlesSucceeded(response.data));
    } else {
      yield put(createGroupActions.getFilteredArticlesFailed());
    }
  } catch (error) {
    yield put(createGroupActions.getFilteredArticlesFailed());
  }
}

export function* createGroupSaga() {
  yield all([takeLatest(
    createGroupActions.createGroup.type,
    createGroupGenerator,
  ),
  takeLatest(
    createGroupActions.getFilteredExperts.type,
    getFilteredExpertsGenerator,
  ),
  takeLatest(
    createGroupActions.getFilteredSessions.type,
    getFilteredSessionsGenerator,
  ),
  takeLatest(
    createGroupActions.getFilteredContent.type,
    getFilteredContentGenerator,
  ),
  takeLatest(
    createGroupActions.getFilteredArticles.type,
    getFilteredArticlesGenerator,
  ),
  ]);
}

export default createGroupSaga;
