import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Avatar, Tooltip, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  ClosableModal, PrimaryOutlinedButton, PrimaryTable, StatusTag,
} from 'components';
import { IInvitation } from '../../../../../../interface';

const itemTableColumns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    flex: 0.4,
    renderCell: (params) => (
      <>
        <Avatar sx={{ width: 32, height: 32, mr: 2 }} alt={params.value} />
        <Tooltip title={params.value} enterDelay={1500}>
          <Typography variant="body2" component="p">
            {params.value}
          </Typography>
        </Tooltip>
      </>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.4,
    renderCell: (params) => (
      <div>
        {params.value === 'pending' ? (
          <StatusTag
            color="#FFB302"
            status={`${params.value.charAt(0).toUpperCase()}${params.value.slice(1)}`}
          />
        ) : (
          <StatusTag
            status={`${params.value.charAt(0).toUpperCase()}${params.value.slice(1)}`}
            success={params.value === 'accepted'}
          />
        )}
      </div>
    ),
  },
];

const ViewInviteUsersModal = ({ invitations }: { invitations: IInvitation[] }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>([]);

  React.useEffect(() => {
    const formatted: any[] = [];
    invitations.forEach((item) => {
      formatted.push({
        id: item._id,
        email: item.userEmail,
        status: item.status,
      });
    });
    setData(formatted);
  }, [invitations]);

  return (
    <>
      <PrimaryOutlinedButton
        onClick={() => {
          setOpen(true);
        }}
        className="inline-wrapper"
      >
        View invitations
        <ArrowForwardIcon sx={{ color: 'primary.main', fontSize: 20, ml: 1 }} />
      </PrimaryOutlinedButton>
      <ClosableModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title="Session Invitations"
        subTitle={`${data.length} user${
          data.length - 1 ? 's' : ''
        } has been invited to this session. you can re-send the invitation by adding the user again`}
        modalClass="invite-users-modal"
      >
        <PrimaryTable
          rows={data}
          columns={itemTableColumns}
          checkboxSelection={false}
          rowsPerPageOptions={[50]}
          pageSize={50}
          rowCount={data.length}
          disableSelectionOnClick
        />
      </ClosableModal>
    </>
  );
};

export default ViewInviteUsersModal;
