import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import CourseGoalsForm from '../goals-form';
import courseGoalsFormValidation from '../../validation/goals-form-validation';
import { ICourseGoals } from '../../../../interface';
import { selectCourseGoals, selectLoader } from '../../../../selector';
import { createSessionActions } from '../../../../slice';

export default function CourseGoalsView() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);
  const { goals } = useSelector(selectCourseGoals);
  const methods = useForm<ICourseGoals>({
    defaultValues: {
      goals,
    },
    resolver: yupResolver(courseGoalsFormValidation),
    shouldFocusError: true,
  });
  const onSubmit:
  SubmitHandler<ICourseGoals> = React.useCallback((data) => {
    dispatch(createSessionActions.AddCourseGoals(data));
  }, [dispatch]);
  return (
    <div>
      <FormProvider {...methods}>
        <CourseGoalsForm
          onSubmit={onSubmit}
          loading={loading}

        />
      </FormProvider>
    </div>
  );
}
