/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Grid } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { PrimaryOutlinedButton } from 'components/molecules';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { handleContentDataDelete } from 'utils/file-uploader';
import { Label } from '../../molecules/custom-typography';
import PrimaryTextField from '../../molecules/primary-text-field';
import FileUploader from '../file-uploader';

interface SponsorLogoUploaderProps {
  index:number,
  label:string,
  imageLabel:string,
  textFieldLabel:string,
  currentImageUrl:string,
  currentText:string,
  scenario:UploadScenarios,
  fileCategory?: string,
  handleRemove: (index:number)=>void
  handleFile: (index:number, res:UploadResponse)=> void,
  handleInputChange: (index:number, input:string)=> void,
  handleRemoveImage: (index:number) => void
}

const SponsorLogoUploader = ({
  index, label,
  imageLabel, textFieldLabel, currentImageUrl, scenario, fileCategory = 'image',
  currentText, handleInputChange, handleRemove, handleFile, handleRemoveImage,
}:SponsorLogoUploaderProps) => {
  const [imageUrl, setImageUrl] = React.useState(currentImageUrl);
  const [textInput, setTextInput] = React.useState(currentText);

  const handleRemoveWrapper = async () => {
    await handleContentDataDelete(imageUrl, scenario);
    handleRemove(index);
  };
  const handleInputChangeWrapper = (event:any) => {
    handleInputChange(index, event.target.value);
    setTextInput(event.target.value);
  };
  const handleFileWrapper = (res:UploadResponse) => {
    setImageUrl(res.url);
    handleFile(index, res);
  };
  const handleRemoveImageWrapper = async () => {
    await handleContentDataDelete(imageUrl, scenario);
    setImageUrl('');
    handleRemoveImage(index);
  };
  return (
    <div style={{
      background: '#FAFAFA 0% 0% no-repeat padding-box',
      border: '1px solid #E5E5E5',
      padding: '0.5rem',
      borderRadius: '5px',
    }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Label>
            <i>
              {label}
            </i>
          </Label>

        </Grid>
        <Grid item xs="auto" alignItems="center" sx={{ color: '#E93E3E' }}>
          <Grid container alignItems="center">
            <Grid item />
            <Close />
            <PrimaryOutlinedButton onClick={handleRemoveWrapper}>Remove </PrimaryOutlinedButton>
          </Grid>

        </Grid>
      </Grid>
      <div style={{ marginBottom: '2rem' }}>
        <div style={{ marginBottom: '1rem' }}>
          <Label>{imageLabel}</Label>
        </div>

        <Grid container justifyContent="flex-start" alignItems="center">

          <Grid item xs="auto" className="grid-item">
            <FileUploader
              currentContentUrl={imageUrl}
              handleFile={handleFileWrapper}
              handleRemove={handleRemoveImageWrapper}
              scenario={scenario}
              currentMediaType={fileCategory}
            />

          </Grid>

        </Grid>
      </div>
      <Label>{textFieldLabel}</Label>
      <PrimaryTextField value={textInput} onChange={handleInputChangeWrapper} />
    </div>
  );
};
export default SponsorLogoUploader;
