/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Typography, useTheme,
} from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const Display1 = ({ children }: Props) => (
  <label style={{ fontWeight: '700', fontSize: '80px', lineHeight: '84px' }}>{children}</label>
);
export const Display2 = ({ children }: Props) => (
  <label style={{ fontWeight: '700', fontSize: '64px', lineHeight: '68px' }}>{children}</label>
);
export const Display3 = ({ children }: Props) => (
  <label style={{ fontWeight: '700', fontSize: '48px', lineHeight: '52px' }}>{children}</label>
);

export const Title = ({ children }: Props) => (
  <label style={{ fontWeight: '500', fontSize: '32px' }}>{children}</label>
);

export const SubTitle = ({ children }: Props) => (
  <label
    style={{
      fontWeight: '500',
      fontSize: '16px',
      color: '#B2B2B2',
      opacity: '1',
      textTransform: 'uppercase',
      lineHeight: '22px',
    }}
  >
    {children}
  </label>
);

export const Link = ({ children }: Props) => (
  <label
    style={{
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '22px',
      color: '#21B8E8',
      textDecoration: 'underline',
    }}
  >
    {children}
  </label>
);

export const ErrorMessage = ({ children, style }: any) => (
  <label style={{
    fontWeight: '500', fontSize: '16px', lineHeight: '19px', color: '#E93E3E', ...style,
  }}
  >
    {children}
  </label>
);
export const HintText = ({ children }: Props) => (
  <label style={{ fontWeight: '500', fontSize: '14px', lineHeight: '19px' }}>{children}</label>
);

export const ButtonText = ({ children }: Props) => (
  <label style={{ fontWeight: '500', fontSize: '16px', lineHeight: '22px' }}>{children}</label>
);

export const Label = ({ children, htmlFor }: any) => (
  <label
    htmlFor={htmlFor}
    style={{
      fontSize: '16px', color: '#1b1b1b', fontWeight: '400', marginBottom: '10px',
    }}
  >
    {children}
  </label>
);

export const BlackLabel = ({ children }: Props) => (
  <label style={{ fontWeight: '300', fontSize: '16px', color: '#000' }}>{children}</label>
);

export const SecondaryLabel = ({ children }: Props) => {
  const theme = useTheme();
  return (
    <Typography
      variant="body2"
      component="label"
      sx={{ color: theme.palette.text.secondary }}
    >
      {children}
    </Typography>
  );
};

export const PrimaryLabel = ({ children }: Props) => {
  const theme = useTheme();
  return (
    <Typography
      variant="body2"
      component="label"
      sx={{ color: theme.palette.text.primary }}
    >
      {children}
    </Typography>
  );
};

export const LogoLabel = ({ children }: Props) => (
  <label style={{ fontWeight: '500', fontSize: '26px', color: '#FFF' }}>{children}</label>
);
