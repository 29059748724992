/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserDeleteCheckRequest, PollingRequestStatus } from 'interfaces/base-user';
import { isUserDeleted } from 'utils/data-utils';
import { Role } from 'interfaces/user-roles';
import { ExpertDocument, IUser } from '../../interface';
import {
  EditExpertState,
  IAccountTypeModalForm,
  IAchievementsModalForm,
  IBankDetailsModalForm,
  ISponsorContentModalForm,
  IGeneralInformationModalForm,
  ModalStates,
  IStatisticsModalForm,
  IBadgesModalForm,
  FetchUsersPayload,
  IUsersListResponse,
} from './interface';

export const initialState: EditExpertState = {
  stateLoading: false,
  loading: false,
  isDeleted: false,
  deleteCheckStatus: PollingRequestStatus.success,
  deleteCheckId: '',
  id: '',
  isActive: false,
  IsUserDeleting: false,
  modal: '',
  email: '',
  generalInformation: {
    firstName: '',
    lastName: '',
    email: '',
    profileImgUrl: '',
    bio: '',
    category: '',
    currency: 'AUD',
    introVideoUrl: '',
    thankVideoUrl: '',
    rateVideoUrl: '',
    categories: [],
  },
  accountType: {
    accountType: 'public',
    subscriptionFee: 0.0,
    subscriptionFrequency: 'monthly',
  },
  achievements: [{ title: '', description: '' }],
  bankDetails: {
    accountHolder: '',
    accountNumber: '',
    bankName: '',
    branchName: '',
  },
  statistics: [{ title: '', description: '' }],
  sponsorContent: [{ contentUrl: '', url: '' }],
  badges: [
    {
      badgeTitle: '',
      badgeDescription: '',
      badgeImgUrl: '',
      fromAdmin: false,
    },
  ],
  badgesFromAdmin: [
    {
      badgeTitle: '',
      badgeDescription: '',
      badgeImgUrl: '',
      fromAdmin: true,
    },
  ],
  deleteCheck: {
    roles: [],
    actions: [],
    blockers: [],
    toBeDeleted: [],
  },
  users: [],
  total: 0,
  totalFollowers: 0,
  totalSubscribers: 0,
};

export const editExpertSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/edit-expert',
  initialState,
  reducers: {
    openModal(state: EditExpertState, action: PayloadAction<string>) {
      state.modal = action.payload;
    },
    closeModal(state: EditExpertState) {
      state.modal = ModalStates.close;
    },
    getExpert(state: EditExpertState) {
      state.stateLoading = true;
    },
    getExpertSucceeded(state: EditExpertState, action: PayloadAction<ExpertDocument>) {
      const expert = action.payload;
      state.stateLoading = false;
      state.loading = false;
      state.email = expert.email;
      state.generalInformation.email = expert.email || '';
      state.generalInformation.bio = expert.bio || '';
      state.generalInformation.profileImgUrl = expert.profileImgUrl || '';
      state.generalInformation.firstName = expert.firstName || '';
      state.generalInformation.lastName = expert.lastName || '';
      state.generalInformation.currency = expert.attributes?.currency || 'AUD';
      state.isActive = expert.attributes?.isActive;
      state.accountType.accountType = expert.attributes?.accountType || '';
      state.accountType.subscriptionFee = expert.attributes?.subscriptionFee || 0.0;
      state.accountType.subscriptionFrequency = expert.attributes?.subscriptionFrequency || '';
      state.generalInformation.category = expert.attributes?.category || '';
      state.generalInformation.introVideoUrl = expert.attributes?.introVideoUrl || '';
      state.generalInformation.thankVideoUrl = expert.attributes?.thankVideoUrl || '';
      state.generalInformation.rateVideoUrl = expert.attributes?.rateVideoUrl || '';
      state.bankDetails.accountHolder = expert.attributes?.accountHolder || '';
      state.bankDetails.accountNumber = expert.attributes?.accountNumber || '';
      state.bankDetails.bankName = expert.attributes?.bankName || '';
      state.bankDetails.branchName = expert.attributes?.branchName || '';
      state.achievements = expert.attributes?.achievements || [];
      state.statistics = expert.attributes?.statistics || [];
      state.sponsorContent = expert.attributes?.sponsorContent || [];
      state.badges = expert.attributes?.badges?.filter((badge) => !badge.fromAdmin) || [];
      state.badgesFromAdmin = expert.attributes?.badges?.filter((badge) => badge.fromAdmin) || [];
      state.isDeleted = isUserDeleted(expert, Role.Expert);
      state.generalInformation.categories = expert.categories?.map((i) => i.id);
      state.users = expert.users?.map((user: IUser) => ({
        ...user,
        isFollowing: user.isFollowing,
        isSubscribed: user.isSubscribed,
        sessionPurchaseCount: user.sessionPurchaseCount,
      }
      )) || [];
    },
    getExpertFailed(state: EditExpertState) {
      state.stateLoading = false;
    },
    setId(state: EditExpertState, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    setDeleteCheckStatus(state: EditExpertState, action: PayloadAction<PollingRequestStatus>) {
      state.deleteCheckStatus = action.payload;
    },
    setDeleteCheckId(state: EditExpertState, action: PayloadAction<string>) {
      state.deleteCheckId = action.payload;
    },
    cancelPollingWork(state: EditExpertState) {},
    editGeneralInformation(
      state: EditExpertState,
      _action: PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = true;
    },
    editGeneralInformationSucceeded(
      state: EditExpertState,
      action: PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = false;
      state.generalInformation = action.payload;
      state.modal = ModalStates.close;
    },

    editGeneralInformationFailed(state: EditExpertState) {
      state.loading = false;
    },
    editAccountType(state: EditExpertState, _action: PayloadAction<IAccountTypeModalForm>) {
      state.loading = true;
    },
    editAccountTypeSucceeded(state: EditExpertState, action: PayloadAction<IAccountTypeModalForm>) {
      state.loading = false;
      state.accountType = action.payload;
      state.modal = ModalStates.close;
    },
    editAccountTypeFailed(state: EditExpertState) {
      state.loading = false;
    },
    editBankDetails(state: EditExpertState, _action: PayloadAction<IBankDetailsModalForm>) {
      state.loading = true;
    },
    editBankDetailsSucceeded(state: EditExpertState, action: PayloadAction<IBankDetailsModalForm>) {
      state.loading = false;
      state.bankDetails = action.payload;
      state.modal = ModalStates.close;
    },
    editBankDetailsFailed(state: EditExpertState) {
      state.loading = false;
    },
    editSponsorContent(state: EditExpertState, _action: PayloadAction<ISponsorContentModalForm>) {
      state.loading = true;
    },
    editSponsorContentSucceeded(
      state: EditExpertState,
      action: PayloadAction<ISponsorContentModalForm>,
    ) {
      state.sponsorContent = action.payload.sponsorContent;
      state.loading = false;
      state.modal = ModalStates.close;
    },
    editSponsorContentFailed(state: EditExpertState) {
      state.loading = false;
    },
    editAchievement(state: EditExpertState, _action: PayloadAction<IAchievementsModalForm>) {
      state.loading = true;
    },
    editAchievementSucceeded(
      state: EditExpertState,
      action: PayloadAction<IAchievementsModalForm>,
    ) {
      state.loading = false;
      state.achievements = action.payload.achievements;
      state.modal = ModalStates.close;
    },
    editAchievementFailed(state: EditExpertState) {
      state.loading = false;
    },
    editStatistic(state: EditExpertState, _action: PayloadAction<IStatisticsModalForm>) {
      state.loading = true;
    },
    editStatisticSucceeded(state: EditExpertState, action: PayloadAction<IStatisticsModalForm>) {
      state.loading = false;
      state.statistics = action.payload.statistics;
      state.modal = ModalStates.close;
    },
    editStatisticFailed(state: EditExpertState) {
      state.loading = false;
    },
    editBadge(state: EditExpertState, _action: PayloadAction<IBadgesModalForm>) {
      state.loading = true;
    },
    editBadgeSucceeded(state: EditExpertState, action: PayloadAction<IBadgesModalForm>) {
      state.loading = false;
      state.badges = action.payload.badges;
      state.modal = ModalStates.close;
    },
    editBadgeFromAdmin(state: EditExpertState, _action: PayloadAction<IBadgesModalForm>) {
      state.loading = true;
    },
    editBadgeFromAdminSucceeded(state: EditExpertState, action: PayloadAction<any>) {
      state.loading = false;
      state.badgesFromAdmin = action.payload.badgesFromAdmin;
      state.modal = ModalStates.close;
    },
    editBadgeFailed(state: EditExpertState) {
      state.loading = false;
    },
    editActive(state: EditExpertState, _action: PayloadAction<{ isActive: boolean }>) {
      state.loading = true;
    },
    editActiveSucceeded(state: EditExpertState, action: PayloadAction<boolean>) {
      state.loading = false;
      state.isActive = action.payload;
      state.modal = ModalStates.close;
    },
    editActiveFailed(state: EditExpertState) {
      state.loading = false;
    },

    deleteExpertCheck(state: EditExpertState) {
      state.deleteCheckStatus = PollingRequestStatus.pending;
    },
    deleteExpertCheckSucceeded(
      state: EditExpertState,
      action: PayloadAction<IUserDeleteCheckRequest>,
    ) {
      state.deleteCheck = action.payload;
      state.deleteCheckStatus = PollingRequestStatus.success;
    },
    deleteExpertCheckFailed(state: EditExpertState) {
      state.deleteCheckStatus = PollingRequestStatus.error;
    },
    deleteExpert(state: EditExpertState) {
      state.loading = true;
    },
    deleteExpertSucceeded(state: EditExpertState) {
      state.loading = false;
      state.modal = ModalStates.close;
    },
    deleteExpertFailed(state: EditExpertState) {
      state.loading = false;
    },
    recoverExpert(state: EditExpertState) {
      state.loading = true;
    },
    recoverExpertSucceeded(state: EditExpertState) {
      state.loading = false;
      state.modal = ModalStates.close;
      state.isDeleted = false;
    },
    recoverExpertFailed(state: EditExpertState) {
      state.loading = false;
    },
    getExpertUsers(state: EditExpertState, action: PayloadAction<FetchUsersPayload>) {
      state.loading = true;
    },
    getExpertUsersSucceeded(state: EditExpertState, action: PayloadAction<IUsersListResponse>) {
      const {
        result, total, totalFollowers, totalSubscribers,
      } = action.payload;
      state.loading = false;
      state.users = result;
      state.total = total;
      state.totalFollowers = totalFollowers ?? 0;
      state.totalSubscribers = totalSubscribers ?? 0;
    },
    getExpertUsersFailed(state: EditExpertState) {
      state.loading = false;
    },
  },
});

export const { actions: editExpertActions } = editExpertSlice;
