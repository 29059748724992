import React from 'react';
import {
  Box, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryModal } from 'components/molecules';
import './index.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
  children: React.ReactNode;
  modalClass?: string;
}

const ClosableModal = ({
  open, onClose, title = '', subTitle = '', children, modalClass,
}: Props) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <PrimaryModal open={open} onClose={onClose} modalClass={modalClass}>
      <Box className="closable-modal-width">
        <div className="inline-wrapper modal-header">
          <div className="inline-item">
            <Typography variant="h5" component="h5" sx={{ mb: 1 }} fontWeight="bold">{title}</Typography>
            <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
              {subTitle}
            </Typography>
          </div>
          <IconButton aria-label="close" onClick={handleClose} className="inline-item">
            <CloseIcon />
          </IconButton>

        </div>

        <div className="close-modal-body">
          {children}
        </div>
      </Box>
    </PrimaryModal>
  );
};

ClosableModal.defaultProps = {
  modalClass: '',
};

export default ClosableModal;
