import * as yup from 'yup';
import { EmailValidation } from 'helpers/validations';
import {
  CourseTypes, LiveClassTypes, SessionAudienceTypes, SessionType,
} from 'config';
import {
  IUserSettings,
} from '../../../../../interface';

export const baseInformationValidation = {
  sessionType: yup.string().trim().required('Session type is required'),
  audienceType: yup.string().trim().required('Session audience is a required field'),
  userSettings: yup.object().shape({
    allowMessaging: yup.boolean(),
    allowUpload: yup.boolean(),
  }),
  title: yup.string().trim().required('Session name is a required field').max(250, 'Session name cannot be more than 250 characters'),
  description: yup.string().trim().test('is-valid', 'Session description is a required field', (value) => (value?.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]+/gm, '').length !== 0)).test('length-limit', 'Session description must have upto 1000 characters', (value) => !value || value?.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]+/gm, '').length <= 1000),
  message: yup.string().trim()
    .when(['sessionType', 'courseType', 'liveClassType', 'userSettings'], {
      is: (
        sessionType:string,
        courseType:string,
        liveClassType:string,
        userSettings:IUserSettings,
      ) => !(
        sessionType === SessionType.content || (liveClassType === LiveClassTypes.streaming)
        || (sessionType === SessionType.liveClass && !userSettings.allowMessaging)
      || (courseType === CourseTypes.lsm && sessionType === SessionType.course)
      ),
      then: yup.string().trim().required('Session message is a required field'),
    }),
  language: yup.string().trim().required('Language is a required field'),
  numberOfSessions: yup.number().when('audienceType', {
    is: (audienceType:SessionAudienceTypes) => audienceType !== SessionAudienceTypes.courseOnly,
    then: yup.number().positive().min(1).max(999999)
      .required()
      .label('No of sessions')
      .test('min-booked-sessions', 'Number of sessions cannot be lower than booked sessions', function validateNumberOfSessions(value: number | undefined) {
        const { bookedSessions } = this.parent;
        const { editMode, sessionType } = this.options.context as any;
        if (value === undefined || bookedSessions === undefined
          || !editMode || sessionType === SessionType.liveClass) {
          return true;
        }
        return value >= bookedSessions;
      }),
  }),

  sessionDuration: yup.number().when('sessionType', {
    is: SessionType.liveSession,
    then: yup.number().positive().min(30)
      .typeError('Session duration is a required field')
      .required()
      .label('Session duration'),
  }),
  invitePeople: yup.array().of(yup.string().trim()).when('audienceType', {
    is: SessionAudienceTypes.private,
    then: yup.array().of(yup.string().trim()).min(1)
      .test('at-least-one-user', 'You must add at least one invite to create an invite only session', function (value) {
        const invitations = this.options.context?.invitations;
        return (value && value.length > 0) || (invitations && invitations.length > 0);
      }),
    otherwise: yup.array().of(yup.string().trim()).notRequired(),
  }),
  isObserverAllowed: yup.boolean().notRequired().when('sessionType', { is: SessionType.liveClass, then: yup.boolean().required('Allow observers is a required field') }),
  numberOfParticipants: yup.number().when('sessionType', {
    is: SessionType.liveClass,
    then: yup.number().positive().min(1).required()
      .max(250)
      .label('No of participants'),
  }),
  numberOfObservers: yup.number().when(['sessionType', 'isObserverAllowed'], {
    is: (sessionType: string, isObserverAllowed: boolean) => (sessionType === SessionType.liveClass)
      && isObserverAllowed,
    then: yup.number().positive().min(1).required()
      .max(250)
      .label('No of observers'),
  }),
};

export const inviteParticipantsValidation = {
  invitedParticipants: yup.array().of(yup.object().nullable().shape({
    firstName: yup.string().trim().max(40).required('Name'),
    email: EmailValidation(),
  })),
};

export const sessionMetaValidation = {
  sessionMeta: yup.object().nullable().when('sessionType', {
    is: SessionType.liveClass,
    then: yup.object().shape({ liveClassType: yup.string().label('Live class type') }).label('Live class type'),
  }),
};
export const mediaValidation = {
  media: yup
    .array()
    .of(yup.object())
    .when('sessionType', {
      is: SessionType.content,
      then: yup.array().of(yup.object()).min(1).label('Session content'),
    }),
};

export const formValidation = {
  form: yup.array().notRequired().when('sessionType', {
    is: (SessionType.digitalSession || SessionType.liveClass
       || SessionType.liveSession),
    then: yup.array().of(yup.string().trim().notRequired()),
  }),
};
export const waitingRoomMediaValidation = {
  waitingRoomMedia: yup.object().nullable().when('sessionType', {
    is: SessionType.liveSession || SessionType.liveClass,
    then: yup
      .object()
      .shape({
        fileName: yup.string().label('Waiting room media file name'),
        thumbnailUrl: yup.string(),
        contentUrl: yup.string().label('Waiting room media url'),
        fileType: yup.string().label('Waiting room media type'),
      })
      .label('Waiting room media'),
  }),
};

export const badgeValidation = {
  badge: yup.object().nullable().notRequired().when('sessionType', {
    is: SessionType.course,
    then: yup.object().shape({
      title: yup.string().trim(),
      description: yup.string().trim(),
      url: yup.string().trim(),
    }),
  }),
};

const sessionInformationFormValidation = yup.object().shape({
  ...baseInformationValidation,
  ...inviteParticipantsValidation,
  ...formValidation,
  ...mediaValidation,
  ...waitingRoomMediaValidation,
  ...badgeValidation,
  ...sessionMetaValidation,
});
export default sessionInformationFormValidation;
