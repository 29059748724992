/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { Close } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { SquareMenuIcon } from 'assets/icons';
import { ErrorMessage, PrimaryFilledTextField, PrimaryOutlinedButton } from 'components';
import { IInvitedParticipants } from '../../../../../../interface';

interface ParticipantProps {
  handleChange:(index:number, participant:IInvitedParticipants) =>void
  index:number,
  removeParticipant:(index:number)=>void
  errors: any,
  participantInit:IInvitedParticipants
}

export default function InvitedParticipants({
  index, handleChange, removeParticipant, errors, participantInit,
}:ParticipantProps) {
  const [participant,
    setParticipant] = React.useState<IInvitedParticipants>(
    participantInit,
  );
  // handle participant name
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setParticipant({
    ...participant,
    firstName: event.target.value,
  });
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => setParticipant({
    ...participant,
    email: event.target.value,
  });

  // handle badges
  const handleRemoveWrapper = () => {
    removeParticipant(index);
  };

  React.useEffect(() => {
    handleChange(index, participant);
  }, [participant]);

  return (
    <div style={{
      background: '#FFF',
      border: '1px solid #E5E5E5',
      padding: '0 2rem',
      borderRadius: '5px',
    }}
    >
      <SquareMenuIcon sx={{ marginLeft: '-2rem' }} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">
            Participant #
            {index + 1}
          </Typography>

        </Grid>
        <Grid item xs="auto" alignItems="center" sx={{ color: '#E93E3E' }}>
          <Grid container alignItems="center">
            <Close />
            <PrimaryOutlinedButton onClick={handleRemoveWrapper}>Remove </PrimaryOutlinedButton>
          </Grid>

        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs className="form-item">
          <PrimaryFilledTextField placeholder="Name" value={participant.firstName} onChange={handleName} />
          <ErrorMessage>
            {errors?.invitedParticipants ? errors?.invitedParticipants[index]?.firstName?.message : ''}

          </ErrorMessage>
        </Grid>
        <Grid item xs className="form-item">
          <PrimaryFilledTextField placeholder="Email" value={participant.email} onChange={handleEmail} />
          <ErrorMessage>
            {errors?.invitedParticipants ? errors?.invitedParticipants[index]?.email?.message : ''}

          </ErrorMessage>
        </Grid>
      </Grid>

    </div>
  );
}
