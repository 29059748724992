import { Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import React from 'react';
import { PrimaryTable } from 'components';
import { ITopPerformingSessions } from '../../interface';

interface TopPerformingSessionsProps {
  sessions:ITopPerformingSessions[],
  loading:boolean,
}

const TopPerformingSessions = ({ sessions, loading }:TopPerformingSessionsProps) => {
  type Row = typeof sessions[number];
  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'meta',
        headerName: 'Session',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography variant="h4">
              {(params.row?.meta?.title)}
              {' '}
            </Typography>
          </div>
        ),
      },
      {
        field: 'expert',
        headerName: 'Expert',
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
      },
      // {
      //   field: 'views',
      //   headerName: 'Views',
      //   headerAlign: 'center',
      //   align: 'right',
      //   flex: 0.5,
      // },
      {
        field: 'purchases',
        headerName: 'Purchases',
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
      },
    ],
    [],
  );
  return (
    <>
      <Typography variant="h2" sx={{ m: '1rem 0' }}>Top Performing sessions</Typography>
      <div className="overview-table">
        <PrimaryTable
          loading={loading}
          columns={columns}
          rows={sessions}
          hideFooter
          getRowId={(row) => JSON.stringify(row.purchases * 100 + Math.random())}
        />
      </div>

    </>

  );
};

export default TopPerformingSessions;
