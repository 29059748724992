const API = {
  GET_GROUP_BY_ENT: {
    path: '/group/search',
    method: 'GET',
  },
  DELETE_GROUP: {
    path: '/group/delete',
    method: 'POST',
  },
  POST_UPDATE_GROUP: {
    path: '/group/update',
    method: 'POST',
  },

};

export default API;
