import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ENVIRONMENT from 'config/environment';
import { IOnboardGeneralInformationModalForm } from '../../../../../interface';
import { selectGeneralInformationForm } from '../../../../../selector';
import { onboardEntAccActions } from '../../../../../slice';
import OnboardGeneralInformationModalForm from '../general-information-form';
import onboardGeneralInformationFormValidation from '../validation/general-information-form-validation';

const OnboardGeneralInformationModalView = () => {
  const dispatch = useDispatch();
  const generalInformationForm = useSelector(selectGeneralInformationForm);
  const methods = useForm<IOnboardGeneralInformationModalForm>({
    defaultValues: {
      name: generalInformationForm.name,
      entUrl: generalInformationForm.entUrl + ENVIRONMENT.USER_HUB_DOMAIN,
      email: generalInformationForm.email,
      currency: generalInformationForm.currency,
      country: generalInformationForm.country,
      city: generalInformationForm.city,
      facebookUrl: generalInformationForm.facebookUrl,
      instagramUrl: generalInformationForm.instagramUrl,
      linkedinUrl: generalInformationForm.linkedinUrl,
      youtubeUrl: generalInformationForm.youtubeUrl,
      twitterUrl: generalInformationForm.twitterUrl,
      webLogoUrl: generalInformationForm.webLogoUrl,
      entLogoUrl: generalInformationForm.entLogoUrl,
      categories: generalInformationForm.categories,
      skills: generalInformationForm.skills,
    },
    resolver: yupResolver(onboardGeneralInformationFormValidation),
    shouldFocusError: true,
  });
  const onSubmit: SubmitHandler<IOnboardGeneralInformationModalForm> = useCallback(
    (data) => {
      dispatch(onboardEntAccActions.AddGeneralInformation(data));
      dispatch(onboardEntAccActions.editGeneralInformation(data));
    },
    [dispatch],
  );
  return (
    <Grid container justifyContent="center">
      <Grid xs item>
        <FormProvider {...methods}>
          <OnboardGeneralInformationModalForm onSubmit={onSubmit} />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default OnboardGeneralInformationModalView;
