const API = {
  GET_SESSIONS_BY_EXPERT: {
    path: '/session/search',
    method: 'GET',
  },
  DELETE_SESSION: {
    path: '/session/delete',
    method: 'DELETE',
  },
  GET_PREQUISITE_SEARCH: {
    path: '/session/course-search',
    method: 'GET',
  },
};
export default API;
