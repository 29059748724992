/* eslint-disable no-empty */
/* eslint-disable no-await-in-loop */
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import moment from 'moment';
import Countries from 'data/countries-cities.json';
import { IOption } from 'interfaces';
import { IPurchasedSession, IRecording } from 'pages/dashboard/components/ent-account/components/edit-account/components/users/interface';
import { IBaseUserDocument } from 'interfaces/base-user';
import { Role } from 'interfaces/user-roles';
import { CurrencySymbols } from 'config';
/**
 * @param  {any} e The options in enum
 * @returns IOption
 */
export function getOptionsFromEnum(e: any): IOption[] {
  const values = Object.values(e);
  const options: IOption[] = [];
  values.forEach((value, index) => {
    options.push({ id: index + 1, value: value as string, text: (value as string).replace(/^./, (value as string)[0].toUpperCase()) });
  });
  return options;
}

/**
 * returns all the countries
 */
export function getCountries(): string[] {
  return Object.keys(Countries);
}

/**
 * @param  {string} country Country which the cities are required
 */
export function getCitiesForCountry(country: string) {
  return Array.from(new Set(Object.values(Countries[country as keyof typeof Countries])));
}

/**
 * return the SLUG of the text
 */
export function slugify(...args: (string | number)[]): string {
  const value = args.join(' ');

  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-'); // separator
}

export const uniqueIdGenerate = () => uuidv4();

export const replaceExt = (url: string, ext: string) => `${url.substring(0, url.lastIndexOf('.'))}${ext}`;

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

// eslint-disable-next-line consistent-return
export const isStreamableVideoAvailable = async (url: string): Promise<any> => {
  for (let index = 0; index < 60; index += 1) {
    try {
      await sleep(5000);
      const streamUrl = replaceExt(url, '.m3u8');
      return await axios.head(streamUrl);
    } catch (error) {
    }
  }
};

export const getCurrencySymbol = (currency: string | undefined) => CurrencySymbols[(currency ?? 'USD') as keyof typeof CurrencySymbols];

export const enumerateDaysBetweenDates = async (
  startDate: moment.MomentInput,
  endDate: moment.MomentInput,
): Promise<string[]> => {
  const dates = [];
  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');
  for (let m = moment(currDate); m.isSameOrBefore(lastDate); m.add(1, 'days')) {
    dates.push(m.toISOString());
  }
  return dates;
};

export const getDateWithTime = (time: string, date: string) => {
  // split the expert avalible time and append the avalible time
  // to the date string to get the date with the time

  // example
  // if the day is 2021-01-02 and the given time time is 9
  // this will return 2021-01-02:9.00

  const t = time.split(':').map((item) => parseInt(item, 10));
  const m = moment(date);
  m.set({
    hour: t[0],
    minute: t[1],
    second: t[2],
    millisecond: 0,
  });
  return m.toISOString();
};

export const getTimeStops = (start: string, end: string, min: number): any[] => {
  // generate the time slots based on the given time in minutes
  // by using the startTime and the endTime generate the time slots
  const startTime = moment(start);
  const endTime = moment(end);

  const timeStops: any = [];

  if (endTime.isBefore(startTime)) {
    return timeStops;
  }

  while (startTime < endTime) {
    timeStops.push({
      start: startTime.toDate().toISOString(),
      end: startTime.add(min, 'minutes').toDate().toISOString(),
    });
  }
  return timeStops;
};

export const getRecordingsFromSessionPurchased = (sessionPurchased:
IPurchasedSession) => sessionPurchased?.userSessions?.reduce((acc: IRecording[], session) => {
  acc.push(
    ...session.recordings.map((recording) => (
      { path: recording.path, startAt: session.startAt })),
  );
  return acc;
}, []);

export const isNumeric = (val: string): boolean => /^-?\d+$/.test(val);

export const convertHexToOpacity = (hex: string, opacity: number): string => {
  const hexValue = hex.replace('#', '');
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return `rgb(${r} ${g} ${b} /${opacity}%)`;
};

export const formattedDate = (date: string): string => moment(date).format('YYYY/M/D');

interface Interface {
  [key: string]: string | number | Interface | Array<string | number | Interface>;
}

export const initializeState = (i: Interface): any => {
  if (typeof i === 'string') {
    return '';
  } if (typeof i === 'number') {
    return 0;
  } if (Array.isArray(i)) {
    return [];
  } if (typeof i === 'object') {
    const state: any = {};
    Object.keys(i).forEach((key) => {
      state[key] = initializeState(i[key] as Interface);
    });
    return state;
  }
  return null; // Handle unsupported i types
};

export const getCalendarConfig = async (
  session: any,
  slot: any,
  entName: string,
  entId:string,
  expertId:string,
) => {
  const baseUrl = window.location.origin.toString();
  const config: any = {
    title: `${entName}: ${session.title}`,
    location: '',
    description: `For full details or to join the session, visit below link.<br><a href="${baseUrl}/ent-account/edit/${entId}/experts/edit-expert/${expertId}/sessions/${session.id}">Click here</a>.`,
    start: new Date(),
    end: new Date(),
  };
  config.start = moment(slot.from).toDate();
  config.end = moment(slot.to).toDate();

  return config;
};

export const isUserDeleted = (user: IBaseUserDocument, role: Role) => {
  const foundRole = user.roles?.find((rol) => rol.role === role);
  return !!(foundRole?.isDeleting || (foundRole?.deletedAt));
};

export const truncateString = (text: string, num: number) => {
  if (text.length > num) {
    return `${text.slice(0, num)}...`;
  }
  return text;
};

export const doesPathMatch = (pathname: string, patterns: string[]) => {
  const isValid = patterns.some((component) => {
    const pathSegments = pathname.split('/');
    const patternSegments = component.split('/');

    if (pathSegments.length !== patternSegments.length) {
      return false;
    }

    return patternSegments.every(
      (segment, index) => segment === '*' || segment === pathSegments[index],
    );
  });

  return isValid;
};
