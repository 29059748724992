import React from 'react';
import {
  Modal, Fade, Box, Backdrop,
} from '@mui/material';

import './styles.scss';

interface Props {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  open: boolean;
  onClose: () => void;
  modalClass?: string;
}

const PrimaryModal = ({
  children, open, onClose, modalClass,
} : Props) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style} className={`custom-modal-body ${modalClass}`}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

PrimaryModal.defaultProps = {
  modalClass: '',
};

export default PrimaryModal;
