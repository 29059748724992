/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { convertHexToOpacity } from 'utils/data-utils';
import './index.scss';

interface StatusTagProps {
  status: string;
  success?: boolean;
  color?: string | undefined;
}
const StatusTag = ({

  status, success = false, color,
}: StatusTagProps) => {
  const [colorV, setColorV] = React.useState(color || (success ? '#54c115' : '#e93e3e'));
  const [backgroundColorV, setBackgroundColorV] = React.useState(convertHexToOpacity(colorV, 20));

  React.useEffect(() => {
    setBackgroundColorV(convertHexToOpacity(colorV, 20));
  }, [colorV]);

  React.useEffect(() => {
    if (color) {
      setColorV(color);
    }
  }, [color]);

  React.useEffect(() => {
    setColorV(color || (success ? '#63c32d' : '#e93e3e'));
  }, [success, color]);

  return (
    <div
      className="status-tag"
      style={{
        color: colorV,
        backgroundColor: backgroundColorV,
        padding: '3px 8px',
        fontSize: '12px',
      }}
    >
      {status}
    </div>
  );
};

export default StatusTag;
