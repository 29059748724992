const API = {
  POST_CREATE_ADMIN: {
    path: '/ent-admin/create',
    method: 'POST',
  },
  POST_UPDATE_ADMIN: {
    path: '/user/update',
    method: 'POST',
  },
  PATCH_PERMISSIONS_ADMIN: {
    path: '/user/permissions',
    method: 'PATCH',
  },
  RESET_PASSWORD: {
    path: '/user/force-reset',
    method: 'POST',
  },
  GET_ALL_ADMINS: {
    path: '/ent-admin/all',
    method: 'GET',
  },
  GET_ADMIN: {
    path: '/ent-admin/details',
    method: 'GET',
  },
  GET_ADMIN_BY_ENT: {
    path: '/ent-admin/details/ent',
    method: 'GET',
  },
  DELETE_ADMIN_CHECK: {
    path: '/user-delete/steps',
    method: 'POST',
  },
  DELETE_ADMIN_CHECK_STATUS: {
    path: '/user-delete/status',
    method: 'POST',
  },
  DELETE_ADMIN: {
    path: '/user-delete',
    method: 'DELETE',
  },
  RECOVER_ADMIN: {
    path: '/account-recover',
    method: 'POST',
  },
};

export default API;
