import { IFiltered } from 'interfaces/api-response';
import { ISCAdmin, EntAccountDocument, SCAdminDocument } from '../../interface';

export enum ModalStates {
  close = 'CLOSE',
  active = 'ACTIVE',
  delete = 'DELETE',
  favorite = 'FAVORITE',
}
export interface IEntAccount extends EntAccountDocument {
  modal:ModalStates,
}
export interface ViewEntAccountState {
  entAccountsLoading:boolean,
  scAdminsLoading:boolean,
  loading:boolean,
  totalActive:number,
  totalInactive:number,
  entAccounts: IFiltered<IEntAccount>,
  scAdmins:ISCAdmin[]

}
export interface IEntAccountRequest {
  total:number,
  active:number,
  inactive:number,
  result: EntAccountDocument[]
}
export interface ISCAdminRequest {
  scAdmins:SCAdminDocument[]
}
export interface IDeleteEntAcc {
  id:string, email:string
}

export interface IActivateEntAcc {
  id:string, active :boolean
}
export interface IPinEntAcc {
  id:string, isDefault :boolean
}
export interface IOpenModal {
  id:string,
  state:ModalStates
}

export interface IEntAccountFilterPayload {
  keyword: string;
  status:string,
  scAdmin:string,
  page: number;
  limit: number;
}
