import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { SessionAudienceTypes } from 'config';
import SessionFrequencyForm from '../frequency-form';
import sessionFrequencyFormValidation from '../../validation/frequency-form-vlaidation';
import {
  selectCourseOnlySessionMeta,
  selectLiveClassFrequency, selectLoader, selectSessionFee, selectSessionInformation,
} from '../../../../selector';
import { CourseOnlySessionStatus, ILiveClassFrequencyForm } from '../../../../interface';
import { createSessionActions } from '../../../../slice';

interface SessionFrequencyViewProps {
  editMode:boolean
}

export default function SessionFrequencyView({ editMode }: SessionFrequencyViewProps) {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);
  const frequency = useSelector(selectLiveClassFrequency);
  const { audienceType } = useSelector(selectSessionInformation);
  const { status } = useSelector(selectCourseOnlySessionMeta);
  const sessionFee = useSelector(selectSessionFee);
  const methods = useForm<ILiveClassFrequencyForm>({
    defaultValues: {
      startAt: frequency.startAt,
      startDate: frequency.startDate,
      endAt: frequency.endAt,
      endDate: frequency.endDate,
      frequency: frequency.frequency,
      dayOfTheMonth: frequency.dayOfTheMonth,
      dayOfTheWeek: frequency.dayOfTheWeek,
    },
    resolver: yupResolver(sessionFrequencyFormValidation),
    shouldFocusError: true,
  });

  const onSubmit:
  SubmitHandler<ILiveClassFrequencyForm> = React.useCallback((data) => {
    dispatch(createSessionActions.AddLiveClassFrequency(data));
    if (audienceType === SessionAudienceTypes.courseOnly) {
      if (status === CourseOnlySessionStatus.editing || editMode) {
        dispatch(createSessionActions.editSession(sessionFee));
      } else {
        dispatch(createSessionActions.createSession(sessionFee));
      }
    }
  }, [dispatch]);
  return (
    <div>
      <FormProvider {...methods}>
        <SessionFrequencyForm
          onSubmit={onSubmit}
          loading={loading}

        />
      </FormProvider>
    </div>
  );
}
