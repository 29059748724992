import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IOnboardContentModalForm } from '../../../../../interface';
import { selectContentForm } from '../../../../../selector';
import OnboardContentModalForm from '../content-form';
import { onboardEntAccActions } from '../../../../../slice';
import onboardContentModelFormValidation from '../validation/content-form-validation';

const OnboardContentModelView = () => {
  const dispatch = useDispatch();
  const contentForm = useSelector(selectContentForm);
  const methods = useForm<IOnboardContentModalForm>({
    defaultValues: {
      landingPageContent: contentForm.landingPageContent,
      landingPageSections: contentForm.landingPageSections,
      groupLandingPageContent: contentForm.groupLandingPageContent,
      expertLandingPageContent: contentForm.expertLandingPageContent,
      articleLandingPageContent: contentForm.articleLandingPageContent,
      sessionLandingPageContent: contentForm.sessionLandingPageContent,
      contentLandingPageContent: contentForm.contentLandingPageContent,
    },
    resolver: yupResolver(onboardContentModelFormValidation),
    shouldFocusError: true,
  });

  const onSubmit: SubmitHandler<IOnboardContentModalForm> = useCallback(
    (data) => {
      dispatch(onboardEntAccActions.AddContent(data));
      dispatch(onboardEntAccActions.editContents(data));
    },
    [dispatch],
  );
  return (
    <Grid container justifyContent="center">
      <Grid xs item>
        <FormProvider {...methods}>
          <OnboardContentModalForm onSubmit={onSubmit} loading={false} />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default OnboardContentModelView;
