/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltered } from 'interfaces/api-response';
import {
  ViewFormsState, ISaveFormModalForm, ModalStates,
  IFormFilterPayload, IFormDocument, IDeleteForm, IOpenModal, FormTypes, IForm,
} from './interface';

export const initialState: ViewFormsState = {
  filteredForms: { result: [], total: 0 },
  loading: false,
  status: 0,
  formSettings: {
    formTitle: '',
    formDescription: '',
    fileName: '',
    scormFileUrl: '',
    availability: '',
    formType: FormTypes.INTERACTIVE_FORM,
    displayMarksUntilMarkingComplete: true,
    passMark: 0,
    badgeImgUrl: '',
    badgeDescription: '',
    badgeTitle: '',
    marksEnabled: true,
  },
  stateLoading: false,
  formId: '',
  formConfig: '',
  answerData: '',
  modal: ModalStates.close,
  initiatedBy: '',
  formConfigModal: ModalStates.close,
};

export const viewFormsSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/edit-expert/view-forms',
  initialState,
  reducers: {
    getFilteredForms(state: ViewFormsState, _action: PayloadAction<IFormFilterPayload>) {
      state.stateLoading = true;
    },
    getFilteredFormsSucceeded(state: ViewFormsState, action: PayloadAction<IFiltered<IForm>>) {
      state.filteredForms = action.payload;
      state.stateLoading = false;
    },
    getFilteredFormsFailed(state: ViewFormsState) {
      state.stateLoading = false;
    },
    setFormId(state: ViewFormsState, action: PayloadAction<string>) {
      state.formId = action.payload;
    },
    getForm(state: ViewFormsState) {
      state.stateLoading = true;
    },
    getFormSucceeded(state: ViewFormsState, action: PayloadAction<IFormDocument>) {
      const form = action.payload;
      state.formSettings = {
        availability: form.availability,
        formTitle: form.formTitle,
        formDescription: form.formDescription,
        fileName: form?.fileName || '',
        scormFileUrl: form?.scormFileUrl || '',
        formType: form.formType || FormTypes.INTERACTIVE_FORM,
        marksEnabled: form.marksEnabled,
        displayMarksUntilMarkingComplete: form.displayMarksUntilMarkingComplete,
        passMark: form.passMark,
        badgeImgUrl: form.badgeImgUrl,
        badgeDescription: form.badgeDescription,
        badgeTitle: form.badgeTitle,
      };
      state.formConfig = form.formConfig;
      state.answerData = form.answerData;
      state.stateLoading = false;
    },
    getFormFailed(state: ViewFormsState) {
      state.stateLoading = false;
    },
    openConfirmationModal(state:ViewFormsState, action:PayloadAction<IOpenModal>) {
      const temp = state.filteredForms.result;
      temp.forEach((form) => {
        if (form._id === action.payload.id) {
          form.modalState = action.payload.state;
        }
      });
      state.filteredForms.result = [...temp];
    },
    closeConfirmationModal(state:ViewFormsState, action:PayloadAction<string>) {
      const temp = state.filteredForms.result;
      temp.forEach((form) => {
        if (form._id === action.payload) {
          form.modalState = ModalStates.close;
        }
      });
      state.filteredForms.result = [...temp];
    },

    openModal(state: ViewFormsState) {
      state.modal = ModalStates.open;
    },
    closeModal(state: ViewFormsState) {
      state.modal = ModalStates.close;
    },
    setFormSetting(state: ViewFormsState, action: PayloadAction<ISaveFormModalForm>) {
      state.formSettings = action.payload;
      state.status = 1;
      state.modal = ModalStates.close;
    },
    setAnswerData(state: ViewFormsState, action: PayloadAction<string>) {
      state.answerData = action.payload;
    },
    setFormConfig(state: ViewFormsState, action: PayloadAction<string>) {
      state.formConfig = action.payload;
    },
    setInitiatedBy(state:ViewFormsState, action:PayloadAction<string>) {
      state.initiatedBy = action.payload;
    },
    createForm(state: ViewFormsState) {
      state.loading = true;
    },
    createFormSucceeded(state: ViewFormsState) {
      state.loading = true;
      state.formSettings = initialState.formSettings;
      if (state.initiatedBy === 'session') {
        state.modal = ModalStates.successful;
      } else {
        window.history.back();
      }
    },
    createFormFailed(state: ViewFormsState) {
      state.loading = false;
    },
    saveScormForm(state: ViewFormsState, _action: PayloadAction<ISaveFormModalForm>) {
      state.loading = true;
    },
    saveScormFormSucceeded(state: ViewFormsState) {
      state.loading = false;
    },
    saveScormFormFailed(state: ViewFormsState) {
      state.loading = false;
    },
    editForm(state: ViewFormsState) {
      state.loading = true;
    },
    editFormSucceeded(state: ViewFormsState) {
      state.loading = true;
      state.formSettings = initialState.formSettings;
      window.history.back();
    },
    editFormFailed(state: ViewFormsState) {
      state.loading = false;
    },
    deleteForm(state:ViewFormsState, _action:PayloadAction<IDeleteForm>) {
      state.loading = true;
    },
    deleteFormSucceeded(state:ViewFormsState, action:PayloadAction<string>) {
      const id = action.payload;
      const forms = state.filteredForms.result.filter((form) => (form._id !== id));
      state.modal = ModalStates.close;
      state.filteredForms.result = [...forms];
      state.loading = false;
    },
    deleteFormFailed(state:ViewFormsState) {
      state.loading = false;
    },
    closeModalConfirmation(state:ViewFormsState, action:PayloadAction<string>) {
      const temp = state.filteredForms.result;
      temp.forEach((form) => {
        if (form._id === action.payload) {
          form.modalState = ModalStates.close;
        }
      });
      state.filteredForms.result = [...temp];
    },
    reset(state: ViewFormsState) {
      state.formSettings = initialState.formSettings;
      state.loading = false;
      state.formId = initialState.formId;
      state.status = initialState.status;
      state.formConfig = initialState.formConfig;
    },
    openFormConfigModal(state: ViewFormsState) {
      state.formConfigModal = ModalStates.open;
    },
    closeFormConfigModal(state: ViewFormsState) {
      state.formConfigModal = ModalStates.close;
    },
  },
});

export const { actions: viewFormsActions } = viewFormsSlice;
