const API = {
  POST_CREATE_SESSION: {
    path: '/session/create',
    method: 'POST',
  },
  POST_UPDATE_SESSION: {
    path: '/session/update',
    method: 'POST',
  },
  POST_RESEND_INVITE: {
    path: '/invitations/resend',
    method: 'POST',
  },
  GET_SESSIONS_BY_ID: {
    path: '/session/details',
    method: 'GET',
  },
  GET_GROUPS_BY_EXPERT: {
    path: '/group/details/expert',
    method: 'GET',
  },
  GET_CATEGORIES: {
    path: '/categories',
    method: 'GET',
  },
  POST_ASSIGN_CATEGORIES: {
    path: '/categories/assign',
    method: 'POST',
  },
};
export default API;
