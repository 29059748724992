/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/edit-expert'] || initialState;

export const selectEditExpert = createSelector([selectDomain], (EditExpertState) => EditExpertState);
export const selectStateLoader = createSelector([selectDomain], (EditExpertState) => EditExpertState.stateLoading);
export const selectDeleteCheckStatus = createSelector([selectDomain], (EditExpertState) => EditExpertState.deleteCheckStatus);
export const selectDeleteCheckId = createSelector([selectDomain], (EditExpertState) => EditExpertState.deleteCheckId);
export const selectLoader = createSelector([selectDomain], (EditExpertState) => EditExpertState.loading);
export const selectId = createSelector([selectDomain], (EditExpertState) => EditExpertState.id);
export const selectActive = createSelector([selectDomain], (EditExpertState) => EditExpertState.isActive);
export const selectModalState = createSelector([selectDomain], (EditExpertState) => EditExpertState.modal);
export const selectEmail = createSelector([selectDomain], (EditExpertState) => EditExpertState.email);
export const selectGeneralInformation = createSelector([selectDomain], (EditExpertState) => EditExpertState.generalInformation);
export const selectAccountType = createSelector([selectDomain], (EditExpertState) => EditExpertState.accountType);
export const selectBankDetails = createSelector([selectDomain], (EditExpertState) => EditExpertState.bankDetails);
export const selectSponsorContent = createSelector([selectDomain], (EditExpertState) => EditExpertState.sponsorContent);
export const selectAchievements = createSelector([selectDomain], (EditExpertState) => EditExpertState.achievements);
export const selectStatistics = createSelector([selectDomain], (EditExpertState) => EditExpertState.statistics);
export const selectBadges = createSelector([selectDomain], (EditExpertState) => EditExpertState.badges);
export const selectBadgesFromAdmin = createSelector([selectDomain], (EditExpertState) => EditExpertState.badgesFromAdmin);
export const selectDeleteCheck = createSelector([selectDomain], (EditExpertState) => EditExpertState.deleteCheck);
export const selectIsDeleted = createSelector([selectDomain], (EditExpertState) => EditExpertState.isDeleted);
export const selectExpertUsers = createSelector([selectDomain], (EditExpertState) => EditExpertState.users);
export const selectExpertUsersTotal = createSelector([selectDomain], (EditExpertState) => EditExpertState.total);
export const selectExpertUsersFollowersCount = createSelector([selectDomain], (EditExpertState) => EditExpertState.totalFollowers);
export const selectExpertUsersSubscribersCount = createSelector([selectDomain], (EditExpertState) => EditExpertState.totalSubscribers);
