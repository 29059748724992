/* eslint-disable import/no-cycle */
import { IFiltered } from 'interfaces/api-response';
import { ExpertDocument } from '../../interface';

export enum ModalStates {
  close = 'CLOSE',
  resetPassword = 'RESET_PASSWORD',
}
export interface IExpert extends ExpertDocument {
  modalState?:string,
  isDeleted?:boolean,
}
export interface ViewExpertState {
  stateLoading:boolean,
  loading:boolean,
  experts: IExpert[],
  entId:string,
  filteredExperts:IFiltered<IExpert>
}

export interface IExpertRequest {
  experts:ExpertDocument[]
}

export interface IOpenModal {
  id:string,
  state:ModalStates
}

export interface IResetPasswordAction {
  payload:string,
  type:string,
}

export interface IPaginateFilterPayload {
  keyword: string;
  page: number;
  limit: number;
  status:string,
  category:string,
  withoutDeleted?:boolean,
  visibility?:boolean,
}
