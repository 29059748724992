import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { currencyFormat } from 'utils/formats';
import { viewSessionActions } from './slice';
import API from './constant';
import { selectInvitePeople, selectSession, selectSessionId } from './selector';
import {
  CancelSession, IActive, IGenerateSession, ISession,
} from './interface';
import { selectGeneralInformation } from '../../../../selector';
import { IDeleteSession, IFilterStateAction } from '../view-sessions/interface';
import { selectEntId, selectExpertId } from '../view-sessions/selector';

export function* getSessionGenerator(): any {
  try {
    const id = yield select(selectSessionId);
    const { currency } = yield select(selectGeneralInformation);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_SESSIONS_BY_ID.path}/${id}`, method: API.GET_SESSIONS_BY_ID.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      const preparedCurrency = currency ?? 'AUD';
      const fee = yield call(
        currencyFormat,
        response.data.fee,
        response.data.currency ?? 'AUD',
        preparedCurrency,
      );
      const observerFee = yield call(
        currencyFormat,
        response.data.observerFee,
        response.data.observerCurrency ?? 'AUD',
        preparedCurrency,
      );
      const subscriberFee = yield call(
        currencyFormat,
        response.data.subscriberFee,
        response.data.subscriberCurrency ?? 'AUD',
        preparedCurrency,
      );
      const subscribeObserverFee = yield call(
        currencyFormat,
        response.data.subscribeObserverFee,
        response.data.subscribeObserverCurrency ?? 'AUD',
        preparedCurrency,
      );

      const formattedData = yield {
        ...response.data,
        fee,
        observerFee,
        subscriberFee,
        subscribeObserverFee,
      };
      yield put(viewSessionActions.getSessionsSucceeded(formattedData));
    } else {
      yield put(viewSessionActions.getSessionsFailed());
    }
  } catch (error) {
    yield put(viewSessionActions.getSessionsFailed());
  }
}
export function* getFilteredSessionsGenerator({ payload }: IFilterStateAction): any {
  try {
    const id = yield select(selectExpertId);
    const entId = yield select(selectEntId);
    if (id !== '' && entId !== '') {
      const response: IRestApiResponse = yield call(
        request,
        {
          path: `${API.GET_SESSIONS_BY_EXPERT.path}/${entId}/${id}?keyword=${
            payload?.keyword || ''
          }&page=${payload?.page || ''}&publicOnly=${payload.publicOnly || ''}&limit=${
            payload?.limit || ''
          }&type=${payload?.type || 'All'}&status=${payload?.status || 'All'}&sortBy=${
            payload?.sortBy || ''
          }&withoutDeleted=${payload.withoutDeleted || 'true'}`,
          method: API.GET_SESSIONS_BY_EXPERT.method,
        },
        null,
        true,
      );
      if (response.statusCode === 200) {
        yield put(viewSessionActions.getFilteredSessionsSucceeded(response.data));
      } else {
        yield put(viewSessionActions.getFilteredSessionsFailed());
      }
    } else {
      throw Error('Invalid entId or expertId');
    }
  } catch (error) {
    yield put(viewSessionActions.getFilteredSessionsFailed());
  }
}
export function* getSessionUrlGenerator({ payload }: PayloadAction<IGenerateSession>) {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_SESSION_URL,
      { ...payload },
      true,
      false,
    );

    if (response.statusCode === 201) {
      yield put(viewSessionActions.getSessionUrlSucceeded(response.data));
    } else {
      yield put(viewSessionActions.getSessionUrlFailed());
    }
  } catch (error) {
    yield put(viewSessionActions.getSessionUrlFailed());
  }
}
export function* inviteUserGenerator() {
  try {
    const id: string = yield select(selectSessionId);
    const invitePeople: string[] = yield select(selectInvitePeople);
    const sessionDetails = (yield select(selectSession)) as ISession;
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_SESSION,
      { invitePeople, audienceType: sessionDetails.audienceType, id },
      true,
      false,
    );

    if (response.statusCode === 201) {
      yield put(viewSessionActions.inviteUserSucceeded());
    } else {
      yield put(viewSessionActions.inviteUserFailed());
    }
  } catch (error) {
    yield put(viewSessionActions.inviteUserFailed());
  }
}
export function* resendInviteGenerator({ payload }: PayloadAction<string>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_RESEND_INVITE,
      { id: payload },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewSessionActions.resendInviteSucceeded());
    } else {
      yield put(viewSessionActions.resendInviteFailed());
    }
  } catch (error) {
    yield put(viewSessionActions.resendInviteFailed());
  }
}
export function* editActiveGenerator({ payload }: PayloadAction<IActive>): any {
  try {
    const id: string = yield select(selectSessionId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_SESSION,
      { isActive: payload.isActive, id },
      true,
      false,
    );

    if (response.statusCode === 201) {
      yield put(viewSessionActions.editActiveSessionSucceeded());
    } else {
      yield put(viewSessionActions.editActiveSessionFailed());
    }
  } catch (error) {
    yield put(viewSessionActions.editActiveSessionFailed());
  }
}
export function* deleteSessionGenerator({ payload }: PayloadAction<IDeleteSession>): any {
  try {
    const { id, reasonForDeletion, linkedSessionId } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_SESSION,
      { id, reasonForDeletion, linkedSessionId },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewSessionActions.deleteSessionSucceeded());
      yield put(viewSessionActions.getSession());
    } else {
      yield put(viewSessionActions.deleteSessionFailed());
    }
  } catch (error) {
    yield put(viewSessionActions.deleteSessionFailed());
  }
}

export function* cancelSessionGenerator({ payload }: CancelSession) {
  try {
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.POST_CANCEL_SESSION.path}/${payload.id}`,
        method: API.POST_CANCEL_SESSION.method,
      },
      payload,
    );

    if (response.statusCode === 201) {
      yield put(viewSessionActions.cancelSessionSuccess());
      yield put(viewSessionActions.getSession());
    } else {
      yield put(viewSessionActions.cancelSessionFailed());
    }
  } catch (error) {
    yield put(viewSessionActions.cancelSessionFailed());
  }
}

export function* viewSessionSaga() {
  yield all([
    takeLatest(viewSessionActions.getSession.type, getSessionGenerator),
    takeLatest(viewSessionActions.getFilteredSessions.type, getFilteredSessionsGenerator),
    takeLatest(viewSessionActions.getSessionUrl.type, getSessionUrlGenerator),
    takeLatest(viewSessionActions.inviteUser.type, inviteUserGenerator),
    takeLatest(viewSessionActions.resendInvite.type, resendInviteGenerator),
    takeLatest(viewSessionActions.editActiveSession.type, editActiveGenerator),
    takeLatest(viewSessionActions.deleteSession.type, deleteSessionGenerator),
    takeLatest(viewSessionActions.cancelSession.type, cancelSessionGenerator),
  ]);
}

export default viewSessionSaga;
