import * as React from 'react';
import Box from '@mui/material/Box';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { PrimaryOutlinedButton } from 'components';
import { EditIcon } from 'assets/icons';
import BadgeModalForm from '../badge-modal-form';
import badgeModalFormValidation from '../validation/badge-modal-form-validation';
import { IBadge } from '../../../../../interface';

interface BadgeModalViewProps {
  add:boolean,
  formTitle:string,
  badge:IBadge,
  setBadge:(badge:IBadge)=>void
}

export default function BadgeModalView({
  add, formTitle, badge, setBadge,
}:BadgeModalViewProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const methods = useForm<IBadge>({
    defaultValues: {
      ...badge,

    },
    resolver: yupResolver(badgeModalFormValidation),
    shouldFocusError: true,
  });

  const onSubmit: SubmitHandler<IBadge> = (data) => {
    setBadge(data);
    handleClose();
  };
  return (
    <>
      {add ? (
        <PrimaryOutlinedButton
          onClick={handleOpen}
          startIcon={<AddIcon />}
        >
          Add badge
        </PrimaryOutlinedButton>
      ) : (
        <PrimaryOutlinedButton
          onClick={handleOpen}
          startIcon={<EditIcon />}
        >
          Edit
        </PrimaryOutlinedButton>
      )}

      <Modal
        open={open}

      >
        <Box className="modal-scrollable">
          <IconButton aria-label="close" sx={{ float: 'right' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <FormProvider {...methods}>
            <BadgeModalForm
              title={formTitle}
              onSubmit={onSubmit}
              loading={false}
              closeModal={handleClose}
            />
          </FormProvider>

        </Box>
      </Modal>
    </>
  );
}
