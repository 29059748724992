export interface IMessageEvent {
  type: MessageType;
  data: any;
}

export enum MessageType {
  windowClose = 'WINDOW_CLOSE',
  windowOpen = 'WINDOW_OPEN',
  formSubmit = 'FORM_SUBMIT',
}
