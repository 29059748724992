/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGiftCardUserFilterPayload,
  IUserVoucherResponse,
  ModalStates,
  ViewGiftCardUsersState,
} from './interface';

export const initialState: ViewGiftCardUsersState = {
  loading: false,
  stateLoading: false,
  id: '',
  userVouchers: { result: [], total: 0 },
  modal: ModalStates.close,
  giftCard: {
    _id: '',
    amountWithCurrency: [],
    purchases: 0,
    reimbursement: 0,
    isActive: false,
    deleted: false,
  },
};

export const viewGiftCardUsersSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/GiftCard/edit-GiftCard',
  initialState,
  reducers: {
    getGiftCard(state: ViewGiftCardUsersState, _action:PayloadAction<IGiftCardUserFilterPayload>) {
      state.stateLoading = true;
    },
    getGiftCardSucceeded(
      state:ViewGiftCardUsersState,
      action: PayloadAction<IUserVoucherResponse>,
    ) {
      state.id = action.payload.voucher._id;
      state.userVouchers.result = action.payload.result || [];
      state.userVouchers.total = action.payload.total || 0;
      state.giftCard = action.payload.voucher;
      state.loading = false;
      state.stateLoading = false;
    },
    getGiftCardFailed(state: ViewGiftCardUsersState) {
      state.stateLoading = false;
    },
    setId(state:ViewGiftCardUsersState, action:PayloadAction<string>) {
      state.id = action.payload;
    },
    openModal(state:ViewGiftCardUsersState, action:PayloadAction<string>) {
      state.modal = action.payload;
    },
    closeModal(state:ViewGiftCardUsersState) {
      state.modal = ModalStates.close;
    },
    editActive(state: ViewGiftCardUsersState, _action: PayloadAction<{ isActive: boolean }>) {
      state.loading = true;
    },
    editActiveSucceeded(state: ViewGiftCardUsersState, action: PayloadAction<boolean>) {
      state.loading = false;
      state.giftCard.isActive = action.payload;
      state.modal = ModalStates.close;
    },
    editActiveFailed(state: ViewGiftCardUsersState) {
      state.loading = false;
    },
    deleteGiftCard(state: ViewGiftCardUsersState) {
      state.loading = true;
    },
    deleteGiftCardSucceeded(state: ViewGiftCardUsersState) {
      state.loading = false;
      state.modal = ModalStates.close;
    },
    deleteGiftCardFailed(state: ViewGiftCardUsersState) {
      state.loading = false;
    },

    reset: () => initialState,

  },
});

export const { actions: viewGiftCardUsersActions } = viewGiftCardUsersSlice;
