import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link as NavLink } from 'react-router-dom';
import './index.scss';
import { PrimaryButton, PrimaryLiteButton } from 'components';
import { selectGeneralInformationForm } from '../../../../selector';

interface SuccessfulViewProps {
  createAnotherEntAcc:()=>void;
}
const SuccessfulView = ({ createAnotherEntAcc }:SuccessfulViewProps) => {
  const generalEntInformation = useSelector(selectGeneralInformationForm);
  return (
    <div>
      <div className="successful-view-icon">
        <CheckCircleIcon fontSize="large" />
      </div>
      <h3 className="successful-view-heading">You have successfully created an enterprise account</h3>
      <div className="successful-view-body">
        <Typography variant="body1">
          The enterprise account for
          {' '}
          {generalEntInformation.name}
          {' '}
          has been created
          successfully. An invite has been sent to
          {' '}
          {generalEntInformation.email}

        </Typography>
      </div>

      <Grid container spacing={3}>
        <Grid item>
          <PrimaryButton onClick={createAnotherEntAcc}>
            Create another enterprise account
          </PrimaryButton>
        </Grid>
        <Grid item>
          <NavLink style={{ textDecoration: 'none' }} to="/ent-account" onClick={createAnotherEntAcc}>
            <PrimaryLiteButton>Back home</PrimaryLiteButton>
          </NavLink>
        </Grid>
      </Grid>
    </div>
  );
};

export default SuccessfulView;
