/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Avatar, CardHeader, Grid, Tooltip, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridColumns } from '@mui/x-data-grid';
import {
  ClosableModal,
  PrimaryChip,
  PrimaryChipsSlider,
  PrimaryLiteButton, PrimaryLoadingButton, PrimaryOutlinedButton, PrimaryTable, SearchBar,
} from 'components';
import { MonitorIcon } from 'assets/icons';
import { ISessionAvatarWithAction, ISessionAvatar } from '../../../../../interface';

interface SelectSessionModalProps {
  selectedSessions: ISessionAvatarWithAction[],
  filteredSession: ISessionAvatar[],
  total: number,
  loading: boolean,
  handleUpdate: (sessionList: ISessionAvatarWithAction[]) => void,
  searchFunction: (keyword: string, page: number) => void,
  selectOne?: boolean,
  maxSelect?: number,
  title?: string,
  subTitle?: string,
  modalState?: boolean | undefined,
  setModelState?: (value: boolean) => void | undefined
  confirmationLoading?: boolean,
  label?: React.ReactNode,
  confirmationLabel?: string,
  addNewSession?: () => void,
  addNewSessionLabel?: string,
  isPrequisite?: boolean;
  overWriteText?: string;
}

const SelectSessionModal = ({
  selectedSessions, filteredSession, total, loading, handleUpdate, searchFunction, title = 'Select Session', subTitle = '',
  selectOne = false, maxSelect = 0, modalState = undefined, setModelState = undefined,
  confirmationLoading = false, confirmationLabel = ' Add & continue',
  addNewSession = undefined, addNewSessionLabel = '', isPrequisite = false, overWriteText,
  label = (
    <div className="inline-wrapper">
      <AddIcon />
      {overWriteText || 'Add Session'}
      {' '}
    </div>),
}: SelectSessionModalProps) => {
  type Row = typeof sessions[number];

  // initiate states
  const [open, setOpen] = React.useState<boolean>(modalState || false);
  const [keyword, setKeyword] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [selectedItems, setSelectedItems] = React.useState<ISessionAvatarWithAction[]>(
    selectedSessions,
  );
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [sessions, setSessions] = React.useState<ISessionAvatarWithAction[]>(
    filteredSession?.map((session) => ({
      ...session,
      isAdded: (selectedIds.includes(session.id)),
    })),
  );

  // handle modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (setModelState) { setModelState(false); }
    setOpen(false);
  };

  // handle search
  const handleSearchChange = (event: any) => {
    setKeyword(event.target.value);
  };

  // handle mutations
  const handleRemoveItem = (id: string) => {
    setSelectedItems((prev) => ([...prev.filter((session) => session.id !== id)]));
  };

  const addSession = (id: string) => {
    const temp = [...sessions];
    const index = temp.findIndex(((item: ISessionAvatarWithAction) => item.id === id));
    temp[index].isAdded = true;

    // updating the chips
    const tempSelectItems = selectOne ? [temp[index]] : [...selectedItems, temp[index]];
    setSelectedItems(tempSelectItems);
  };

  // update mutations
  const handleUpdateWrapper = () => {
    handleUpdate(selectedItems);
    if (!setModelState) { handleClose(); }
  };

  // configure table
  const columns = React.useMemo<GridColumns<Row>>(
    () => (isPrequisite ? [
      {
        field: 'title',
        headerName: 'Course Title',
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) => (
          <Tooltip title={params.value} enterDelay={1000}>
            <Typography variant="body2" component="p">
              {params.value}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'groupName',
        headerName: 'Group Name',
        flex: 0.4,
        minWidth: 100,
        renderCell: (params) => (
          params.row?.assignedGroups && params.row.assignedGroups.length > 0 ? (
            <Tooltip title={params.row.assignedGroups[0].title} enterDelay={500}>
              <Typography variant="body2" component="p">
                {params.row.assignedGroups[0].title}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2" component="p">
              -
            </Typography>
          )
        ),
      },
      {
        field: 'groupType',
        headerName: 'Group Type',
        flex: 0.2,
        minWidth: 100,
        renderCell: (params) => (
          params.row?.assignedGroups && params.row.assignedGroups.length > 0 ? (
            <Typography variant="body2" component="p">
              {params.row.assignedGroups[0].groupType}
            </Typography>
          ) : (
            <Typography variant="body2" component="p">
              -
            </Typography>
          )
        ),
      },
      {
        field: 'isAdded',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        flex: 0.1,
        minWidth: 100,
        renderCell: (params) => (
          <div>
            {params.value ? (
              <PrimaryOutlinedButton>
                Added
              </PrimaryOutlinedButton>
            ) : (
              <>
                {maxSelect > 0 && selectedItems.length < maxSelect && (
                  <PrimaryOutlinedButton onClick={() => { addSession(params.row.id); }}>
                    Add
                  </PrimaryOutlinedButton>
                )}
                {maxSelect === 0 && (
                  <PrimaryOutlinedButton onClick={() => { addSession(params.row.id); }}>
                    Add
                  </PrimaryOutlinedButton>
                )}
              </>

            )}

          </div>
        ),
      },
    ] : [
      {
        field: 'title',
        headerName: 'Session Title',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => (
          <CardHeader
            avatar={(
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  mr: 1,
                  backgroundColor: 'background.default',
                  color: 'primary.main',
                  borderWidth: 1,
                  borderColor: 'primary.light',
                  borderStyle: 'solid',
                }}
              >
                <MonitorIcon sx={{ fontSize: 16 }} />
              </Avatar>
            )}
            title={(
              <Tooltip title={params.value} enterDelay={1000}>
                <Typography variant="h4" noWrap>
                  {params.value}
                </Typography>
              </Tooltip>
            )}
          />
        ),
      },
      {
        field: 'sessionType',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        flex: 0.5,
        renderCell: (params) => (
          <div className="status-tag" style={{ background: '#dfdfe2' }}>{params.value.charAt(0).toUpperCase() + params.value.slice(1)}</div>
        ),
      },
      {
        field: 'isAdded',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        flex: 0.5,
        renderCell: (params) => (
          <div>
            {params.value ? (
              <PrimaryOutlinedButton>
                Added
              </PrimaryOutlinedButton>
            ) : (
              <>
                {maxSelect > 0 && selectedItems.length < maxSelect && (
                  <PrimaryOutlinedButton onClick={() => { addSession(params.row.id); }}>
                    Add
                  </PrimaryOutlinedButton>
                )}
                {maxSelect === 0 && (
                  <PrimaryOutlinedButton onClick={() => { addSession(params.row.id); }}>
                    Add
                  </PrimaryOutlinedButton>
                )}
              </>

            )}

          </div>
        ),
      },
    ]),
    [addSession],
  );
  React.useEffect(() => {
    searchFunction(keyword, page);
  }, []);

  React.useEffect(() => {
    searchFunction(keyword, page);
  }, [keyword, page, open]);

  React.useEffect(() => {
    if (filteredSession) {
      const temp = filteredSession?.map((session) => ({
        ...session,
        isAdded: (selectedIds.includes(session.id)),
      }));
      setSessions(JSON.parse(JSON.stringify(temp)) as ISessionAvatarWithAction[]);
    }
  }, [filteredSession, selectedIds]);

  React.useEffect(() => {
    setSelectedIds(selectedItems.map((session: ISessionAvatarWithAction) => session.id));
  }, [selectedItems]);

  React.useEffect(() => {
    setSelectedItems(selectedSessions);
  }, [selectedSessions]);

  // keep the external modal state sync with the internal modal state
  React.useEffect(() => {
    if (modalState !== undefined) { setOpen(modalState); }
  }, [modalState]);

  React.useEffect(() => {
    if (setModelState) { setModelState(open); }
  }, [open]);

  return (
    <>
      <PrimaryOutlinedButton
        onClick={handleOpen}
      >
        {label}
      </PrimaryOutlinedButton>
      <ClosableModal
        open={open}
        onClose={handleClose}
        title={title}
        subTitle={subTitle}
        modalClass={isPrequisite ? 'select-prerequisite-item-modal' : 'select-item-modal'}
      >

        <div className="form-item">
          <SearchBar
            value={keyword}
            onChange={handleSearchChange}
            placeholder="Search by session name"
          />
        </div>
        <div className="form-item">
          {selectedItems.length > 0 ? (
            <PrimaryChipsSlider>
              {selectedItems.map((item) => (
                <PrimaryChip
                  key={item.id}
                  label={item.title}
                  variant="outlined"
                  onDelete={() => { handleRemoveItem(item.id); }}
                />

              ))}
            </PrimaryChipsSlider>

          ) : ''}
        </div>
        <div className="form-item">
          <PrimaryTable
            disableSelectionOnClick
            rows={sessions}
            columns={columns}
            loading={loading}
            page={page}
            pageSize={3}
            rowsPerPageOptions={[3]}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            rowCount={total}
            paginationMode="server"
            getRowId={(row) => row.id}
          />
        </div>
        {addNewSession && (
          <div style={{ float: 'left' }}>
            <PrimaryOutlinedButton size="large" fullWidth onClick={() => { addNewSession(); handleClose(); }}>
              <AddIcon sx={{ margin: '0 0.5rem' }} />
              {addNewSessionLabel}
            </PrimaryOutlinedButton>
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item xs>
            <PrimaryLiteButton fullWidth onClick={handleClose}>Cancel</PrimaryLiteButton>
          </Grid>
          <Grid item xs>
            <PrimaryLoadingButton
              loading={confirmationLoading}
              fullWidth
              onClick={handleUpdateWrapper}
            >
              {confirmationLabel}
            </PrimaryLoadingButton>
          </Grid>

        </Grid>
      </ClosableModal>

    </>

  );
};

export default SelectSessionModal;
