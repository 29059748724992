import * as React from 'react';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  className?: string;
  onBackgroundClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const PrimaryLightBgCard = ({
  children,
  sx,
  className,
  onBackgroundClick,
}: Props) => {
  const backgroundClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onBackgroundClick) {
      onBackgroundClick(event);
    }
  };

  return (
    <Box
      sx={[
        {
          backgroundColor: (theme) => theme.palette.secondary.main,
          borderRadius: '6px',
          p: 2,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: (theme) => theme.palette.text.disabled,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={`${className}`}
      component="div"
      onClick={backgroundClickHandler}
    >
      {children}
    </Box>
  );
};

PrimaryLightBgCard.defaultProps = {
  sx: [],
  className: '',
  onBackgroundClick: () => {},
};

const WhiteBgCard = ({
  children,
  sx,
  className,
  onBackgroundClick,
}: Props) => {
  const backgroundClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onBackgroundClick) {
      onBackgroundClick(event);
    }
  };
  return (
    <Box
      sx={[
        {
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: '6px',
          padding: '20px 24px',
          boxShadow: '0px 5px 20px #00000005',
          border: '1px solid #F2F2F2',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={`${className}`}
      component="div"
      onClick={backgroundClickHandler}
    >
      {children}
    </Box>
  );
};

WhiteBgCard.defaultProps = {
  sx: [],
  className: '',
  onBackgroundClick: () => {},
};

export { PrimaryLightBgCard, WhiteBgCard };
