import {
  all,
  call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { viewGiftCardActions } from './slice';
import API from '../../constant';
import {
  IGiftCardFilterPayload, IDeleteGiftCard,
} from './interface';

export function* viewGiftCardGenerator({ payload }:
PayloadAction<IGiftCardFilterPayload>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_GIFT_CARDS_BY_ENT.path}/?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&status=${payload.status}`, method: API.GET_GIFT_CARDS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewGiftCardActions.viewGiftCardSucceeded(response.data));
    } else {
      yield put(viewGiftCardActions.viewGiftCardFailed());
    }
  } catch (error) {
    yield put(viewGiftCardActions.viewGiftCardFailed());
  }
}
export function* activateGiftCardGenerator({ payload }:PayloadAction<IDeleteGiftCard>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.PUT_UPDATE_GIFT_CARD,
      { ...payload },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewGiftCardActions.activateGiftCardSucceeded(payload.id));
    } else {
      yield put(viewGiftCardActions.activateGiftCardFailed());
    }
  } catch (error) {
    yield put(viewGiftCardActions.activateGiftCardFailed());
  }
}

export function* deleteGiftCardGenerator({ payload }:PayloadAction<IDeleteGiftCard>): any {
  try {
    const { id } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_GIFT_CARD,
      { id },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewGiftCardActions.deleteGiftCardSucceeded(id));
    } else {
      yield put(viewGiftCardActions.deleteGiftCardFailed());
    }
  } catch (error) {
    yield put(viewGiftCardActions.deleteGiftCardFailed());
  }
}

export function* viewGiftCardSaga() {
  yield all([takeLatest(
    viewGiftCardActions.viewGiftCard.type,
    viewGiftCardGenerator,
  ), takeLatest(
    viewGiftCardActions.activateGiftCard.type,
    activateGiftCardGenerator,
  ),
  takeLatest(
    viewGiftCardActions.deleteGiftCard.type,
    deleteGiftCardGenerator,
  )]);
}

export default viewGiftCardSaga;
