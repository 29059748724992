import { Star } from '@mui/icons-material';
import {
  Avatar,
  Card, CardContent, Grid, Typography,
} from '@mui/material';
import { PrimaryButton } from 'components';
import { IExpertAvatar } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/interface';

interface ExpertProps {
  entName:string,
  expert:IExpertAvatar
}
const Expert = ({ expert, entName }:ExpertProps) => (
  <Card variant="outlined" sx={{ mb: '1rem', backgroundColor: '#FFFFFF' }}>
    <CardContent>
      <Grid container alignItems="center" sx={{ mb: '0.5rem' }}>
        <Grid item sx={{ mr: '0.7rem' }}>
          <Avatar src={expert?.profileImgUrl} sx={{ width: 50, height: 50 }} />
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant="h4">
              {expert?.firstName}
              {' '}
              {expert?.lastName}
              {' '}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {' '}
              {entName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="body1" className="form-item">{expert?.attributes?.bio}</Typography>
      <Grid container spacing={3} justifyContent="space-between" alignItems="center">
        <Grid item><PrimaryButton>View this expert</PrimaryButton></Grid>
        {expert?.attributes?.rating && (
        <Grid item className="inline-wrapper">
          <Star sx={{ color: 'primary.main', mr: '0.5rem' }} />
          <b>{(+expert.attributes.rating || 0)?.toFixed(2)}</b>
          (
          {expert?.attributes?.noOfRatings}
          {' '}
          ratings)
        </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
);
export default Expert;
