import * as React from 'react';
import { Grid } from '@mui/material';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  StatusTag, SearchBar, PrimarySelect, DashboardLayout,
} from 'components';
import { getOptionsFromEnum } from 'utils/data-utils';
import { IOption } from 'interfaces';
import { Role } from 'interfaces/user-roles';
import useAuthorization from 'base/auth/hooks/use-authorization';
import { FeedbackStatus } from 'config';
import FeedbacksTable from './components/feedback-table';
import {
  selectFeedback, selectFeedbacksLoader, selectTotalPending,
  selectTotalPickedUp, selectTotalResolved,
} from './selector';
import { viewFeedbackActions } from './slice';
import { selectEntAccounts, selectEntAccountsLoader } from '../overview/selector';
import { overviewActions } from '../overview/slice';

const FeedbacksView = () => {
  const dispatch = useDispatch();
  const { isAuthorized } = useAuthorization();
  const entAccounts = useSelector(selectEntAccounts);
  const entAccountsLoading = useSelector(selectEntAccountsLoader);
  const filteredFeedback = useSelector(selectFeedback).result;
  const { total } = useSelector(selectFeedback);
  const feedbacksLoading = useSelector(selectFeedbacksLoader);
  const totalResolved = useSelector(selectTotalResolved);
  const totalPending = useSelector(selectTotalPending);
  const totalPickedUp = useSelector(selectTotalPickedUp);
  const [keyword, setKeyword] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [selectedStatus, setSelectedStatus] = React.useState<string>('All');
  const [selectedEntAccount, setSelectedEntAccount] = React.useState<string>('All');
  const [entOptions, setEntOptions] = React.useState<IOption[]>(
    [{ id: '0', text: 'All', value: 'All' }],
  );

  const handleSearchChange = (event: any) => {
    setKeyword(event.target.value);
  };
  const handleStatusChange = (event: any) => {
    setSelectedStatus(event.target.value);
  };

  const handleEntAccountChange = (event: any) => {
    setSelectedEntAccount(event.target.value);
  };

  useEffect(() => {
    dispatch(viewFeedbackActions.viewFeedback({
      keyword, page: (page + 1), limit: 25, status: selectedStatus, entId: (selectedEntAccount === 'All' || !isAuthorized([Role.SuperAdmin])) ? '' : selectedEntAccount,
    }));
  }, [keyword, selectedStatus, page, selectedEntAccount]);

  useEffect(() => {
    if (isAuthorized([Role.SuperAdmin])) {
      dispatch(overviewActions.getEntAccounts());
    }
  }, []);

  useEffect(() => {
    if (entAccounts.length > 0) {
      let entAccs = [{ id: '0', text: 'All', value: 'All' }];
      entAccs = entAccs.concat(entAccounts?.map((entAcc) => (
        { id: entAcc._id, value: entAcc._id, text: entAcc.name })));
      setEntOptions(entAccs);
    }
  }, [entAccounts]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Home
    </Link>,
    <Link underline="hover" key="2" color="text.primary" href="/feedback">
      Feedbacks
    </Link>,
  ];
  const statuses: IOption[] = [{ id: 0, text: 'All', value: 'All' }, ...getOptionsFromEnum(FeedbackStatus)];

  const topContent = (
    <Grid container alignItems="center">
      <Grid item xs={10}>
        <Grid container alignItems="center">
          <Grid item>
            <h1>Feedbacks</h1>
          </Grid>
          {(selectedStatus === 'All' || selectedStatus === FeedbackStatus.RESOLVED)
          && (
          <Grid item sx={{ marginLeft: '1rem' }}>
            <StatusTag success status={`${totalResolved} Resolved`} />
          </Grid>
          )}
          {(selectedStatus === 'All' || selectedStatus === FeedbackStatus.PICKED_UP) && (
          <Grid item sx={{ marginLeft: '1rem' }}>
            <StatusTag color="#FFB302" status={`${totalPickedUp} PickedUp`} />
          </Grid>
          )}
          {(selectedStatus === 'All' || selectedStatus === FeedbackStatus.PENDING) && (
          <Grid item sx={{ marginLeft: '1rem' }}>
            <StatusTag success={false} status={`${totalPending} Pending`} />
          </Grid>
          )}
        </Grid>
      </Grid>

    </Grid>
  );
  return (
    <div>
      <DashboardLayout breadcrumbs={breadcrumbs} topContent={topContent}>
        <div>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ margin: '1rem 0' }}
          >
            <Grid item xs={6}>
              <SearchBar value={keyword} onChange={handleSearchChange} placeholder="Search by feedback" />
            </Grid>
            {isAuthorized([Role.SuperAdmin]) && (
            <Grid item xs sx={{ paddingLeft: '1rem' }}>
              <PrimarySelect innerLabel="Ent accounts:" options={entOptions} onChange={handleEntAccountChange} loading={entAccountsLoading} />
            </Grid>
            )}
            <Grid item xs sx={{ paddingLeft: '1rem' }}>
              <PrimarySelect innerLabel="Status:" options={statuses} onChange={handleStatusChange} />
            </Grid>

          </Grid>
          <div />
          <div>
            <FeedbacksTable
              feedbacks={filteredFeedback}
              loading={feedbacksLoading}
              total={total}
              page={page}
              setPage={setPage}
            />

          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default FeedbacksView;
