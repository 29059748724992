/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'interfaces';
import { initialState, pluginSlice } from './slice';

const selectDomain = (state: RootState) => state[pluginSlice.name] || initialState;

export const selectStateLoader = createSelector([selectDomain], (state) => state.stateLoading);
export const selectPlugins = createSelector([selectDomain], (state) => state.plugins);
export const selectEntId = createSelector([selectDomain], (state) => state.entId);

export default selectStateLoader;
