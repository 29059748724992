/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/view-expert'] || initialState;

export const selectViewExpertState = createSelector([selectDomain], (ViewExpertState) => ViewExpertState);
export const selectExperts = createSelector([selectDomain], (ViewExpertState) => ViewExpertState.experts);
export const selectFilteredExperts = createSelector([selectDomain], (ViewExpertState) => ViewExpertState.filteredExperts);
export const selectExpertLoader = createSelector([selectDomain], (ViewExpertState) => ViewExpertState.stateLoading);
export const selectLoader = createSelector([selectDomain], (ViewExpertState) => ViewExpertState.loading);
export const selectEntId = createSelector([selectDomain], (ViewExpertState) => ViewExpertState.entId);
