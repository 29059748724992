enum Permissions {
  generalInformation = 'general-information',
  experts = 'experts',
  sessions = 'sessions',
  articles = 'articles',
  users = 'users',
  groups = 'groups',
  admins = 'admins',
  accessClasses = 'accessClasses',
  observeSessions = 'observeSessions',
  moderateArticles = 'articles',
  authenticateComplaints = 'authenticateComplaints',
  accessRecordings = 'accessRecordings',

}

export default Permissions;
