import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ResetPasswordFormValidation from 'pages/reset-password/validation/reset-password-form-validation';
import './index.scss';

import {
  Label, Title,
} from 'components';
import ROUTE from 'constants/route';
import useAuthorization from 'base/auth/hooks/use-authorization';
import { BaseLayout } from 'components/templates';
import { selectAccessToken } from 'base/auth/selectors';
import { authSliceActions } from 'base/auth/slice';
import { IResetPasswordForm } from 'pages/reset-password/interface';
import ResetPasswordForm from '../reset-password-form';

const ResetPasswordView = () => {
  useAuthorization();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const verificationCode = urlParams.get('verificationCode') as string;
  const methods = useForm<IResetPasswordForm>({
    defaultValues: {
      verificationCode,
      name: email?.replace(' ', '+'),
      newPassword: '',
      confirmPassword: '',
      retainLogin: false,
    },
    resolver: yupResolver(ResetPasswordFormValidation),
    shouldFocusError: true,
  });
  const dispatch = useDispatch();

  const token = useSelector(selectAccessToken);

  useEffect(() => {
    if (token && !(email && verificationCode)) navigate(ROUTE.DASHBOARD);
  }, [token, navigate]);

  const handleSubmitFrom: SubmitHandler<IResetPasswordForm> = useCallback(
    (data) => {
      dispatch(authSliceActions.resetPassword(data));
    },
    [dispatch],
  );

  return (
    <BaseLayout>
      <>
        <br />
        <div style={{ textAlign: 'center' }}>
          <Title>Reset your password</Title>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Label>Enter a new password and then repeat it.</Label>
        </div>
        <br />
        <FormProvider {...methods}>
          <ResetPasswordForm onSubmit={handleSubmitFrom} />
        </FormProvider>
      </>
    </BaseLayout>
  );
};

export default ResetPasswordView;
