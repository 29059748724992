import * as yup from 'yup';
import { SessionAudienceTypes, SessionType } from 'config';

const sessionFeeFormValidation = yup.object().shape({
  fee: yup
    .number()
    .when('$audienceType', {
      is: !SessionAudienceTypes.courseOnly,
      then: yup
        .number()
        .positive()
        .min(0)
        .test('maxDigits', 'Fee per session must have 2 decimal or less', (number) => /^\d+(\.\d{1,2})?$/.test(number?.toString() ?? ''))
        .required()
        .label('Fee per session'),
    })
    .test({
      name: 'feeValidate',
      exclusive: true,
      params: {},
      message: 'Fee per session is a required to apply discounts',
      test(value, ctx) {
        return !(Number(ctx.parent.discount) > 0 && Number(value) <= 0);
      },
    })
    .typeError('Fee per session is a required field'),
  discount: yup
    .number()
    .when('$audienceType', {
      is: !SessionAudienceTypes.courseOnly,
      then: yup.number().min(0).max(99).required()
        .label('Discount rate per session'),
    })
    .typeError('Discount rate per session is a required field'),
  currency: yup.string().when('$audienceType', {
    is: !SessionAudienceTypes.courseOnly,
    then: yup.string().required('Currency  is a required field'),
  }),

  subscriberFee: yup
    .number()
    .when('$audienceType', {
      is: SessionAudienceTypes.subscription || SessionAudienceTypes.subscriptionOnly,
      then: yup
        .number()
        .positive()
        .min(0)
        .required()
        .test('maxDigits', 'Subscriber fee per session must have 2 decimal or less', (number) => /^\d+(\.\d{1,2})?$/.test(number?.toString() ?? ''))
        .label('Subscribed fee per session'),
    })
    .test({
      name: 'subscriberFeeValidate',
      exclusive: true,
      params: {},
      message: 'Subscriber fee per session is a required to apply discounts',
      test(value, ctx) {
        return !(Number(ctx.parent.subscriberDiscount) > 0 && Number(value) <= 0);
      },
    })
    .test({
      name: 'subscriberFeeLessThanOrEqualToFee',
      exclusive: true,
      params: {},
      message: 'Subscriber fee cannot be higher than the Non subscriber fee',
      test(value, ctx) {
        return value == null || ctx.parent.fee == null || Number(value) <= Number(ctx.parent.fee);
      },
    })
    .typeError('Subscriber fee per session is a required field'),

  subscriberDiscount: yup
    .number()
    .when('$audienceType', {
      is: !SessionAudienceTypes.courseOnly && (
        SessionAudienceTypes.subscription
        || SessionAudienceTypes.subscriptionOnly
      ),
      then: yup
        .number()
        .min(0).max(99).required()
        .label('Subscriber discount'),
    })
    .typeError('Subscriber discount is a required field'),

  subscriberCurrency: yup.string().when('$audienceType', {
    is: SessionAudienceTypes.subscription || SessionAudienceTypes.subscriptionOnly,
    then: yup.string().required(),
  }).label('Subscribed participant currency'),

  observerFee: yup.number().when(['$sessionType', '$isObserverAllowed', '$audienceType'], {
    is: (
      sessionType: string,
      audienceType: string,
      isObserverAllowed: boolean,
    ) => sessionType === SessionType.liveClass
  && isObserverAllowed
  && audienceType !== SessionAudienceTypes.courseOnly,
    then: yup
      .number()
      .positive()
      .min(0)
      .test(
        'maxDigits',
        'Observer fee per session must have 2 decimal or less',
        (number) => (/^\d+(\.\d{1,2})?$/.test(number?.toString() ?? '')),
      )
      .required()
      .label('Observer fee per session'),
  })
    .test({
      name: 'observerFeeValidate',
      exclusive: false,
      params: {},
      message: 'Observer fee per session is a required to apply discounts',
      test(value, ctx) {
        return !(Number(ctx.parent.observerDiscount) > 0 && Number(value) <= 0);
      },
    })
    .typeError('Observer fee per session is a required field'),

  observerDiscount: yup
    .number()
    .when(['$sessionType', '$isObserverAllowed', '$audienceType'], {
      is: (
        sessionType: string,
        audienceType: string,
        isObserverAllowed: boolean,
      ) => sessionType === SessionType.liveClass
  && isObserverAllowed
  && audienceType !== SessionAudienceTypes.courseOnly
         && isObserverAllowed,
      then: yup
        .number()
        .min(0)
        .max(99)
        .required()
        .label('Observer discount'),
    })
    .typeError('Observer discount is a required field'),

  observerCurrency: yup
    .string()
    .when(['$sessionType', '$audienceType', '$isObserverAllowed'], {
      is: (
        sessionType: string,
        audienceType: string,
        isObserverAllowed: boolean,
      ) => sessionType === SessionType.liveClass
      && (SessionAudienceTypes.subscription || SessionAudienceTypes.subscriptionOnly)
      && audienceType !== SessionAudienceTypes.courseOnly
      && isObserverAllowed,
      then: yup.string().required().label('Observer currency'),
    }),

  subscribeObserverFee: yup
    .number()
    .when(['$sessionType', '$audienceType', '$isObserverAllowed'], {
      is: (
        sessionType: string,
        audienceType: string,
        isObserverAllowed: boolean,
      ) => sessionType === SessionType.liveClass
    && (SessionAudienceTypes.subscription || SessionAudienceTypes.subscriptionOnly)
    && audienceType !== SessionAudienceTypes.courseOnly
    && isObserverAllowed,
      then: yup
        .number()
        .positive()
        .min(0)
        .test('maxDigits', 'Subscribed observer fee per session must have 2 decimal or less', (number) => /^\d+(\.\d{1,2})?$/.test(number?.toString() ?? ''))
        .required()
        .label('Subscribed observer fee per session'),
    })
    .test({
      name: 'subscribeObserverFeeValidate',
      exclusive: false,
      params: {},
      message: 'Subscribed observer fee per session is a required to apply discounts',
      test(value, ctx) {
        return !(Number(ctx.parent.subscribeObserverDiscount) > 0 && Number(value) <= 0);
      },
    })
    .typeError('Subscribed observer fee per session is a required field'),
  subscribeObserverDiscount: yup
    .number()
    .when(['$sessionType', '$audienceType', '$isObserverAllowed'], {
      is: (
        sessionType: string,
        audienceType: string,
        isObserverAllowed: boolean,
      ) => sessionType === SessionType.liveClass
        && (SessionAudienceTypes.subscription || SessionAudienceTypes.subscriptionOnly)
        && audienceType !== SessionAudienceTypes.courseOnly
        && isObserverAllowed,
      then: yup
        .number()
        .min(0)
        .max(99)
        .required()
        .label('Subscribed observer discount'),
    })
    .typeError('Subscribed observer discount is a required field'),
  subscribeObserverCurrency: yup
    .string()
    .when(['$sessionType', '$audienceType', '$isObserverAllowed'], {
      is: (
        sessionType: string,
        audienceType: string,
        isObserverAllowed: boolean,
      ) => sessionType === SessionType.liveClass
        && (SessionAudienceTypes.subscription || SessionAudienceTypes.subscriptionOnly)
        && audienceType !== SessionAudienceTypes.courseOnly
        && isObserverAllowed,
      then: yup.string().required().label('Subscribed observer currency'),
    }),
});
export default sessionFeeFormValidation;
