export interface ContentData {
  action: string;
  url: string;
  contentUrl?: string; // additional data when it comes to sponsors and promotions
  fileName: string;
  file: any;
  type: string;
  extension: string;
}

export interface UploadResponse {
  name:string,
  url:string,
  extension: string,
  category: string
  statusCode: number;
  message:string
  fileData?: {
    fileName: string,
    size: number
  },
}
export interface UploadRequestMetaData {
  path: string;
  method: string;
}
export enum UploadScenarios {
  entFontUpload,
  expertVideo,
  entLogo,
  entSponsor,
  entPromotional,
  adminProfile,
  expertProfile,
  expertBadge,
  expertSponsor,
  expertContent,
  articleBanner,
  sessionBanner,
  sessionHighlightMedia,
  formBadge,
  groupBanner,
  scorm,
  ctaBackground,
  textBlock,
}
export interface IUploadConfig {
  allowedExtensions:string[],
  sizeLimit:number,
}
