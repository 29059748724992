import * as React from 'react';
import Box from '@mui/material/Box';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { PrimaryOutlinedButton } from 'components';
import { BlockType } from '@surge-global-engineering/form-builder-v2';
import { UploadScenarios } from 'interfaces/file-uploader';
import ContentModalForm from '../add-content-modal-form';
import contentModalFormValidation from '../validation/add-content-modal-form-validation';
import { viewContentActions } from '../../../slice';
import { ICreateContentModalForm, ModalStates } from '../../../interface';
import { selectLoader, selectModalState } from '../../../selector';

interface ContentModalProps {
  blockType?: BlockType;
  scenario?: UploadScenarios;
}
export default function ContentModal({ blockType, scenario }: ContentModalProps) {
  const modalState = useSelector(selectModalState);
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);
  const methods = useForm<ICreateContentModalForm>({
    defaultValues: {
      contentUrl: '',
      title: '',
      description: '',
      fileType: '',
      path: '/root',
      fileCategory: '',
    },
    resolver: yupResolver(contentModalFormValidation),
    shouldFocusError: true,
  });

  const handleOpen = () => {
    dispatch(viewContentActions.openModal(ModalStates.addContent));
    methods.reset();
  };
  const handleClose = () => { dispatch(viewContentActions.closeModal()); methods.reset(); };

  const onSubmit: SubmitHandler<ICreateContentModalForm> = React.useCallback((data) => {
    dispatch(viewContentActions.createContent(data));
  }, [dispatch]);

  return (
    <div>
      <PrimaryOutlinedButton size="large" fullWidth onClick={handleOpen}>
        <AddIcon sx={{ margin: '0 0.5rem' }} />
        Add new media
      </PrimaryOutlinedButton>
      <Modal
        open={modalState === ModalStates.addContent}

      >
        <Box className="modal-scrollable">
          <IconButton aria-label="close" sx={{ float: 'right' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <FormProvider {...methods}>
            <ContentModalForm
              onSubmit={onSubmit}
              loading={loading}
              closeModal={handleClose}
              blockType={blockType}
              scenarios={scenario}
            />
          </FormProvider>

        </Box>
      </Modal>
    </div>
  );
}

ContentModal.defaultProps = {
  blockType: undefined,
  scenario: undefined,
};
