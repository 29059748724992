import { useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { getRefreshToken } from 'helpers/token';

const useAuthentication = () => {
  const token = getRefreshToken();

  const isAuthenticated = useCallback((): boolean => {
    if (token) {
      const user: { exp: number } = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      return user.exp >= currentTime;
    }
    return false;
  }, [token]);

  return { isAuthenticated };
};

export default useAuthentication;
