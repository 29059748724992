import * as yup from 'yup';
import { SessionFrequencyTypes } from 'config';

const sessionFrequencyFormValidation = yup.object().shape({
  frequency: yup.string().trim().required(' Frequency is required '),
  startAt: yup.string().trim().required(' Start at is required '),
  endAt: yup.string().trim().required(' End at is required '),
  startDate: yup.string().trim().when('frequency', { is: SessionFrequencyTypes.oneTime, then: yup.string().trim().required(' Start date is is required ') }),
  endDate: yup.string().trim().when('frequency', { is: SessionFrequencyTypes.oneTime, then: yup.string().trim().required(' End date is is required ') }),
  dayOfTheWeek: yup.string().trim().when('frequency', { is: SessionFrequencyTypes.everyWeek, then: yup.string().trim().required(' Day of the week is is required ') }),
  dayOfTheMonth: yup.string().trim().when('frequency', { is: SessionFrequencyTypes.everyMonth, then: yup.string().trim().required(' Day of the month is is required ') }),
});

export default sessionFrequencyFormValidation;
