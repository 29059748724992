/* eslint-disable no-empty */
import * as React from 'react';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import axios from 'axios';
import { replaceExt } from 'utils/data-utils';
import { GalleryIcon } from 'assets/icons';

interface VideoPlayerProps {
  url?:string,
  thumbnail?:string
}

const VideoPlayer = ({ url, thumbnail }:VideoPlayerProps) => {
  const [videoUrl, setVideoUrl] = React.useState('');
  const checkStreamable = async () => {
    try {
      const streamUrl = replaceExt(url as string, '.m3u8');
      await axios.head(streamUrl);
      setVideoUrl(replaceExt(url as string, '.m3u8'));
    } catch (err) {
      setVideoUrl(url as string);
    }
  };
  React.useEffect(() => {
    if (url && url?.length > 0) { checkStreamable(); }
  }, [url]);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: (url === '') ? '#F2F2F2' : '',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {url === ''
        ? (

          <GalleryIcon fontSize="large" />

        )

        : (

          <ReactPlayer
            url={videoUrl}
            light={thumbnail !== '' ? thumbnail : false}
            pip
            height="100%"
            width="100%"
            playIcon={<PlayCircleIcon />}
            controls
          />

        ) }
    </Box>
  );
};
VideoPlayer.defaultProps = {
  url: '',
  thumbnail: '',
};

export default VideoPlayer;
