import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Role } from 'interfaces/user-roles';
import { Permissions } from 'config';
import { selectPermission, selectRole, selectUser } from '../selectors';

// Purpose: This code is used to check whether a user is authorized for a particular
//          page or component

// Function: isAuthorized
// Inputs: roles?: Role[], permissions?: Permissions[]
// Output: boolean
// Description: This function checks whether a user is authorized for a particular page or component

const useAuthorization = () => {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectRole);
  const userPermissions = useSelector(selectPermission);

  const isAuthorized = useCallback((roles?:Role[], permissions?:Permissions[]): boolean => {
    if (user?.roles) {
      if (roles && roles?.length > 0 && permissions && permissions?.length > 0) {
        // Check if the user has all the roles and permissions specified
        return (roles.some((role) => userRole.includes(role))
        && permissions.some((permission) => userPermissions.includes(permission)));
      }
      if (roles && roles?.length > 0) {
        // Check if the user has all the roles specified
        return (roles.some((role) => userRole.includes(role)));
      }
      if (permissions && permissions?.length > 0) {
        // Check if the user has all the permissions specified
        return permissions.some((permission) => userPermissions.includes(permission));
      }
      // No roles or permissions specified
      return false;
    }
    // No user
    return false;
  }, [user]);

  return { isAuthorized };
};

export default useAuthorization;
