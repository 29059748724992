import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  BlackLabel, PrimaryLabel, PrimaryLink, PrimaryTextField, ErrorMessage, PrimaryLoadingButton,
} from 'components';
import { selectLoader } from 'base/auth/selectors';
import { IForgotPasswordForm } from 'pages/forgot-password/interface';

interface ForgotPasswordFormProps {
  onSubmit: SubmitHandler<IForgotPasswordForm>;
}

const ForgotPasswordForm = ({ onSubmit }: ForgotPasswordFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IForgotPasswordForm>();

  const loading = useSelector(selectLoader);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-item">
        <Controller
          control={control}
          name="name"
          render={({ field }) => <PrimaryTextField {...field} label="Email address" />}
        />
        <ErrorMessage>{errors?.name?.message}</ErrorMessage>
      </div>

      <div className="form-item" style={{ marginTop: 20 }}>
        <PrimaryLoadingButton loading={loading} size="large" fullWidth type="submit">
          Send reset instructions
        </PrimaryLoadingButton>
      </div>
      <div style={{ textAlign: 'center' }}>
        <BlackLabel>Remember your password? </BlackLabel>
        <PrimaryLink to="/login">
          <PrimaryLabel>Sign In</PrimaryLabel>
        </PrimaryLink>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
