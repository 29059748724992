import * as React from 'react';
import {
  SubmitHandler, useFormContext, useFieldArray, Controller,
} from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  FormControlLabel,
  Grid,
} from '@mui/material';
import {
  ErrorMessage,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimarySwitch,
} from 'components';
import _ from 'lodash';
import Module from '../module';
import { IModule } from '../../../../../../interface';
import { ICourseModules } from '../../../../interface';

interface CourseModulesModalFormProps {
  onSubmit: SubmitHandler<any>;
  loading:boolean;
}

const CourseModulesModalForm = ({
  onSubmit, loading,
}: CourseModulesModalFormProps) => {
  const {
    control,
    handleSubmit, setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<ICourseModules>();
  const { fields, append, remove } = useFieldArray({ name: 'modules', control, keyName: 'key' });
  const handleChange = (index:number, module:IModule) => {
    if (errors) {
      clearErrors();
    }

    const currentModule = fields.find((field) => field.id === module.id);
    const isEqual = _.isEqual(currentModule?.form ?? [], module.form ?? [])
      && _.isEqual(currentModule?.content ?? [], module.content ?? [])
      && _.isEqual(currentModule?.session ?? [], module.session ?? []);

    if (!isEqual) {
      // eslint-disable-next-line no-param-reassign
      module.isUpdated = true;
    }

    setValue(`modules.${index}`, module);
  };
  const addModule = () => {
    append({
      name: '',
      description: '',
      session: [],
      form: [],
      content: [],
      badge: { badgeTitle: '', badgeDescription: '', badgeImgUrl: '' },
      version: 0,
    });
  };

  const removeModule = (index:number) => {
    remove(index);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item">
        Modules
      </h3>
      {fields.map((item, i) => (
        <div key={item.id}>
          <div className="form-item">
            <Module
              handleChange={handleChange}
              index={i}
              removeModule={removeModule}
              errors={errors}
              moduleInit={item}
            />

          </div>

        </div>

      ))}

      <PrimaryLiteButton sx={{ margin: '1rem 0' }} onClick={addModule}>
        <AddIcon />
        Add new module
      </PrimaryLiteButton>
      <Controller
        control={control}
        name="isSequentialModuleNavigationEnabled"
        render={({ field: { onChange, value, name } }) => (
          <Box display="flex" alignItems="center" sx={{ my: 2 }}>
            <FormControlLabel
              control={(
                <PrimarySwitch
                  checked={value}
                  onChange={onChange}
                  name={name}
                />
              )}
              label="Enable sequential module navigation"
            />
          </Box>
        )}
      />
      <div>
        <ErrorMessage>{errors?.modules?.message}</ErrorMessage>
      </div>

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} type="submit">
            Save & continue
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

export default CourseModulesModalForm;
