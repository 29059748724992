const API = {
  // Group Management
  GET_GROUP: {
    path: '/group',
    method: 'GET',
  },
  POST_UPDATE_GROUP: {
    path: '/group/update',
    method: 'POST',
  },
  DELETE_GROUP: {
    path: '/group/delete',
    method: 'POST',
  },
  POST_UPDATE_GROUP_USER: {
    path: '/group/user/update',
    method: 'POST',
  },
  POST_HANDLE_USER_REQUEST: {
    path: '/group/handleRequest',
    method: 'POST',
  },

  // Group Experts Management
  POST_UPDATE_GROUP_EXPERT: {
    path: '/group/:id/experts',
    method: 'PATCH',
  },
  DELETE_GROUP_EXPERT: {
    path: '/group/:id/experts/:expertId',
    method: 'DELETE',
  },
  DELETE_GROUP_SESSION: {
    path: '/group/:id/experts/:expertId/session/:resourceId',
    method: 'DELETE',
  },
  VISIBILITY_GROUP_SESSION: {
    path: '/group/:id/experts/:expertId/session/:resourceId/visibility',
    method: 'PATCH',
  },
  DELETE_GROUP_CONTENT: {
    path: '/group/:id/experts/:expertId/content/:resourceId',
    method: 'DELETE',
  },
  VISIBILITY_GROUP_CONTENT: {
    path: '/group/:id/experts/:expertId/content/:resourceId/visibility',
    method: 'PATCH',
  },
  DELETE_GROUP_ARTICLE: {
    path: '/group/:id/experts/:expertId/article/:resourceId',
    method: 'DELETE',
  },

  // Resource Management
  POST_UPDATE_GROUP_EXPERT_RESOURCES: {
    path: '/group/:id/resources',
    method: 'POST',
  },
  DELETE_GROUP_USERS: {
    path: '/group/:id/users',
    method: 'DELETE',
  },
  // Search Endpoints
  GET_EXPERTS_BY_ENT: {
    path: '/expert/search',
    method: 'GET',
  },
  GET_SESSIONS_BY_ENT: {
    path: '/session/search',
    method: 'GET',
  },
  GET_ARTICLES_BY_ENT: {
    path: '/article/groups',
    method: 'GET',
  },
  GET_ARTICLES_BY_GROUP: {
    path: '/article/search',
    method: 'GET',
  },
};

export default API;
