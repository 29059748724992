/* eslint-disable no-return-assign */
import {
  SubmitHandler, useFormContext, useFieldArray, Controller,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import React from 'react';
import { Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  ErrorMessage,
  FileUploader,
  Label,
  PrimaryAutoComplete,
  PrimaryLoadingButton, PrimaryOutlinedButton, PrimarySelect, PrimaryTextField,
} from 'components';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { IOption } from 'interfaces/option';
import { getCitiesForCountry, getCountries, getOptionsFromEnum } from 'utils/data-utils';
import { CurrencyTypes } from 'config';
import { selectGeneralInformationForm } from '../../../../../selector';
import { IOnboardGeneralInformationModalForm } from '../../../../../interface';
import OnboardCategoryModal from '../../category-modal/category-modal-view';
import OnboardSkillModal from '../../skill-modal/skill-modal-view';

interface OnboardGeneralInformationModalFormProps {
  onSubmit: SubmitHandler<any>;
}

const OnboardGeneralInformationModalForm = (props: OnboardGeneralInformationModalFormProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext<IOnboardGeneralInformationModalForm>();
  const { onSubmit } = props;
  const onboardGeneralInformation = useSelector(selectGeneralInformationForm);
  const currencyOptions: IOption[] = getOptionsFromEnum(CurrencyTypes);
  const countries: IOption[] = getCountries().map((
    country,
    id,
  ) => ({ id, value: country, text: country }));

  const [selectedCountry, setSelectedCountry] = React.useState<IOption>({
    id: '',
    value: '',
    text: '',
  });

  const [selectedCity, setSelectedCity] = React.useState<IOption>({ id: '', value: '', text: '' });
  const [cities, setCities] = React.useState<IOption[]>([{ id: 1, value: '', text: '' }]);
  const categoriesFiledArray = useFieldArray({ name: 'categories', control });
  const skillsFieldArray = useFieldArray({ name: 'skills', control });
  let j = 0;
  let k = 0;
  const addCategory = (name:string) => {
    categoriesFiledArray.append({ name });
  };
  const removeCategory = (i: number) => {
    categoriesFiledArray.remove(i);
  };

  const addSkill = (name:string) => {
    skillsFieldArray.append({ name });
  };
  const removeSkill = (i: number) => {
    skillsFieldArray.remove(i);
  };

  const handleEntLogo = (res: UploadResponse) => {
    clearErrors('entLogoUrl');
    setValue('entLogoUrl', res.url);
    if (res.statusCode !== 201) {
      setError('entLogoUrl', { type: 'custom', message: res.message });
    }
  };
  const handleWebLogo = (res: UploadResponse) => {
    clearErrors('webLogoUrl');
    setValue('webLogoUrl', res.url);
    if (res.statusCode !== 201) {
      setError('webLogoUrl', { type: 'custom', message: res.message });
    }
  };
  const handleWebLogoRemove = () => {
    setValue('webLogoUrl', '');
  };
  const handleEntLogoRemove = () => {
    setValue('entLogoUrl', '');
  };

  React.useEffect(() => {
    if (selectedCountry.value) {
      setCities(getCitiesForCountry(selectedCountry.value as string).map((
        city,
        id,
      ) => ({ id, value: city, text: city })));
    }
  }, [selectedCountry]);

  React.useEffect(() => {
    if (onboardGeneralInformation.country) {
      const currentCountry:IOption = countries.filter((country) => (
        country.value === onboardGeneralInformation.country))[0];

      setSelectedCountry({
        id: currentCountry?.id,
        value: currentCountry?.value,
        text: currentCountry?.text,
      });
      const cityId = getCitiesForCountry(currentCountry.value as string).indexOf(
        onboardGeneralInformation.city,
      );
      setSelectedCity({
        id: cityId,
        value: onboardGeneralInformation.city,
        text: onboardGeneralInformation.city,
      });
    }
  }, [onboardGeneralInformation.country]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item">Website Logos</h3>
      <div className="form-item">
        <h4>Enterprise logo</h4>
        <div style={{ textAlign: 'left' }}>
          <Label>
            This logo will be used as your account picture. We recommend using an image with a size
            of 300X300 pixels or higher in JPG or PNG format.
          </Label>
        </div>
        <FileUploader
          currentContentUrl={onboardGeneralInformation.entLogoUrl}
          handleFile={handleEntLogo}
          scenario={UploadScenarios.entLogo}
          currentMediaType="image"
          handleRemove={handleEntLogoRemove}
        />
        <ErrorMessage>{errors.entLogoUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Website logo</h4>
        <div style={{ textAlign: 'left' }}>
          <Label>
            This logo will be used on your website navigation and footer. We recommend using an
            image with a height of 45 pixels or higher in PNG format.
          </Label>
        </div>
        <FileUploader
          currentContentUrl={onboardGeneralInformation.webLogoUrl}
          handleFile={handleWebLogo}
          scenario={UploadScenarios.entLogo}
          currentMediaType="image"
          handleRemove={handleWebLogoRemove}
        />
        <ErrorMessage>{errors.webLogoUrl?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">General information</h3>
      <div className="form-item">
        <Controller
          control={control}
          name="name"
          render={({ field }) => <PrimaryTextField {...field} label="Name of enterprise" disabled />}
        />
        <ErrorMessage>{errors?.name?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="entUrl"
          render={({ field }) => <PrimaryTextField {...field} label="Enterprise URL" disabled />}
        />
        <ErrorMessage>{errors?.entUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="email"
          render={({ field }) => <PrimaryTextField {...field} label="Email address" disabled />}
        />
        <ErrorMessage>{errors?.email?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="currency"
          render={({ field }) => (
            <PrimarySelect {...field} label="Currency" options={currencyOptions} placeholder="Select Currency" />
          )}
        />
        <ErrorMessage>{errors?.currency?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">Social Media</h3>
      <div className="form-item">
        <Controller
          control={control}
          name="facebookUrl"
          render={({ field }) => <PrimaryTextField {...field} label="Facebook" />}
        />
        <ErrorMessage>{errors?.facebookUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="instagramUrl"
          render={({ field }) => <PrimaryTextField {...field} label="Instagram" />}
        />
        <ErrorMessage>{errors?.instagramUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="linkedinUrl"
          render={({ field }) => <PrimaryTextField {...field} label="LinkedIn" />}
        />
        <ErrorMessage>{errors?.linkedinUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="youtubeUrl"
          render={({ field }) => <PrimaryTextField {...field} label="Youtube" />}
        />
        <ErrorMessage>{errors?.youtubeUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="twitterUrl"
          render={({ field }) => <PrimaryTextField {...field} label="Twitter" />}
        />
        <ErrorMessage>{errors?.twitterUrl?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">Country</h3>
      <div className="form-item">
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange } }) => (
            <PrimaryAutoComplete value={selectedCountry} label="Country" placeholder="Select country" options={countries} setValue={setSelectedCountry} onChange={(value: string) => { onChange(value); setSelectedCity({ id: '', value: '', text: '' }); }} />
          )}
        />
        <ErrorMessage>{errors?.country?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="city"
          render={({ field: { onChange } }) => (
            <PrimaryAutoComplete value={selectedCity} label="City" placeholder="Select city" options={cities} setValue={setSelectedCity} onChange={onChange} />
          )}
        />
        <ErrorMessage>{errors?.city?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">Category</h3>
      <div className="form-item">
        {categoriesFiledArray.fields.map((item, i) => (
          <div key={item.id}>
            <div className="form-item">
              <div
                style={{
                  background: '#FAFAFA 0% 0% no-repeat padding-box',
                  border: '1px solid #E5E5E5',
                  padding: '1rem',
                  borderRadius: '5px',
                }}
              >
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Label>
                      <i>
                        Category #
                        {(j = i + 1)}
                      </i>
                    </Label>
                  </Grid>
                  <Grid item xs="auto" alignItems="center" sx={{ color: '#E93E3E' }}>
                    <Grid container alignItems="center">
                      <Grid item />
                      <Close />
                      <PrimaryOutlinedButton onClick={() => removeCategory(i)}>
                        Remove
                        {' '}
                      </PrimaryOutlinedButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Controller
                  name={`categories.${i as 0}.name`}
                  control={control}
                  render={({ field }) => <PrimaryTextField {...field} label="Category name" />}
                />
              </div>
              <ErrorMessage>
                {errors?.categories ? errors?.categories[i]?.name?.message : ' '}
              </ErrorMessage>
            </div>
          </div>
        ))}
      </div>
      <div className="form-item">
        <OnboardCategoryModal index={j + 1} addCategory={addCategory} />
      </div>
      <div className="form-item">
        <ErrorMessage>{errors?.categories?.message}</ErrorMessage>
      </div>

      <h3 className="form-item">Skill</h3>
      <div className="form-item">
        {skillsFieldArray.fields.map((item, i) => (
          <div key={item.id}>
            <div className="form-item">
              <div
                style={{
                  background: '#FAFAFA 0% 0% no-repeat padding-box',
                  border: '1px solid #E5E5E5',
                  padding: '1rem',
                  borderRadius: '5px',
                }}
              >
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Label>
                      <i>
                        Skill #
                        {(k = i + 1)}
                      </i>
                    </Label>
                  </Grid>
                  <Grid item xs="auto" alignItems="center" sx={{ color: '#E93E3E' }}>
                    <Grid container alignItems="center">
                      <Grid item />
                      <Close />
                      <PrimaryOutlinedButton onClick={() => removeSkill(i)}>
                        Remove
                        {' '}
                      </PrimaryOutlinedButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Controller
                  name={`skills.${i as 0}.name`}
                  control={control}
                  render={({ field }) => <PrimaryTextField {...field} label="Skill name" />}
                />
              </div>
              <ErrorMessage>
                {errors?.skills ? errors?.skills[i]?.name?.message : ' '}
              </ErrorMessage>
            </div>
          </div>
        ))}
      </div>
      <div className="form-item">
        <OnboardSkillModal index={k + 1} addSkill={addSkill} />
      </div>

      <div>
        <PrimaryLoadingButton size="large" type="submit" variant="contained">
          Save & continue
        </PrimaryLoadingButton>
      </div>
    </form>
  );
};

export default OnboardGeneralInformationModalForm;
