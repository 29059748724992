import React from 'react';
import {
  Avatar, FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import {
  ClosableModal,
  PrimaryLiteButton, PrimaryLoadingButton, PrimaryTable,
} from 'components';
import { MonitorIcon } from 'assets/icons';
import { ISessionFormAvatarWithAction } from '../../../../../interface';

interface ConfigFormModalProps {
  open:boolean,
  handleClose:()=>void;
  selectedItems:ISessionFormAvatarWithAction[],
  handleUpdate:(formList:ISessionFormAvatarWithAction[])=>void

}

const ConfigFormsModal = ({
  open, handleClose, selectedItems, handleUpdate,
}:ConfigFormModalProps) => {
  type Row = typeof selectItems[number];

  // initiate states
  const [selectItems, setSelectItems] = React.useState<ISessionFormAvatarWithAction[]>(
    JSON.parse(JSON.stringify(selectedItems)) as ISessionFormAvatarWithAction[],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id:string) => {
    const temp = [...selectItems];
    const index = temp.findIndex(((item) => item.id === id));
    temp[index].submission = event.target.value;
    setSelectItems(temp);
  };

  // update mutations
  const handleUpdateWrapper = () => {
    handleUpdate(selectItems);
    handleClose();
  };

  // configure table
  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'title',
        headerName: 'Form Title',
        headerAlign: 'left',
        align: 'left',
        flex: 0.75,
        renderCell: (params) => (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sx={{ mr: 0.7 }}>
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      mr: 1,
                      backgroundColor: 'background.default',
                      color: 'primary.main',
                      borderWidth: 1,
                      borderColor: 'primary.light',
                      borderStyle: 'solid',
                    }}
                  >
                    <MonitorIcon sx={{ fontSize: 16 }} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h4" sx={{ fontWeight: '400' }}>{params.value}</Typography>
                </Grid>

              </Grid>
            </Grid>

          </Grid>

        ),
      },
      {
        field: 'submission',
        headerName: 'Submission',
        flex: 1,
        renderCell: (params) => (
          <RadioGroup
            row
            name="submission-settings"
            value={params.value}
            onChange={(e) => { handleChange(e, params.row.id); }}
          >
            <FormControlLabel componentsProps={{ typography: { variant: 'body2' } }} value="before" control={<Radio size="small" />} label="Before" />
            <FormControlLabel componentsProps={{ typography: { variant: 'body2' } }} value="after" control={<Radio size="small" />} label="After" />
            <FormControlLabel componentsProps={{ typography: { variant: 'body2' } }} value="undefined" control={<Radio size="small" />} label="Undefined" />
          </RadioGroup>
        ),
      },

    ],
    [handleChange],
  );

  return (
    <ClosableModal
      open={open}
      onClose={handleClose}
      title="Select form submission"
      subTitle="select at which point of the session you want the forms to be submitted"
      modalClass="select-item-modal"
    >

      <div className="form-item">
        <PrimaryTable
          disableSelectionOnClick
          rows={selectItems}
          columns={columns}
          loading={false}
          pageSize={5}
        />
      </div>

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={handleClose}>Cancel</PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton loading={false} fullWidth onClick={handleUpdateWrapper}>
            Save & continue
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </ClosableModal>

  );
};

export default ConfigFormsModal;
