import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import userAllocationFormValidation from '../../validation/user-allocation-from-validation';
import UserAllocationForm from '../user-allocation-form';
import { createEntAccActions } from '../../../../slice';
import { IAddUserAllocationForm } from '../../../../interface';
import { selectUserAllocationForm } from '../../../../selector';

const UserAllocationView = () => {
  const dispatch = useDispatch();
  const userAllocationForm = useSelector(selectUserAllocationForm);
  const methods = useForm<IAddUserAllocationForm>({
    defaultValues: {
      maxNoOfGroups: userAllocationForm.maxNoOfGroups,
      maxNoOfCoaches: userAllocationForm.maxNoOfCoaches,
      maxNoOfEndUsers: userAllocationForm.maxNoOfEndUsers,
    },
    resolver: yupResolver(userAllocationFormValidation),
    shouldFocusError: true,
  });
  const onSubmit: SubmitHandler<IAddUserAllocationForm> = useCallback((data) => {
    dispatch(createEntAccActions.AddUserAllocation(data));
  }, [dispatch]);
  return (
    <Grid container justifyContent="center">
      <Grid xs item>
        <FormProvider {...methods}>
          <UserAllocationForm onSubmit={onSubmit} />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default UserAllocationView;
