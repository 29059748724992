enum FilterOptions {
  date = 'Last 30 days',
}

export enum Actions {
  create = 'create',
  purchase = 'purchase',
  signUp = 'sign-up',
}
export enum ResourceTypes {
  session = 'session',
  contentOnly = 'content-only-purchase',
  user = 'slocoach-user',
  expert = 'slocoach-expert',
  admin = 'slocoach-admin',
  group = 'group',
}

export default FilterOptions;
