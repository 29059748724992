import {
  all,
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { viewExpertActions } from './slice';
import API from './constants';
import { selectEntId } from './selector';
import { IPaginateFilterPayload, IResetPasswordAction } from './interface';

export function* getExpertsGenerator(): any {
  try {
    const id = yield select(selectEntId);
    if (id) {
      const response: IRestApiResponse = yield call(
        request,
        { path: `${API.GET_EXPERTS_BY_ENT.path}/${id}`, method: API.GET_EXPERTS_BY_ENT.method },
        null,
        true,
      );
      if (response.statusCode === 200) {
        yield put(viewExpertActions.getExpertsSucceeded({ experts: response.data }));
      } else {
        yield put(viewExpertActions.getExpertsFailed());
      }
    } else {
      throw new Error('Ent id not defined');
    }
  } catch (error) {
    yield put(viewExpertActions.getExpertsFailed());
  }
}

export function* getFilteredExpertsGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectEntId);
    if (id) {
      const response: IRestApiResponse = yield call(
        request,
        { path: `${API.GET_FILTERED_EXPERTS_BY_ENT.path}/${id}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&category=${payload.category || 'All'}&status=${payload.status || 'ACTIVE'}&withDeleted=${!payload.withoutDeleted || 'false'}`, method: API.GET_FILTERED_EXPERTS_BY_ENT.method },
        null,
        true,
      );
      if (response.statusCode === 200) {
        yield put(viewExpertActions.getFilteredExpertsSucceeded(response.data));
      } else {
        yield put(viewExpertActions.getFilteredExpertsFailed());
      }
    } else {
      throw new Error('Ent id not defined');
    }
  } catch (error) {
    yield put(viewExpertActions.getFilteredExpertsFailed());
  }
}

export function* resetPasswordGenerator({ payload }:IResetPasswordAction): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.RESET_PASSWORD,
      { id: payload },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewExpertActions.resetPasswordSucceeded(payload));
    } else {
      yield put(viewExpertActions.resetPasswordFailed());
    }
  } catch (error) {
    yield put(viewExpertActions.resetPasswordFailed());
  }
}

export function* viewExpertSaga() {
  yield all([takeLatest(
    viewExpertActions.getExperts.type,
    getExpertsGenerator,
  ), takeLatest(
    viewExpertActions.getFilteredExperts.type,
    getFilteredExpertsGenerator,
  ), takeLatest(
    viewExpertActions.resetPassword.type,
    resetPasswordGenerator,
  )]);
}

export default viewExpertSaga;
