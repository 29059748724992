import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IOnboardSitePreferencesModalForm } from '../../../../../interface';
import { selectSitePreferenceForm } from '../../../../../selector';
import { onboardEntAccActions } from '../../../../../slice';
import OnboardSitePreferencesModalForm from '../site-preferences-form';
import onboardSitePreferencesModalFormValidation from
  '../validation/site-preferences-form-validation';

const OnboardSitePreferencesModalView = () => {
  const dispatch = useDispatch();
  const SitePreferencesForm = useSelector(selectSitePreferenceForm);
  const methods = useForm<IOnboardSitePreferencesModalForm>({
    defaultValues: {
      colors: SitePreferencesForm.colors,
      sponsorContent: SitePreferencesForm.sponsorContent,
      promotionalContent: SitePreferencesForm.promotionalContent,
      headerFont: {
        name: SitePreferencesForm?.headerFont?.name || '', url: SitePreferencesForm?.headerFont?.url || '',
      },
      bodyFont: {
        name: SitePreferencesForm?.bodyFont?.name || '', url: SitePreferencesForm?.bodyFont?.url || '',
      },
      headerTemplate: SitePreferencesForm.headerTemplate,
      isFontDefault: SitePreferencesForm.isFontDefault,
    },
    resolver: yupResolver(onboardSitePreferencesModalFormValidation),
    shouldFocusError: true,
  });
  const onSubmit: SubmitHandler<IOnboardSitePreferencesModalForm> = useCallback(
    (data) => {
      dispatch(onboardEntAccActions.AddSitePreferences(data));
      dispatch(onboardEntAccActions.editSitePreferences(data));
    },
    [dispatch],
  );
  return (
    <Grid container justifyContent="center">
      <Grid xs item>
        <FormProvider {...methods}>
          <OnboardSitePreferencesModalForm onSubmit={onSubmit} loading={false} />
        </FormProvider>
      </Grid>
    </Grid>
  );
};
export default OnboardSitePreferencesModalView;
