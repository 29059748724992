/* eslint-disable no-param-reassign */
import * as React from 'react';
import { Grid } from '@mui/material';
import { TrashIcon, UploadIcon } from 'assets/icons';
import './index.scss';
import handleContentDataUpload, { uploadConfig } from 'utils/file-uploader';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { isStreamableVideoAvailable, replaceExt } from 'utils/data-utils';
import { PrimaryLoadingOutlinedButton } from '../../molecules/primary-button';
import ContentMediaView from '../content-media-view';

interface FileUploaderProps {
  handleFile: (res:UploadResponse)=> void,
  handleRemove:()=>void,
  currentContentUrl:string,
  scenario:UploadScenarios
  currentMediaType:string,
}

const FileUploader = ({
  handleFile, handleRemove, currentContentUrl, scenario, currentMediaType,
}:FileUploaderProps) => {
  const [uploading, setUploading] = React.useState(false);
  const [contentUrl, setContentUrl] = React.useState(currentContentUrl);
  const [mediaType, setMediaType] = React.useState(currentMediaType);
  const hiddenFileInput:any = React.useRef(null);
  const allowedExtension = (uploadConfig.get(scenario)?.allowedExtensions as string[]).join(',');
  const [fileName, setFileName] = React.useState('');

  // triggers the native input file button on click
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleRemoveWrapper = async () => {
    setUploading(true);
    setContentUrl('');
    handleRemove();
    setUploading(false);
  };

  // handles file upload and send the image content data object to the parent component
  const handleChange = async (event:any) => {
    setUploading(true);
    const res = await handleContentDataUpload(
      event,
      currentContentUrl,
      scenario,
    );
    setFileName(res.fileData?.fileName || `${res.name}${res.extension}`);
    setMediaType(res.category as string);
    if (res.category === 'video') {
      await isStreamableVideoAvailable(res.url);
      setContentUrl(replaceExt(res.url, '.m3u8'));
      setUploading(false);
      handleFile(res);
    } else {
      setTimeout(async () => {
        setContentUrl(res.url);
        setUploading(false);
        handleFile(res);
      }, 2000);
    }
    event.target.value = null;
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item xs className="grid-item">
        <div style={{ height: mediaType === 'image' ? '6rem' : '8rem', width: mediaType === 'image' ? '6rem' : '100%' }}>
          <ContentMediaView url={contentUrl} mediaType={mediaType} fileName={fileName} />
        </div>
      </Grid>
      <Grid item xs="auto">
        <Grid container>
          <Grid item xs="auto" className="grid-item">
            <PrimaryLoadingOutlinedButton
              loading={uploading}
              onClick={handleClick}
            >
              <UploadIcon sx={{ margin: '0 0.5rem 0 0' }} />
              Upload new
            </PrimaryLoadingOutlinedButton>

            <input
              type="file"
              accept={allowedExtension}
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
            />
          </Grid>
          <Grid item xs="auto" className="grid-item">
            <PrimaryLoadingOutlinedButton
              loading={uploading}
              sx={{ color: '#E93E3E' }}
              size="small"
              onClick={handleRemoveWrapper}
            >
              <TrashIcon color="error" style={{ margin: '0 0.5rem 0 0' }} />

              Remove
            </PrimaryLoadingOutlinedButton>

          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};
export default FileUploader;
