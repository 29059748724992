import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import './index.scss';
import { useDispatch } from 'react-redux';

import { useCallback } from 'react';
import ResetTempPasswordFormValidation from 'pages/reset-temp-password/validation/reset-password-form-validation';
import {
  Label, Title,
} from 'components';
import useAuthorization from 'base/auth/hooks/use-authorization';
import { BaseLayout } from 'components/templates';
import { authSliceActions } from 'base/auth/slice';
import { IResetTempPasswordForm } from 'pages/reset-temp-password/interface';
import ResetTempPasswordForm from '../reset-temp-password-form';

const ResetTempPasswordView = () => {
  useAuthorization();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const tempPassword = urlParams.get('tempPassword');

  const methods = useForm<IResetTempPasswordForm>({
    defaultValues: {
      password: tempPassword as string,
      name: email?.replace(' ', '+'),
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(ResetTempPasswordFormValidation),
    shouldFocusError: true,
  });
  const dispatch = useDispatch();

  const handleSubmitFrom: SubmitHandler<IResetTempPasswordForm> = useCallback(
    (data) => {
      dispatch(authSliceActions.resetTempPassword(data));
    },
    [dispatch],
  );

  return (
    <BaseLayout>
      <>
        <br />
        <div style={{ textAlign: 'center' }}>
          <Title>Welcome to SLOCOACH</Title>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Label>
            Almost there! This is the email
            {' '}
            {email}
            {' '}
            have been invited to setup the password.
            Once that&apos;s done, we can get into the account.
          </Label>
        </div>
        <br />
        <FormProvider {...methods}>
          <ResetTempPasswordForm onSubmit={handleSubmitFrom} />
        </FormProvider>
      </>
    </BaseLayout>
  );
};

export default ResetTempPasswordView;
