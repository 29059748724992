/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isUserDeleted } from 'utils/data-utils';
import { Role } from 'interfaces/user-roles';
import { IUserDocument } from '../../interface';
import { EditUserState, IGeneralInformationModalForm, ModalStates } from './interface';

export const initialState: EditUserState = {
  stateLoading: false,
  loading: false,
  isDeleted: false,
  id: '',
  modal: '',
  generalInformation: {
    firstName: '',
    lastName: '',
    profileImgUrl: '',
    bio: '',
    email: '',
    country: '',
    height: '',
    weight: '',
    bmi: '',
    guardian: { email: '', name: '', dob: '' },
  },
  sessionsPurchased: [],
  subscriptedExperts: [],
  subscriptedGroups: [],
  followingExperts: [],
  followingGroups: [],
  badges: [],

};

export const editUserSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/user/edit-user',
  initialState,
  reducers: {
    openModal(state: EditUserState, action: PayloadAction<string>) {
      state.modal = action.payload;
    },
    closeModal(state: EditUserState) {
      state.modal = ModalStates.close;
    },
    getUser(state: EditUserState) {
      state.stateLoading = true;
    },
    getUserSucceeded(state: EditUserState, action: PayloadAction<IUserDocument>) {
      const user = action.payload;
      state.stateLoading = false;
      state.loading = false;
      state.generalInformation.firstName = user.firstName || '';
      state.generalInformation.lastName = user.lastName || '';
      state.generalInformation.email = user.email || '';
      state.generalInformation.profileImgUrl = user.profileImgUrl || '';
      state.generalInformation.country = user.country || '';
      state.generalInformation.bio = user.bio || '';
      state.generalInformation.guardian = user.attributes?.guardian || undefined;
      state.generalInformation.height = user.attributes?.height || '';
      state.generalInformation.weight = user.attributes?.weight || '';
      state.generalInformation.bmi = user.attributes?.bmi || '';
      state.sessionsPurchased = user.attributes?.sessionsPurchased || [];
      state.followingExperts = user.attributes?.experts;
      state.followingGroups = user.attributes?.groups;
      state.subscriptedExperts = user.attributes?.expertsPurchased || [];
      state.subscriptedGroups = user.attributes?.groupsPurchased || [];
      state.badges = user.attributes?.badges || [];
      state.isDeleted = isUserDeleted(user, Role.User);
    },
    getUserFailed(state: EditUserState) {
      state.stateLoading = false;
    },
    setId(state: EditUserState, action: PayloadAction<string>) {
      state.id = action.payload;
    },

    editGeneralInformation(
      state: EditUserState,
      _action: PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = true;
    },
    editGeneralInformationSucceeded(
      state: EditUserState,
      action: PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = false;
      state.generalInformation = action.payload;
      state.modal = ModalStates.close;
    },

    editGeneralInformationFailed(state: EditUserState) {
      state.loading = false;
    },

    deleteUser(state: EditUserState) {
      state.loading = true;
    },
    deleteUserSucceeded(state: EditUserState) {
      state.loading = false;
      state.modal = ModalStates.close;
    },
    deleteUserFailed(state: EditUserState) {
      state.loading = false;
    },
  },
});

export const { actions: editUserActions } = editUserSlice;
