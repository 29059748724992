/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { UploadIcon } from 'assets/icons';
import './index.scss';

interface CSVFileDragUploaderProps {
  textLabel: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CSVFileDragUploader = ({ onChange, textLabel }: CSVFileDragUploaderProps) => {
  const hiddenFileInput: any = React.useRef(null);
  const [uploading, setUploading] = React.useState(false);
  const handleFileWrapper = async (event: any) => {
    setUploading(true);
    onChange(event);
    setUploading(false);
  };
  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileWrapper(e);
    }
  };
  // triggers when file is selected with click
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFileWrapper(e);
    }
  };

  return (
    <>
      <Box />
      {uploading ? (
        <Box className="file-upload-on-upload">
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
            <div>{textLabel}</div>
          </div>
        </Box>
      ) : (
        <Box onDragEnter={handleDrag} className={dragActive ? 'drag-active' : ''}>
          <input type="file" id="input-file-upload" onChange={handleChange} ref={hiddenFileInput} accept=".csv" />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? 'drag-active' : ''}
          >
            <div style={{ textAlign: 'center' }}>
              <UploadIcon />
              <div>{textLabel}</div>
            </div>
          </label>

          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              onChange={handleChange}
            />
          )}
        </Box>
      )}
    </>
  );
};
export default CSVFileDragUploader;
