import {
  call, put, all, takeLatest, select,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { createUserActions } from './slice';
import API from '../../constant';
import { ICreateBulkUsers, ICreateUser } from './interface';
import { selectGeneralInformation, selectId } from '../../../../selector';
import { viewUserActions } from '../view-users/slice';

export function* createUserGenerator({ payload }: PayloadAction<ICreateUser>): any {
  try {
    const { email } = payload;

    const entId = yield select(selectId);
    const { entUrl } = yield select(selectGeneralInformation);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_USER_BY_ADMIN,
      {
        entId: [entId],
        entUrl,
        email,
        attributes: {},
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(createUserActions.createUserSucceeded());
      yield put(viewUserActions.viewFilteredUser({ keyword: '', page: 1, limit: 25 }));
    } else {
      yield put(createUserActions.createUserFailed());
    }
  } catch (error) {
    yield put(createUserActions.createUserFailed());
  }
}

export function* createUsersGenerator({ payload }:PayloadAction<ICreateBulkUsers>) {
  const entId: string = yield select(selectId);
  const { emails } = payload;
  const { entUrl } = yield select(selectGeneralInformation);
  try {
    for (let i = 0; i < emails.length; i += 1) {
      yield call(
        request,
        API.POST_CREATE_USER_BY_ADMIN,
        {
          entId: [entId],
          entUrl,
          email: emails[i],
          attributes: {},
        },
        true,
      );
    }
    yield put(createUserActions.createUserSucceeded());
    yield put(viewUserActions.viewFilteredUser({ keyword: '', page: 1, limit: 25 }));
  } catch (error) {
    yield put(createUserActions.createUserFailed());
  }
}

export function* createUserSaga() {
  yield all([
    takeLatest(createUserActions.createUser.type, createUserGenerator),
    takeLatest(createUserActions.createUsers.type, createUsersGenerator),
  ]);
}

export default createUserSaga;
