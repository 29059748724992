/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModifyPluginStatePayload, IPlguinsRequest, IViewPluginState } from './interface';

export const initialState: IViewPluginState = {
  stateLoading: false,
  plugins: [],
  entId: '',
};

export const pluginSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/plugins/view-plugin',
  initialState,
  reducers: {
    setEntId(state: IViewPluginState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    viewPlugins(state) {
      state.stateLoading = true;
    },
    viewPluginsSucceeded(state, action: PayloadAction<IPlguinsRequest>) {
      state.plugins = action.payload;
      state.stateLoading = false;
    },
    viewPluginsFailed(state) {
      state.stateLoading = false;
    },
    modifyPluginState(state, action: PayloadAction<IModifyPluginStatePayload>) {
      const plugin = state.plugins.find((p) => p.id === action.payload.id);
      if (plugin) {
        plugin.isActive = action.payload.isActive;
      }
    },
  },
});

export const { actions: viewPluginActions } = pluginSlice;
