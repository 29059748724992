/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Avatar, CardHeader, Grid, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridColumns } from '@mui/x-data-grid';
import {
  ClosableModal,
  PrimaryChip,
  PrimaryChipsSlider,
  PrimaryLiteButton, PrimaryLoadingButton, PrimaryOutlinedButton, PrimaryTable, SearchBar,
} from 'components';
import { IGroupAvatar, IGroupAvatarWithAction } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/interface';
import { GroupTypes } from 'config';

interface SelectGroupModalProps {
  selectedGroups:IGroupAvatarWithAction[],
  filteredGroup:IGroupAvatar[],
  total:number,
  loading:boolean,
  handleUpdate:(groupList:IGroupAvatarWithAction[])=>void,
  searchFunction:(keyword:string, page:number)=>void,
  selectOne?:boolean,
  maxSelect?:number,
  title?:string,
  subTitle?:string,
  modalState?:boolean | undefined,
  setModelState?:(value:boolean)=>void | undefined
  confirmationLoading?:boolean
  multiplePublicGroups?:boolean,
  publicGroupSelected?:boolean | undefined,
  setPublicGroupSelected?:(value:boolean)=>void | undefined,
  overWriteText?:string

}

const SelectGroupModal = ({
  selectedGroups, filteredGroup, total, loading, handleUpdate, searchFunction, title = 'Select Group', subTitle = '',
  selectOne = false, maxSelect = 0, modalState = undefined, setModelState = undefined,
  confirmationLoading = false, multiplePublicGroups = false, publicGroupSelected = undefined,
  setPublicGroupSelected = undefined, overWriteText,
}:SelectGroupModalProps) => {
  type Row = typeof groups[number];

  // initiate states
  const [open, setOpen] = React.useState<boolean>(modalState || false);
  const [keyword, setKeyword] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [selectedItems, setSelectedItems] = React.useState<IGroupAvatarWithAction[]>(
    selectedGroups,
  );
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [groups, setGroups] = React.useState<IGroupAvatarWithAction[]>(
    filteredGroup?.map((group) => ({
      ...group,
      isAdded: (selectedIds.includes(group._id)),
    })),
  );

  // handle modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (setModelState) { setModelState(false); }
    setOpen(false);
  };

  // handle search
  const handleSearchChange = (event:any) => {
    setKeyword(event.target.value);
  };

  // handle mutations
  const handleRemoveItem = (id:string) => {
    setSelectedItems((prev) => ([...prev.filter((group) => group._id !== id)]));
  };

  const addGroup = (id:string) => {
    const temp = [...groups];
    const index = temp.findIndex(((item:IGroupAvatarWithAction) => item._id === id));
    temp[index].isAdded = true;
    if (multiplePublicGroups && publicGroupSelected !== undefined && !publicGroupSelected
       && temp[index].groupType === GroupTypes.public) {
      if (setPublicGroupSelected) setPublicGroupSelected(true);
    }

    // updating the chips
    const tempSelectItems = (selectOne && !publicGroupSelected)
      ? [temp[index]] : [...selectedItems, temp[index]];
    setSelectedItems(tempSelectItems);
  };

  // update mutations
  const handleUpdateWrapper = () => {
    handleUpdate(selectedItems);
    if (!setModelState) { handleClose(); }
  };

  // configure table
  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'title',
        headerName: 'Group Title',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => (
          <CardHeader
            avatar={(
              <Avatar
                src={params.row.bannerImgUrl === '' ? '/static/images/avatar/1.jpg' : params.row.bannerImgUrl}
                sx={{ width: 40, height: 40 }}
              />
          )}
            title={(
              <Typography variant="h4" noWrap>
                {params.value}

              </Typography>
          )}
          />

        ),
      },
      {
        field: 'groupType',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        flex: 0.5,
        renderCell: (params) => (
          <div className="status-tag" style={{ background: '#dfdfe2' }}>
            {params.value
            && params.value.charAt(0).toUpperCase() + params.value.slice(1)}
          </div>
        ),
      },
      {
        field: 'isAdded',
        headerName: '',
        headerAlign: 'left',
        align: 'left',
        flex: 0.5,
        renderCell: (params) => (
          <div>
            {params.value ? (
              <PrimaryOutlinedButton>
                Added
              </PrimaryOutlinedButton>
            ) : (
              <>
                {maxSelect > 0 && selectedItems.length < maxSelect && (
                  <PrimaryOutlinedButton onClick={() => { addGroup(params.row._id); }}>
                    Add
                  </PrimaryOutlinedButton>
                )}
                {maxSelect === 0 && (
                  <PrimaryOutlinedButton onClick={() => { addGroup(params.row._id); }}>
                    Add
                  </PrimaryOutlinedButton>
                )}
              </>

            )}

          </div>
        ),
      },

    ],
    [addGroup],
  );

  React.useEffect(() => {
    searchFunction(keyword, page);
  }, [keyword, page, publicGroupSelected]);

  React.useEffect(() => {
    if (filteredGroup) {
      const temp = filteredGroup?.map((group) => ({
        ...group,
        isAdded: (selectedIds.includes(group._id)),
      }));
      setGroups(JSON.parse(JSON.stringify(temp)) as IGroupAvatarWithAction[]);
    }
    if (selectedIds.length === 0 && setPublicGroupSelected) setPublicGroupSelected(false);
  }, [filteredGroup, selectedIds]);

  React.useEffect(() => {
    setSelectedIds(selectedItems.map((group:IGroupAvatarWithAction) => group._id));
  }, [selectedItems]);

  React.useEffect(() => {
    setSelectedItems(selectedGroups);
    if (selectedGroups) {
      if (multiplePublicGroups && publicGroupSelected !== undefined && !publicGroupSelected
        && selectedGroups[0]?.groupType === GroupTypes.public) {
        if (setPublicGroupSelected) setPublicGroupSelected(true);
      }
    }
  }, [selectedGroups, open]);

  // keep the external modal state sync with the internal modal state
  React.useEffect(() => {
    if (modalState !== undefined) { setOpen(modalState); }
  }, [modalState]);

  React.useEffect(() => {
    if (setModelState) { setModelState(open); }
  }, [open]);

  return (
    <>
      <PrimaryOutlinedButton
        onClick={handleOpen}
        startIcon={<AddIcon />}
      >
        {overWriteText || 'Add Group'}
      </PrimaryOutlinedButton>
      <ClosableModal
        open={open}
        onClose={handleClose}
        title={title}
        subTitle={subTitle}
        modalClass="select-item-modal"
      >

        <div className="form-item">
          <SearchBar
            value={keyword}
            onChange={handleSearchChange}
            placeholder="Search by group name"
          />
        </div>
        <div className="form-item">
          {selectedItems.length > 0 ? (
            <PrimaryChipsSlider>
              {selectedItems.map((item) => (
                <PrimaryChip
                  key={item._id}
                  avatar={(
                    <Avatar
                      sx={{
                        backgroundColor: 'background.default',
                        borderColor: 'primary.light',
                      }}
                      src={item.bannerImgUrl}
                    />
                  )}
                  label={item.title}
                  variant="outlined"
                  onDelete={() => { handleRemoveItem(item._id); }}
                />

              ))}
            </PrimaryChipsSlider>

          ) : ''}
        </div>
        <div className="form-item">
          <PrimaryTable
            disableSelectionOnClick
            rows={groups}
            columns={columns}
            loading={loading}
            page={page}
            pageSize={3}
            rowsPerPageOptions={[3]}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            rowCount={total}
            paginationMode="server"
            getRowId={(row) => row._id}
          />
        </div>

        <Grid container spacing={3}>
          <Grid item xs>
            <PrimaryLiteButton fullWidth onClick={handleClose}>Cancel</PrimaryLiteButton>
          </Grid>
          <Grid item xs>
            <PrimaryLoadingButton
              loading={confirmationLoading}
              fullWidth
              onClick={handleUpdateWrapper}
            >
              Add & continue
            </PrimaryLoadingButton>
          </Grid>

        </Grid>
      </ClosableModal>

    </>

  );
};

export default SelectGroupModal;
