import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';

const createAdminModalFormValidation = yup.object().shape({
  profileImg: yup.string().trim(),
  firstName: yup.string().trim().required('First name is a required field'),
  lastName: yup.string().trim().required('Last name is a required field'),
  email: yup
    .string()
    .trim()
    .required('Email is a required field')
    .test(
      'is-valid',
      (message) => `${message.path} is invalid`,
      (value) => (value ? isEmail(value) : new yup.ValidationError('Invalid value')),
    ),
  authorityLevel: yup.string().trim().required('Authority level is a required field'),
  accessRecordings: yup.boolean().required('Access recordings is a required field'),
  authenticateComplaints: yup.boolean().required('Authenticate complaints is a required field'),
  accessClasses: yup.boolean().required('Access classes is a required field'),
});
export default createAdminModalFormValidation;
