/* eslint-disable no-unsafe-optional-chaining */
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectUserSignInAnalytics } from '../../selector';

Chart.register(...registerables);
const UserChart = () => {
  const userSignInAnalytics = useSelector(selectUserSignInAnalytics);
  const chartData = {
    labels: [...userSignInAnalytics?.users?.timeSeries?.map((date) => moment(date).format('DD MMM'))],

    datasets: [
      {
        label: 'admin',
        data: userSignInAnalytics?.admins?.countSeries,
        backgroundColor: '#79D4F1',
      },
      {
        label: 'expert',
        data: userSignInAnalytics?.experts?.countSeries,
        backgroundColor: '#BA80C6',
      },
      {
        label: 'user',
        data: userSignInAnalytics?.users?.countSeries,
        backgroundColor: '#F7C85C',
      },
    ],
  };

  return (
    <Box sx={{ m: '1rem ' }}>
      <Bar
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
            },
          },
        }}
      />
    </Box>
  );
};
export default UserChart;
