/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/create-ent-acc'] || initialState;

export const selectCreateEntAccState = createSelector([selectDomain], (CreateEntAccState) => CreateEntAccState);
export const selectActive = createSelector([selectDomain], (CreateEntAccState) => CreateEntAccState.active);
export const selectGeneralInformationForm = createSelector([selectDomain], (CreateEntAccState) => CreateEntAccState.generalInformationForm);
export const selectUserAllocationForm = createSelector([selectDomain], (CreateEntAccState) => CreateEntAccState.userAllocationForm);
export const selectChargeForm = createSelector([selectDomain], (CreateEntAccState) => CreateEntAccState.chargesForm);
export const selectStatus = createSelector([selectDomain], (CreateEntAccState) => CreateEntAccState.status);
export const selectLoader = createSelector([selectDomain], (CreateEntAccState) => CreateEntAccState.loading);
