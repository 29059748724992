import {
  all, call, delay, put, race, select, take, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { Role } from 'interfaces/user-roles';
import {
  CategoryResourceType,
} from 'config';
import API from './constants';
import {
  selectBadges, selectBadgesFromAdmin, selectDeleteCheckId, selectId,
} from './selector';
import { selectGeneralInformation, selectId as selectEntId } from '../../../../selector';
import {
  IAccountTypeModalForm,
  IAchievementsModalForm,
  IActive,
  IBadgesFromAdminModalForm,
  IBadgesModalForm,
  IBankDetailsModalForm,
  IGeneralInformationModalForm,
  ISponsorContentModalForm,
  IStatisticsModalForm,
} from './interface';
import { editExpertActions } from './slice';
import { ExpertDocument } from '../../interface';

function* assignCategories(resourceId: string, categories: string[]) {
  yield call(request, API.POST_ASSIGN_CATEGORIES, {
    resourceId,
    categoryId: categories,
    resourceType: CategoryResourceType.EXPERT,
  });
}

export function* editGeneralInformationGenerator({
  payload,
}: PayloadAction<IGeneralInformationModalForm>): any {
  try {
    const {
      firstName,
      lastName,
      email,
      profileImgUrl,
      currency,
      bio,
      category,
      introVideoUrl,
      thankVideoUrl,
      rateVideoUrl,
      categories,
    } = payload;
    const id = yield select(selectId);
    const { entUrl } = yield select(selectGeneralInformation);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      {
        firstName,
        lastName,
        email,
        profileImgUrl,
        bio,
        attributes: {
          category,
          introVideoUrl,
          thankVideoUrl,
          rateVideoUrl,
          currency,
        },
        entUrl,
        id,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield assignCategories(id, categories);
      yield put(
        editExpertActions.getExpertSucceeded({
          ...response.data,
          categories: categories.map((i) => ({ id: i })),
        } as ExpertDocument),
      );
      yield put(editExpertActions.closeModal());
    } else {
      yield put(editExpertActions.editGeneralInformationFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editGeneralInformationFailed());
  }
}
export function* editAccountTypeGenerator({ payload }: PayloadAction<IAccountTypeModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      { attributes: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.editAccountTypeSucceeded({ ...payload }));
    } else {
      yield put(editExpertActions.editAccountTypeFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editAccountTypeFailed());
  }
}

export function* editBankDetailsGenerator({ payload }: PayloadAction<IBankDetailsModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      { attributes: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.editBankDetailsSucceeded({ ...payload }));
    } else {
      yield put(editExpertActions.editBankDetailsFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editBankDetailsFailed());
  }
}

export function* editSponsorContentGenerator({
  payload,
}: PayloadAction<ISponsorContentModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      { attributes: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.editSponsorContentSucceeded({ ...payload }));
      yield put(editExpertActions.closeModal());
    } else {
      yield put(editExpertActions.editSponsorContentFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editSponsorContentFailed());
  }
}

export function* editAchievementGenerator({ payload }: PayloadAction<IAchievementsModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      { attributes: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.editAchievementSucceeded({ ...payload }));
    } else {
      yield put(editExpertActions.editAchievementFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editAchievementFailed());
  }
}
export function* editStatisticGenerator({ payload }: PayloadAction<IStatisticsModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      { attributes: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.editStatisticSucceeded({ ...payload }));
    } else {
      yield put(editExpertActions.editStatisticFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editStatisticFailed());
  }
}
export function* editBadgeGenerator({ payload }: PayloadAction<IBadgesModalForm>): any {
  try {
    const id = yield select(selectId);
    const badgesFromAdmin = yield select(selectBadgesFromAdmin);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      { attributes: { badges: payload.badges.concat(badgesFromAdmin) }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.editBadgeSucceeded({ ...payload }));
      window.location.reload();
    } else {
      yield put(editExpertActions.editBadgeFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editBadgeFailed());
  }
}
export function* editBadgeFromAdminGenerator({
  payload,
}: PayloadAction<IBadgesFromAdminModalForm>): any {
  try {
    const id = yield select(selectId);
    const badges = yield select(selectBadges);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      { attributes: { badges: badges.concat(payload.badgesFromAdmin) }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.editBadgeFromAdminSucceeded({ ...payload }));
      window.location.reload();
    } else {
      yield put(editExpertActions.editBadgeFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editBadgeFailed());
  }
}

export function* editActiveGenerator({ payload }: PayloadAction<IActive>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_EXPERT,
      { attributes: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.editActiveSucceeded(payload.isActive));
    } else {
      yield put(editExpertActions.editActiveFailed());
    }
  } catch (error) {
    yield put(editExpertActions.editActiveFailed());
  }
}

function* getCategories(resourceId: string) {
  const response: IRestApiResponse = yield call(
    request,
    {
      path: `${API.GET_CATEGORIES.path}?resourceId=${resourceId}&resourceType=${CategoryResourceType.EXPERT}`,
      method: API.GET_CATEGORIES.method,
    },
    null,
    true,
  );

  return response;
}

export function* getExpertGenerator(): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_EXPERT.path}/${id}`, method: API.GET_EXPERT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      const categories: IRestApiResponse = yield getCategories(id);
      yield put(
        editExpertActions.getExpertSucceeded({
          ...response.data,
          categories: categories.data,
        }),
      );
    } else {
      yield put(editExpertActions.getExpertFailed());
    }
  } catch (error) {
    yield put(editExpertActions.getExpertFailed());
  }
}

export function* getExpertUsersGenerator({
  payload,
}: {
  payload: { keyword: string; page: number; limit: number, userType: string };
}): any {
  try {
    const id = yield select(selectId);
    const {
      keyword, page, limit, userType,
    } = payload;

    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_EXPERT_USERS.path}?expertId=${id}&keyword=${keyword}&page=${page}&limit=${limit}&userType=${userType}`,
        method: API.GET_EXPERT.method,
      },
      null,
      true,
    );

    if (response.statusCode === 200) {
      yield put(
        editExpertActions.getExpertUsersSucceeded({
          ...response.data,
        }),
      );
    } else {
      yield put(editExpertActions.getExpertUsersFailed());
    }
  } catch (error) {
    yield put(editExpertActions.getExpertUsersFailed());
  }
}

export function* deleteExpertCheckStatusPollingWorker(): any {
  while (true) {
    try {
      const jobId = yield select(selectDeleteCheckId);
      const response: IRestApiResponse = yield call(
        request,
        API.DELETE_EXPERT_CHECK_STATUS,
        { jobId },
        true,
      );
      if (response.statusCode === 200) {
        yield put(editExpertActions.deleteExpertCheckSucceeded(response.data?.returnvalue));
        yield put(editExpertActions.cancelPollingWork());
      } else {
        yield delay(2500); // 2500 ms
      }
    } catch (error) {
      yield put(editExpertActions.deleteExpertCheckFailed());
    }
  }
}

export function* deleteExpertCheckStatusWatchWorker() {
  yield race({
    task: call(deleteExpertCheckStatusPollingWorker),
    cancel: take(editExpertActions.cancelPollingWork),
  });
}

export function* deleteExpertCheckGenerator(): any {
  try {
    const userId = yield select(selectId);
    const role = Role.Expert;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_EXPERT_CHECK,
      { userId, role },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editExpertActions.setDeleteCheckId(response.data?.id));
      // start polling
      yield call(deleteExpertCheckStatusWatchWorker);
    } else {
      yield put(editExpertActions.deleteExpertCheckFailed());
    }
  } catch (error) {
    yield put(editExpertActions.deleteExpertCheckFailed());
  }
}

export function* deleteExpertGenerator(): any {
  try {
    const userId = yield select(selectId);
    const entId = yield select(selectEntId);
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_EXPERT,
      { userId, entId },
      true,
    );
    if (response.statusCode === 202) {
      yield put(editExpertActions.deleteExpertSucceeded());
      window.location.pathname = `/ent-account/edit/${entId}/experts`;
    } else {
      yield put(editExpertActions.deleteExpertFailed());
    }
  } catch (error) {
    yield put(editExpertActions.deleteExpertFailed());
  }
}

export function* recoverExpertGenerator(): any {
  try {
    const userId = yield select(selectId);
    const role = Role.Expert;
    const response: IRestApiResponse = yield call(
      request,
      API.RECOVER_EXPERT,
      { userId, role },
      true,
    );
    if (response.statusCode === 202) {
      yield put(editExpertActions.recoverExpertSucceeded());
    } else {
      yield put(editExpertActions.recoverExpertFailed());
    }
  } catch (error) {
    yield put(editExpertActions.recoverExpertFailed());
  }
}
export function* editExpertSaga() {
  yield all([
    takeLatest(editExpertActions.editGeneralInformation.type, editGeneralInformationGenerator),
    takeLatest(editExpertActions.editAccountType.type, editAccountTypeGenerator),
    takeLatest(editExpertActions.editAchievement.type, editAchievementGenerator),
    takeLatest(editExpertActions.editStatistic.type, editStatisticGenerator),
    takeLatest(editExpertActions.editBankDetails.type, editBankDetailsGenerator),
    takeLatest(editExpertActions.editSponsorContent.type, editSponsorContentGenerator),
    takeLatest(editExpertActions.editBadge.type, editBadgeGenerator),
    takeLatest(editExpertActions.editBadgeFromAdmin.type, editBadgeFromAdminGenerator),
    takeLatest(editExpertActions.editActive.type, editActiveGenerator),
    takeLatest(editExpertActions.deleteExpertCheck.type, deleteExpertCheckGenerator),
    takeLatest(editExpertActions.deleteExpert.type, deleteExpertGenerator),
    takeLatest(editExpertActions.recoverExpert.type, recoverExpertGenerator),
    takeLatest(editExpertActions.getExpert.type, getExpertGenerator),
    takeLatest(editExpertActions.getExpertUsers, getExpertUsersGenerator),
  ]);
}

export default editExpertSaga;
