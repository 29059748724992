import React from 'react';
import './scss/index.scss';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeWrapper from 'config/theme-wrapper';
import { injectStore as injectStoreRequest } from 'utils/request';
import { injectStore as injectStoreFormats } from 'utils/formats';

import reportWebVitals from './reportWebVitals';
import store from './store';
import Router from './routes/router';
// import i18n from './i18n';
import '@surge-global-engineering/form-builder/dist/fm-editor-inbuilt.css';
import '@surge-global-engineering/form-builder/dist/fm-editor-custom.css';
import '@surge-global-engineering/form-builder/dist/fm-aos.min.css';
import '@surge-global-engineering/form-builder/dist/photoswipe.css';

const persistor = persistStore(store);
injectStoreRequest(store);
injectStoreFormats(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <I18nextProvider i18n={i18n}> */}
      <ThemeWrapper>
        <CssBaseline />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ThemeWrapper>
      {/* </I18nextProvider> */}
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
