/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-sessions'] || initialState;

export const selectViewSessionsState = createSelector([selectDomain], (ViewSessionState) => ViewSessionState);
export const selectFilteredSessions = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.filteredSessions);
export const selectSessionLoader = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.stateLoading);
export const selectLoader = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.loading);
export const selectExpertId = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.expertId);
export const selectEntId = createSelector([selectDomain], (ViewSessionState) => ViewSessionState.entId);
