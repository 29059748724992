const API = {
  GET_FORMS_BY_EXPERT: {
    path: '/form/search',
    method: 'GET',
  },
  GET_FORM_BY_ID: {
    path: '/form/details',
    method: 'GET',
  },
  POST_CREATE_FORM: {
    path: '/form/create',
    method: 'POST',
  },
  POST_UPDATE_FORM: {
    path: '/form/update',
    method: 'POST',
  },
  POST_DELETE_FORM: {
    path: '/form/delete',
    method: 'POST',
  },
};
export default API;
