import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';

const generalInformationFormValidation = yup.object().shape({
  name: yup.string().trim().required('Name of enterprise is a required field').matches(/^[A-Za-z0-9-\s]+$/, 'Name should only contain letter and numbers')
    .max(40, 'Name should only have a maximum length of 40 characters'),
  entUrl: yup.string().trim().required('Enterprise URL is a required field'),
  email: yup.string().trim().required('Email address is a required field').test('is-valid', (message) => `${message.path.charAt(0).toUpperCase() + message.path.slice(1)} is invalid`, (value) => (value ? isEmail(value) : new yup.ValidationError('Invalid value'))),
  currency: yup.string().trim().required('Currency is a required field'),
  country: yup.string().trim().required('Country is a required field'),
  city: yup.string().trim().required('City is a required field'),
  slocoachAdmin: yup.string().trim().required('Slocoach admin is a required field'),

});
export default generalInformationFormValidation;
