import { IFiltered } from 'interfaces/api-response';
import { IEntAccountAvatar } from 'pages/dashboard/components/ent-account/interface';
import { IContentAvatar } from '../../../content/interface';
import {
  Frequency,
  IAvailableTime,
  IBadge,
  IInvitation,
  ISessionMeta,
  IWaitingMedia,
} from '../../interface';
import { IExpertAvatar } from '../../../../../../interface';

export enum ModalState {
  deletePending = 'DELETE_PENDING',
  deleteComplete = 'DELETE_COMPLETE',
  close = 'CLOSE',
  resendInvite = 'RESEND_INVITE',
  active = 'ACTIVE',
  cancelPending = 'CANCEL_PENDING',
  cancelComplete = 'CANCEL_COMPLETE',
}
export interface IModuleSession {
  id: string;
  title: string;
  thumbnailUrl: string;
  sessionType: string;
  audienceType: string;
}
export interface ISummaryModule {
  name: string;
  session: IModuleSession[];
  form: string[];
  content: IContentAvatar[];
  badge: IBadge;
}
export interface ISessionUpdate {
  user: {
    id?: string;
    avatar?: string;
    name: string;
  };
  notification: string;
  date: string;
}

export interface ISessionSummary {
  [x: string]: any;
  user: {
    id: string;
    avatar: string;
    name: string;
  } | null;
  userSessionId: string;
  bookedAt: string;
  completedAt: string;
  isCompleted?: boolean;
  isCancel?: boolean;
  slot?: {
    from: string;
    to: string;
    date: string;
  };
  userType: string;
  meetingId?: string;
}

export interface ISessionSummaryItem {
  title: string;
  date: string;
  timestamp: number;
  userSessionId?: string;
  slot:
  {
    from: string;
    to: string;
    date: string;
  } | undefined;
  user?: {
    id: string;
    avatar: string;
    name: string;
  } | null;
  deleted?: boolean;
  isCompleted?: boolean;
  isCancel?: boolean;
  meetingId?: string;
}
export interface IStatistic {
  allTimeSessionPurchased: number;
  weeklyTimeSessionPurchased: number;
  userEnrolled: number;
  completed: number;
}

export interface ISession {
  id: string;
  isActive: boolean;
  sessionType: string;
  audienceType: string;
  title: string;
  description: string;
  language: string;
  numberOfSessions: number;
  waitingRoomMedia: IWaitingMedia;
  numberOfParticipants: number;
  isObserverAllowed: boolean;
  numberOfObservers: number;
  forms: string[];
  media: string[];
  availableTimes: IAvailableTime[];
  frequency: Frequency;
  fee: string;
  discount: string;
  expert: IExpertAvatar;
  entId: IEntAccountAvatar;
  currency: string;
  subscriberFee: string;
  subscriberCurrency: string;
  subscriberDiscount: string;
  observerFee: string;
  observerDiscount: string;
  observerCurrency: string;
  subscribeObserverFee: string;
  subscribeObserverCurrency: string;
  subscribeObserverDiscount: string;
  summary: ISessionSummary[];
  updates: ISessionUpdate[];
  modules: ISummaryModule[];
  statistics: IStatistic;
  badge: IBadge;
  sessionMeta: ISessionMeta;
  purchaseHistory: ISessionUpdate[];
  bookedSessions: number;
  bookedObservers: number;
  availableSessions: number;
  availableParticipants: number;
  availableObservers: number;
  invitations:IInvitation[]
  deleted:boolean,
  prerequisites?: { id: string; title: string; }[];
}
export interface IGetSupportModalForm {
  comment: string;
}
export interface IGenerateSession {
  name: string;
  role: string;
  sessionId: string;
  sessionType: string;
}
export interface ViewSessionState {
  sessionId: string;
  stateLoading: boolean;
  loading: boolean;
  session: ISession;
  sessionUrl: string;
  invitePeople: string[];
  filteredSessions: IFiltered<ISession>;
  subStateLoading: boolean;
  modalState: string;
}

export interface IActive {
  isActive: boolean;
}

export interface CancelSession {
  payload: CancelSessionParams;
  type: string;
}

export interface CancelSessionParams {
  id: string;
  meetingId: string;
  userId: string;
}
