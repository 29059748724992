import * as yup from 'yup';

const userAllocationFormValidation = yup.object().shape({
  maxNoOfGroups: yup.number().required('No of max groups is a required field').positive('No of max groups should be a positive number').integer()
    .typeError('Maximum number of groups must be greater than 0')
    .min(1, 'Maximum number of groups must be greater than 0')
    .max(500, 'Maximum number of groups must be 500 or less.'),
  maxNoOfCoaches: yup.number().required('No of max Experts is a required field').positive('No of max experts should be a positive number').integer()
    .typeError('There must be at least 1 expert')
    .min(1, 'There must be at least 1 expert')
    .max(5000, 'Maximum number of experts must be 5000 or less.'),
  maxNoOfEndUsers: yup.number().required('No of max end users is a required field').positive('No of max end users should be a positive number').integer()
    .typeError('Maximum number of end users must be greater than 0')
    .min(1, 'Maximum number of end users must be greater than 0'),
});
export default userAllocationFormValidation;
