const API = {
  POST_CREATE_EXPERT: {
    path: '/expert/create',
    method: 'POST',
  },
  POST_ASSIGN_CATEGORIES: {
    path: '/categories/assign',
    method: 'POST',
  },
};
export default API;
