import {
  Alert, AlertColor, SxProps, Theme,
} from '@mui/material';
import React from 'react';

export interface AlertProps {
  children: React.ReactNode;
  action?: React.ReactNode;
  color?: AlertColor;
  icon?: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
}

const AlertStrip = ({
  children, action, color, icon, sx,
}: AlertProps) => (
  <Alert
    icon={icon}
    action={action}
    color={color}
    sx={sx}
  >
    {children}
  </Alert>
);

AlertStrip.defaultProps = {
  action: null,
  color: 'info',
  icon: null,
  sx: {},
};

export default AlertStrip;
