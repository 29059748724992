import {
  Autocomplete,
  InputAdornment,
  TextField,
} from '@mui/material';

import { KeyboardEventHandler, ReactNode } from 'react';
import { IOption } from 'interfaces';

interface PrimaryAutoCompleteProps {
  label?:string,
  value:IOption,
  options:IOption[],
  placeholder?:string,
  startAdornment?:ReactNode,
  setValue: (value:IOption) =>void
  onChange?: (e:any)=>void
  onKeyDown ?:KeyboardEventHandler<HTMLDivElement>
}

const style = {
  borderWidth: '5px',
};

const PrimaryAutoComplete = ({
  label, value, options, setValue, onChange, placeholder, startAdornment, onKeyDown,
}:PrimaryAutoCompleteProps) => (
  <Autocomplete
    onKeyDown={onKeyDown ?? undefined}
    options={options}
    size="small"
    autoHighlight
    value={value}
    style={style}
    disableClearable
    isOptionEqualToValue={(option, currentValue) => option.value === currentValue.value}
    onChange={(_event: any, newValue: IOption | null) => {
      setValue(newValue as IOption);
      if (onChange !== undefined) {
        onChange(newValue?.value);
      }
    }}
    getOptionLabel={(option) => option.text as string}
    ListboxProps={{
      style: {
        border: '1px solid #000',
        borderRadius: '5px',
      },
    }}
    renderInput={(params) => (
      <TextField
        label={label}
        placeholder={placeholder}
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment,
        }}
      />
    )}
  />
);

PrimaryAutoComplete.defaultProps = {
  placeholder: '',
  startAdornment: <InputAdornment position="start" />,
  onChange: (_e:any) => {},
  onKeyDown: (_e:any) => {},
  label: '',
};

export default PrimaryAutoComplete;
