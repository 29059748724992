import * as React from 'react';
import { SubmitHandler, useFormContext, useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import {
  Grid,
} from '@mui/material';
import {
  PrimaryLiteButton,
  PrimaryLoadingButton,
} from 'components';
import { ICourseGoals } from '../../../../interface';
import { IGoal } from '../../../../../../interface';
import Goal from '../goal';

interface CourseGoalsModalFormProps {
  onSubmit: SubmitHandler<ICourseGoals>;
  loading:boolean;
}

const CourseGoalsForm = ({
  onSubmit, loading,
}: CourseGoalsModalFormProps) => {
  const {
    control,
    handleSubmit, setValue,
    formState: { errors },
  } = useFormContext<ICourseGoals>();
  const { fields, append, remove } = useFieldArray({ name: 'goals', control });
  const handleChange = (index:number, goal:IGoal) => {
    setValue(`goals.${index}`, goal);
  };
  const addGoal = () => {
    append({
      title: '',
    });
  };

  const removeGoal = (index:number) => {
    remove(index);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item">
        Goals
      </h3>
      {fields.map((item, i) => (
        <div key={item.id}>
          <div className="form-item">
            <Goal
              handleChange={handleChange}
              index={i}
              removeGoal={removeGoal}
              errors={errors}
              goalInit={item}
            />
          </div>

        </div>

      ))}

      <PrimaryLiteButton sx={{ margin: '1rem 0' }} onClick={addGoal} disabled={fields.length >= 6}>
        <AddIcon />
        Add new goal
      </PrimaryLiteButton>
      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} type="submit">
            Save & continue
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

export default CourseGoalsForm;
