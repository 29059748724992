import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ForgotPasswordFormValidation from 'pages/forgot-password/validation/forgot-password-form-validation';
import './index.scss';

import {
  Label, Title,
} from 'components';
import ROUTE from 'constants/route';

import useAuthorization from 'base/auth/hooks/use-authorization';
import { BaseLayout } from 'components/templates';
import { selectAccessToken } from 'base/auth/selectors';
import { authSliceActions } from 'base/auth/slice';
import ForgotPasswordForm from '../forgot-password-form';

const ForgotPasswordView = () => {
  useAuthorization();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(ForgotPasswordFormValidation),
    shouldFocusError: true,
  });
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);

  useEffect(() => {
    if (token) navigate(ROUTE.DASHBOARD);
  }, [token, navigate]);

  const handleSubmitFrom: SubmitHandler<any> = useCallback(
    (data) => {
      dispatch(authSliceActions.getCode(data));
    },
    [dispatch],
  );

  return (
    <BaseLayout>
      <>
        <br />
        <div style={{ textAlign: 'center' }}>
          <Title>Forgot your password?</Title>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Label>
            Don’t worry! Resetting your password is easy. Just type In the email you registered
            to SLOCOACH.
          </Label>
        </div>
        <br />
        <FormProvider {...methods}>
          <ForgotPasswordForm onSubmit={handleSubmitFrom} />
        </FormProvider>
      </>
    </BaseLayout>
  );
};

export default ForgotPasswordView;
