import React from 'react';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardTopBar } from 'components/organisms';
import { selectSnack } from 'base/core/selector';
import { coreSliceActions } from 'base/core/slice';
import { PrimarySnackbar } from 'components/molecules';

export interface Props {
  topContent: React.ReactElement | undefined;
  children: React.ReactElement;
}
export default function OnboardDashboardLayout({ topContent, children }: Props) {
  const dispatch = useDispatch();
  const { open, message, success } = useSelector(selectSnack);
  const handleClose = () => {
    dispatch(coreSliceActions.closeSnackbar());
  };
  return (
    <div>
      <OnboardTopBar />
      <div style={{ padding: '2rem' }}>
        {topContent && (
          <>
            <div style={{ padding: '1rem 0' }}>{topContent}</div>
            <Divider style={{ width: '100%' }} />
          </>
        )}
        <PrimarySnackbar open={open} message={message} severity={success ? 'success' : 'error'} handleClose={handleClose} />
        {children}
      </div>
    </div>
  );
}
