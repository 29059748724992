/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR } from 'constants/style';

const PrimaryLink = ({ children, to }: any) => {
  const navigate = useNavigate();

  return (
    <label
      style={{
        cursor: 'pointer',
        color: PRIMARY_COLOR,
        fontWeight: '500',
        fontSize: '14px',
      }}
      onClick={() => navigate(to)}
    >
      {children}
    </label>
  );
};
export default PrimaryLink;
