/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from 'interfaces/user-roles';
import { IForgotPasswordForm } from 'pages/forgot-password/interface';
import { IResetPasswordForm } from 'pages/reset-password/interface';
import { IResetTempPasswordForm } from 'pages/reset-temp-password/interface';
import { ISignInForm } from 'pages/sign-in/interface';

import { IBaseUserDocument } from 'interfaces/base-user';
import { AuthState, IEntAccDetails } from './interface';

export const initialState: AuthState = {
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    roles: [],
    bio: '',
    profileImgUrl: '',
    country: '',
    dob: '',
    createdAt: '',
  },
  loading: false,
  entId: '',
  entUrl: '',
  accessToken: '',
  isSuperAdmin: false,
  role: '',
  permission: [],
  callbackPath: '',

};
export const authSlice = createSlice({
  name: 'feature/base/auth',
  initialState,
  reducers: {
    setEntId(state: AuthState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    signIn(state: AuthState, _action: PayloadAction<ISignInForm>) {
      state.loading = true;
    },
    signInSucceeded(state: AuthState, action: PayloadAction<any>) {
      state.accessToken = action.payload.idToken;
    },
    signInFailed(state: AuthState, _action: PayloadAction<any>) {
      state.loading = false;
    },
    setCallbackPath(state: AuthState, action: PayloadAction<string>) {
      state.callbackPath = action.payload;
    },
    accDetailsSucceeded(state: AuthState, action: PayloadAction<IBaseUserDocument>) {
      const user = action.payload;
      state.user = user;
      if (user.roles.some((role) => role.role === Role.SuperAdmin)) { // if super admin
        const userPermissions = user.roles.filter((role) => role.role
        === Role.SuperAdmin)[0].permissions;
        state.role = Role.SuperAdmin;
        state.permission = userPermissions;
        state.isSuperAdmin = true;
        if (state.callbackPath) {
          window.location.pathname = state.callbackPath;
        } else {
          window.location.pathname = '/';
        }
      }
      if (user.roles.some((role) => role.role === Role.EntAccountAdmin)) { // if client admin
        const userPermissions = user.roles.filter((role) => role.role
        === Role.EntAccountAdmin)[0].permissions;
        state.role = Role.EntAccountAdmin;
        state.permission = userPermissions;
        state.entId = user.roles.filter((role) => role.role
        === Role.EntAccountAdmin)[0].entId;
      }
      state.loading = false;
    },
    accDetailsFailed(state: AuthState) {
      state.loading = false;
    },
    entAccDetailsSucceeded(state: AuthState, action: PayloadAction<IEntAccDetails>) {
      state.entId = action.payload._id;
      if (action.payload.isOnBoardingDone || state.isSuperAdmin) {
        if (state.callbackPath) {
          window.location.pathname = state.callbackPath;
        } else {
          window.location.pathname = '/';
        }
      } else {
        window.location.pathname = `/ent-account/onboard-ent-account/${action.payload._id}`;
      }
    },
    entAccDetailsFailed(state: AuthState) {
      state.loading = false;
    },

    signOut(state:AuthState) {
      state.loading = true;
    },
    signOutSucceeded(state:AuthState) {
      window.location.pathname = '/login';
      state.accessToken = initialState.accessToken;
      state.loading = initialState.loading;
      state.user = initialState.user;
      state.isSuperAdmin = initialState.isSuperAdmin;
      state.entId = initialState.entId;
    },
    signOutFailed(state:AuthState, _action: PayloadAction<any>) {
      state.loading = false;
    },

    getCode(state: AuthState, _action: PayloadAction<IForgotPasswordForm>) {
      state.loading = true;
    },
    getCodeSucceeded(state: AuthState, _action: PayloadAction<any>) {
      state.loading = false;
    },
    getCodeFailed(state: AuthState, _action: PayloadAction<any>) {
      state.loading = false;
    },

    resetPassword(state: AuthState, _action: PayloadAction<IResetPasswordForm>) {
      state.loading = true;
    },
    resetPasswordSucceeded(state: AuthState, _action: PayloadAction<any>) {
      state.loading = false;
    },
    resetPasswordFailed(state: AuthState, _action: PayloadAction<any>) {
      state.loading = false;
    },

    resetTempPassword(state: AuthState, _action: PayloadAction<IResetTempPasswordForm>) {
      state.loading = true;
    },
    resetTempPasswordSucceeded(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.accessToken = action.payload.idToken;
    },
    resetTempPasswordFailed(state: AuthState, _action: PayloadAction<any>) {
      state.loading = false;
    },
  },
});

export const { actions: authSliceActions } = authSlice;
