import {
  Grid, Avatar, Typography, CardHeader,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryOutlinedButton } from 'components';
import BadgeModal from '../badge-modal';
import { IBadge } from '../../../../interface';

interface ViewBadgeProps {
  formTitle:string,
  badge:IBadge;
  handleBadge:(badge:IBadge)=>void;
  handleBadgeRemove:()=>void
}

const ViewBadge = ({
  badge, handleBadge, handleBadgeRemove, formTitle,
}:ViewBadgeProps) => (
  <div>
    {badge.badgeTitle === '' ? (
      <div>
        <BadgeModal add formTitle={formTitle} badge={badge} setBadge={handleBadge} />
      </div>
    ) : (
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <CardHeader
            avatar={
              <Avatar src={badge.badgeImgUrl} sx={{ width: 40, height: 40 }} />
          }
            title={(
              <Typography variant="h4">{badge?.badgeTitle}</Typography>
          )}
            subheader={<div style={{ lineBreak: 'anywhere' }}>{badge?.badgeDescription}</div>}
            subheaderTypographyProps={{ noWrap: false }}
          />

        </Grid>
        <Grid item xs sx={{ textAlign: 'right' }}>
          <Grid container>
            <Grid item>
              <BadgeModal add={false} formTitle={formTitle} badge={badge} setBadge={handleBadge} />
            </Grid>
            <Grid item>
              <PrimaryOutlinedButton
                onClick={handleBadgeRemove}
                startIcon={<CloseIcon />}
              >
                Remove
              </PrimaryOutlinedButton>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )}
  </div>

);

export default ViewBadge;
