/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserDeleteCheckRequest, PollingRequestStatus } from 'interfaces/base-user';
import { Role } from 'interfaces/user-roles';
import { isUserDeleted } from 'utils/data-utils';
import { AdminDocument } from '../../interface';
import {
  EditAdminState, IGeneralInformationModalForm, IAuthorityLevelModalForm, ModalStates,
} from './interface';

export const initialState: EditAdminState = {
  loading: false,
  stateLoading: false,
  isDeleted: false,
  deleteCheckStatus: PollingRequestStatus.success,
  deleteCheckId: '',
  deleteCheck: {
    roles: [],
    actions: [],
    blockers: [],
    toBeDeleted: [],
  },
  id: '',
  modal: '',
  generalInformation: {
    firstName: '', lastName: '', email: '', profileImgUrl: '',
  },
  authorityLevel: {
    authorityLevel: '',
    accessRecordings: false,
    accessClasses: false,
    authenticateComplaints: false,
    articles: false,
    observeSessions: false,
  },
};

export const editAdminSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/admin/edit-admin',
  initialState,
  reducers: {
    getAdmin(state: EditAdminState) {
      state.stateLoading = true;
    },

    openModal(state:EditAdminState, action:PayloadAction<string>) {
      state.modal = action.payload;
    },
    closeModal(state:EditAdminState) {
      state.modal = ModalStates.close;
    },
    getAdminSucceeded(state:EditAdminState, action: PayloadAction<AdminDocument>) {
      const admin = action.payload;
      state.id = admin.id;
      state.authorityLevel.authorityLevel = admin.attributes?.authorityLevel;
      state.generalInformation.email = admin.email;
      state.generalInformation.firstName = admin.firstName;
      state.generalInformation.lastName = admin.lastName;
      state.generalInformation.profileImgUrl = admin.profileImgUrl;
      state.authorityLevel.accessClasses = admin?.roles[0]?.permissions.includes('accessClasses');
      state.authorityLevel.observeSessions = admin?.roles[0]?.permissions.includes('observeSessions');
      state.authorityLevel.accessRecordings = admin?.roles[0]?.permissions.includes('accessRecordings');
      state.authorityLevel.authenticateComplaints = admin?.roles[0]?.permissions.includes('authenticateComplaints');
      state.authorityLevel.articles = admin?.roles[0]?.permissions.includes('articles');
      state.loading = false;
      state.stateLoading = false;
      state.isDeleted = isUserDeleted(admin, Role.EntAccountAdmin);
    },
    getAdminFailed(state: EditAdminState) {
      state.stateLoading = false;
    },
    setId(state: EditAdminState, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    setDeleteCheckStatus(state:EditAdminState, action:PayloadAction<PollingRequestStatus>) {
      state.deleteCheckStatus = action.payload;
    },
    setDeleteCheckId(state:EditAdminState, action:PayloadAction<string>) {
      state.deleteCheckId = action.payload;
    },
    cancelPollingWork(state:EditAdminState) {
    },
    editGeneralInformation(
      state: EditAdminState,
      _action:PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = true;
    },
    editGeneralInformationSucceeded(
      state: EditAdminState,
      _action:PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = false;
    },
    editGeneralInformationFailed(state: EditAdminState) {
      state.loading = false;
    },
    editAuthorityLevel(state: EditAdminState, _action:PayloadAction<IAuthorityLevelModalForm>) {
      state.loading = true;
    },
    editAuthorityLevelSucceeded(
      state: EditAdminState,
      action:PayloadAction<IAuthorityLevelModalForm>,
    ) {
      state.loading = false;
      state.authorityLevel = action.payload;
      state.modal = ModalStates.close;
    },
    editAuthorityLevelFailed(state: EditAdminState) {
      state.loading = false;
    },
    deleteAdminCheck(state: EditAdminState) {
      state.deleteCheckStatus = PollingRequestStatus.pending;
    },
    deleteAdminCheckSucceeded(
      state: EditAdminState,
      action:PayloadAction<IUserDeleteCheckRequest>,
    ) {
      state.deleteCheck = action.payload;
      state.deleteCheckStatus = PollingRequestStatus.success;
    },
    deleteAdminCheckFailed(state: EditAdminState) {
      state.deleteCheckStatus = PollingRequestStatus.error;
    },
    deleteAdmin(state: EditAdminState, _action:PayloadAction<string>) {
      state.loading = true;
    },
    deleteAdminSucceeded(state: EditAdminState) {
      state.loading = false;
      state.isDeleted = true;
      state.modal = ModalStates.close;
    },
    deleteAdminFailed(state: EditAdminState) {
      state.loading = false;
    },
    recoverAdmin(state: EditAdminState) {
      state.loading = true;
    },
    recoverAdminSucceeded(state: EditAdminState) {
      state.loading = false;
      state.modal = ModalStates.close;
      state.isDeleted = false;
    },
    recoverAdminFailed(state: EditAdminState) {
      state.loading = false;
    },
    reset: () => initialState,

  },
});

export const { actions: editAdminActions } = editAdminSlice;
