import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Label } from '../custom-typography';
import PrimaryNumericField from '../primary-numeric-field';

interface SliderInputProps {
  marks:{ value:number, label:string }[]
  defaultValue:number,
  step:number,
  min:number,
  max:number,
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onChange: any;
  value: number | undefined;
  name: string | undefined;
  label: string | React.ReactNode | undefined;
  disabled?:boolean,
  placeholder?: string;
  type?: string;
  testId?:string
}
const CustomSlider = styled(Slider)({
  color: '#E5E5E5',
  height: 5,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: 'white',
    border: '3px solid #9D8336',
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    padding: 0,
    width: 32,
    height: 32,
    backgroundColor: 'black',
    color: 'white',
  },
});

const SliderInput = React.forwardRef(({
  marks, defaultValue, step, min, max, onChange, onBlur, value, name, label, disabled, placeholder,
  type, testId = '',
}:SliderInputProps, ref) => {
  const onChangeInput = (inputValue:string) => {
    if (inputValue) {
      onChange(parseInt(inputValue, 10));
    } else {
      onChange(0);
    }
  };
  return (
    <Box>
      <Label htmlFor={name}>{label}</Label>
      <div className="slider-wrapper">
        <CustomSlider
          aria-label="slider"
          defaultValue={defaultValue}
          step={step}
          min={min}
          max={max}
          value={value}
          onChange={(e, v, __) => onChange(v)}
          valueLabelDisplay="auto"
          marks={marks}
          disabled={disabled}
        />

        <PrimaryNumericField
          onBlur={onBlur}
          value={value}
          name={name}
          ref={ref}
          onChange={(e:any) => onChangeInput(e.target.value)}
          placeholder={placeholder}
          fullWidth
          type={type}
          id={`${name}`}
          min={min}
          max={max}
          disabled={disabled}
          testId={testId}
        />
      </div>

    </Box>
  );
});
SliderInput.defaultProps = {
  disabled: false,
  placeholder: '',
  type: 'number',
  testId: '',
};

export default SliderInput;
