/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateAdminState, ICreateAdminModalForm,
} from './interface';

export const initialState: CreateAdminState = {
  loading: false,
  modal: false,
};

export const createAdminSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/admin/create-admin',
  initialState,
  reducers: {
    createAdmin(state: CreateAdminState, _action:PayloadAction<ICreateAdminModalForm>) {
      state.loading = true;
    },
    createAdminSucceeded(state: CreateAdminState) {
      state.loading = false;
      state.modal = false;
    },
    createAdminFailed(state: CreateAdminState) {
      state.loading = false;
    },
    openModal(state: CreateAdminState) {
      state.modal = true;
    },
    closeModal(state: CreateAdminState) {
      state.modal = false;
    },
    reset: () => initialState,

  },
});

export const { actions: createAdminActions } = createAdminSlice;
