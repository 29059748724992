import {
  call, put, all, takeLatest, select,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { viewGiftCardUsersActions } from './slice';
import API from '../../constant';
import { selectId } from './selector';

import { IActive, IGiftCardUserFilterPayload } from './interface';

export function* getGiftCardGenerator({ payload }:PayloadAction<IGiftCardUserFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_GIFT_CARD_BY_ID.path}/${id}?email=${payload.email}&page=${payload.page}&limit=${payload.limit}`, method: API.GET_GIFT_CARD_BY_ID.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewGiftCardUsersActions.getGiftCardSucceeded(response.data));
    } else {
      yield put(viewGiftCardUsersActions.getGiftCardFailed());
    }
  } catch (error) {
    yield put(viewGiftCardUsersActions.getGiftCardFailed());
  }
}
export function* deleteGiftCardGenerator(): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_GIFT_CARD,
      { id },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewGiftCardUsersActions.deleteGiftCardSucceeded());
    } else {
      yield put(viewGiftCardUsersActions.deleteGiftCardFailed());
    }
  } catch (error) {
    yield put(viewGiftCardUsersActions.deleteGiftCardFailed());
  }
}
export function* editActiveGenerator({ payload }:PayloadAction<IActive>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.PUT_UPDATE_GIFT_CARD,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewGiftCardUsersActions.editActiveSucceeded(payload.isActive));
    } else {
      yield put(viewGiftCardUsersActions.editActiveFailed());
    }
  } catch (error) {
    yield put(viewGiftCardUsersActions.editActiveFailed());
  }
}

export function* viewGiftCardUsersSaga() {
  yield all([takeLatest(
    viewGiftCardUsersActions.getGiftCard.type,
    getGiftCardGenerator,
  ), takeLatest(
    viewGiftCardUsersActions.editActive.type,
    editActiveGenerator,
  ),
  takeLatest(
    viewGiftCardUsersActions.deleteGiftCard.type,
    deleteGiftCardGenerator,
  ),
  ]);
}

export default viewGiftCardUsersSaga;
