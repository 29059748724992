import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import {
  Box, InputAdornment, TextField, Typography,
} from '@mui/material';
import moment from 'moment';

import './index.scss';

interface PrimaryTimePickerProps {
  value: any;
  onChange:(value: any) => void;
  minTime?:any;
  maxTime?:any;
  label?:string;
  disabled?:boolean;
}

const style = {
  border: 0,
  borderRadius: '6px',
  fontSize: '18px',
  fontWeight: 500,
  outline: 'none',
  marginTop: '5px',
  width: '100%',
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px',
    textTransform: 'uppercase',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '6px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CDCDCD',
      padding: '11px 14px',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CDCDCD',
      borderWidth: '1px',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CDCDCD',
    },
    '&:hover fieldset': {
      borderColor: '#171772',
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#171772',
      borderWidth: '1px',
    },
    '& .MuiButtonBase-root': {
      color: '#CDCDCD',
    },
  },
};

const activeMeridiemStyles = { color: 'primary.main' };

const PrimaryTimePicker = React.forwardRef(
  ({
    onChange, value, minTime, maxTime, label, disabled,
  }: PrimaryTimePickerProps, _ref) => {
    const [timeInput, setTimeInput] = React.useState(value ? value.format('hh:mm') : '');
    const [meridiem, setMeridiem] = React.useState(value?.format('A') || 'AM');

    // Handle manual input changes
    const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setTimeInput(newValue);

      // Parse the input and call onChange if valid
      const parsedTime = moment(`${newValue} ${meridiem}`, 'hh:mm A', true);
      if (parsedTime.isValid()) {
        onChange(parsedTime);
      }
    };

    // Toggle AM/PM when clicking on it
    const toggleMeridiem = () => {
      const newMeridiem = meridiem === 'AM' ? 'PM' : 'AM';
      setMeridiem(newMeridiem);

      // Update the time with the new meridiem
      const updatedTime = moment(`${timeInput} ${newMeridiem}`, 'hh:mm A', true);
      if (updatedTime.isValid()) {
        onChange(updatedTime);
      }
    };

    // extract AM PM from the value
    React.useEffect(() => {
      if (value) {
        const newMeridiem = value.format('A');
        setMeridiem(newMeridiem);
      }
    }, [value]);

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {label && <Typography variant="body1">{label}</Typography>}
        <MobileTimePicker
          value={value}
          ampmInClock
          disableIgnoringDatePartForTimeValidation
          inputFormat="hh:mm"
          minTime={minTime}
          maxTime={maxTime}
          onChange={(newValue) => {
            setTimeInput(newValue ? newValue.format('hh:mm') : '');
            setMeridiem(newValue ? newValue.format('A') : 'AM');
            onChange(newValue); // Update state when selecting from clock
          }}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              sx={style}
              {...params}
              fullWidth
              value={timeInput}
              onChange={handleTimeInputChange} // Handle manual input
              InputProps={{
                ...params.InputProps,
                placeholder: 'hh:mm',
                endAdornment: (
                  <InputAdornment position="end">
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <Typography
                        variant="body1"
                        onClick={toggleMeridiem}
                        sx={meridiem === 'AM' ? activeMeridiemStyles : { color: 'text.secondary' }}
                      >
                        AM
                      </Typography>
                      <Typography
                        variant="body1"
                        onClick={toggleMeridiem}
                        sx={meridiem === 'PM'
                          ? { ...activeMeridiemStyles, ml: 0.8 } : { color: 'text.secondary', ml: 0.8 }}
                      >
                        PM
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
    );
  },
);

PrimaryTimePicker.defaultProps = {
  minTime: '',
  maxTime: '',
  label: '',
  disabled: false,
};

export default PrimaryTimePicker;
