/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  Box,
  Chip,
  Grid, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import {
  ErrorMessage,
  Label,
  PrimaryLoadingButton,
  PrimaryMultiSelect,
  PrimaryTextField,
} from 'components';
import { SessionAudienceTypes, SessionType } from 'config';
import { IOption } from 'interfaces';
import { selectCategories, selectSkills } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import { CloseSquareIcon } from 'assets/icons';
import { IconLoadingButton } from 'components/molecules/primary-button';
import { ISkill } from 'pages/dashboard/components/ent-account/interface';
import { UploadScenarios } from 'interfaces/file-uploader';
import { selectFilteredContent, selectLoader } from '../../../../../../../content/selector';
import { CourseOnlySessionStatus, ISessionDetailsForm } from '../../../../interface';
import { SelectContentModal } from '../../../select-modals';
import { SelectedContentTable } from '../../../selected-tables';
import './index.scss';
import { selectCourseOnlySessionMeta, selectSessionDetails, selectSessionInformation } from '../../../../selector';
import { IContentAvatarWithAction } from '../../../../../../../content/interface';
import { viewContentActions } from '../../../../../../../content/slice';

interface SessionDetailsModalFormProps {
  onSubmit: SubmitHandler<ISessionDetailsForm>;
  loading: boolean;
  editMode: boolean
}

const SessionDetailsModalForm = ({
  onSubmit, loading,
}: SessionDetailsModalFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }, setValue, getValues,
  } = useFormContext<ISessionDetailsForm>();

  const dispatch = useDispatch();
  const { highlightMedia } = useSelector(selectSessionDetails);
  const { audienceType, sessionType } = useSelector(selectSessionInformation);
  const courseOnlySessionStatus = useSelector(selectCourseOnlySessionMeta).status;
  const filteredContent = useSelector(selectFilteredContent).result;
  const totalContent = useSelector(selectFilteredContent).total;
  const contentLoading = useSelector(selectLoader);

  const entSkills = useSelector(selectSkills);
  // highligh media
  const [selectedMedia, setSelectedMedia] = React.useState<IContentAvatarWithAction[]>(() => (highlightMedia._id !== '' ? [
    { ...highlightMedia, isAdded: true }] : []));

  // related skills
  const relatedSkillOptions = React.useMemo<IOption[]>(() => entSkills.map(
    (skill: ISkill) => ({ id: skill.id, value: skill.id as string, text: skill.name }),
  ), [entSkills]);

  const entCategories = useSelector(selectCategories);
  const categoryOptions = React.useMemo<IOption[]>(() => entCategories.map(
    (category) => ({ id: category.id!, value: category.id!, text: category.name }),
  ), [entCategories]);

  // handle content selection
  const updateMedia = (contentList: IContentAvatarWithAction[]) => {
    setSelectedMedia(contentList);
  };
  const handleMediaRemove = (contentId: string) => {
    setSelectedMedia((prev) => ([...prev.filter((content) => (content._id !== contentId))]));
  };

  const searchContent = (keyword:string, page:number) => {
    dispatch(viewContentActions.getContent({
      keyword,
      page: (page + 1),
      limit: 3,
      type: ['image', 'video'],
      sortBy: 'uploaded',
    }));
  };

  // session outcomes
  const [sessionOutcomes, setSessionOutcomes] = React.useState<string[]>(getValues('sessionOutcomes'));

  const addOutcome = () => {
    const tempList = [...getValues('sessionOutcomes'), ''];
    setValue('sessionOutcomes', tempList);
    setSessionOutcomes(tempList);
  };

  const removeOutcome = (index: number) => {
    const tempList = getValues('sessionOutcomes').filter((out, i: number) => i !== index);
    setValue('sessionOutcomes', tempList);
    setSessionOutcomes(tempList);
  };

  const sessionOutcomesWithIds = sessionOutcomes.map((outcome) => ({
    id: uuidv4(),
    value: outcome,
  }));

  // updating the selected highlight media in the form
  React.useEffect(() => {
    if (selectedMedia.length) {
      const { isAdded, ...remainP } = selectedMedia[0];
      setValue('highlightMedia', remainP);
    }
  }, [selectedMedia]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item">
        Session Details
      </h3>

      <div className="form-item">
        <Label sx={{ mb: '0.5rem' }}>Highlight media</Label>
        {selectedMedia.length > 0 ? (
          <SelectedContentTable
            items={selectedMedia}
            handelRemove={handleMediaRemove}
          />
        ) : ''}

        <SelectContentModal
          filteredContent={filteredContent}
          selectOne
          selectedContents={selectedMedia}
          handleUpdate={updateMedia}
          searchFunction={searchContent}
          total={totalContent}
          loading={contentLoading}
          scenario={UploadScenarios.sessionHighlightMedia}
        />
        <ErrorMessage>{errors?.highlightMedia?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Typography variant="body1">What will users learn from this session?</Typography>
        {sessionOutcomesWithIds.map((outcome, index) => (
          <div key={outcome.id} style={{ width: '100%', marginBottom: '0.5rem' }} className="inline-wrapper">
            <Controller
              control={control}
              name={`sessionOutcomes.${index}`}
              render={({ field }) => (
                <>
                  <PrimaryTextField {...field} placeholder={index === 0 ? 'Ex: basics about the pre workout techniques' : ''} />
                  <IconLoadingButton
                    startIcon={<CloseSquareIcon />}
                    onClick={() => removeOutcome(index)}
                  />
                </>
              )}
            />
            <ErrorMessage>
              {errors?.sessionOutcomes ? errors?.sessionOutcomes[sessionOutcomes.length - 1]?.message : ' '}
            </ErrorMessage>
          </div>
        ))}
        <IconLoadingButton
          startIcon={<AddIcon />}
          onClick={addOutcome}
          className="w-auto mt-2"
        >
          { `${sessionOutcomes.length > 0 ? 'Add another' : 'Add an'} outcome` }
        </IconLoadingButton>
      </div>
      <div className="form-item">
        <Typography variant="body1">Select related skills</Typography>
        <Controller
          control={control}
          name="relatedSkills"
          render={({ field }) => (
            <PrimaryMultiSelect
              options={relatedSkillOptions}
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e.target.value);
              }}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value:any) => (
                    <Chip key={value} label={relatedSkillOptions.filter((skill) => skill.id === value)[0].text} color="primary" />
                  ))}
                </Box>
              )}
            />
          )}
        />
        <ErrorMessage>
          {errors?.relatedSkills ? errors?.relatedSkills?.message : ' '}
        </ErrorMessage>
      </div>
      <div className="form-item">
        <Typography variant="body1">Select categories</Typography>
        <Controller
          control={control}
          name="categories"
          render={({ field }) => (
            <PrimaryMultiSelect
              options={categoryOptions}
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e.target.value);
              }}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value:any) => (
                    <Chip key={value} label={categoryOptions.filter((skill) => skill.id === value)[0].text} color="primary" />
                  ))}
                </Box>
              )}
            />
          )}
        />
        <ErrorMessage>
          {errors?.categories ? errors?.categories?.message : ' '}
        </ErrorMessage>
      </div>

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLoadingButton type="submit" loading={loading}>
            {audienceType === SessionAudienceTypes.courseOnly
              ? (sessionType === SessionType.liveSession || sessionType === SessionType.liveClass
                ? 'Save & continue'
                : (courseOnlySessionStatus !== CourseOnlySessionStatus.editing ? 'Add to course' : 'Update session'))
              : 'Save & continue'}
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

export default SessionDetailsModalForm;
