import * as React from 'react';
import Box from '@mui/material/Box';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { PrimaryLoadingOutlinedButton } from 'components';
import { ISkillModalForm, ModalStates } from '../../../../../interface';
import { selectModal } from '../../../../../selector';
import onboardSkillModalFormValidation from '../validation/skill-form-validation';
import { onboardEntAccActions } from '../../../../../slice';
import OnboardSkillModalForm from '../skill-modal-form';

interface OnboardSkillModalProps {
  index: number;
  addSkill:(name:string)=>void
}

const OnboardSkillModal = ({ index, addSkill }: OnboardSkillModalProps) => {
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const methods = useForm<ISkillModalForm>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(onboardSkillModalFormValidation),
    shouldFocusError: true,
  });

  const handleOpen = () => {
    dispatch(onboardEntAccActions.openModal(ModalStates.skill));
  };
  const handleClose = () => {
    dispatch(onboardEntAccActions.closeModal());
    methods.reset();
  };
  const onSubmit: SubmitHandler<ISkillModalForm> = React.useCallback(
    (data) => {
      addSkill(data.name);
      handleClose();
    },
    [dispatch],
  );

  return (
    <Grid container justifyContent="flex-start">
      <Grid item>
        <PrimaryLoadingOutlinedButton sx={{ padding: '0.5rem 0' }} onClick={handleOpen}>
          <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
          Add new skill
        </PrimaryLoadingOutlinedButton>
      </Grid>

      <Modal open={modal === ModalStates.skill}>
        <Box className="modal-scrollable">
          <IconButton aria-label="close" sx={{ float: 'right' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <FormProvider {...methods}>
            <OnboardSkillModalForm onSubmit={onSubmit} closeModal={handleClose} index={index} />
          </FormProvider>
        </Box>
      </Modal>
    </Grid>
  );
};
export default OnboardSkillModal;
