import {
  all,
  call, put, takeLatest,
} from 'redux-saga/effects';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { coreSliceActions } from './slice';
import API from './constants';

export function* getExchangeRatesGenerator(): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.GET_EXCHANGE_RATES,
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(coreSliceActions.getExchangeRatesSucceeded(response.data));
    } else {
      yield put(coreSliceActions.getExchangeRatesFailed());
    }
  } catch (error) {
    yield put(coreSliceActions.getExchangeRatesFailed());
  }
}

export function* coreSaga() {
  yield all([takeLatest(
    coreSliceActions.getExchangeRates.type,
    getExchangeRatesGenerator,
  )]);
}

export default coreSaga;
