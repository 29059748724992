export enum UserDeletionActionsEnum {
  Subscriptions = 'subscriptions',
  UserSubscribed = 'user-subscribed',
  AdminAccountswillDelete = 'admin-accounts-will-delete',
}

export enum UserDeletionBlockersEnum {
  AdminAccountsNeedsToSwitchOwner = 'admin-accounts-change-owner-required',
  GroupAdminUpdateRequired = 'group-admin-update-required',
  OnlyAdminLeftForEnt = 'only-admin-left-ent',
}
export enum PollingRequestStatus {
  pending,
  error,
  success,
}
export interface IDeletionActions {
  entId: string;
  action: UserDeletionActionsEnum;
  related: any;
  type: 'subscriptions' | 'entaccounts';
}

export interface IDeletionBlockers {
  entId: string;
  action: UserDeletionBlockersEnum;
  related: any;
  type: 'subscriptions' | 'entaccounts' | 'groups';
}
export interface IRole {
  userId: string,
  entId: string,
  role: string,
  permissions: string[]
  isFavorite:string,
  createdBy:string,
  createdAt:string,
  deletedAt?:string,
  isDeleting?:boolean,
}

export interface IBaseUserAvatar {
  id: string;
  profileImgUrl: string,
  firstName: string;
  lastName: string;
  email: string;
}
export interface IBaseUserDocument {
  id: string;
  email: string;
  profileImgUrl: string,
  firstName: string;
  lastName: string;
  country: string;
  dob: string;
  bio: string,
  roles: IRole[],
  createdAt: string,
}

export interface IUserDeleteCheckRequest {
  roles: {
    entId: string,
    permissions: [],
    role: IRole,
    userId: string
  }[],
  actions: IDeletionActions[],
  blockers: IDeletionBlockers[],
  toBeDeleted: [],
}
