import * as yup from 'yup';

const sessionAvailabilityFormValidation = yup.object().shape({
  availableTimes: yup.array().of(yup.object().shape({
    day: yup.string().trim().required().label('Session day'),
    isAvailable: yup.boolean().required(),
    isAllDay: yup.boolean().required(),
    times: yup.array().when(['isAvailable', 'isAllDay'], {
      is: (isAvailable:boolean, isAllDay:boolean) => !isAllDay && isAvailable,
      then: yup.array().of(yup.object().shape({
        from: yup.string().required().label('From time'),
        to: yup.string().required().label('To time'),
      })),
    }),
  })).test(
    'availableTimes',
    '',
    (obj) => {
      const enabledItems = obj?.filter((availableTime) => availableTime.isAvailable);
      if (enabledItems && enabledItems.length > 0) {
        return true;
      }
      return new yup.ValidationError(
        'Please enable at least one available time',
        null,
        'availableTimes',
      );
    },
  ),
  sessionMeta: yup.object()
    .shape({
      startDate: yup.string().label('Start date'),
      endDate: yup.string().label('End date'),
    })
    .label('Start date and end date'),

});

export default sessionAvailabilityFormValidation;
