/* eslint-disable import/no-cycle */
import { IUserDeleteCheckRequest, PollingRequestStatus } from 'interfaces/base-user';
import {
  Statistic, Achievement, Badge, IUser,
} from '../../interface';

export enum ModalStates {
  delete = 'DELETE',
  close = 'CLOSE',
  recover = 'RECOVER',
  generalInformation = 'GENERAL_INFORMATION',
  accountType = 'ACCOUNT_TYPE',
  bankDetails = 'BANK_DETAILS',
  sponsorContent = 'SPONSOR_CONTENT',
  achievements = 'ACHIEVEMENTS',
  statistics = 'STATISTICS',
  badges = 'BADGES',
  badgesFromAdmin = 'BADGES_FROM_ADMIN',
  active = 'ACTIVE',
  content = 'CONTENT',
}
export interface IGeneralInformationModalForm {
  firstName: string;
  lastName: string;
  email: string;
  profileImgUrl: string;
  bio: string;
  category: string;
  currency: string;
  introVideoUrl: string;
  thankVideoUrl: string;
  rateVideoUrl: string;
  categories: string[];
}
export interface IAccountTypeModalForm {
  accountType: string;
  subscriptionFee: number;
  subscriptionFrequency: string;
}

export interface IBankDetailsModalForm {
  accountHolder: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
}

export interface ISponsorContentModalForm {
  sponsorContent: SponsorContent[];
}
export interface SponsorContent {
  url: string;
  contentUrl: string;
}
export interface IAchievementsModalForm {
  achievements: [
    {
      title: string;
      description: string;
    },
  ];
}
export interface IStatisticsModalForm {
  statistics: Statistic[];
}

export interface IBadgesModalForm {
  badges: Badge[];
}
export interface IBadgesFromAdminModalForm {
  badgesFromAdmin: Badge[];
}
export interface IActive {
  isActive: boolean;
}
export interface EditExpertState {
  total: number;
  totalFollowers: number;
  totalSubscribers: number;
  loading: boolean;
  stateLoading: boolean;
  isDeleted: boolean;
  deleteCheckStatus: PollingRequestStatus;
  deleteCheckId: string;
  id: string;
  isActive: boolean;
  IsUserDeleting: boolean;
  modal: string;
  email: string;
  generalInformation: IGeneralInformationModalForm;
  accountType: IAccountTypeModalForm;
  bankDetails: IBankDetailsModalForm;
  sponsorContent: SponsorContent[];
  achievements: Achievement[];
  badges: Badge[];
  badgesFromAdmin: Badge[];
  statistics: Statistic[];
  deleteCheck: IUserDeleteCheckRequest;
  users: IUser[];
}

export interface FetchUsersPayload {
  keyword: string;
  page: number;
  limit: number;
  userType: string;
}

export interface IUsersListResponse {
  result: IUser[];
  total: number;
  totalFollowers: number;
  totalSubscribers: number;
}
