import {
  all, call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { selectId } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import { viewFormsActions } from './slice';
import {
  selectFormConfig, selectFormId, selectFormSettings,
} from './selector';
import API from './constants';
import {
  FormBuilderVersion,
  ISaveScormFormModalFormAction,
  IDeleteForm, IFormDocument, IFormFilterPayload,
} from './interface';
import { selectId as selectExpertId } from '../../selector';

export function* getFilteredFormsGenerator({ payload }: PayloadAction<IFormFilterPayload>): any {
  try {
    const id = yield select(selectExpertId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_FORMS_BY_EXPERT.path}/${id}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&sortBy=${payload.sortBy}&formType=${payload.formType || ''}`, method: API.GET_FORMS_BY_EXPERT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewFormsActions.getFilteredFormsSucceeded(response.data));
    } else {
      yield put(viewFormsActions.getFilteredFormsFailed());
    }
  } catch (error) {
    yield put(viewFormsActions.getFilteredFormsFailed());
  }
}
export function* getFormGenerator(): any {
  try {
    const id = yield select(selectFormId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_FORM_BY_ID.path}/${id}`, method: API.GET_FORM_BY_ID.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewFormsActions.getFormSucceeded(response.data as IFormDocument));
    } else {
      yield put(viewFormsActions.getFormFailed());
    }
  } catch (error) {
    yield put(viewFormsActions.getFormFailed());
  }
}
export function* createFormGenerator(): any {
  try {
    const expertId = yield select(selectExpertId);
    const entId = yield select(selectId);
    const formSettings = yield select(selectFormSettings);
    const formConfig = yield select(selectFormConfig);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_FORM,
      {
        entId,
        expertId,
        ...formSettings,
        formConfig,
        version: FormBuilderVersion.V2,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewFormsActions.createFormSucceeded());
    } else {
      yield put(viewFormsActions.createFormFailed());
    }
  } catch (error) {
    yield put(viewFormsActions.createFormFailed());
  }
}

export function* saveScormFormGenerator({ payload }: ISaveScormFormModalFormAction) :any {
  try {
    const expertId = yield select(selectExpertId);
    const entId = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      payload.id ? API.POST_UPDATE_FORM : API.POST_CREATE_FORM,
      {
        entId,
        expertId,
        ...payload,
        version: FormBuilderVersion.V2,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewFormsActions.saveScormFormSucceeded());
      yield payload.resolve();
    } else {
      yield put(viewFormsActions.saveScormFormFailed());
      yield payload.reject();
    }
  } catch (error) {
    yield put(viewFormsActions.saveScormFormFailed());
    yield payload.reject();
  }
}

export function* editFormsGenerator(): any {
  try {
    const id = yield select(selectFormId);
    const formSettings = yield select(selectFormSettings);
    const formConfig = yield select(selectFormConfig);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_FORM,
      {
        id,
        ...formSettings,
        formConfig,
        version: FormBuilderVersion.V2,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewFormsActions.editFormSucceeded());
    } else {
      yield put(viewFormsActions.editFormFailed());
    }
  } catch (error) {
    yield put(viewFormsActions.editFormFailed());
  }
}
export function* deleteFormsGenerator({ payload }:PayloadAction<IDeleteForm>): any {
  try {
    const { id, bannerImgUrl } = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.POST_DELETE_FORM,
      {
        id,
        bannerImgUrl,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(viewFormsActions.deleteFormSucceeded(id));
    } else {
      yield put(viewFormsActions.deleteFormFailed());
    }
  } catch (error) {
    yield put(viewFormsActions.deleteFormFailed());
  }
}

export function* viewFormsSaga() {
  yield all([
    takeLatest(viewFormsActions.getFilteredForms.type, getFilteredFormsGenerator),
    takeLatest(viewFormsActions.getForm.type, getFormGenerator),
    takeLatest(viewFormsActions.createForm.type, createFormGenerator),
    takeEvery(viewFormsActions.saveScormForm.type, saveScormFormGenerator),
    takeLatest(viewFormsActions.editForm.type, editFormsGenerator),
    takeLatest(viewFormsActions.deleteForm.type, deleteFormsGenerator),
  ]);
}

export default viewFormsSaga;
