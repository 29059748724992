/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/article/create-article'] || initialState;

export const selectCreateArticle = createSelector([selectDomain], (CreateArticleState) => CreateArticleState);
export const selectLoader = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.loading);
export const selectStateLoader = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.stateLoading);
export const selectSubStateLoader = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.subStateLoading);
export const selectEntId = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.entId);
export const selectStatus = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.status);
export const selectIsAuthorActive = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.isAuthorActive);
export const selectArticleId = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.articleId);
export const selectActive = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.isActive);
export const selectArticle = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.article);
export const selectFilteredGroups = createSelector([selectDomain], (CreateArticleState) => CreateArticleState.filteredGroups);
