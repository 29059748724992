import * as React from 'react';
import {
  useParams, Routes, Route, Link, useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tabs, Tab, Grid, CircularProgress,
} from '@mui/material';
import { PrimarySnackbar, TopBar } from 'components';
import './index.scss';
import { a11yProps } from 'components/molecules/tab-panel/index';
import { selectSnack } from 'base/core/selector';
import { coreSliceActions } from 'base/core/slice';
import useAuthorization from 'base/auth/hooks/use-authorization';
import { Permissions } from 'config';
import AuthorizationWrapper from 'base/auth/components/authorized-wrapper';
import { editEntAccActions } from './slice';
import { selectStateLoader } from './selector';

const EntGeneralInformation = React.lazy(() => import('./components/general-information'));
const EntUserAllocation = React.lazy(() => import('./components/user-allocation'));
const EntSitePreferences = React.lazy(() => import('./components/site-preferences'));
const EntContent = React.lazy(() => import('./components/content'));
const EntCharges = React.lazy(() => import('./components/charges'));
const EntAdmins = React.lazy(() => import('./components/admins'));
const EntExperts = React.lazy(() => import('./components/experts'));
const EntUsers = React.lazy(() => import('./components/users'));
const EntGroups = React.lazy(() => import('./components/groups'));
const EntArticles = React.lazy(() => import('./components/articles'));
const EntGiftCards = React.lazy(() => import('./components/gift-cards'));
const EntPlugins = React.lazy(() => import('./components/plugins'));

const TopContent = () => {
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = React.useState(pathname.split('/')[4]);
  const { isAuthorized } = useAuthorization();

  React.useEffect(() => {
    setCurrentTab(pathname.split('/')[4]);
  }, [pathname]);
  return (

    <div>
      <Grid container justifyContent="center" className="tab-wrapper">
        <Grid item xs={12} lg={10}>
          <Tabs value={currentTab}>
            <Tab
              to="general-information"
              component={Link}
              value="general-information"
              className="tab"
              label="General information"
              onClick={() => setCurrentTab('general-information')}
              disabled={!isAuthorized(undefined, [Permissions.generalInformation])}
              {...a11yProps(0)}
            />
            <Tab
              to="user-allocation"
              component={Link}
              value="user-allocation"
              className="tab"
              label="User allocation"
              onClick={() => setCurrentTab('user-allocation')}
              disabled={!isAuthorized(undefined, [Permissions.generalInformation])}
              {...a11yProps(1)}
            />
            <Tab
              to="site-preference"
              component={Link}
              value="site-preference"
              className="tab"
              label="Site preference"
              onClick={() => setCurrentTab('site-preference')}
              disabled={!isAuthorized(undefined, [Permissions.generalInformation])}
              {...a11yProps(2)}
            />
            <Tab
              to="content"
              component={Link}
              value="content"
              className="tab"
              label="Content"
              onClick={() => setCurrentTab('content')}
              disabled={!isAuthorized(undefined, [Permissions.generalInformation])}
              {...a11yProps(3)}
            />
            <Tab
              to="payments"
              component={Link}
              value="payments"
              className="tab"
              label="Payments"
              onClick={() => setCurrentTab('payments')}
              disabled={!isAuthorized(undefined, [Permissions.generalInformation])}
              {...a11yProps(4)}
            />
            <Tab
              to="admins"
              component={Link}
              value="admins"
              className="tab"
              label="Admins"
              onClick={() => setCurrentTab('admins')}
              disabled={!isAuthorized(undefined, [Permissions.admins])}
              {...a11yProps(5)}
            />
            <Tab
              to="experts"
              component={Link}
              value="experts"
              className="tab"
              label="Experts"
              onClick={() => setCurrentTab('experts')}
              disabled={!isAuthorized(undefined, [Permissions.experts])}
              {...a11yProps(6)}
            />
            <Tab
              to="groups"
              component={Link}
              value="groups"
              className="tab"
              label="Groups"
              onClick={() => setCurrentTab('groups')}
              disabled={!isAuthorized(undefined, [Permissions.groups])}
              {...a11yProps(7)}
            />
            <Tab
              to="articles"
              component={Link}
              value="articles"
              className="tab"
              label="Articles"
              onClick={() => setCurrentTab('articles')}
              disabled={!isAuthorized(undefined, [Permissions.articles])}
              {...a11yProps(7)}
            />
            <Tab
              to="users"
              component={Link}
              value="users"
              className="tab"
              label="Users"
              onClick={() => setCurrentTab('users')}
              disabled={!isAuthorized(undefined, [Permissions.users])}
              {...a11yProps(8)}
            />
            <Tab
              to="gift-cards"
              component={Link}
              value="gift-cards"
              className="tab"
              label="Gift Cards"
              onClick={() => setCurrentTab('gift-cards')}
              disabled={!isAuthorized(undefined, [Permissions.generalInformation])}
              {...a11yProps(7)}
            />
            <Tab
              to="plugins"
              component={Link}
              value="plugins"
              className="tab"
              label="Plugins"
              onClick={() => setCurrentTab('plugins')}
              disabled={!isAuthorized(undefined, [Permissions.generalInformation])}
              {...a11yProps(7)}
            />

          </Tabs>
        </Grid>

      </Grid>
    </div>
  );
};
const Loading = () => <Grid container justifyContent="center" alignItems="center" sx={{ margin: 'auto', height: '80vh' }}><CircularProgress /></Grid>;

const EntAccountEdit = () => {
  const stateLoading = useSelector(selectStateLoader);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { open, message, success } = useSelector(selectSnack);
  const handleClose = () => {
    dispatch(coreSliceActions.closeSnackbar());
  };
  React.useEffect(() => {
    dispatch(editEntAccActions.setId(id as string));
    dispatch(editEntAccActions.getEntAcc());
  }, []);

  return (
    <div>
      <TopBar />
      <PrimarySnackbar open={open} message={message} severity={success ? 'success' : 'error'} handleClose={handleClose} />
      {stateLoading ? <Grid container justifyContent="center" alignItems="center" sx={{ margin: 'auto', height: '80vh' }}><CircularProgress /></Grid> : (
        <>
          <TopContent />
          <React.Suspense fallback={<Loading />}>
            <Routes>
              <Route
                path="/"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.generalInformation]}>
                    <EntGeneralInformation />
                  </AuthorizationWrapper>
)}
              />
              <Route
                path="/general-information/*"
                element={(
                  <EntGeneralInformation />
)}
              />
              <Route
                path="/user-allocation/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.generalInformation]}>
                    <EntUserAllocation />
                  </AuthorizationWrapper>
)}
              />
              <Route
                path="/site-preference/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.generalInformation]}>
                    <EntSitePreferences />
                  </AuthorizationWrapper>
)}
              />
              <Route
                path="/content/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.generalInformation]}>
                    <EntContent />
                  </AuthorizationWrapper>
             )}
              />
              <Route
                path="/payments/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.generalInformation]}>
                    <EntCharges />
                  </AuthorizationWrapper>
)}
              />
              <Route
                path="/admins/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.admins]}>
                    <EntAdmins />
                  </AuthorizationWrapper>
             )}
              />
              <Route
                path="/experts/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.experts]}>
                    <EntExperts />
                  </AuthorizationWrapper>
             )}
              />
              <Route
                path="/groups/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.groups]}>
                    <EntGroups />
                  </AuthorizationWrapper>
             )}
              />
              <Route
                path="/articles/*"
                element={(
                  <AuthorizationWrapper
                    permissions={[Permissions.articles, Permissions.moderateArticles]}
                  >
                    <EntArticles />
                  </AuthorizationWrapper>
)}
              />
              <Route
                path="/users/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.users]}>
                    <EntUsers />
                  </AuthorizationWrapper>
)}
              />

              <Route
                path="/gift-cards/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.generalInformation]}>
                    <EntGiftCards />
                  </AuthorizationWrapper>
)}
              />

              <Route
                path="/plugins/*"
                element={(
                  <AuthorizationWrapper permissions={[Permissions.generalInformation]}>
                    <EntPlugins />
                  </AuthorizationWrapper>
                )}
              />

            </Routes>
          </React.Suspense>
        </>
      )}
    </div>

  );
};

export default EntAccountEdit;
